import React, { Fragment, useState } from 'react';
import { Global, css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { formatIntNumber, formatPercent } from '../../../../libs/format';
import HighChart from '../../../molecules/HighChart';
import { CheckBoxIcon } from '../../../atoms/Icon';
import BlurOpacityWrapper from '../../../molecules/BlurOpacityWrapper';
import { getGrowthArrow, getGrowthPrefix } from '../../AnalyticsShared/utils';
import { EngagementDataType } from '../../AnalyticsShared/Overview/helpers';
import * as Styled from '../../AnalyticsShared/Overview/StyledComponents';
import { InstagramInteractionOverview } from './__generated__/InstagramInteractionOverview';
import { getOptions, defaultSelectedCharts, colors, allInteractionMetrics } from './interactionHelpers';

const InteractionOverview = ({ instagramInteractionOverview }: InstagramInteractionOverview) => {
  const { t } = useTranslation();

  const [selectedCharts, setSelectedCharts] = useState<typeof defaultSelectedCharts>(defaultSelectedCharts);
  const { options, engagement } = instagramInteractionOverview
    ? getOptions(selectedCharts, instagramInteractionOverview)
    : { options: [] as Highcharts.Options, engagement: [] as EngagementDataType[] };

  const toggleSelectedCharts = (isSelected: boolean, name: typeof defaultSelectedCharts[0]) => {
    if (isSelected) {
      setSelectedCharts(selectedCharts.filter(selectedItem => selectedItem !== name));
    } else {
      setSelectedCharts([...selectedCharts, name]);
    }
  };

  const noData = engagement.every(item => !item.rate);

  return (
    <BlurOpacityWrapper header="" dataNotAvailable={noData}>
      <Styled.OverviewWrapper>
        <Styled.Engagement>
          {engagement.map(item => {
            const isPositiveGrowth = item.growth === 0 ? undefined : item.growth > 0;
            const prefix = getGrowthPrefix(item.growth) || '';
            const arrow = getGrowthArrow(item.growth);

            return (
              <Fragment key={item.title}>
                <Styled.EngagementTile>
                  <Styled.PartWithIcon>
                    <Styled.LeftPart>
                      <Styled.EngagementTitle>{t(item.title)}</Styled.EngagementTitle>
                      <Styled.TotalNumber>{formatIntNumber(item.total)}</Styled.TotalNumber>
                    </Styled.LeftPart>
                    <Styled.EngagementTileIcon src={item.icon} />
                  </Styled.PartWithIcon>
                  <Styled.Growth isPositive={isPositiveGrowth}>{`${prefix}${formatIntNumber(
                    item.growth
                  )}  ${arrow}  (${formatPercent(item.rate)})`}</Styled.Growth>
                </Styled.EngagementTile>
              </Fragment>
            );
          })}
        </Styled.Engagement>
        <Styled.Chart>
          <Styled.LegendBar>
            {allInteractionMetrics.map(item => {
              const isChecked = selectedCharts.includes(item);
              const color = colors[item];

              return (
                <Styled.LegendTileColored key={item} onClick={() => toggleSelectedCharts(isChecked, item)}>
                  <CheckBoxIcon checked={isChecked} />
                  <Styled.Text>{t(item)}</Styled.Text>
                  <span style={{ background: color }} />
                </Styled.LegendTileColored>
              );
            })}
          </Styled.LegendBar>
          <Global
            styles={css`
              .highcharts-tooltip path {
                fill: #27313b;
              }

              .highcharts-root {
                padding-left: 4px;
              }
            `}
          />
          <HighChart options={options} />
        </Styled.Chart>
      </Styled.OverviewWrapper>
    </BlurOpacityWrapper>
  );
};

export default InteractionOverview;
