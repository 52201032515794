import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import css from '@emotion/css';
import { CircularProgress } from '@material-ui/core';
import chainIcon from '../../../assets/icon/chain.svg';
import checkIcon from '../../../assets/icon/checkIcon.svg';
import { SocialAccountType } from '../../../__generated__/globalTypes';
import { SocialMediaColumn } from '../../atoms/List';
import { switchImageWhite, switchLinkText, switchConnectSocialAccountBackground } from '../../../libs/SocialMedia';

interface IgSignInProps {
  className?: string;
  onClick: () => void;
  socialMedia: SocialAccountType;
  disabled?: boolean;
  loading?: boolean;
}
export const SocialMediaSignInButton = ({ className, onClick, loading, socialMedia }: IgSignInProps) => {
  const { t } = useTranslation();
  const smIcon = switchImageWhite(socialMedia);
  const btnCss = switchConnectSocialAccountBackground(socialMedia);
  const smTitle = switchLinkText(socialMedia);

  return (
    <IgColorButton className={className} type="button" onClick={onClick} disabled={!!loading} css={btnCss}>
      {loading ? (
        <CircularProgress
          size={20}
          thickness={2}
          css={css`
            color: #e0e8ed;
          `}
        />
      ) : (
        <Image src={smIcon} alt={`${socialMedia} icon`} />
      )}
      <Text>{t(`Sign in with ${smTitle}`)}</Text>
    </IgColorButton>
  );
};

interface ConnectedIgAndFbAccountsProps {
  igAccountName: string;
  fbAccountName: string;
  className?: string;
  reconnectSuccess?: boolean;
}
export const ConnectedIgAndFbAccounts = ({
  igAccountName,
  fbAccountName,
  className,
  reconnectSuccess
}: ConnectedIgAndFbAccountsProps) => (
  <AccountWrapper className={className}>
    <StyledSocialMedia socialMedia={SocialAccountType.INSTAGRAM} accountName={igAccountName} isTable={false} />
    <ChainIcon src={chainIcon} />
    <StyledSocialMedia socialMedia={SocialAccountType.FACEBOOK} accountName={fbAccountName} isTable={false} />
    {reconnectSuccess && <GreenCheckMark src={checkIcon} />}
  </AccountWrapper>
);

interface ReconnectButtonType {
  onClick: () => void;
  type?: 'submit' | 'reset' | 'button';
  socialMedia?: SocialAccountType;
}
export const ReconnectButton: React.FC<ReconnectButtonType> = ({
  onClick,
  children,
  type = 'button',
  socialMedia = SocialAccountType.INSTAGRAM
}) => {
  const btnCss = switchConnectSocialAccountBackground(socialMedia);

  return (
    <StyledReconnectButton onClick={onClick} type={type} css={btnCss}>
      {children}
    </StyledReconnectButton>
  );
};

export const IgColorButton = styled.button`
  display: flex;
  width: 197px;
  height: 32px;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    cursor: unset;
  }
`;
const Image = styled.img`
  width: 16px;
  height: 16px;
  padding: 0 16px;
`;
const Text = styled.span`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #fff;
`;
export const AccountWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledSocialMedia = styled(SocialMediaColumn)`
  padding-left: 8px;

  img {
    margin: 0;
    padding-right: 8px;
  }
`;
export const ChainIcon = styled.img`
  padding-right: 4px;
`;
export const StyledReconnectButton = styled(IgColorButton)`
  width: max-content;
  padding: 0 16px;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  align-items: center;
`;
export const GreenCheckMark = styled.img`
  margin-left: auto;
`;
