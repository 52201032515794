import React from 'react';
import { useUpdateDocumentTitle, useInstagramAnalyticsHeader } from '../../libs/hooks';
import { usePaging } from '../../libs/paging';
import { useSort } from '../../libs/filter';
import { getUserAnalysisSortFiledType } from '../../components/organisms/AnalyticsInteraction/helpers';
import { useFilter } from '../../components/organisms/AnalyticsShared/BasePostListFilter/useFilter';
import { InstagramInteractionUserSortField } from '../../__generated__/globalTypes';
import Template from './template';

const UserAnalysisPage = () => {
  const {
    analyticsAccountInfo: { accountName, id }
  } = useInstagramAnalyticsHeader();
  useUpdateDocumentTitle({
    title: !!accountName ? 'documentTitle.UserAnalysisListWithAccountName' : 'documentTitle.User Analysis List',
    interpolation: {
      title: accountName
    },
    appHeader: !!accountName ? 'appHeader.UserAnalysisListWithAccountName' : 'appHeader.User Analysis List',
    href: `/analytics/dashboard/${id}/interaction`
  });

  const currentPage = usePaging();
  const { filter, setFilter } = useFilter();
  const { sort, setSort } = useSort<InstagramInteractionUserSortField>(getUserAnalysisSortFiledType);

  return (
    <Template sort={sort} setSort={setSort} currentPage={currentPage} filter={filter} setFilter={setFilter} id={id} />
  );
};

export default UserAnalysisPage;
