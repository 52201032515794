import styled from '@emotion/styled';

export const Form = styled.form`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 428px;
  padding: 48px 40px;
  border-radius: 3px;
  color: #27313b;
`;
export const Title = styled.h1`
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
`;
export const Annotation = styled.p`
  padding: 16px 0 22px;
  color: #757575;
  text-align: center;
  line-height: 1.38;
`;
export const Confirmation = styled.p`
  padding-top: 16px;
  color: #6e7c89;
  font-size: 11px;
  line-height: 1.18;
`;
export const FlexRow = styled.div`
  display: flex;
  width: 100%;
`;
export const FlexRowWithMargin = styled(FlexRow)`
  margin-bottom: 16px;
`;
export const LeftWrapper = styled.div`
  flex: 1;
  padding-right: 8px;
`;
export const RightWrapper = styled.div`
  flex: 1;
  padding-left: 8px;
`;
