import React from 'react';
import HighChart, { Highcharts } from '../../../molecules/HighChart';

export interface AgeGenderDemographicChartProps {
  name: string;
  ageGenderRate: {
    maleRate: number[] | null;
    femaleRate: number[] | null;
  };
  ageGroup: string[];
}

const AgeGenderDemographicChart = (props: AgeGenderDemographicChartProps) => {
  const {
    ageGenderRate: { maleRate, femaleRate },
    ageGroup
  } = props;

  const options: Highcharts.Options = {
    chart: {
      type: 'bar',
      height: 220
    },
    title: {
      text: '',
      style: {
        fontSize: '13px',
        fontWeight: '600',
        fontFamily: 'Inter'
      },
      margin: 16
    },
    xAxis: [
      {
        categories: ageGroup,
        reversed: false,
        labels: {
          step: 1
        }
      },
      {
        opposite: true,
        reversed: false,
        categories: ageGroup,
        linkedTo: 0,
        labels: {
          step: 1,
          enabled: false
        }
      }
    ],
    yAxis: {
      title: {
        text: null
      },
      labels: {
        enabled: false
      }
    },
    plotOptions: {
      series: {
        stacking: 'normal'
      }
    },
    legend: {
      enabled: false
    },
    tooltip: {
      padding: 0,
      formatter: function formatter(): string {
        return `
            <div style="border-radius:3px;border: 2px solid #27313b;margin:-2px;background-color:#27313b;font-size:12px;padding:13px 16px;display:flex;align-items:center">
              <span style="display:inline-block;margin-right:8px;background-color:${
                this.point.color
              };height:12px;width:12px"></span>
              <span style="margin-right:16px">${this.series.name} ${this.key}
              </span><span>${Highcharts.numberFormat(Math.abs(this.point.y || 0), 1)}%</span>
            </div>`;
      },
      pointFormat: '',
      borderColor: '#27313b',
      borderWidth: 0,
      backgroundColor: '#27313b',
      style: {
        color: '#ffffff'
      },
      useHTML: true,
      headerFormat: ''
    },
    series: [
      {
        name: 'Female',
        // @ts-ignore
        data: femaleRate.map(item => item * -100),
        color: '#ff8a94'
      },
      {
        name: 'Male',
        // @ts-ignore
        data: maleRate.map(item => item * 100),
        color: '#707fc0'
      }
    ]
  };

  return <HighChart options={options} />;
};

export default AgeGenderDemographicChart;
