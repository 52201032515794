import { useQuery } from '@apollo/client';
import { LIMIT } from '../../../../libs/paging/constant';
import { getOffset } from '../../../../libs/paging';
import { getPageInfo } from '../../../../libs/paging';
import * as ALL_ACCOUNTS_ANALYTICS_ADMIN from './AccountListAnalyticsForAdmin.graphql';
import * as ALL_ACCOUNTS_ANALYTICS_ADVERTISER from './AccountListAnalyticsForAdvertiser.graphql';
import {
  AccountListAnalyticsForAdminV2,
  AccountListAnalyticsForAdminV2Variables
} from './__generated__/AccountListAnalyticsForAdminV2';
import {
  AccountListAnalyticsForAdvertiserV2,
  AccountListAnalyticsForAdvertiserV2Variables
} from './__generated__/AccountListAnalyticsForAdvertiserV2';
import { AdvertiserListProps } from './types';

interface UseAnalyticsProps extends AdvertiserListProps {
  isAdminOrAgencyUser: boolean;
}
export const useAnalyticsData = ({
  adminFilter,
  advertiserFilter,
  currentPage,
  isAdminOrAgencyUser
}: UseAnalyticsProps) => {
  const adminAnalyticsData = useQuery<AccountListAnalyticsForAdminV2, AccountListAnalyticsForAdminV2Variables>(
    ALL_ACCOUNTS_ANALYTICS_ADMIN,
    {
      variables: {
        offset: getOffset(currentPage),
        limit: LIMIT,
        keyword: adminFilter.keyword,
        subscriptionPlan: adminFilter.subscriptionPlan || null,
        countryId: adminFilter.countryId || null,
        ...(adminFilter.advertiserId && { advertiserId: Number(adminFilter.advertiserId) })
      },
      skip: !isAdminOrAgencyUser,
      fetchPolicy: 'cache-and-network'
    }
  );
  const advertiserAnalyticsData = useQuery<
    AccountListAnalyticsForAdvertiserV2,
    AccountListAnalyticsForAdvertiserV2Variables
  >(ALL_ACCOUNTS_ANALYTICS_ADVERTISER, {
    variables: {
      keyword: advertiserFilter.keyword,
      subscriptionPlan: advertiserFilter.subscriptionPlan || null
    },
    skip: isAdminOrAgencyUser,
    fetchPolicy: 'cache-and-network'
  });

  const analyticsAccountsAdmin = adminAnalyticsData.data?.allAccountAnalyticsForAdminV2?.accounts || [];
  const analyticsAccountsAdvertiser = advertiserAnalyticsData.data?.allAccountAnalyticsForAdvertiserV2?.accounts || [];
  const pageInfo = getPageInfo(currentPage, adminAnalyticsData.data);

  return {
    loading: adminAnalyticsData.loading || advertiserAnalyticsData.loading,
    analyticsAccountsAdmin,
    analyticsAccountsAdvertiser,
    pageInfo
  };
};
