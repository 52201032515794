import styled from '@emotion/styled';

export const OverviewWrapper = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px #dee5ec80;
  background-color: #fff;
  color: #27313b;
`;
export const Engagement = styled.div<{}>`
  display: flex;
  flex-wrap: wrap;
`;
export const EngagementTile = styled.div<{ hasSecondRow?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px;
  border-right: 1px solid #dee5ec;
  flex: 1 1 calc(16% - 32px);

  &:last-of-type,
  :nth-of-type(6n) {
    border-right: none;
  }

  ${({ hasSecondRow }) =>
    hasSecondRow
      ? `
  &:nth-of-type(n - 6) {
    border-bottom: 1px solid #dee5ec;
  }
  `
      : 'border-bottom: 1px solid #dee5ec'}
`;
export const EngagementTileIcon = styled.img`
  width: 48px;
  height: 48px;

  /* we have to position icon & ignoring padding */
  margin: -16px;
`;
export const EngagementTitle = styled.h3`
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  padding-bottom: 4px;
`;
export const TotalNumber = styled.span`
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.3px;
  padding-bottom: 4px;
`;
export const Growth = styled.div<{ isPositive?: boolean }>`
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${({ isPositive }) => (isPositive === undefined ? 'inherit' : isPositive ? '#40b87c' : '#ff2b52')};
  white-space: pre-wrap;
`;
export const PartWithIcon = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const LeftPart = styled.div`
  display: flex;
  flex-flow: column wrap;
`;
export const Chart = styled.div``;
export const LegendBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 12px;
`;
export const LegendTileColored = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  margin-right: 8px;
  border-radius: 3px;
  border: solid 1px #dee5ec;
  background-color: #f6f8fa;
  cursor: pointer;
  margin-bottom: 8px;

  span {
    width: 8px;
    height: 8px;
    border-radius: 2px;
  }
`;
export const Text = styled.p`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #27313b;
  padding: 0 4px;
`;
export const LegendTile = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  margin-right: 8px;
  cursor: pointer;
`;
