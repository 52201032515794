import React from 'react';
import css from '@emotion/css';
import { formatIntNumber } from '../../../../libs/format';
import { SliderComponents, SliderCss, StyledComponents } from '../../../molecules/SliderTable';
import * as List from '../../../atoms/List';
import CustomCss from './CustomCss';
import { InstagramComparePosts_instagramComparePosts_compareAccounts_popularHashtags as PopularHashtagsType } from './__generated__/InstagramComparePosts';

interface HashtagDialogContentType {
  hashtagsData: PopularHashtagsType[];
}
const HashtagDialogContent = ({ hashtagsData }: HashtagDialogContentType) => (
  <SliderComponents.Wrapper
    css={[SliderCss.clearPadding, CustomCss.clearMargin, CustomCss.marginTop24, CustomCss.htDialogWidth]}
  >
    <SliderComponents.Container
      css={css`
        overflow-x: hidden;
      `}
    >
      <SliderComponents.SliderSection>
        <SliderComponents.SliderTable>
          <thead>
            <SliderComponents.HeaderRow css={[SliderCss.bgndColor]}>
              <StyledComponents.StyledHeaderColumn
                title="Hash Tag"
                textAlign="left"
                css={[CustomCss.customHeaderStyle, CustomCss.paddingLeft8]}
              />
              <StyledComponents.StyledHeaderColumn
                title="Used Post"
                css={[CustomCss.customHeaderStyle, SliderCss.twoLineHeader, CustomCss.paddingLeft8]}
              />
              <StyledComponents.StyledHeaderColumn
                title="Ave like"
                css={[CustomCss.customHeaderStyle, CustomCss.paddingLeft8]}
              />
              <StyledComponents.StyledHeaderColumn
                title="Ave Comment"
                css={[CustomCss.customHeaderStyle, SliderCss.twoLineHeader, CustomCss.paddingLeft8]}
              />
              <StyledComponents.StyledHeaderColumn
                title="Ave Eng"
                css={[CustomCss.customHeaderStyle, CustomCss.paddingLeft8]}
              />
            </SliderComponents.HeaderRow>
          </thead>

          <tbody>
            {hashtagsData.map((item, index) => (
              <StyledComponents.StyledRowNew css={[CustomCss.hashtagTableCol]} key={index}>
                <List.TextColumn data={`${item.hashtag || 0}`} css={CustomCss.overflowText} />
                <List.TextColumn data={`${item.usedPosts || 0}`} />
                <List.TextColumn data={formatIntNumber(item.averageLikes)} />
                <List.TextColumn data={`${item.averageComments || 0}`} />
                <List.TextColumn data={`${item.averageEngagement || 0}`} />
              </StyledComponents.StyledRowNew>
            ))}
          </tbody>
        </SliderComponents.SliderTable>
      </SliderComponents.SliderSection>
    </SliderComponents.Container>
  </SliderComponents.Wrapper>
);

export default HashtagDialogContent;
