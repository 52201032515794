import styled from '@emotion/styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { MainNavbarWidth } from '../../../libs/globalCss';

const styles = createStyles({
  progress: {
    color: '#e0e8ed',
    marginRight: MainNavbarWidth
  }
});

const PageLoading = (props: WithStyles<typeof styles>) => {
  const { classes } = props;

  return (
    <Wrapper>
      <CircularProgress className={classes.progress} size={64} thickness={2.4} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 190px;
  z-index: 1000;
  background-color: #fff;
`;

export default withStyles(styles)(PageLoading);
