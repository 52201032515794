import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Highcharts } from '../../../molecules/HighChart';
import { formatNumber } from '../../../../libs/format';
import { getGrowthArrow, getGrowthPrefix } from '../../AnalyticsShared/utils';
import {
  formatLineChartDateCount,
  generateYAxisBasedOnDisplayedCharts,
  TooltipDataType,
  formatDate,
  formatDateCount,
  getEngagementData
} from '../../AnalyticsShared/Overview/helpers';
import {
  InstagramHashtagsOverview_instagramHashtagsOverview,
  InstagramHashtagsOverview_instagramHashtagsOverview_graph_hashtags as HashtagsHistoryDataType
} from './__generated__/InstagramHashtagsOverview';
import { HashtagsDropdownType } from './HashtagDropdownsSection';

// For Compare page we are only select Followers
export const allHashtagCharts: HashtagChartSeriesTypes[] = ['Follower', 'Post', 'Like', 'Comment'];

export type HashtagChartSeriesTypes = 'Follower' | 'Post' | 'Like' | 'Comment';
export const HIDDEN_KEY = '_EMPTY_';

export const hashtagFollowersColor = '#3892e5';
export const hashtagColors: Record<number, string> = {
  0: '#40b87c',
  1: '#ff2b52',
  2: '#966acc',
  3: '#ffbe82',
  4: '#aec0e4'
} as const;

export interface HastagInfo {
  label: string;
  value: string;
}

interface SeriesType {
  name: string;
  type: string;
  hashtag?: string;
  data: number[];
  color: string;
}
const getSerieDayData = (index: number, series: SeriesType[]) =>
  series.reduce(
    (acc, serie) => ({
      ...acc,
      [serie.name as HashtagChartSeriesTypes]: {
        // @ts-ignore not sure how to fix this
        value: formatNumber(serie.data[index] || null),
        // currenlty we just show difference between prev and curr values as increment
        increment: serie.name === 'Follower' && index !== 0 ? serie.data[index] - serie.data[index - 1] : 0
      }
    }),
    {}
  );

export const getDailyDataForTooltip = (
  categories: string[],
  series: SeriesType[]
): TooltipDataType<HashtagChartSeriesTypes> =>
  categories.reduce(
    (acc, day, index) => ({
      ...acc,
      [day]: getSerieDayData(index, series)
    }),
    {}
  );

interface EngagementType {
  total: number | null;
  growth: number | null;
  rate: number | null;
}
export interface EngagementDataType {
  comments: EngagementType;
  hashtags: { id: number; name: string };
  likes: EngagementType;
  posts: EngagementType;
}

interface SeriesAndCategoriesType {
  followersHistory: SeriesHistoryType[];
  hashtagsHistoryData: Array<HashtagsHistoryDataType & { hashtag: string; color: string }>;
  displayedCharts: HashtagChartSeriesTypes[];
}
export const getSeriesAndCategories = ({
  followersHistory,
  hashtagsHistoryData,
  displayedCharts
}: SeriesAndCategoriesType) => {
  const { count: followersCount, date: followersCategories } = formatLineChartDateCount(followersHistory);
  const dateCategories = followersCategories;
  const { series, tooltipData } = hashtagsHistoryData.reduce(
    (
      acc: {
        series: any[];
        tooltipData: any;
      },
      { posts: postsHistory, likes: likesHistory, comments: commentsHistory, color, hashtag }
    ) => {
      const { count: postsCount } = formatDateCount(followersHistory, postsHistory);
      const { count: likesCount } = formatDateCount(followersHistory, likesHistory);
      const { count: commentsCount } = formatDateCount(followersHistory, commentsHistory);
      const hashtagTooltipData = [
        {
          name: 'Post',
          hashtag,
          type: 'column',
          data: postsCount,
          color,
          yAxis: 1,
          visible: displayedCharts.includes('Post')
        },
        {
          name: 'Like',
          hashtag,
          type: 'column',
          data: likesCount,
          color,
          yAxis: 2,
          visible: displayedCharts.includes('Like')
        },
        {
          name: 'Comment',
          hashtag,
          type: 'column',
          data: commentsCount,
          color,
          yAxis: 3,
          visible: displayedCharts.includes('Comment')
        }
      ];

      return {
        series: [...acc.series, ...hashtagTooltipData],
        tooltipData: {
          ...acc.tooltipData,
          [color]: getDailyDataForTooltip(dateCategories, hashtagTooltipData)
        }
      };
    },
    {
      series: [
        {
          name: 'Follower',
          type: 'line',
          data: followersCount,
          color: hashtagFollowersColor,
          marker: {
            symbol: 'circle',
            lineWidth: 2,
            lineColor: undefined, // inherit from chart
            fillColor: '#fff'
          },
          visible: displayedCharts.includes('Follower'),
          zIndex: 1
        }
      ],
      tooltipData: {
        [hashtagFollowersColor]: getDailyDataForTooltip(dateCategories, [
          {
            name: 'Follower',
            type: 'line',
            data: followersCount,
            color: hashtagFollowersColor
          }
        ])
      }
    }
  );

  return {
    series: series as Highcharts.SeriesOptionsType[],
    categories: dateCategories,
    tooltipData
  };
};

interface SeriesHistoryType {
  count: number | null;
  date: any | null;
}
export const getOptions = (
  displayedCharts: HashtagChartSeriesTypes[],
  instagramHashtagsOverview: InstagramHashtagsOverview_instagramHashtagsOverview,
  selectedHashtags: (HashtagsDropdownType | null)[]
): {
  options: Highcharts.Options;
  engagement: EngagementDataType[];
} => {
  const { t } = useTranslation();
  const igHashtagsData = instagramHashtagsOverview.graph.hashtags;
  // filter hastagsHistoryData and add color from dropdown to use in bar-charts
  const hashtagsHistoryData = selectedHashtags.flatMap((ht, index) => {
    const data = igHashtagsData.find(ig => ig.id === ht?.value);

    return data && ht
      ? {
          ...data,
          hashtag: ht.label,
          color: hashtagColors[index]
        }
      : [];
  });

  const followersHistory = instagramHashtagsOverview.graph.followers;

  // we are showing X-Axis dates based on displayed charts checkboxes
  const { series, categories, tooltipData } = getSeriesAndCategories({
    followersHistory,
    hashtagsHistoryData,
    displayedCharts
  });

  const yAxis = generateYAxisBasedOnDisplayedCharts<HashtagChartSeriesTypes>(allHashtagCharts, false);
  const engagement = instagramHashtagsOverview.summaries
    .filter(summ => selectedHashtags.find(e => e?.value === summ.hashtag.id))
    .map(summ => ({
      posts: getEngagementData(summ.posts),
      likes: getEngagementData(summ.likes),
      comments: getEngagementData(summ.comments),
      hashtags: { id: summ.hashtag.id, name: summ.hashtag.hashTag }
    }));

  return {
    engagement,
    options: {
      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      xAxis: {
        // @ts-ignore -> categories doesnt exist on xAxis
        categories,
        crosshair: true,
        type: 'category',
        labels: {
          formatter: function formatter() {
            return formatDate(`${this.value}`);
          }
        }
      },
      yAxis,
      legend: {
        enabled: false
      },
      tooltip: {
        padding: 0,
        useHTML: true,
        borderColor: '#27313b',
        borderWidth: 0,
        borderRadius: 3,
        headerFormat: '',
        formatter: function formatter(): string {
          // @ts-ignore Highchart color has some specific type
          const currentTooltipData = tooltipData?.[this.color];
          if (!currentTooltipData) {
            return '';
          }
          const hashtagName =
            (this.series as Highcharts.Series & { userOptions: SeriesType }).userOptions.hashtag || '';

          return this.series.name === 'Follower'
            ? tooltipForFollower(hashtagName, this, currentTooltipData, t)
            : tooltipForHastag(hashtagName, this, currentTooltipData, t);
        },
        pointFormat: ''
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series
    }
  };
};

export const tooltipForHastag = (
  name: string,
  formatterContext: Highcharts.TooltipFormatterContextObject,
  currentTooltipData: any,
  t: TFunction
) => `<div style="display:flex;flex-direction:column;border-radius:3px;border: 2px solid #27313b;margin:-2px;background-color:#27313b;font-size:12px;padding:13px 16px;">
    ${!!name &&
      `<span style="padding-bottom: 12px; font-size: 13px; font-weight: 600; color: #fff;">
        ${name}
      </span>`}
    <h3 style="margin-bottom:8px; font-size: 13px; color: #fff;">${formatterContext.key}</h3>
    <div style="display:flex; flex-direction: column;">
      <div style="display: flex;">
        <div style="display: flex; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px; padding-right: 4px;">
          <div>
            <span style="color: #c5d0da; font-size: 11px;">${t('Post')}</span>
            <br />
            <p style="font-size: 13px; color: #fff;">
              ${currentTooltipData[formatterContext.key].Post?.value || '-'}
            </p>
          </div>
        </div>

        <div style="display: flex; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px; padding-right: 4px;">
        <div>
          <span style="color: #c5d0da; font-size: 11px;">${t('Like')}</span>
          <br />
          <p style="font-size: 13px; color: #fff;">
            ${currentTooltipData[formatterContext.key].Like?.value || '-'}
          </p>
        </div>
      </div>
      </div>

      <div style="display: flex; margin-top: 12px;">
        <div style="display: flex; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
          <div>
            <span style="color: #c5d0da; font-size: 11px;">${t('Comment')}</span>
            <br />
            <p style="font-size: 13px; color: #fff;">
              ${currentTooltipData[formatterContext.key].Comment?.value || '-'}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>`;

export const tooltipForFollower = (
  name: string,
  formatterContext: Highcharts.TooltipFormatterContextObject,
  currentTooltipData: any,
  t: TFunction
) => {
  const followersIncrement = currentTooltipData[formatterContext.key].Follower.increment;
  const incrementArrow = getGrowthArrow(followersIncrement);
  const prefix = getGrowthPrefix(followersIncrement);

  const incrementSection = followersIncrement
    ? `<span style="color: ${
        followersIncrement > 0 ? '#40b87c' : '#ff2b52'
      };">${prefix} ${followersIncrement} ${incrementArrow}</span>`
    : '';

  return `
    <div style="display:flex;flex-direction:column;border-radius:3px;border: 2px solid #27313b;margin:-2px;background-color:#27313b;font-size:12px;padding:13px 16px;">
      ${!!name &&
        `<span style="padding-bottom: 12px; font-size: 13px; font-weight: 600; color: #fff;">
          ${name}
        </span>`}
      <h3 style="margin-bottom:8px; font-size: 13px; color: #fff;">${formatterContext.key}</h3>
      <div style="display:flex; flex-direction: column;">
        <div style="display: flex;">
          <div style="display: flex; width: max-content; padding-right: 16px; border-left: 1px solid #6e7c89; padding-left: 2px;">
            <div>
              <span style="color: #c5d0da; font-size: 11px;">${t('Follower')}</span>
              <br />
              <p style="font-size: 13px; color: #fff;">
                ${currentTooltipData[formatterContext.key].Follower.value}
                ${incrementSection}
              </p>
            </div>
          </div>
      </div>
    </div>`;
};

export const getHashtagsDropdownOptions = (hashtags: Record<number, string>, selectedHashtagIds: number[]) =>
  Object.keys(hashtags)
    .filter(htKey => selectedHashtagIds.includes(+htKey))
    .map(htKey => ({
      value: +htKey,
      label: hashtags[+htKey]
    }));
