import React, { useCallback } from 'react';
import { Redirect, useParams } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import Styled from '../StyledComponents';
import { useQueryHelper } from '../../../../libs/hooks';
import Template from '../TaggedPostDetail/template';
import * as INSTAGRAM_MENTIONED_POST_DETAILS from './InstagramInteractionMentionedPostDetails.graphql';
import {
  InstagramInteractionMentionedPostDetails,
  InstagramInteractionMentionedPostDetailsVariables
} from './__generated__/InstagramInteractionMentionedPostDetails';

interface AnayticsStoryPostDetailProps {
  closeModal: () => void;
}

const AnayticsMentionedPostDetail = (props: AnayticsStoryPostDetailProps) => {
  const { closeModal } = props;
  const { t, history, enqueueSnackbar } = useQueryHelper();
  const {
    location: { search, pathname }
  } = history;
  const { id } = useParams<{ id: string }>();
  const postId = Number(new URLSearchParams(search).get('popup'));

  const { data, loading, error } = useQuery<
    InstagramInteractionMentionedPostDetails,
    InstagramInteractionMentionedPostDetailsVariables
  >(INSTAGRAM_MENTIONED_POST_DETAILS, {
    variables: { postId, accountId: Number(id) },
    onError: useCallback(() => {
      history.push(pathname);
      closeModal();
      enqueueSnackbar(t('Post was not found'), { variant: 'error' });
    }, [])
  });

  if (loading) {
    return <CircularProgress size={64} thickness={2.4} color="inherit" />;
  }

  if (error) {
    return <Redirect to="/404" />;
  }

  return (
    <Styled.PostDetailWrapper>
      <Template closeModal={closeModal} postData={data?.instagramInteractionMentionedPostDetails} />
    </Styled.PostDetailWrapper>
  );
};

export default AnayticsMentionedPostDetail;
