import React from 'react';
import styled from '@emotion/styled';

interface RadioIconProps {
  isChecked: boolean;
  onClick: () => void;
  className?: string;
}
export const RadioIcon = (props: RadioIconProps) => {
  const { className, ...iconProps } = props;

  return (
    <Wrapper className={className}>
      <Icon {...iconProps} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const Icon = styled.div<RadioIconProps>`
  width: 16px;
  height: 16px;
  background-color: #fff;
  box-shadow: inset 1px 1px 2px 0 #0003;
  border-radius: 50%;
  border: ${({ isChecked }) => (isChecked ? 'solid 4px #3892e5' : 'solid 1px #c5d0da')};
  box-sizing: border-box;
  cursor: pointer;
`;
