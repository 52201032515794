import styled from '@emotion/styled';
import * as React from 'react';
import { useHistory, useParams } from 'react-router';
import PasswordForm from '../../components/organisms/PasswordForm';
import SignInSignUpHeader from '../../components/organisms/SignIn/SignInSignUpHeader';

const Template = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();

  if (!token) {
    history.push('/');

    return null;
  }

  return (
    <Container>
      <SignInHeaderWrapper />
      <PasswordForm token={token} />
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  background-color: #27313b;
  padding-top: 82px;
`;

const SignInHeaderWrapper = styled(SignInSignUpHeader)`
  padding-bottom: 24px;
  width: auto;
`;

export default Template;
