import React from 'react';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useProviderResponse } from '../helpers';
import { manageLocalStorageItem } from '../../../libs/localStorage';
import { getCallbackUrl } from '../../../libs/auth';
import { AnalyticsRedirectType, QuerySocialAccountType } from '../../../__generated__/globalTypes';
import PageLoading from '../../../components/molecules/PageLoading';
import * as RECONNECT_IG_ANALYTICS_ACCOUNT from '../SocialAuthReconnectInstagramAccountAnalytics.graphql';
import * as RECONNECT_YT_ANALYTICS_ACCOUNT from '../SocialAuthReconnectYouTubeAccountAnalytics.graphql';
import {
  SocialAuthReconnectInstagramAccountAnalytics,
  SocialAuthReconnectInstagramAccountAnalyticsVariables
} from '../__generated__/SocialAuthReconnectInstagramAccountAnalytics';
import {
  SocialAuthReconnectYouTubeAccountAnalytics,
  SocialAuthReconnectYouTubeAccountAnalyticsVariables
} from '../__generated__/SocialAuthReconnectYouTubeAccountAnalytics';
import { useQueryHelper } from '../../../libs/hooks';

const AnalyticsConnectRedirect = () => {
  const { t, history, enqueueSnackbar } = useQueryHelper();
  const { response, provider } = useProviderResponse();
  const analyticsAccountId = manageLocalStorageItem('get', 'analyticsIgAccountId');
  const businessAccountId = manageLocalStorageItem('get', 'businessAccountId');
  const analyticsAccountName = manageLocalStorageItem('get', 'analyticsIgAccountName');
  const fbPageId = manageLocalStorageItem('get', 'fbPageId');
  const channelId = manageLocalStorageItem('get', 'channelId') || '';
  const brandAccountId = manageLocalStorageItem('get', 'redirectBackRoute') || '';
  const pathname = `/analytics/${brandAccountId}`;

  const [reconnectIgAnalyticsAccount] = useMutation<
    SocialAuthReconnectInstagramAccountAnalytics,
    SocialAuthReconnectInstagramAccountAnalyticsVariables
  >(RECONNECT_IG_ANALYTICS_ACCOUNT);
  const [reconnectYtAnalyticsAccount] = useMutation<
    SocialAuthReconnectYouTubeAccountAnalytics,
    SocialAuthReconnectYouTubeAccountAnalyticsVariables
  >(RECONNECT_YT_ANALYTICS_ACCOUNT);

  const callReconnectAnalyticsAccount = async () => {
    try {
      if (provider === QuerySocialAccountType.FACEBOOK) {
        await reconnectIgAnalyticsAccount({
          variables: {
            input: {
              businessAccountId: String(businessAccountId),
              response,
              callbackUrl: getCallbackUrl(QuerySocialAccountType.FACEBOOK, AnalyticsRedirectType.ANALYTICS_CONNECT),
              redirectType: AnalyticsRedirectType.ANALYTICS_CONNECT,
              accountId: Number(analyticsAccountId),
              username: String(analyticsAccountName),
              fbPageId: fbPageId || null
            }
          }
        });
      } else if (provider === QuerySocialAccountType.YOUTUBE) {
        await reconnectYtAnalyticsAccount({
          variables: {
            input: {
              channelId,
              response,
              callbackUrl: getCallbackUrl(QuerySocialAccountType.YOUTUBE, AnalyticsRedirectType.ANALYTICS_CONNECT)
            }
          }
        });
      }
      enqueueSnackbar(t('Your account has been reconnected successfully'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('Fail to reconnect account, please try again later'), { variant: 'error' });
    } finally {
      history.push(pathname);
      manageLocalStorageItem('remove', 'analyticsIgAccountId');
      manageLocalStorageItem('remove', 'businessAccountId');
      manageLocalStorageItem('remove', 'redirectBackRoute');
    }
  };

  useEffect(() => {
    callReconnectAnalyticsAccount();
  }, []);

  return <PageLoading />;
};

export default AnalyticsConnectRedirect;
