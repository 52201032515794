import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { PageInfo } from '../../../../libs/paging';
import { SortActionType } from '../../../../libs/filter';
import { formatIntNumber, formatNumber } from '../../../../libs/format';
import { InstagramInteractionUserSortField, ORDER } from '../../../../__generated__/globalTypes';
import { TextColumn, HeaderColumn } from '../../../atoms/List';
import { SliderComponents, SliderCss, StyledComponents } from '../../../molecules/SliderTable';
import { AllInstagramInteractionUsers } from './__generated__/AllInstagramInteractionUsers';
import { getUserAnalysisColumns } from './helpers';

export type UserAnalysisSortAction = SortActionType<InstagramInteractionUserSortField>;

export interface UserAnalysisListProps extends UserAnalysisSortAction {
  pageInfo: PageInfo;
  userData?: AllInstagramInteractionUsers;
}

const UserAnalysisList = (props: UserAnalysisListProps) => {
  const {
    sort: { field, order },
    setSort,
    pageInfo: { firstIndex, lastIndex, totalCount, currentPage, totalPages },
    userData
  } = props;

  return (
    <>
      <PageCountWrapper>
        <SliderComponents.PageCount total={totalCount} countLabel="Users" />
      </PageCountWrapper>

      <SliderComponents.Wrapper css={[SliderCss.marginTop, SliderCss.clearPadding]}>
        <SliderComponents.Container>
          <SliderComponents.SliderSection>
            <SliderComponents.SliderTable>
              <thead>
                <SliderComponents.HeaderRow css={[SliderCss.bgndColor]}>
                  <SliderComponents.StyckyHeaderDivWrapper>
                    <StyledHeader
                      title="User Name"
                      css={css`
                        width: 230px;
                        padding: 0;
                      `}
                      isTable={false}
                    />
                  </SliderComponents.StyckyHeaderDivWrapper>
                  {/* Slider */}
                  {getUserAnalysisColumns().map(({ id, title, sort, customCss, help }) => (
                    <HeaderColumn
                      key={id}
                      title={title}
                      help={help}
                      activeAsc={field === sort && order === ORDER.ASC}
                      activeDesc={field === sort && order === ORDER.DESC}
                      sortAsc={() =>
                        setSort({
                          field: sort,
                          order: ORDER.ASC
                        })
                      }
                      sortDesc={() =>
                        setSort({
                          field: sort,
                          order: ORDER.DESC
                        })
                      }
                      css={customCss}
                    />
                  ))}
                </SliderComponents.HeaderRow>
              </thead>

              <tbody>
                {userData?.allInstagramInteractionUsers?.usernames?.map((item, index) => {
                  const {
                    username,
                    avgMentionedComments,
                    avgMentionedLikes,
                    avgTaggedComments,
                    avgTaggedLikes,
                    avgTotalComments,
                    avgTotalLikes,
                    mentionedPosts,
                    taggedPosts,
                    totalPostsCount
                  } = item;

                  return (
                    <StyledComponents.StyledRowNew css={[SliderCss.paddingSides, SliderCss.rowHeight40]} key={index}>
                      <SliderComponents.StickyBodyRowWrapper>
                        <Anchor target="_blank" rel="noreferrer" href={`https://www.instagram.com/${username}`}>
                          {username}
                        </Anchor>
                      </SliderComponents.StickyBodyRowWrapper>
                      <TextColumn
                        data={formatIntNumber(totalPostsCount)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                      <TextColumn
                        data={formatIntNumber(taggedPosts)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                      <TextColumn
                        data={formatIntNumber(mentionedPosts)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                      <TextColumn
                        data={formatNumber(avgTotalLikes, 2)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                      <TextColumn
                        data={formatNumber(avgTaggedLikes, 2)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                      <TextColumn
                        data={formatNumber(avgMentionedLikes, 2)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                      <TextColumn
                        data={formatNumber(avgTotalComments, 2)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                      <TextColumn
                        data={formatNumber(avgTaggedComments, 2)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                      <TextColumn
                        data={formatNumber(avgMentionedComments, 2)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                    </StyledComponents.StyledRowNew>
                  );
                })}
              </tbody>
            </SliderComponents.SliderTable>
          </SliderComponents.SliderSection>
        </SliderComponents.Container>
      </SliderComponents.Wrapper>
      <SliderComponents.Pager
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        first={firstIndex}
        last={lastIndex}
      />
    </>
  );
};

const StyledHeader = styled(HeaderColumn)`
  & > div {
    justify-content: flex-start;
  }
`;
const PageCountWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 24px 0;
  padding: 15px 16px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
  border-top: none;
  border-bottom: none;
`;

const Anchor = styled.a`
  color: #3892e5;
  font-weight: normal;

  &:hover {
    text-decoration: underline;
  }
`;
export default UserAnalysisList;
