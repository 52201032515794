import * as yup from 'yup';
import {
  AdvertiserSchema,
  BudgetSchema,
  CategoriesSchema,
  GendersSchema,
  MaxAgeSchema,
  MaxFollowersSchema,
  MinAgeSchema,
  MinFollowersSchema,
  PeriodSchema,
  PositiveNumberSchema,
  ServiceInformationSchema,
  TitleSchema
} from './share';

const SocialMediasSchema = yup
  .array()
  .of(yup.string())
  .required('requiredSocialMediaMessage');
const ObjectectiveSchema = yup.string().required('requiredRequirementMessage');
const ServiceUrlSchema = yup.string().url('invalidUrlMessage');
const NumberOfInfluencersSchema = PositiveNumberSchema.required('requiredNumberOfInfluencersMessage');
const ThumbnailSchema = yup.array();
export const CurrencySchema = yup.string().when('countryIds', {
  is: (countryIds: string[]) => countryIds.length > 1,
  then: yup.string().required('requiredCurrencyMessage')
});

export const CountriesSchema = yup
  .array()
  .of(yup.string().required('requiredCountryMessage'))
  .required('requiredCountryMessage');

const SalesPicsSchema = yup.array().required('requiredSalesPicMessage');
const InfluencerManagementPicsSchema = yup.array().required('requiredInfluencerManagementPicMessage');
const TrackingMethodSchema = yup.string().when('trackClickAction', {
  is: (trackClickAction: boolean) => trackClickAction,
  then: yup.string().required('requiredTrackingMethodMessage')
});
const LandingPageUrlSchema = yup.string().when('trackClickAction', {
  is: (trackClickAction: boolean) => trackClickAction,
  then: yup.string().required('requiredLandingPageUrlMessage')
});

export default (isAdminStaff: boolean) =>
  yup.object().shape({
    advertiserId: AdvertiserSchema,
    title: TitleSchema,
    serviceInformation: ServiceInformationSchema,
    objective: ObjectectiveSchema,
    serviceUrl: ServiceUrlSchema,
    countryIds: CountriesSchema,
    socialMedias: SocialMediasSchema,
    categoryIds: CategoriesSchema,
    genders: GendersSchema,
    period: PeriodSchema,
    minAge: MinAgeSchema,
    maxAge: MaxAgeSchema,
    minFollowers: MinFollowersSchema,
    maxFollowers: MaxFollowersSchema,
    numberOfInfluencers: NumberOfInfluencersSchema,
    budget: BudgetSchema,
    thumbNail: ThumbnailSchema,
    currency: CurrencySchema,
    ...(isAdminStaff && { salesPics: SalesPicsSchema, influencerManagementPics: InfluencerManagementPicsSchema }),
    trackingMethod: TrackingMethodSchema,
    landingPageUrl: LandingPageUrlSchema
  });
