import React from 'react';
import { Redirect } from 'react-router';
import { checkSignedIn } from '../../libs/auth';
import { useUpdateDocumentTitle } from '../../libs/hooks';
import Template from './template';

const SignUp = () => {
  useUpdateDocumentTitle({ title: 'documentTitle.SignUp', appHeader: '', href: '' });

  if (checkSignedIn()) {
    return <Redirect to="/analytics" />;
  }

  return <Template />;
};

export default SignUp;
