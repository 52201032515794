import styled from '@emotion/styled';
import * as React from 'react';
import { defaultEmptyImage } from '../../../../libs/image';

export interface PostProps {
  id: number;
  src: string | null;
  title: string | null;
}

interface ThumbnailProps {
  src: string | null;
  onClick: () => void;
}

const PostColumn = (props: PostProps & ThumbnailProps) => {
  const { src, title, onClick } = props;

  return (
    <Wrapper>
      <ThumbnailWrapper>
        <Thumbnail src={defaultEmptyImage(src)} onClick={onClick} />
      </ThumbnailWrapper>
      <div>{!!title && <Title onClick={onClick}>{title}</Title>}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  height: 61px;
  align-items: center;
  padding-left: 16px;
`;
const ThumbnailWrapper = styled.a`
  flex-shrink: 0;
  margin-right: 8px;
  border: 1px solid #f5f5f5;
  border-radius: 2px;

  ${Wrapper}:hover & {
    opacity: 0.7;
  }
`;
const Thumbnail = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
`;

const Title = styled.a`
  overflow: hidden;
  flex: 1;
  height: 31px;
  margin-top: 4px;
  font-size: 13px;
  line-height: 16px;
  word-break: break-word;
  padding-right: 8px;

  ${Wrapper}:hover & {
    opacity: 0.7;
  }
`;

export default PostColumn;
