import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { useLocation } from 'react-router';
import Styled from '../StyledComponents';
import { useQueryHelper } from '../../../../libs/hooks';
import { SocialAccountType } from '../../../../__generated__/globalTypes';
import Template from './template';
import {
  InstagramAnalyticsPostDetails,
  InstagramAnalyticsPostDetailsVariables
} from './__generated__/InstagramAnalyticsPostDetails';
import * as INSTAGRAM_ANALYTICS_POST_DETAILS from './InstagramAnalyticsPostDetails.graphql';

interface AnayticsFeedPostDetailProps {
  closeModal: () => void;
  hasViewColumn?: boolean;
}

const AnayticsFeedPostDetail = (props: AnayticsFeedPostDetailProps) => {
  const { closeModal, hasViewColumn } = props;
  const { t, history, enqueueSnackbar } = useQueryHelper();
  const { search, pathname } = useLocation();

  const postId = Number(new URLSearchParams(search).get('popup'));

  const { data, loading, error } = useQuery<InstagramAnalyticsPostDetails, InstagramAnalyticsPostDetailsVariables>(
    INSTAGRAM_ANALYTICS_POST_DETAILS,
    {
      variables: { postId },
      fetchPolicy: 'cache-and-network',
      onError: useCallback(() => {
        history.push(pathname);
        enqueueSnackbar(t('Post was not found'), { variant: 'error' });
      }, [])
    }
  );

  if (loading || error) {
    return <CircularProgress size={64} thickness={2.4} color="inherit" />;
  }

  return (
    <Styled.PostDetailWrapper>
      <Template
        closeModal={closeModal}
        data={data}
        hasViewColumn={hasViewColumn}
        accountType={SocialAccountType.INSTAGRAM}
      />
    </Styled.PostDetailWrapper>
  );
};

export default AnayticsFeedPostDetail;
