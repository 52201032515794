import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NavBar, NavTab } from '../../../molecules/NavTab';

const SignInSignUpHeader = () => {
  const { t } = useTranslation();

  return (
    <NavBar hasBorder>
      <TabWrapper>
        <NavTab activeClassName="selected" exact to={`/`}>
          {t('Sign in')}
        </NavTab>
        <NavTab activeClassName="selected" to={`/signup`}>
          {t('Sign up')}
        </NavTab>
      </TabWrapper>
    </NavBar>
  );
};

export default SignInSignUpHeader;

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  margin: auto;
`;
