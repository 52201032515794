import React from 'react';
import TaggedPostsFilter from '../../components/organisms/AnalyticsShared/BasePostListFilter';
import { SortType } from '../../libs/filter';
import TaggedPostsList from '../../components/organisms/AnalyticsPost/TaggedPostList';
import { InstagramFeedPostSortField } from '../../__generated__/globalTypes';
import { TaggedPostFilterType } from './types';

interface TemplateProps {
  currentPage: number;
  filter: TaggedPostFilterType;
  setFilter: (filter: TaggedPostFilterType) => void;
  sort: SortType<InstagramFeedPostSortField>;
  setSort: (sort: SortType<InstagramFeedPostSortField>) => void;
  id: number;
}
const Template = ({ filter, setFilter, currentPage, sort, setSort, id }: TemplateProps) => (
  <>
    <TaggedPostsFilter filter={filter} setFilter={setFilter} />
    <TaggedPostsList currentPage={currentPage} sort={sort} setSort={setSort} filter={filter} id={id} />
  </>
);

export default Template;
