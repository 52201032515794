import React from 'react';
import { Redirect } from 'react-router';
import PostDetail from '../../AnalyticsDashboard/PostDetail';

interface TemplateProps {
  closeModal: () => void;
  postData?: {
    id: number;
    content: string;
    postUrl: string;
    instagramAccountName: string | null;
    avatarUrl: string | null;
    postImageUrl: string | null;
    commentList: { id: number; content: string; username: string | null }[] | null;
    likes: number;
    comments: number;
  } | null;
}

const Template = (props: TemplateProps) => {
  const { postData, closeModal } = props;

  if (!postData) {
    return <Redirect to="/404" />;
  }

  const { avatarUrl, instagramAccountName, commentList, content, postImageUrl, postUrl, comments, likes } = postData;

  return (
    <PostDetail
      avatarUrl={avatarUrl}
      closeModal={closeModal}
      accountName={instagramAccountName}
      followers={null}
      content={content}
      commentList={commentList || []}
      postImageUrl={postImageUrl}
      engagement={{
        postUrl,
        analysis: null,
        sentiment: null,
        storyData: null,
        taggedPost: { comments, likes }
      }}
    />
  );
};

export default Template;
