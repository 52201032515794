import styled from '@emotion/styled';
import * as React from 'react';
import { APP_NAME } from '../../../libs/constant';
import TermsText from '../../atoms/TermsText';
import TermsTitle from '../../atoms/TermsTitle';

const TermsContent = () => (
  <Wrapper>
    <MainTitle title={`Terms and Conditions of Service for Advertisers on ${APP_NAME} Marketplace`} />
    <TermsText>
      {`${APP_NAME} is a social influencer marketing service provided by, ${APP_NAME} Pte. Ltd. and its subsidiaries
      (hereafter referred to as “AT”), which connects influencers and the clients of AT. AT has made every attempt to
      ensure the reliability and accuracy of information provided on this but as such, this information is provided
      without warranty of any kind. AT does not and will not accept liability or responsibility of any manner for the
      completeness, accuracy, content, legality, or reliability for the information on this. The services on ${APP_NAME}
      Marketplace as well as all information, products, content and other services included are made available to you on
      an "as is" basis, unless specified in writing. You agree that you use the ${APP_NAME} Marketplace at your sole
      risk. By accessing, using and browsing this, you acknowledge that you have read, understood and agree to be bound
      by these term and conditions below, and to comply with all applicable laws, rules and regulations.`}
    </TermsText>

    <TermsText heading="1. AGREEMENT">
      {`In consideration of the terms outlined in this Agreement, the Client and AT agree to enter into an agreement
      whereby AT shall provide access to its proprietary influencer marketing ${APP_NAME} Marketplace® (“${APP_NAME}
      Marketplace”) and its management services (“Services”) to the Client for a specific price and duration as mutually
      agreed specified in the insertion order.`}
    </TermsText>

    <BreakTermsText heading="2. TERMS AND DEFINITIONS">
      {`Client: The legal or natural person(s), or entity that is using AT's services covered in ${APP_NAME} Marketplace.`}
    </BreakTermsText>
    <BreakTermsText>
      {`Influencer: The legal or natural person(s), or entity providing services to AT and it's Clients through
      ${APP_NAME} Marketplace.`}
    </BreakTermsText>
    <BreakTermsText>
      Insertion Order: A supplementary contract issued either electronically or as a physical copy which details certain
      aspects of the agreement between the Client and AT, such as scope of services required, fees, payment details, and
      timeline for a certain Live Campaign.
    </BreakTermsText>
    <BreakTermsText>
      {`${APP_NAME} Marketplace: an online under the ownership of AT which can be utilized by brands and entities to
      connect with social influencers to market the former’s product.`}
    </BreakTermsText>
    <TermsText>
      Live Campaign: a project uploaded by a Client of AT which requires bidding by different influencers, who shall
      then propose a method of rolling out the Campaign to be agreed upon by the Client.
    </TermsText>

    <BreakTermsText>The Client accepts these terms and conditions in the following events by:</BreakTermsText>
    <BreakTermsText>
      {`(1) accepting by clicking accept to this Terms and Conditions agreement, and thereafter, using ${APP_NAME}
      Marketplace`}
    </BreakTermsText>
    <BreakTermsText>
      (2) subsequently executing an Insertion Order that will further detail specifics of this agreement on a case by
      case basis.
    </BreakTermsText>
    <TermsText>
      {`By using ${APP_NAME} Marketplace, the Client represents and warrants that the Client has the right, authority and
      capacity to enter into the Agreement. If the Client does not meet all of these requirements, the Client must not
      access or use ${APP_NAME} Marketplace.`}
    </TermsText>

    <TermsText heading="3. TRADEMARKS">
      {`AT name, the terms, AT logo and all related names, logos, product and service names, designs and slogans are
      trademarks of AT or its Affiliates. The Client must not use such marks without the prior written permission from
      AT. All other names, logos, product and service names, designs and slogans on ${APP_NAME} Marketplace are the
      trademarks of their respective owners.`}
    </TermsText>

    <TermsText heading="4. SCOPE DEFINITION">
      {`AT name, the terms, AT logo and all related names, logos, product and service names, designs and slogans are
      trademarks of AT or its Affiliates. The Client must not use such marks without the prior written permission from
      AT. All other names, logos, product and service names, designs and slogans on ${APP_NAME} Marketplace are the
      trademarks of their respective owners.`}
    </TermsText>

    <BreakTermsText heading="5. FEES AND PAYMENT TERMS">
      Insertion Orders: If an Insertion Order is issued for the purchase of Services, Client agrees to pay all fees as
      and when described in the applicable Insertion Order. Company shall invoice Client for the fees in the currency
      set forth on the applicable Insertion Order. Unless otherwise stated on the Insertion Order, all invoices shall
      amounts. Client shall make payments to the entity and address set forth in the invoice submitted by AT in relation
      to the applicable Insertion Order. AT may from time to time vary any fee and/or introduce new fees in relation to
      the Services. The Fees to be paid shall be inclusive of all applicable local government taxes or levies that AT
      incurs.
    </BreakTermsText>
    <BreakTermsText>
      Late Payments: If any amounts due hereunder are not received by the Company by the due date, interest will accrue
      over the original service fee at the rate of 15% per annum, from the date such payment was due until the date
      paid. In addition, upon 10 days written notice, the Company may suspend your access to the Services if the Company
      does not receive the amounts invoiced hereunder at the expiration of such period.
    </BreakTermsText>
    <TermsText>
      {`Additional terms and conditions may also apply to specific portions, services or features of ${APP_NAME}
      Marketplace. All such additional terms and conditions are hereby incorporated by this reference into the
      Agreement. All settlements and fund transfers are to be carried out by a relevant financial institution or bank
      which AT will specify from time to time.`}
    </TermsText>

    <BreakTermsText heading="6. AT RESPONSIBLITIES">
      {`6.1 AT reserves the right to provide access, withdraw or amend the access to ${APP_NAME} Marketplace, and any
      service or material that AT provides on ${APP_NAME} Marketplace, at its sole discretion and without notice. AT
      will not be liable if for any reason all or any part of ${APP_NAME} Marketplace is unavailable at any time or for
      any period. From time to time, AT may restrict access to some parts of ${APP_NAME} Marketplace, or the entire
      ${APP_NAME} Marketplace.`}
    </BreakTermsText>
    <BreakTermsText>
      6.2 AT will provide the proposal and insertion order which shall include the following but not limited to, the
      list of influencers as chosen by the Client, the detailed statement of work to be performed by the influencers,
      the fees and payment terms associated with such statement of work, and/or any additional conditions agreed upon
      between both the Parties.
    </BreakTermsText>
    <BreakTermsText>
      {`6.3 AT is solely responsible for the remuneration of Influencers on the ${APP_NAME} Marketplace.`}
    </BreakTermsText>
    <BreakTermsText>6.4 AT will provide the report of the campaign in AT’s reporting format.</BreakTermsText>
    <BreakTermsText>
      6.5 Any requests for additional Services or reports may incur additional costs to the Client.
    </BreakTermsText>
    <TermsText>
      {`6.6 The influencer is an independent person and has no employment relationship with AT. If the influencers and
      time slot cannot be delivered due to any other reason, the Client can choose to renew a time slot or run a
      different campaign. In any cases, AT will not refund any payment for ${APP_NAME} Marketplace the Client has made.`}
    </TermsText>
    <BreakTermsText>Additionally, at its sole discretion, AT has the right to:</BreakTermsText>
    <BreakTermsText>6.7 Remove or refuse any Client content for any or no reason.</BreakTermsText>
    <BreakTermsText>
      6.8 AT has the right to disable any user name, password or other identifier, whether chosen by the Client or
      provided by AT, at any time at its sole discretion for any or no reason, including if, in AT’s opinion, the Client
      has violated any terms and conditions of the Agreement.
    </BreakTermsText>
    <BreakTermsText>
      {`6.9 Block, ban, deactivate, refrain or take any action to temporarily/permanently suspend the Client member
      account of ${APP_NAME} Marketplace without further notice if AT believes that the Client and the influencer are
      directly engaging and/or are in contact in connection with the Service and purpose hereof without using
      ${APP_NAME} Marketplace, whereby the Client’s content or the use of ${APP_NAME} Marketplace violates all or part
      thereof of the terms and conditions of the Agreement.`}
    </BreakTermsText>
    <BreakTermsText>
      6.10 Disclose the Client’s identity or other information about the Client to the influencer or any third-party who
      claims that material posted by the Client violates their rights, including their intellectual property rights or
      their right to privacy.
    </BreakTermsText>
    <BreakTermsText>
      {`6.11 Take any action with respect to any Client content that AT deems necessary or appropriate, including if AT
      believes that such Client content violates the terms and conditions of the Agreement, including the content
      standards, infringes any intellectual property right or other right of any person or entity, threatens the
      personal safety of users of ${APP_NAME} Marketplace or the public or could create liability for AT.`}
    </BreakTermsText>
    <BreakTermsText>
      {`6.12 Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or
      unauthorized use of ${APP_NAME} Marketplace.`}
    </BreakTermsText>
    <TermsText>
      {`6.13 Without limiting the foregoing, AT has the right to fully cooperate with any law enforcement authorities or
      court order requesting or directing the disclosure of the identity or other information of anyone posting any
      materials on or through ${APP_NAME} Marketplace. The Client waives and indemnifies AT and its affiliates,
      licensees and service providers from any claims resulting from any action taken by the company/any of the
      foregoing parties during or as a result of its investigations and from any actions taken as a consequence of
      investigations by either the company/such parties or law enforcement authorities. However, AT does not undertake
      to review material before it is posted on ${APP_NAME} Marketplace, any social media channels or the internet, and
      therefore cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, AT assumes
      no liability for any action or inaction regarding transmissions, communications or content provided by any user or
      third party. AT has no liability or responsibility to anyone for performance or nonperformance of the activities
      described in this section either by AT or the influencers.`}
    </TermsText>

    <BreakTermsText heading="7. CLIENT RESPONSIBLITIES">
      {`7.1 The Client must provide certain registration details or other information as required by AT to use certain
      features of ${APP_NAME} Marketplace. The Client guarantees that the information of the Client provided to AT for
      the use of ${APP_NAME} Marketplace is correct, current and complete and up to date.`}
    </BreakTermsText>
    <BreakTermsText>
      {`7.2 The Client must provide detailed requirements at ${APP_NAME} Marketplace for influencers to prepare the
      proposal.`}
    </BreakTermsText>
    <BreakTermsText>
      7.3 The Client shall make payments as per the Insertion Orders and Invoices placed by AT.
    </BreakTermsText>
    <BreakTermsText>
      {`7.4 The Client shall adhere to the conditions of using the ${APP_NAME} Marketplace and the content standards set
      out by AT in Appendix A.`}
    </BreakTermsText>
    <BreakTermsText>
      {`7.5 The Client shall ensure that all persons who have access to Client’s account on ${APP_NAME} Marketplace and
      access either through the Client’s internet or any network connection are aware of the terms and conditions of the
      Agreement and comply with them. The Client shall be wholly responsible for access to its own account. AT shall not
      be held liable for any damages caused by or on the Client’s account.`}
    </BreakTermsText>
    <BreakTermsText>
      {`7.6 The Client shall ensure that the material to be posted on ${APP_NAME} Marketplace, any social media channels
      or internet is reviewed and complies with all local laws and regulations.`}
    </BreakTermsText>
    <TermsText>
      7.7 The Client acknowledges that Influencers are independent. AT cannot and will not be held liable for
      non-performance of an influencer within the scope of time given. In such cases, the remedy shall be for the Client
      to choose a different time slot and/or influencer.
    </TermsText>

    <BreakTermsText heading="8. CONFIDENTIAL INFORMATION">
      {`8.1 The Client acknowledges that the member account is personal to the Client and agrees not to provide any other
      person with access to ${APP_NAME} Marketplace or portions of it using the Client’s user name, password or other
      security information.`}
    </BreakTermsText>
    <BreakTermsText>
      {`8.2 The Client also acknowledges that the Client must treat all information gathered from AT or ${APP_NAME}
      Marketplace as confidential and the Client must not disclose it to any other person or entity or third-party
      without the prior written consent from AT.`}
    </BreakTermsText>
    <BreakTermsText>
      8.3 The Client agrees to notify AT immediately of any unauthorized access to or use of its user name or password
      or any other breach of security. The Client also agrees to ensure that the Client shall exit or sign out from the
      member account at the end of each session. The Client should use particular caution when accessing the member
      account from a public or shared computer so that others are not able to view or record the password or other
      personal information.
    </BreakTermsText>
    <BreakTermsText>
      8.4 AT shall not be liable for any loss or damage arising from the Client’s failure to comply with the above
      requirements.
    </BreakTermsText>
    <TermsText>
      8.5 AT shall not be liable for any data privacy law violation arising from the Client’s failure to comply with the
      above requirements.
    </TermsText>

    <TermsText heading="9. INDEMNIFICATION">
      {`The Client agrees to defend, indemnify and hold harmless AT, its Affiliates, licensors and service providers, and
      its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and
      assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees
      (including reasonable attorneys' fees) arising out of or relating to the Client’s violation of the Agreement or
      the Client’s use of ${APP_NAME} Marketplace, including, but not limited to, the Client content, any use of
      ${APP_NAME} Marketplace’s content, services and products other than as expressly authorized in the Agreement or
      the Client’s use of any information obtained from ${APP_NAME} Marketplace.`}
    </TermsText>

    <BreakTermsText heading="10. TERMINATION">
      {`AT has the right to terminate or suspend the Client’s access to all or part of ${APP_NAME} Marketplace for any or
      no reason, including without limitation, any violation of the Agreement.`}
    </BreakTermsText>
    <BreakTermsText>
      Upon termination of the Agreement, the Client shall immediately pay AT all amounts owed by the Client to AT within
      seven (7) days if any. AT will not have any liability whatsoever to the Client for any termination of the
      Agreement, including for termination of the Client’s member account or deletion of the Client content.
    </BreakTermsText>
    <TermsText>
      AT reserves the right to modify or discontinue any or all of the services including returning the remaining value
      of the Credit with or without notice to the Client. AT will not be liable to the Client or any third-party for
      termination of any service.
    </TermsText>

    <BreakTermsText heading="11. WAIVER AND SEVERABILITY">
      No omission or delay on AT's part in exercising any or part of its rights under the terms an conditions of the
      Agreement shall operate as a waiver thereof.
    </BreakTermsText>
    <TermsText>
      If any provision of the terms and conditions of the Agreement is held by a court or other tribunal of competent
      jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited
      to the minimum extent such that the remaining provisions of the Agreement will continue in full force and effect.
    </TermsText>

    <TermsText heading="12. FORCE MAJEURE">
      Except for payment obligations, neither Client nor AT shall be liable for any failure to perform its obligations
      hereunder where such failure results from any cause beyond either Client’s or AT’s reasonable control, including
      the elements; fire; flood; severe weather; earthquake; vandalism; accidents; sabotage; power failure; denial of
      service attacks or similar attacks; Internet failure; acts of God and the public enemy; acts of war; acts of
      terrorism; riots; civil or public disturbances; strikes, lock-outs, or labour disruptions; and any laws, orders,
      rules, regulations, acts, or restraints of any government or governmental body or authority, civil or military,
      including the orders and judgments of courts.
    </TermsText>

    <BreakTermsText heading="13. GOVERNING LAW AND JURISDICTION">
      13.1 Any dispute arising out of or in connection with this contract, including any question regarding its
      existence, validity or termination, shall be referred to and finally resolved by arbitration administered by the
      Singapore International Arbitration Centre (“SIAC”) in accordance with the Arbitration Rules of the Singapore
      International Arbitration Centre (“SIAC Rules”) for the time being in force, which rules are deemed to be
      incorporated by reference in this clause.
    </BreakTermsText>
    <BreakTermsText>The seat of the arbitration shall be Singapore.</BreakTermsText>
    <BreakTermsText>The Tribunal shall consist of 3 arbitrator(s).</BreakTermsText>
    <BreakTermsText>The language of the arbitration shall be English.</BreakTermsText>
    <TermsText>
      13.2 Parties should also include an applicable law clause. The following is recommended: This contract is governed
      by the laws of Singapore.
    </TermsText>

    <InSentenceTermsTitle title="APPENDIX A: CONTENT STANDARDS AND PROHIBITED USES" />
    <BreakTermsText>
      All Client’s content must comply with the content standards set out in the Agreement.
    </BreakTermsText>
    <TermsText>
      {`Any Client content the influencer posts will be considered non-confidential and non-proprietary. By providing any
      Client content on ${APP_NAME} Marketplace, the Client grants AT and its Affiliates the right to use, reproduce,
      modify, perform, display, distribute and otherwise disclose to third parties any such material.`}
    </TermsText>
    <BreakTermsText>The Client represents and warrants that:</BreakTermsText>
    <SubTermText>
      1. The Client owns or controls all rights in and to the Clients’ content and has the right to grant the license
      granted above to AT, Affiliates and respective licensees, successors and assignees.
    </SubTermText>
    <SubTermText>2. All Client content does and will comply with the Agreement and applicable laws.</SubTermText>
    <SubTermText>3. The Client content is, at all times, correct, current and complete, and up to date.</SubTermText>
    <SubTermText>
      {`4. The Client shall notify AT of any changes to the Client content. If the Client fails to provide notification to
      AT of any change to the Client content in a timely manner, or the Client content is incorrect or untrue, then AT
      may suspend and/or terminate the provision of all or part thereof of ${APP_NAME} Marketplace without further
      notice.`}
    </SubTermText>
    <SubTermText>
      5. The Client understands and acknowledges that the Client has full responsibility of the Client content,
      including its legality, reliability, accuracy and appropriateness.
    </SubTermText>
    <SubTermText>
      {`6. AT is not responsible, or liable to any third party, for the content or accuracy of any Client content posted
      by the Client or any other user of ${APP_NAME} Marketplace.`}
    </SubTermText>
    <SubTermText>
      These content standards apply to any and all Client contents. The Client content must in its entirety comply with
      all laws and regulations. Without limiting the foregoing, Client content must not:
    </SubTermText>
    <SubTermText>
      7. Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful,
      inflammatory or otherwise objectionable.
    </SubTermText>
    <SubTermText>
      8. Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion,
      nationality, disability, sexual orientation or age.
    </SubTermText>
    <SubTermText>
      9. Infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any
      other person.
    </SubTermText>
    <SubTermText>
      10. Violate the legal rights (including the rights of publicity and privacy) of others or contain any material
      that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may
      be in conflict with the Agreement.
    </SubTermText>
    <SubTermText>
      11. Be likely to deceive any person by making unauthorized claims about the products or services provided by the
      Client.
    </SubTermText>
    <SubTermText>12. Promote any illegal activity, or advocate, promote or assist any unlawful act.</SubTermText>
    <SubTermText>
      13. Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other
      person.
    </SubTermText>
    <SubTermText>
      14. Impersonate any person, or misrepresent Client identity or affiliation with any person or organization.
    </SubTermText>
    <SubTermText>
      15. Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not
      the case.
    </SubTermText>
    <SubTermText>Client also acknowledges and accepts that:</SubTermText>
    <SubTermText>
      16. AT may choose to restrict or refuse to post the Client’s content if it is in AT’s views to be against the law
      of the local restriction where the campaign is to take place, or is likely to be controversial. In such situation,
      it will be up to AT’s judgment whether such content should be prohibited.
    </SubTermText>
    <SubTermText>
      17. Notwithstanding the foregoing obligations and responsibility, AT shall not be responsible for correcting or
      preventing any violation made by the Client or others under the Agreement, of the Client Content towards the
      Client or others.
    </SubTermText>
    <SubTermText>
      {`18. AT may take measures that AT considers necessary and appropriate, if AT acknowledges that the Client is using
      ${APP_NAME} Marketplace in a way which violates the Agreement.`}
    </SubTermText>
    <SubTermText>
      {`19. In the case where AT has suffered loss/damage or has been charged an expense directly or indirectly due to the
      Client violating applicable laws or the Agreement while using ${APP_NAME} Marketplace, the Client shall
      immediately compensate AT upon its and/or others’ request.`}
    </SubTermText>

    <InSentenceTermsTitle title={`PROHIBITED USES OF ${APP_NAME} PLATFORM`} />
    <BreakTermsText>
      {`The Client may use ${APP_NAME} Marketplace only for lawful purposes and in accordance with the Agreement. The
      Client agrees not to use the ${APP_NAME} Marketplace:`}
    </BreakTermsText>
    <SubTermText>20. In any way that violates any law or regulation.</SubTermText>
    <SubTermText>{`21. To directly solicit the services of the influencers from ${APP_NAME} Marketplace.`}</SubTermText>
    <SubTermText>
      22. To send, knowingly receive, upload, download, use or re-use any material which does not comply with the
      content standards set out in the Agreement.
    </SubTermText>
    <SubTermText>
      23. To distribute, transmit, or procure the sending of, any advertising or promotional material, including any
      "junk mail", "chain letter" or "spam" or any other similar solicitation.
    </SubTermText>
    <SubTermText>
      24. To impersonate or attempt to impersonate AT, a Company employee, another user or any other person or entity
      (including, without limitation, by using e-mail addresses or screen names, social media account, associated with
      any of the foregoing).
    </SubTermText>
    <SubTermText>
      {`25. To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of ${APP_NAME}
      Marketplace, or which, as determined by us, may harm AT or users of ${APP_NAME} Marketplace or expose them to
      liability.`}
    </SubTermText>
    <SubTermText>Additionally, the Client agrees not to:</SubTermText>
    <SubTermText>
      {`26. Use ${APP_NAME} Marketplace in any manner that could disable, overburden, damage, or impair the site or
      interfere with any other party's use of ${APP_NAME} Marketplace, including their ability to engage in real time
      activities through ${APP_NAME} Marketplace.`}
    </SubTermText>
    <SubTermText>
      {`27. Use any robots or devices, processes or means to access ${APP_NAME} Marketplace for any purpose, including
      monitoring or copying any of the material on ${APP_NAME} Marketplace.`}
    </SubTermText>
    <SubTermText>
      28. Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or
      technologically harmful.
    </SubTermText>
    <SubTermText>
      {`29. Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of ${APP_NAME}
      Marketplace, the server on which ${APP_NAME} Marketplace is stored, or any server, computer or database connected
      to ${APP_NAME} Marketplace.`}
    </SubTermText>
    <SubTermText>{`30. Otherwise attempt to interfere with the proper working of ${APP_NAME} Marketplace.`}</SubTermText>
  </Wrapper>
);

const MainTitle = styled(TermsTitle)`
  margin-bottom: 40px;
`;

const Wrapper = styled.section`
  width: 760px;
  padding: 48px;
  background-color: #fff;
  border-radius: 2px;
`;

const BreakTermsText = styled(TermsText)`
  margin-bottom: 8px;
`;

const InSentenceTermsTitle = styled(TermsTitle)`
  padding: 40px 0;
  text-transform: uppercase;
`;

const SubTermText = styled(TermsText)`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default TermsContent;
