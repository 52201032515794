import React from 'react';
import styled from '@emotion/styled';
import BlurOpacityWrapper, { ProfileDetailsContentWrapperProps } from '../../molecules/BlurOpacityWrapper';

export const OpacityContentWrapper = (props: ProfileDetailsContentWrapperProps) => (
  <StyledContentWrapper {...props} wrapperText="The system is collecting data, please wait until the data is ready" />
);

const StyledContentWrapper = styled(BlurOpacityWrapper)`
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-color: inherit;
`;

export const BlurImage = styled.img`
  width: 100%;
  height: 100%;
`;
