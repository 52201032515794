import * as yup from 'yup';

export const PasswordSchema = yup
  .string()
  .min(8, (props: any) => `leastLetterMessage_${props.min}`)
  .max(64, (props: any) => `lessLetterMessage_${props.max}`)
  .matches(/[\x21-\x7e]+/, 'onlyAlphanumericOrSymbolsMessage')
  .required('requiredPasswordMessage');
export const PasswordConfirmSchema = yup
  .string()
  .oneOf([yup.ref('password'), null], 'PasswordNotMatchMessage')
  .required('requiredPasswordConfirmMessage');

// Password Schema
export default yup.object().shape({
  password: PasswordSchema,
  passwordConfirm: PasswordConfirmSchema
});
