import React from 'react';
import { useQuery } from '@apollo/client';
import { getPageInfo } from '../../../../libs/paging';
import { InstagramHashtagFeedPostFilterType } from '../../../../pages/InstagramHashtagFeedPost/types';
import ListIndicator from '../../../molecules/ListIndicator';
import { InstagramFeedPostsSortAction } from '../InstagramFeedPostList/InstagramFeedPostList';
import { useQueryHelper } from '../../../../libs/hooks';
import InstagramHashtagFeedPostList from './InstagramHashtagFeedPostList';
import {
  AllInstagramHashtagFeedPosts,
  AllInstagramHashtagFeedPostsVariables
} from './__generated__/AllInstagramHashtagFeedPosts';
import * as ALL_INSTAGRAM_HASHTAG_FEED_POSTS from './AllInstagramHashtagFeedPosts.graphql';
import { allInstagramHashtagPostsVariables } from './utils';

export interface InstagramHashtagFeedPostListProps extends InstagramFeedPostsSortAction {
  hashtagId: number;
  filter: InstagramHashtagFeedPostFilterType;
  currentPage: number;
  goBackUrl?: string;
}

const InstagramHashtagFeedPostListComponent = (props: InstagramHashtagFeedPostListProps) => {
  const { sort, setSort, goBackUrl, currentPage } = props;
  const { t, history, enqueueSnackbar } = useQueryHelper();

  const { data, loading, error } = useQuery<AllInstagramHashtagFeedPosts, AllInstagramHashtagFeedPostsVariables>(
    ALL_INSTAGRAM_HASHTAG_FEED_POSTS,
    {
      variables: allInstagramHashtagPostsVariables(props),
      fetchPolicy: 'cache-and-network',
      skip: !props.hashtagId
    }
  );

  if (loading) {
    return <ListIndicator />;
  }

  if (error) {
    history.push(goBackUrl || '/analytics');
    enqueueSnackbar(t(error.message), { variant: 'error' });
  }

  const instagramHashtagPosts = data?.allInstagramHashtagFeedPosts || [];

  const pageInfo = getPageInfo(currentPage, data);

  return (
    <InstagramHashtagFeedPostList
      instagramFeedPosts={instagramHashtagPosts}
      sort={sort}
      setSort={setSort}
      pageInfo={pageInfo}
    />
  );
};

export default InstagramHashtagFeedPostListComponent;
