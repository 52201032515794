import styled from '@emotion/styled';
import * as React from 'react';
import { CheckBoxIcon } from '../Icon';

interface HeaderCheckboxColumnProps {
  checked: boolean;
  handleClick?: () => void;
  disabled?: boolean;
  className?: string;
  fontSizeCheckbox?: string;
  isTable?: boolean;
}

const HeaderCheckboxColumn = (props: HeaderCheckboxColumnProps) => {
  const { className, checked, handleClick, disabled = false, fontSizeCheckbox = '14px', isTable = true } = props;
  const Wrapper = isTable ? TableWrapper : DivWrapper;

  return (
    <Wrapper className={className}>
      <Checkbox onClick={handleClick} disabled={disabled} checked={checked}>
        <CheckBoxIcon checked={checked} disabled={disabled} size={fontSizeCheckbox} />
      </Checkbox>
    </Wrapper>
  );
};

const TableWrapper = styled.th``;
const DivWrapper = styled.div``;

const Checkbox = styled.button<{ checked: boolean }>`
  cursor: pointer;
  display: flex;

  &:disabled {
    opacity: 0.5;
    cursor: unset;
  }

  &:focus > i {
    border: solid 1px ${({ checked }) => (checked ? '#2a78c0' : '#3892e5')};
  }
`;

export default HeaderCheckboxColumn;
