import emptyImage from '../assets/img/emptyImage.jpg';
import influencerAvatar from '../assets/img/influencerAvatar.jpg';
import influencerCover from '../assets/img/influencerCover.jpg';
import emptyVideo from '../assets/img/videoThumbnail.png';
import { getFileTypeFromUrl } from './request/getFileObjectFromStorage';
import { validateUrl } from './url';

export const defaultEmptyImage = (url: string | null) => {
  if (url) {
    const fileType = getFileTypeFromUrl(url);
    const isVideo = ['mp4', 'mov', 'avi'].includes(fileType);

    if (isVideo) {
      return emptyVideo;
    }

    return encodeURI(decodeURI(url))
      .replace(/\(/g, '%28') // encode "("
      .replace(/\)/g, '%29') // encode ")"
      .replace(/\*/g, '%2A'); // encode "*"
  }

  return emptyImage;
};

export const defaultInfluencerCover = (url: string | null) => validateUrl(url) || influencerCover;
export const defaultInfluencerAvatar = (url?: string | null) => validateUrl(url) || influencerAvatar;
