import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { TitleWithHelp } from '../AnalyticsShared/AnalyticsContentWrapper';

export const PostImage = styled.img`
  width: 160px;
  height: 160px;
  margin-right: 16px;
  object-fit: contain;
  cursor: pointer;
`;
export const EngagementInfo = styled.div`
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  position: absolute;
  width: calc(100% - 16px);
  bottom: 0;
  left: 0;
  cursor: pointer;
`;
export const InfoWrapper = styled.div`
  padding: 0 8px 8px;
`;
export const Label = styled.p`
  margin-bottom: 3px;
`;
export const Count = styled.p``;
export const MoreText = styled(Link)`
  font-size: 12px;
  font-weight: 600;
  color: #6e7c89;
  text-align: right;
  margin: 20px 20px 0;
  padding-bottom: 20px;

  span {
    cursor: pointer;
    text-transform: uppercase;
  }

  &:hover {
    opacity: 0.8;
  }
`;
export const Arrow = styled.span`
  color: #ff2b52;
  font-size: 14px;
`;
// Inline styles generated from `react-slick` in here
export const PostImageWrapper = styled.div`
  position: relative;

  &:focus {
    outline: none;
  }
`;
export const PostsTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 27px 0 19px 0;
  font-size: 18px;
  padding-right: 16px;
`;
export const CheckboxText = styled.p`
  font-size: 12px;
  color: #27313b;
  padding: 0 4px;
`;
export const PostsPreviewWrapper = styled.div`
  padding: 8px 0 8px 16px;
  border-top: 1px solid #eef2f5;
`;
export const TotalPostsPreview = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

export const ChartWrapper = styled.div`
  flex: 1;
  padding: 24px 16px;
  border-right: 1px solid #e6ecf0;

  &:last-child {
    border-right-style: none;
  }
`;
export const ChartContainer = styled.div`
  display: flex;
  height: 385px;
`;
export const StyledTitleWithHelp = styled(TitleWithHelp)`
  margin: 27px 0 19px 0;
  font-size: 18px;
`;
export const ChartContainerBorderBottom = styled.div`
  display: flex;
  border-bottom: 1px solid #e6ecf0;
`;
