import css from '@emotion/css';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { SingleSelector } from '../../../molecules/Selector';
import hideGray from '../../../../assets/icon/hide.svg';
import { AvatarAndNameType } from './types';
import { compareColors, HIDDEN_KEY } from './compareHelpers';

interface ComparableAccountsSelectorsProps {
  accountsAvatarAndName: AvatarAndNameType[];
  uniqIdForGraph: string[];
  setUniqIdForGraph: React.Dispatch<React.SetStateAction<string[]>>;
}
export const ComparableAccountsSelectors = (props: ComparableAccountsSelectorsProps) => {
  const { accountsAvatarAndName, uniqIdForGraph, setUniqIdForGraph } = props;
  // in fact there are 15 comparable + 1 main account
  const remainigAccounts = accountsAvatarAndName.slice(5, 16);

  useEffect(() => {
    // recalculate remainig dropdowns options on hide/show accounts
    setDropdownAccounts(remainigAccounts);
  }, [accountsAvatarAndName.length]);

  const [dropdownAccounts, setDropdownAccounts] = useState<AvatarAndNameType[]>(remainigAccounts);

  const handleChange = (currUniqId: string, newUniqId: string) => {
    // No need to do all sorting if we select the same thing.
    if (currUniqId === newUniqId) {
      return;
    }
    setUniqIdForGraph(prevState => prevState.map(selection => (selection === currUniqId ? newUniqId : selection)));

    setDropdownAccounts(prevState => {
      // remove the new newly selected accoutn from dropdown if there is any
      const accounts = prevState.filter(acc => acc.uniqId !== newUniqId);

      // add back the previously selected account to the dropdown if there is any
      const previous = accountsAvatarAndName.find(acc => acc.uniqId === currUniqId);
      if (previous) {
        accounts.push(previous);
      }

      return accounts;
    });
  };

  return (
    <ChartAccountsWrapper>
      {/* 5 is a max */}
      {uniqIdForGraph.map((uniqId, idx) => (
        <SingleSelector
          key={`compareAccount_${uniqId}`}
          name={`compareAccount_${idx}`}
          value={uniqId}
          options={buildOptions(
            accountsAvatarAndName.find(acc => acc.uniqId === uniqId),
            dropdownAccounts,
            idx
          )}
          onChange={newId => handleChange(uniqId, newId)}
          hideInput
          hideDeselectOption
          dropdownCss={css`
            max-width: calc((100vw - 250px) / 5);
          `}
          css={css`
            height: 40px;
            max-width: calc((100vw - 250px) / 5);

            & > div {
              height: 100%;

              & > div {
                height: 100%;
                align-items: center;
              }
            }
          `}
        />
      ))}
    </ChartAccountsWrapper>
  );
};

const buildOptions = (account: AvatarAndNameType | undefined, allAccounts: AvatarAndNameType[], idx: number) => [
  ...(account ? prepareDropdownOptions([account], idx) : []),
  ...prepareDropdownOptions(allAccounts, idx),
  hiddenOption(idx)
];

const hiddenOption = (idx: number) => ({
  label: 'Hidden',
  title: '',
  icon: (
    <DropdownAvatar
      css={css`
        background-color: black;
      `}
      src={hideGray}
    />
  ),
  value: `${HIDDEN_KEY}${idx}`
});

const prepareDropdownOptions = (accounts: AvatarAndNameType[], idx: number) =>
  accounts.map(acc => ({
    ...acc,
    value: acc.uniqId,
    label: acc.name,
    icon: <DropdownAvatar src={acc.avatar} alt="account's avatar" />,
    prefixIcon: (
      <div
        css={css`
          width: 14px;
          height: 14px;
          background-color: ${compareColors[idx]};
          margin-right: 16px;
        `}
      />
    )
  }));

const ChartAccountsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 21px;
`;
const DropdownAvatar = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 4px;
`;
