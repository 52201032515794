import * as yup from 'yup';

export default yup.object().shape({
  companyName: yup.string().required(),
  phoneNumber: yup.string().required(),
  companyCountryId: yup.string().required('Country is a required field'),
  name: yup.string().required(),
  email: yup.string().required(),
  userCountryId: yup.string().required('Country is a required field'),
  password: yup.string().required(),
  advertiserCompanyUrl: yup.string().required('companyUrl is a required field')
  // profileIcon -> add later after we will add it to the form
});
