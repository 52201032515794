import { useQuery } from '@apollo/client';
import React from 'react';
import { getPageInfo } from '../../../../libs/paging';
import { useQueryHelper } from '../../../../libs/hooks';
import ListIndicator from '../../../molecules/ListIndicator';
import InstagramFeedPostList from '../InstagramFeedPostList/InstagramFeedPostList';
import * as ALL_INSTAGRAM_FEED_POSTS from '../InstagramFeedPostList/AllInstagramFeedPosts.graphql';
import { allInstagramFeedPostsVariables } from '../InstagramFeedPostList/utils';
import {
  AllInstagramFeedPosts,
  AllInstagramFeedPostsVariables
} from '../InstagramFeedPostList/__generated__/AllInstagramFeedPosts';
import { InstagramCompareFeedPostListProps } from './types';

export const MainAccountFeedPosts = (props: InstagramCompareFeedPostListProps) => {
  const { currentPage, sort, setSort } = props;
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading, error } = useQuery<AllInstagramFeedPosts, AllInstagramFeedPostsVariables>(
    ALL_INSTAGRAM_FEED_POSTS,
    {
      variables: allInstagramFeedPostsVariables(props)
    }
  );

  if (loading) {
    return <ListIndicator />;
  } else if (error) {
    enqueueSnackbar(t('Can not get Instagram compare feed post data for current account'), { variant: 'error' });
  }

  const posts = data?.allInstagramFeedPosts || [];
  const postsPageInfo = getPageInfo(currentPage, data);

  return (
    <InstagramFeedPostList
      instagramFeedPosts={posts}
      sort={sort}
      setSort={setSort}
      pageInfo={postsPageInfo}
      hasViewColumn
    />
  );
};
