import styled from '@emotion/styled';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface PageArrowProps {
  href: LocationDescriptor;
  left: boolean;
  disabled?: boolean;
}

const PageArrow = (props: PageArrowProps) => {
  const { href, left, disabled } = props;
  const arrow = left ? 'keyboard_arrow_left' : 'keyboard_arrow_right';

  return (
    <PageArrowWrapper left={left}>
      {disabled ? (
        <Page>
          <Icon className="material-icons">{arrow}</Icon>
        </Page>
      ) : (
        <Anchor to={href}>
          <Icon className="material-icons">{arrow}</Icon>
        </Anchor>
      )}
    </PageArrowWrapper>
  );
};

const PageArrowWrapper = styled.li<{ left: boolean }>`
  margin-right: ${props => (props.left ? '12px' : 'inherit')};
`;

const Icon = styled.i`
  font-size: 22px;
`;

const Page = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 32px;
  border-radius: 3px;
  border: solid 1px #dee5ec;
  background-color: #dee5ec;
  color: #8694a2;
  opacity: 0.5;
`;

const Anchor = styled(Page.withComponent(Link))`
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  opacity: 1;
  background-color: #fff;

  &:hover {
    border-color: #c5d0da;
    color: #3b4146;
  }

  &:active {
    background-color: #f6f8fa;
  }
`;

export default PageArrow;
