import React, { useEffect, useState } from 'react';
import { Global, css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { CheckBoxIcon } from '../../../atoms/Icon';
import BlurOpacityWrapper from '../../../molecules/BlurOpacityWrapper';
import HighChart from '../../../molecules/HighChart';
import * as Styled from '../../AnalyticsShared/Overview/StyledComponents';
import {
  getOptions,
  allHashtagCharts,
  HashtagChartSeriesTypes,
  EngagementDataType,
  getHashtagsDropdownOptions,
  HIDDEN_KEY
} from './hashtagHelpers';
import { InstagramHashtagsOverview_instagramHashtagsOverview } from './__generated__/InstagramHashtagsOverview';
import HashtagOverviewTable from './HashtagOverviewTable';
import { HashtagDropdownsSection, HashtagsDropdownType } from './HashtagDropdownsSection';

interface HashtagOverviewType {
  instagramHashtagsOverview: InstagramHashtagsOverview_instagramHashtagsOverview;
  id: number;
  selectedHashtagIds: number[];
  allHashtag: Record<number, string>;
}
const HashtagOverview = ({ instagramHashtagsOverview, allHashtag, selectedHashtagIds }: HashtagOverviewType) => {
  const { t } = useTranslation();

  const hashtagsDropdownOptions = getHashtagsDropdownOptions(allHashtag, selectedHashtagIds);
  const firstFiveHashtags = hashtagsDropdownOptions.slice(0, 5);

  // Recalculate selected dropdowns options on hide/show accounts
  useEffect(() => {
    setSelectedDropdowHashtags(firstFiveHashtags.map(ht => ht.value.toString()));
  }, [selectedHashtagIds.length]);

  const [selectedCharts, setSelectedCharts] = useState<HashtagChartSeriesTypes[]>(['Follower']);
  const [selectedDropdownHashtags, setSelectedDropdowHashtags] = useState<string[]>(
    firstFiveHashtags.map(ht => ht.value.toString())
  );

  const { options, engagement } = instagramHashtagsOverview
    ? getOptions(
        selectedCharts,
        instagramHashtagsOverview,
        selectedDropdownHashtags.map(htId =>
          !htId.includes(HIDDEN_KEY)
            ? ({
                ...hashtagsDropdownOptions.find(ht => ht.value === Number(htId))
              } as HashtagsDropdownType)
            : null
        )
      )
    : { options: [] as Highcharts.Options, engagement: [] as EngagementDataType[] };

  const toggleSelectedCharts = (isSelected: boolean, name: HashtagChartSeriesTypes, isFollowerItem: boolean) => {
    if (isSelected) {
      setSelectedCharts(selectedCharts.filter(selectedItem => selectedItem !== name));
    } else if (isFollowerItem) {
      setSelectedCharts([...selectedCharts, name]);
    } else {
      // can select only one of 'Post' | 'Like' | 'Comment'
      setSelectedCharts(selectedCharts.includes('Follower') ? ['Follower', name] : [name]);
    }
  };
  // @ts-ignore -> data not exist on HighChart SeriesType
  const noData = !instagramHashtagsOverview || options.series?.every(item => item.data.every(el => !el));

  return (
    <>
      <BlurOpacityWrapper
        header=""
        dataNotAvailable={noData}
        css={css`
          box-shadow: none;
          border-radius: 0;
          border-bottom: 1px solid #dee5ef;
        `}
      >
        <Styled.Chart>
          <Styled.LegendBar>
            {allHashtagCharts.map(item => {
              const isChecked = selectedCharts.includes(item);
              const isFollowerItem = item === 'Follower';

              return (
                <Styled.LegendTile
                  key={item}
                  onClick={() => toggleSelectedCharts(isChecked, item, isFollowerItem)}
                  css={
                    isFollowerItem
                      ? css`
                          padding-right: 10px;
                          border-right: 1px solid #dee5ef;
                        `
                      : []
                  }
                >
                  <CheckBoxIcon checked={isChecked} />
                  <Styled.Text>{t(item)}</Styled.Text>
                </Styled.LegendTile>
              );
            })}
            {/* hashtags dropdowns */}
            <HashtagDropdownsSection
              hashtagsDropdownOptions={hashtagsDropdownOptions}
              selectedHashtags={selectedDropdownHashtags}
              setSelectedHashtags={setSelectedDropdowHashtags}
            />
          </Styled.LegendBar>
          <Global
            styles={css`
              .highcharts-tooltip path {
                fill: #27313b;
              }

              .highcharts-root {
                padding-left: 4px;
              }
            `}
          />
          <HighChart options={options} />
        </Styled.Chart>
      </BlurOpacityWrapper>
      <HashtagOverviewTable engagement={engagement} />
    </>
  );
};

export default HashtagOverview;
