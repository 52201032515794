import * as yup from 'yup';
import { CountrySchema } from './share';

export const CompanyNameSchema = yup.string().required('requiredCompanyMessage');
export const PhoneNumberSchema = yup.string().matches(/^\+?[0-9]+$/, 'invalidPhoneNumberMessage');

// Advertiser Schema
export default yup.object().shape({
  name: CompanyNameSchema,
  countryId: CountrySchema,
  phoneNumber: PhoneNumberSchema,
  companyUrl: yup.string()
});
