import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { Formik, FormikHelpers } from 'formik';
import { useMutation, ApolloError } from '@apollo/client';
import { PasswordSchema, UNEXPECTED_ERROR } from '../../../libs/validation';
import PasswordForm from './PasswordForm';
import { FormValues } from './types';
import { ResetPassword, ResetPasswordVariables } from './__generated__/ResetPassword';
import * as RESET_PASSWORD from './ResetPassword.graphql';

interface PasswordFormComponentProps {
  token: string;
}

const PasswordFormComponent = (props: PasswordFormComponentProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [doMutation] = useMutation<ResetPassword, ResetPasswordVariables>(RESET_PASSWORD);

  const handleSubmit = async (payload: FormValues, { setSubmitting, setStatus }: FormikHelpers<FormValues>) => {
    const { password } = payload;
    const { token } = props;

    const variables = {
      input: {
        token,
        password
      }
    };

    await doMutation({ variables })
      .then(result => {
        if (!!result?.data?.resetPassword) {
          enqueueSnackbar(t('succeededInUpdatingPassword'), { variant: 'success' });
          history.push('/');
        } else {
          setStatus(t(UNEXPECTED_ERROR));
        }
      })
      .catch((error: ApolloError) => {
        enqueueSnackbar(t('failedToUpdatePassword'), { variant: 'error' });

        const errorMessage = error.message || UNEXPECTED_ERROR;
        console.error(errorMessage);
        enqueueSnackbar(t(errorMessage), { variant: 'error' });
      });

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ password: '', passwordConfirm: '' }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={PasswordSchema}
    >
      <PasswordForm />
    </Formik>
  );
};

export default PasswordFormComponent;
