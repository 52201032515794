import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AdminStore } from '../../../Context';
import { checkSignedIn, removeToken } from '../../../libs/auth';
import { APP_NAME } from '../../../libs/constant';
import LanguageSwitcher from '../../molecules/LanguageSwitcher';
import LanguageMenu, { LanguageMenuChildProps } from '../LanguageMenu';
import LanguageButton from './LanguageButton';
import MenuLink from './MenuLink';

interface FooterProps {
  padding?: string;
}

const Footer = (props: FooterProps) => {
  const { padding } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatch } = useContext(AdminStore);

  const handleSignout = () => {
    removeToken();
    dispatch({ type: 'LOGOUT' });
    history.push('/');
  };

  return (
    <Wrapper padding={padding}>
      <Copyright>{`Copyright © ${APP_NAME}. All right reserved.`}</Copyright>
      <LinkWrapper>
        <MenuLink href="/privacy" title="Privacy Policy" />
        <MenuLink href="/terms" title="Terms Of Service" />
        <Anchor href="https://casting-asia.com/contact/" target="_blank">
          {t('Button.Contact')}
        </Anchor>
        <LanguageMenu>
          {({ title, handleButtonClick, visible, handleSwitcherClick }: LanguageMenuChildProps) => (
            <>
              <LanguageButton title={title} onClick={handleButtonClick} />
              <Switcher visible={visible} onClick={handleSwitcherClick} />
            </>
          )}
        </LanguageMenu>
        {checkSignedIn() && <SignOutButton onClick={handleSignout}>{t('Button.Sign Out')}</SignOutButton>}
      </LinkWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.footer<{ padding?: string }>`
  display: flex;
  align-items: center;
  padding: ${props => props.padding || '24px'};
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
`;

const Copyright = styled.p`
  margin-right: 24px;
  font-size: 12px;
  line-height: 16px;
  color: #6e7c89;
`;

const LinkWrapper = styled.div`
  display: flex;
  color: #6e7c89;
`;

const Anchor = styled.a`
  padding: 0 14px;
  border-right: 1px solid #8694a2;
  color: #6e7c89;
  font-size: 12px;
  line-height: 16px;

  &:hover {
    text-decoration: underline;
  }
`;

const Switcher = styled(LanguageSwitcher)`
  bottom: 24px;
  left: 0;
`;

const SignOutButton = styled.button`
  color: #6e7c89;
  font-size: 12px;
  line-height: 16px;
  margin-left: 16px;
  cursor: pointer;
`;

export default Footer;
