import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { initialPagingParam } from '../../libs/paging';
import { setUrlParams } from '../../libs/url';
import { AnalyticsAdminFilterTypeV2 } from '../../components/organisms/Analytics/types';
import { getAdminFilterParams, getInitialAdminFilter } from './utils';

export const useAdminFilter = (): [AnalyticsAdminFilterTypeV2, (filter: AnalyticsAdminFilterTypeV2) => void] => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const initialFilter = getInitialAdminFilter(search);
  const [filter, baseSetFilter] = useState(initialFilter);

  const setFilter = (newFilter: AnalyticsAdminFilterTypeV2) => {
    baseSetFilter(newFilter);

    // Set parameters to Url
    const newUrlParams = {
      ...initialPagingParam, // Reset paging
      ...getAdminFilterParams(newFilter)
    };
    setUrlParams(history, pathname, search, newUrlParams);
  };

  return [filter, setFilter];
};
