import styled from '@emotion/styled';
import * as React from 'react';

// Types
export interface LanguageButtonProps {
  title: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const LanguageButton = (props: LanguageButtonProps) => {
  const { title, onClick } = props;

  return <Button onClick={onClick}>{title}</Button>;
};

const Button = styled.button`
  box-sizing: border-box;
  width: 100%;
  padding: 0 16px;
  text-align: left;
  line-height: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
  color: #6e7c89;

  &:hover {
    background-color: #efefef;
  }
`;

export default LanguageButton;
