import React from 'react';
import css from '@emotion/css';
import PostingHabits from '../../AnalyticsDashboard/PostingHabits';
import { InstagramComparePosts_instagramComparePosts_compareAccounts_postsHabit } from './__generated__/InstagramComparePosts';

const PostingHabitsDialogContent = ({
  postsHabit
}: {
  postsHabit: InstagramComparePosts_instagramComparePosts_compareAccounts_postsHabit[];
}) => (
  <PostingHabits
    postsHabit={postsHabit}
    showHeader={false}
    css={css`
      margin-top: 24px;
    `}
  />
);

export default PostingHabitsDialogContent;
