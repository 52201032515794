import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatIntNumber } from '../../../libs/format';

interface PageCountProps {
  className?: string;
  first?: number;
  last?: number;
  total: number;
  countLabel: string;
}

const PageCount = (props: PageCountProps) => {
  const { className, total, countLabel } = props;
  const { t } = useTranslation();

  if (total === 0) {
    return null;
  }

  return (
    <Stats className={className}>
      <Value>{formatIntNumber(total)}</Value> {t(`${countLabel} found`)}
    </Stats>
  );
};

const Stats = styled.p`
  color: #27313b;
  font-size: 13px;
  align-items: baseline;
`;
const Value = styled.span`
  line-height: 18px;
  font-weight: 600;
  margin-right: 4px;
`;

export default PageCount;
