import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { AnalyticsAdvertiserFilterTypeV2 } from '../types';
import { FormValues } from './types';
import AnalyticsAdvertiserFilter from './AnalyticsAdvertiserFilter';

export interface AnalyticsFilterComponentProps {
  filter: AnalyticsAdvertiserFilterTypeV2;
  setFilter: (filter: AnalyticsAdvertiserFilterTypeV2) => void;
}

const AnalyticsFilterComponent = (props: AnalyticsFilterComponentProps) => {
  const { filter, setFilter } = props;

  const handleSubmit = (payload: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const { keyword, subscriptionPlan } = payload;

    setFilter({ keyword, subscriptionPlan });
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        keyword: filter.keyword || '',
        subscriptionPlan: filter.subscriptionPlan || ''
      }}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      <AnalyticsAdvertiserFilter />
    </Formik>
  );
};

export default AnalyticsFilterComponent;
