import React from 'react';
import styled from '@emotion/styled';
import * as Styled from '../../AnalyticsShared/Overview/StyledComponents';
import { getGrowthPrefix, getGrowthArrow } from '../../AnalyticsShared/utils';
import { formatPercent, formatNumber, formatIntNumber } from '../../../../libs/format';

interface GrowthItemType {
  total: number | null;
  growth: number | null;
  rate: number | null;
  hasDecimals?: boolean;
  hasHyphen?: boolean;
}
export const TableGrowthSection = (item: GrowthItemType) => {
  const isPositiveGrowth = item.growth === 0 ? undefined : Number(item.growth) > 0;
  const prefix = getGrowthPrefix(item.growth) || '';
  const arrow = getGrowthArrow(item.growth);
  // FIXME: remove after BE will support null for newly fetched hashtags
  const temporatyNullableTotal = item.hasHyphen ? item.total || null : item.total;
  const formattedTotal = !!item.hasDecimals
    ? formatNumber(temporatyNullableTotal, 2)
    : formatIntNumber(temporatyNullableTotal);
  const formattedGrowth = !!item.hasDecimals ? formatNumber(item.growth, 2) : formatIntNumber(item.growth);

  return (
    <TDWrapper>
      <Styled.PartWithIcon>
        <Styled.LeftPart>
          <Styled.TotalNumber>{formattedTotal}</Styled.TotalNumber>
        </Styled.LeftPart>
      </Styled.PartWithIcon>
      {formattedTotal !== '-' && (
        <Styled.Growth isPositive={isPositiveGrowth}>{`${prefix}${formattedGrowth}  ${arrow}  (${formatPercent(
          item.rate
        )})`}</Styled.Growth>
      )}
    </TDWrapper>
  );
};

const TDWrapper = styled.td`
  padding-left: 16px !important;
`;
