import styled from '@emotion/styled';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { format, isDate, isValid, parse } from 'date-fns';
import React, { useRef, useState } from 'react';
import { Modifier } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import classNames from 'react-day-picker/lib/src/classNames';
import 'react-day-picker/lib/style.css';
import { DEFAULT_FNS_DATE_FORMAT } from '../../../libs/constant';
import { CustomInput, Navbar } from './Components';
import { DAYPICKER_INPUT_FORMAT, getDefaultDate, placeholderSingle } from './helpers';
import { PickerDate } from './types';

interface DatePickerProps extends WithStyles {
  value: string;
  handleChangeDay: (day: string) => void;
  error?: boolean;
  disabled?: boolean;
  disabledRange?: Modifier | Modifier[];
}

const DayPickerHandInput = ({ classes, value, disabled, error, handleChangeDay, disabledRange }: DatePickerProps) => {
  // State
  const defaultInputState = {
    from: getDefaultDate(value)
  };
  const [state, setState] = useState<{
    from?: PickerDate;
  }>(defaultInputState);

  const inputRef = useRef(null);

  // Handlers
  const handleDayClick = (day: Date) => {
    setState({
      from: day
    });
    handleChangeDay(format(day, DEFAULT_FNS_DATE_FORMAT));
  };

  const handleChange = (day: Date) => {
    if (!day || !isValid(day)) {
      return;
    }
    handleDayClick(new Date(day));
  };

  const parseDate = (str: string, parseFormat: string) => {
    if (str.length !== parseFormat.length) {
      return undefined;
    }
    const parsed = parse(str, parseFormat, new Date());

    if (isDate(parsed) && isValid(parsed)) {
      return parsed;
    }

    return undefined;
  };
  const formatDate = (date: Date, pickerFormat: string) => format(date, pickerFormat);

  return (
    <WidgetBlock>
      <DayPickerInput
        formatDate={formatDate}
        format="MMM dd, yyyy"
        placeholder={placeholderSingle}
        parseDate={parseDate}
        value={state.from ? format(state.from, DAYPICKER_INPUT_FORMAT) : ''}
        onDayChange={handleChange}
        inputProps={{ error, disabled }} // to pass props in CustomInput
        component={CustomInput}
        ref={inputRef}
        classNames={{
          // there is NO default DayPickerInput classNames export in react-day-picker
          container: classes.inputWrapper,
          overlayWrapper: 'DayPickerInput-OverlayWrapper',
          overlay: 'DayPickerInput-Overlay'
        }}
        dayPickerProps={{
          numberOfMonths: 2,
          selectedDays: state.from || undefined,
          // eslint-disable-next-line react/display-name
          navbarElement: navbarProps => <Navbar {...navbarProps} />,
          classNames: { ...classNames, ...classes },
          captionElement: () => null,
          initialMonth: state.from || new Date(),
          disabledDays: disabledRange
        }}
      />
    </WidgetBlock>
  );
};

const styles = createStyles({
  inputWrapper: {
    width: '100%'
  },
  tooltip: {
    fontSize: '12px'
  },
  button: {
    color: 'rgb(23, 156, 215)'
  },
  actions: {
    alignItems: 'baseline'
  },
  months: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  today: {
    color: 'tomato'
  },
  selected: {
    backgroundColor: '#4A90E2',
    color: '#fff',
    borderRadius: '0 !important'
  },
  day: {
    display: 'table-cell',
    padding: '0.5em',
    borderRadius: 0,
    verticalAlign: 'middle',
    textAlign: 'center',
    cursor: 'pointer',

    '&.outside': {
      backgroundColor: '#fff !important'
    },

    '&:not($selected)': {
      '&:hover ': {
        backgroundColor: '#e1e2e3'
      }
    }
  },
  container: {
    input: {
      width: '200px',
      height: '32px',
      border: '1px solid #e0e8ed',
      borderRadius: '2px'
    }
  },
  wrapper: {
    outline: 'none'
  },
  disabled: {
    opacity: 0.3,
    backgroundColor: '#fff !important',
    color: '#000',
    cursor: 'unset',
    outline: 'none',

    '&:hover': {
      backgroundColor: '#fff !important',
      color: '#000'
    }
  }
});

const WidgetBlock = styled.div`
  flex-direction: column;
`;

export default withStyles(styles)(DayPickerHandInput);
