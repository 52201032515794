import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import css from '@emotion/css';
import { SliderComponents, SliderCss, StyledComponents } from '../../molecules/SliderTable';
import CustomCss from '../AnalyticsCompare/AnalyticsComparePosts/CustomCss';
import { HeaderColumn, TextColumn } from '../../atoms/List';
import { formatIntNumber, formatNumber } from '../../../libs/format';
import * as BaseStyled from '../AnalyticsDashboard/StyledComponents';
import { TitleWithHelp } from '../AnalyticsShared/AnalyticsContentWrapper';
import { getUserAnalysisColumns } from '../AnalyticsPost/UserAnalysisList/helpers';
import { InstagramInteractionPosts_allInstagramInteractionUsers_usernames } from './__generated__/InstagramInteractionPosts';

interface UserAnalysisType {
  userAnalytics: InstagramInteractionPosts_allInstagramInteractionUsers_usernames[] | null;
}
const UserAnalysis = ({ userAnalytics }: UserAnalysisType) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <StyledTitleWithHelp title="User Analysis" help="interactionUserAnalysis" />
      <SliderComponents.Wrapper css={[SliderCss.clearPadding, CustomCss.clearBorder]}>
        <SliderComponents.Container>
          <SliderComponents.SliderSection>
            <SliderComponents.SliderTable>
              <thead>
                <SliderComponents.HeaderRow css={[SliderCss.bgndColor]}>
                  <SliderComponents.StyckyHeaderDivWrapper>
                    <StyledHeader
                      title="User Name"
                      css={css`
                        width: 230px;
                        padding: 0;
                      `}
                      isTable={false}
                    />
                  </SliderComponents.StyckyHeaderDivWrapper>
                  {/* Slider */}
                  {getUserAnalysisColumns().map(({ id: colId, title, customCss, help }) => (
                    <HeaderColumn key={colId} title={title} help={help} css={customCss} />
                  ))}
                </SliderComponents.HeaderRow>
              </thead>

              <tbody>
                {userAnalytics?.map((item, index) => {
                  const {
                    username,
                    avgMentionedComments,
                    avgMentionedLikes,
                    avgTaggedComments,
                    avgTaggedLikes,
                    avgTotalComments,
                    avgTotalLikes,
                    mentionedPosts,
                    taggedPosts,
                    totalPostsCount
                  } = item;

                  return (
                    <StyledComponents.StyledRowNew
                      css={[SliderCss.paddingSides, SliderCss.rowHeight40, SliderCss.boderBottom]}
                      key={index}
                    >
                      <SliderComponents.StickyBodyRowWrapper>
                        <Anchor target="_blank" rel="noreferrer" href={`https://www.instagram.com/${username}`}>
                          {username}
                        </Anchor>
                      </SliderComponents.StickyBodyRowWrapper>
                      <TextColumn
                        data={formatIntNumber(totalPostsCount)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                      <TextColumn
                        data={formatIntNumber(taggedPosts)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                      <TextColumn
                        data={formatIntNumber(mentionedPosts)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                      <TextColumn
                        data={formatNumber(avgTotalLikes, 2)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                      <TextColumn
                        data={formatNumber(avgTaggedLikes, 2)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                      <TextColumn
                        data={formatNumber(avgMentionedLikes, 2)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                      <TextColumn
                        data={formatNumber(avgTotalComments, 2)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                      <TextColumn
                        data={formatNumber(avgTaggedComments, 2)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                      <TextColumn
                        data={formatNumber(avgMentionedComments, 2)}
                        css={SliderCss.getPaddingLeft('32px !important')}
                      />
                    </StyledComponents.StyledRowNew>
                  );
                })}
              </tbody>
            </SliderComponents.SliderTable>
          </SliderComponents.SliderSection>
        </SliderComponents.Container>
      </SliderComponents.Wrapper>

      <BaseStyled.MoreText to={`/analytics/dashboard/${id}/interaction/user_analysis`}>
        <span>{t('Button.More')}</span> <BaseStyled.Arrow>{'\u2192'}</BaseStyled.Arrow>
      </BaseStyled.MoreText>
    </>
  );
};

const StyledHeader = styled(HeaderColumn)`
  & > div {
    justify-content: flex-start;
  }
`;
const StyledTitleWithHelp = styled(TitleWithHelp)`
  padding: 16px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #27313b;
`;
const Anchor = styled.a`
  color: #3892e5;
  font-weight: normal;

  &:hover {
    text-decoration: underline;
  }
`;

export default UserAnalysis;
