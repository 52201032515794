import styled from '@emotion/styled';
import { Button, ClickAwayListener, DialogActions, Icon, Popover } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import DayPicker from 'react-day-picker';
import classNames from 'react-day-picker/lib/src/classNames';
import 'react-day-picker/lib/style.css';
import { useTranslation } from 'react-i18next';
import { Navbar } from './Components';
import { getDefaultDate, placeholderSingle } from './helpers';
import { PickerDate } from './types';

interface DatePickerProps extends WithStyles {
  value: string;
  handleChangeDay: (day: string) => void;
  error?: boolean;
  disabled?: boolean;
}
const ReactDatePicker = ({ classes, value, disabled, error, handleChangeDay }: DatePickerProps) => {
  // State
  const defaultInputState = {
    from: getDefaultDate(value)
  };
  const [state, setState] = useState<{
    from: PickerDate;
  }>(defaultInputState);
  const [datePicketInput, setDatePicketInput] = useState<string>(placeholderSingle);
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);

  const { t } = useTranslation();
  // if form has default period values, than set picker input
  useEffect(() => {
    if (resultInput) {
      setDatePicketInput(resultInput);
    }
  }, []);

  // Handlers
  const handleDayClick = (day: PickerDate) => {
    setState({
      from: day
    });
  };

  const handleUpdate = () => {
    setDatePicketInput(resultInput);
    setAnchor(null);
    if (state.from) {
      handleChangeDay(format(state.from, 'yyyy-MM-dd'));
    }
  };

  const resultInput = !!state.from ? format(state.from, 'MMMM dd, yyyy') : '';
  const isOpen = Boolean(anchor) && !disabled;
  const isPlaceholder = datePicketInput === placeholderSingle;

  return (
    <WidgetBlock>
      <StyledPickerInput
        onClick={({ target }) => setAnchor(target as HTMLDivElement)}
        disabled={!!disabled}
        error={!!error}
      >
        <Icon className="material-icons" fontSize="small">
          calendar_today
        </Icon>
        <DateInput isPlaceholder={isPlaceholder}>{datePicketInput}</DateInput>
      </StyledPickerInput>

      <Popover
        id="dayPicker"
        open={isOpen}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <ClickAwayListener onClickAway={() => setAnchor(null)}>
          <div>
            <Wrapper className="RangeExample">
              <StyledDayPicker
                className="Range"
                numberOfMonths={2}
                selectedDays={state.from || undefined}
                onDayClick={handleDayClick}
                navbarElement={navbarProps => <Navbar {...navbarProps} />}
                classNames={{ ...classNames, ...classes }}
                captionElement={() => null}
                initialMonth={state.from || new Date()}
              />
            </Wrapper>
            <ControlPanel>
              <DialogActions classes={{ root: classes.actions }}>
                <p>{resultInput}</p>
                <Button onClick={() => setAnchor(null)}>{t('Button.Cancel')}</Button>
                <Button type="submit" color="primary" classes={{ root: classes.button }} onClick={handleUpdate}>
                  {t('Button.Update')}
                </Button>
              </DialogActions>
            </ControlPanel>
          </div>
        </ClickAwayListener>
      </Popover>
    </WidgetBlock>
  );
};

const styles = createStyles({
  tooltip: {
    fontSize: '12px'
  },
  button: {
    color: 'rgb(23, 156, 215)'
  },
  actions: {
    alignItems: 'baseline'
  },
  months: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  today: {
    color: 'tomato'
  },
  selected: {
    backgroundColor: '#4A90E2',
    color: '#fff',
    borderRadius: '0 !important'
  },
  day: {
    display: 'table-cell',
    padding: '0.5em',
    borderRadius: 0,
    verticalAlign: 'middle',
    textAlign: 'center',
    cursor: 'pointer',

    '&.outside': {
      backgroundColor: '#fff !important'
    },

    '&:not($selected)': {
      '&:hover ': {
        backgroundColor: '#e1e2e3'
      }
    }
  }
});

const ControlPanel = styled.div``;

const WidgetBlock = styled.div`
  flex-direction: column;
`;
const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const StyledDayPicker = styled(DayPicker)`
  border-bottom: 1px solid #e4e5e6;
`;
const StyledPickerInput = styled.div<{ disabled: boolean; error: boolean }>`
  align-self: center;
  width: 150px;
  height: 38px;
  background-color: #fff;
  position: relative;
  border: 1px solid #e0e8ed;
  border-radius: 2px;
  padding-left: 40px;
  font-size: 13px;
  line-height: 3em;
  ${({ disabled }) => disabled && 'background-color: #f7f7f7; cursor: default;'}
  ${({ error }) => error && 'border-color: tomato;'}

  span,
  svg {
    position: absolute;
    left: 10px;
    top: 8px;
    color: #d5d5d5;
  }
`;

const DateInput = styled.p<{ isPlaceholder: boolean }>`
  ${({ isPlaceholder }) => isPlaceholder && 'opacity: 0.2;'}
`;

export default withStyles(styles)(ReactDatePicker);
