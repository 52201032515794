import React, { useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { useLocation } from 'react-router';
import { css } from '@emotion/core';
import CarouselModal from '../../../molecules/CarouselPopup';
import { CombinedAccountsType } from '../../AnalyticsDashboard/Overview/AnalyticsOverview';
import BaseHeaderTab from '../../AnalyticsShared/BaseHeaderTab';
import { useQueryHelper } from '../../../../libs/hooks';
import StyledForModal from '../StyledComponents';
import PostDetail from '../../AnalyticsDashboard/PostDetail';
import {
  InstagramAnalyticsSummaryPostsInDate,
  InstagramAnalyticsSummaryPostsInDateVariables
} from './__generated__/InstagramAnalyticsSummaryPostsInDate';
import * as COMPARE_DAILY_POSTS from './InstagramAnalyticsSummaryPostsInDate.graphql';

interface AnayticsFeedPostDetailProps {
  accountId: number;
  comparableAccountIds: number[];
  postDate: string;
  openPostDetail: boolean;
  handleClosePostDetail: () => void;
}

const CompareDailyPostDetail = (props: AnayticsFeedPostDetailProps) => {
  const { accountId, comparableAccountIds, postDate, openPostDetail, handleClosePostDetail } = props;
  const { pathname } = useLocation();
  const { t, history, enqueueSnackbar } = useQueryHelper();

  const [selectedAccount, setSelectedAccount] = useState<{ id: number; elementId?: number }>({
    id: 0,
    elementId: undefined
  });
  const [currSlideIdx, setCurrentSlideIdx] = useState<number>(0);

  const [allPostFormattedData, setAllPostsData] = useState<CombinedAccountsType[][]>([]);

  const { loading, error } = useQuery<
    InstagramAnalyticsSummaryPostsInDate,
    InstagramAnalyticsSummaryPostsInDateVariables
  >(COMPARE_DAILY_POSTS, {
    variables: { accountId, comparableAccountIds, postDate },
    fetchPolicy: 'cache-and-network',
    onError: useCallback(() => {
      history.push(pathname);
      enqueueSnackbar(t('Posts were not found'), { variant: 'error' });
    }, []),
    onCompleted: useCallback((data: InstagramAnalyticsSummaryPostsInDate) => {
      const combinedMainAccPostsData = [
        ...(data.allInstagramComparePostsInDate?.mainAccount.feedPosts?.map(feedPost => ({
          ...feedPost,
          type: 'feedPost'
        })) || []),
        ...(data.allInstagramComparePostsInDate?.mainAccount.storyPosts?.map(storyPost => ({
          ...storyPost,
          type: 'storyPost'
        })) || [])
      ];
      const combinedComparableAccPostsData = data.allInstagramComparePostsInDate
        ? data.allInstagramComparePostsInDate.compareAccounts
            .filter(acc => acc.feedPosts !== null)
            .map(acc =>
              acc.feedPosts ? acc.feedPosts.map(post => ({ ...post, accountId: acc.accountId, type: 'feedPost' })) : []
            )
        : [];

      const allPostsData = [combinedMainAccPostsData, ...combinedComparableAccPostsData].filter(el => el?.length);

      setAllPostsData(allPostsData as CombinedAccountsType[][]); // typecasting due to limitation of UnionToIntersection type
      // set first post elementId for determine active modal tab
      setSelectedAccount({ id: 0, elementId: allPostsData[0]?.[0]?.id });
    }, [])
  });

  if (loading || error) {
    return <CircularProgress size={64} thickness={2.4} color="inherit" />;
  } else if (!allPostFormattedData.length) {
    return null;
  }

  const accountsDailyPosts = allPostFormattedData.map((el, i) => ({
    value: `${i}`,
    elementId: el[0].id,
    avatar: el[0].avatarUrl,
    label: el[0].instagramAccountName
  }));

  const onChangeTab = (tabId: string, elementId?: number) => {
    setSelectedAccount({ id: +tabId, elementId });
    setCurrentSlideIdx(0);
  };
  const onClosePostDialog = () => {
    handleClosePostDetail();
    setSelectedAccount({ id: 0, elementId: undefined });
    setCurrentSlideIdx(0);
  };

  const {
    avatarUrl,
    instagramAccountName,
    followers,
    content,
    postImageUrl,
    postUrl,
    analysis,
    commentList,
    sentiment,
    comments,
    reach,
    impressions,
    exits,
    tapsForward,
    tapsBack,
    type
  } = allPostFormattedData[selectedAccount.id][currSlideIdx];
  const postsPreviewData = allPostFormattedData[selectedAccount.id].map(el => ({
    id: el.id,
    thumbNail: el.postImageUrl
  }));

  return (
    <CarouselModal open={openPostDetail} title="" cancel="OK" onClose={onClosePostDialog}>
      <>
        <BaseHeaderTab
          isPostDetailTabs
          items={accountsDailyPosts}
          selectedAccountId={String(selectedAccount.id)}
          selectedElementId={selectedAccount.elementId}
          onChange={onChangeTab}
          css={css`
            margin-left: 16px;

            & > div {
              height: 40px;
            }
          `}
        />
        <StyledForModal.PostDetailWrapper
          css={css`
            border-top-left-radius: 0;
          `}
        >
          <PostDetail
            avatarUrl={avatarUrl}
            closeModal={onClosePostDialog}
            accountName={instagramAccountName}
            followers={followers || 0}
            content={content}
            commentList={commentList || []}
            postImageUrl={postImageUrl}
            engagement={{
              postUrl,
              analysis: analysis || null,
              sentiment: sentiment || null,
              storyData: type === 'storyPost' ? { comments, reach, impressions, exits, tapsForward, tapsBack } : null
            }}
            currSlideIdx={currSlideIdx}
            setCurrentSlideIdx={setCurrentSlideIdx}
            postsPreviewData={postsPreviewData}
          />
        </StyledForModal.PostDetailWrapper>
      </>
    </CarouselModal>
  );
};

export default CompareDailyPostDetail;
