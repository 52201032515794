import React from 'react';
import styled from '@emotion/styled';

interface SliderArrowProps {
  type: 'next' | 'prev';
  onClick: () => void;
  hasBgImage?: boolean;
}

const SliderArrow = (props: SliderArrowProps) => {
  const { type, hasBgImage = true, ...rest } = props;

  return (
    <SliderNav type={type} {...rest}>
      <Shadow type={type} hasBgImage={hasBgImage} />
      <Icon className="material-icons">{`chevron_${type === 'next' ? 'right' : 'left'}`}</Icon>
    </SliderNav>
  );
};

const Shadow = styled.div<{ type: SliderArrowProps['type']; hasBgImage?: boolean }>`
  position: absolute;
  height: calc(100% + 2px);
  width: 36px;
  top: -1px;
  ${({ type, hasBgImage }) =>
    hasBgImage
      ? type === 'next'
        ? 'right: 29px; background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #f9fbfc);'
        : 'left: 29px; background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #f9fbfc);'
      : ''};
`;
const SliderNav = styled.div<SliderArrowProps>`
  ${({ type }) => type === 'next' && 'right: 0'};
  ${({ type }) => type === 'prev' && 'left: 0'};
  z-index: 1;
  width: 28px;
  height: calc(100% - 4px);
  position: absolute;
  top: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #6e7c89;
  border: 1px solid #dee5ec;

  &:hover {
    cursor: pointer;
  }
`;
const Icon = styled.i`
  color: #6e7c89;
  font-size: 18px;
  width: 16px;
  height: 16px;

  &:hover {
    text-decoration: none !important;
  }
`;

export default SliderArrow;
