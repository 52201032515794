import styled from '@emotion/styled';
import css from '@emotion/css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

interface ButtonProps extends StyledButtonProps {
  className?: string;
  icon?: string;
  title: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  type?: 'button' | 'reset' | 'submit';
  i18Label?: string;
}

const Button = (props: ButtonProps) => {
  const { t } = useTranslation();
  const {
    className,
    onClick,
    icon,
    title,
    disabled,
    color,
    bgcolor,
    hoverbgcolor,
    activeColor,
    loading = false,
    type = 'button',
    i18Label = 'Button'
  } = props;

  return (
    <StyledButton
      className={className}
      disabled={disabled}
      color={color}
      bgcolor={bgcolor}
      hoverbgcolor={hoverbgcolor}
      activeColor={activeColor}
      onClick={onClick}
      type={type}
    >
      {loading ? (
        <CircularProgress
          size={18}
          thickness={2.5}
          css={css`
            margin-right: 9px;
            margin-left: -15px;
            color: #fff;
          `}
        />
      ) : icon ? (
        <Icon src={icon} />
      ) : null}

      <p>{t(`${i18Label}.${title}`).toUpperCase()}</p>
    </StyledButton>
  );
};

interface StyledButtonProps {
  disabled?: boolean;
  color?: string;
  bgcolor?: string;
  hoverbgcolor?: string;
  activeColor?: string;
}

const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.bgcolor ? props.bgcolor : '#fff')};
  border-radius: 3px;
  border: solid 1px #dee5ec;
  color: ${props => (props.color ? props.color : '#fff')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  text-align: center;
  cursor: ${props => (props.disabled ? 'unset' : 'pointer')};
  transition: background-color 0.3s ease-out;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'inherit')};
  min-width: 105px;
  box-sizing: border-box;
  height: 32px;

  &:hover {
    border-color: #c5d0da;
    background-color: ${props => props?.hoverbgcolor || props?.bgcolor || '#fff'};
  }

  &:active {
    background-color: ${props => props?.activeColor || props?.bgcolor || '#f6f8fa'};
  }
`;

const Icon = styled.img`
  margin-right: 9px;
  width: 18px;
`;

export default Button;
