import styled from '@emotion/styled';
import * as React from 'react';

export interface FieldType {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: any) => void;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  name?: string;
}

export interface TextSearchProps extends FieldType {
  placeholder: string;
  value?: string;
}

const TextSearch = (props: TextSearchProps) => {
  const { placeholder, value, name, onBlur, onChange, onKeyPress } = props;

  return (
    <Wrapper>
      <Icon className="material-icons">search</Icon>
      <Input
        type="text"
        placeholder={placeholder}
        value={value}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 0 12px 0 38px;
  background-color: #fff;
  border: solid 1px #dee5ec;
  border-radius: 3px;
  font-size: 13px;
  line-height: 30px;

  &:focus {
    border-color: #179cd7;
  }
`;

const Icon = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 32px;
  position: absolute;
  left: 1px;
  top: 1px;
  background-color: transparent;
  color: #d5d5d5;
  font-size: 22px;
  pointer-events: none;
`;

export default TextSearch;
