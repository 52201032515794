import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { PeriodType } from '../../../../libs/types';
import { getParamsFromSearch } from '../../../../libs/paging/utils';
import { getThirtyDaysAgo, getLastDay } from '../../../../libs/date';
import { setUrlParams } from '../../../../libs/url';
import { initialPagingParam } from '../../../../libs/paging';
import { BasePostFilterType } from './types';

interface FilterParams {
  kw: string;
  stda: string;
  enda: string;
}

export const getFilterParams = (params: BasePostFilterType): FilterParams => ({
  kw: params.keyword || '',
  stda: params.period.startDate || '',
  enda: params.period.endDate || ''
});

export const getInitialFilter = (search: string, defaultPeriod?: PeriodType) => {
  const params = getParamsFromSearch<FilterParams>(search);

  const keyword = params.kw;
  const startDate = params.stda;
  const endDate = params.enda;

  const initialFilter: BasePostFilterType = {
    keyword: keyword || '',
    period: {
      startDate: startDate || defaultPeriod?.startDate || getThirtyDaysAgo(),
      endDate: endDate || defaultPeriod?.endDate || getLastDay()
    }
  };

  return initialFilter;
};

export const useFilter = (defaultPeriod?: PeriodType) => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const initialFilter = getInitialFilter(search, defaultPeriod);
  const [filter, baseSetFilter] = useState<BasePostFilterType>(initialFilter);

  const setFilter = (newFilter: BasePostFilterType) => {
    baseSetFilter(newFilter);

    // Set parameters to Url
    const newUrlParams = {
      ...initialPagingParam,
      ...getFilterParams(newFilter)
    };
    setUrlParams(history, pathname, search, newUrlParams);
  };

  return { filter, setFilter };
};
