import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Omit } from '../../../libs/types';
import { TextSearchProps } from '../../atoms/InputText/TextSearch';
import * as InputText from '../../atoms/InputText';
import Label from '../../atoms/Label';

export interface SearchFormProps extends Omit<TextSearchProps, 'onKeyPress'> {
  className?: string;
  title: string;
  isRequired?: boolean;
  help?: string;
  onEnterKeyPress?: () => void;
}

const SearchForm = (props: SearchFormProps) => {
  const { t } = useTranslation();
  const { className, title, isRequired, help, placeholder, value, name, onBlur, onChange, onEnterKeyPress } = props;

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!!onEnterKeyPress && e.charCode === 13) {
      onEnterKeyPress();
    }
  };

  return (
    <div className={className}>
      <Label title={t(`Search.${title}`)} isRequired={isRequired} help={help} />
      <InputText.TextSearch
        placeholder={t(`Search.${placeholder}`)}
        value={value}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
    </div>
  );
};

export default SearchForm;
