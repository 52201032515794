import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import React, { useCallback } from 'react';
import { useQueryHelper } from '../../libs/hooks';
import { useConnectedAccountFromRouterState } from '../AnalyticsAdd/useConnectedAccountFromRouterState';
import Dialog from '../../components/molecules/Dialog';
import { getSelectAccountModalContent } from '../AnalyticsAdd/helpers';
import * as StyledComponents from '../AnalyticsAdd/StyledComponents';
import warningIcon from '../../assets/icon/warning.svg';
import { AccountsAnalyticDetails, AccountsAnalyticDetailsVariables } from './__generated__/AccountsAnalyticDetails';
import ANALYTICS_ACCOUNT_DETAILS from './AccountsAnalyticsDetails.graphql';
import Template from './template';

const AnalyticsEdit = () => {
  const { id: mainAccountId } = useParams<{ id: string }>();
  const { t, history, enqueueSnackbar } = useQueryHelper();
  const { state, closeIgModal, handleSelectAccountId } = useConnectedAccountFromRouterState();

  const { data } = useQuery<AccountsAnalyticDetails, AccountsAnalyticDetailsVariables>(ANALYTICS_ACCOUNT_DETAILS, {
    variables: { id: Number(mainAccountId) },
    skip: !mainAccountId,
    onError: useCallback(() => {
      enqueueSnackbar(t(`Account doesn't exist`), { variant: 'error' });
      history.push('/analytics');
    }, [])
  });

  const advertiserId = data?.accountAnalyticDetailsV2?.advertiser.id || '';
  const accountName = data?.accountAnalyticDetailsV2.accountName || '';
  const accountManagerIds =
    data?.accountAnalyticDetailsV2?.accountManagers.map(accountManager => String(accountManager.id)) || [];

  const {
    connectableIgAccounts,
    connectableYtChannels,
    connectedIgAccountId,
    connectedYtChannelId,
    isModalOpen
  } = state;

  const subscriptionType = data?.accountAnalyticDetailsV2.subscriptionPlan;

  // Accounts selected from Choose Account Modal
  const chosenConnectedIgAccount =
    connectedIgAccountId && connectableIgAccounts?.length
      ? connectableIgAccounts.find(acc => acc.igAccount.id === connectedIgAccountId)
      : undefined;
  const formattedChosenIgAccount = connectedIgAccountId
    ? {
        fbPageName: chosenConnectedIgAccount?.page.name || '',
        fbPageId: chosenConnectedIgAccount?.page.id || '',
        instagramAccountName: chosenConnectedIgAccount?.igAccount.name || '',
        needReconnect: chosenConnectedIgAccount?.isAlreadyUsed || false,
        businessAccountId: '',
        id: chosenConnectedIgAccount?.igAccount.id || 0
      }
    : undefined;
  const chosenConnectedYtChannel =
    connectedYtChannelId && connectableYtChannels?.length
      ? connectableYtChannels.find(channel => channel.channelId === connectedYtChannelId)
      : undefined;
  // If we have RouterState e.g. some acc data come from signIn flow we need to set it first
  const connectedIgAccount = formattedChosenIgAccount ||
    data?.accountAnalyticDetailsV2.socialMediaAccounts.instagram || {
      fbPageName: 'Facebook Account',
      fbPageId: '',
      instagramAccountName: 'Instagram Account',
      needReconnect: false,
      businessAccountId: '',
      id: 0
    };
  const connectedYtChannel = chosenConnectedYtChannel ||
    data?.accountAnalyticDetailsV2.socialMediaAccounts.youtube || {
      channelId: '',
      channelName: ''
    };

  const hasConnectableAccounts = connectableIgAccounts?.length || connectableYtChannels?.length;
  const modalTitle = connectableIgAccounts?.length ? 'Choose Instagram Account' : 'Choose Youtube Channel';
  const unableToSignIn = connectableIgAccounts === undefined ? 'NoInstagramAccountLinked' : 'NoYouTubeChannelLinked';

  return (
    <>
      <Template
        formValues={{
          accountName,
          advertiserId: `${advertiserId}`,
          accountManagerIds
        }}
        connectedIgAccount={{ ...connectedIgAccount }}
        connectedYtChannel={connectedYtChannel}
        subscriptionType={subscriptionType}
      />
      {/* SignIn modals */}
      <Dialog
        open={Boolean(isModalOpen && hasConnectableAccounts)}
        title={t(modalTitle)}
        execute={t('Button.Add')}
        handleExecute={closeIgModal}
        onClose={closeIgModal}
      >
        {getSelectAccountModalContent({
          connectableIgAccounts,
          connectableYtChannels,
          connectedIgAccountId,
          connectedYtChannelId,
          handleSelectAccountId
        })}
      </Dialog>
      {/* Warning Modal when empty response */}
      <Dialog
        open={Boolean(isModalOpen && !hasConnectableAccounts)}
        titleIcon={<StyledComponents.ReconnectIcon src={warningIcon} />}
        title={t('Unable to Sign In')}
        onClose={closeIgModal}
      >
        <StyledComponents.WarningModalText>{t(unableToSignIn)}</StyledComponents.WarningModalText>
      </Dialog>
    </>
  );
};
export default AnalyticsEdit;
