import React from 'react';
import { Redirect } from 'react-router';
import PostDetail from '../../AnalyticsDashboard/PostDetail';
import { InstagramComparePostDetails } from './__generated__/InstagramComparePostDetails';

interface TemplateProps {
  closeModal: () => void;
  data: InstagramComparePostDetails | undefined;
}

const Template = (props: TemplateProps) => {
  const { data, closeModal } = props;

  if (!data || !data.instagramComparePostDetails) {
    return <Redirect to="/404" />;
  }

  const {
    instagramComparePostDetails: {
      avatarUrl,
      instagramAccountName,
      followers,
      content,
      commentList,
      postImageUrl,
      postUrl,
      analysis,
      sentiment
    }
  } = data;

  return (
    <PostDetail
      avatarUrl={avatarUrl}
      closeModal={closeModal}
      accountName={instagramAccountName}
      followers={followers}
      content={content}
      commentList={commentList || []}
      postImageUrl={postImageUrl}
      engagement={{
        postUrl,
        analysis,
        sentiment,
        storyData: null
      }}
    />
  );
};

export default Template;
