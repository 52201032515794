import styled from '@emotion/styled';
import * as React from 'react';
import { FORM } from '../../../libs/theme';

interface FieldType {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  value?: any;
  name?: string;
}

export interface TextProps extends FieldType, InputProps {
  className?: string;
  placeholder: string;
  type?: string;
  unit?: string;
  autoFocus?: boolean;
}

const Text = (props: TextProps) => {
  const { className, placeholder, unit, type, disabled, autoFocus, ...field } = props;
  const inputType = !!type ? type : 'text';

  return (
    <Wrapper className={className}>
      <Input
        type={inputType}
        placeholder={!!disabled ? '' : placeholder}
        disabled={!!disabled}
        {...field}
        autoFocus={autoFocus}
      />
      {unit && <Unit disabled={!!disabled}>{unit}</Unit>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

interface InputProps {
  error?: boolean;
  disabled?: boolean;
}

const Input = styled.input<InputProps>`
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  padding: 0 12px;
  background-color: ${props => (props.disabled ? FORM.DISABLED : '#fff')};
  border: 1px solid transparent;
  border-color: ${props => (props.error ? FORM.ERROR : FORM.BORDER)};
  border-radius: 3px;
  font-size: 12px;
  line-height: 32px;

  &:focus {
    border-color: #179cd7;
  }
`;

interface UnitProps {
  disabled?: boolean;
}

const Unit = styled.p<UnitProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 12px;
  position: absolute;
  right: 1px;
  top: 1px;
  background-color: ${props => (props.disabled ? FORM.DISABLED : '#fff')};
  color: #828282;
  font-size: 10px;
  pointer-events: none;
`;

export default Text;
