import { useQuery } from '@apollo/client';
import React from 'react';
import { getPageInfo } from '../../../../libs/paging';
import { useQueryHelper } from '../../../../libs/hooks';
import ListIndicator from '../../../molecules/ListIndicator';
import InstagramCompareFeedPostList from './InstagramCompareFeedPostList';
import { allInstagramCompareFeedPostsVariables } from './utils';
import {
  AllInstagramCompareFeedPosts,
  AllInstagramCompareFeedPostsVariables
} from './__generated__/AllInstagramCompareFeedPosts';
import * as ALL_INSTAGRAM_COMPARE_FEED_POSTS from './AllInstagramCompareFeedPosts.graphql';
import { InstagramCompareFeedPostListProps } from './types';

export const ComparableAccountFeedPosts = (props: InstagramCompareFeedPostListProps) => {
  const { currentPage, sort, setSort } = props;
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, error, loading } = useQuery<AllInstagramCompareFeedPosts, AllInstagramCompareFeedPostsVariables>(
    ALL_INSTAGRAM_COMPARE_FEED_POSTS,
    {
      variables: allInstagramCompareFeedPostsVariables(props),
      skip: !props.accountId
    }
  );

  if (loading) {
    return <ListIndicator />;
  } else if (error) {
    enqueueSnackbar(t('Can not get Instagram compare feed post data for current account'), { variant: 'error' });
  }

  const comparePosts = data?.allInstagramCompareFeedPosts || [];
  const postsPageInfo = getPageInfo(currentPage, data);

  return (
    <InstagramCompareFeedPostList
      instagramFeedPosts={comparePosts}
      sort={sort}
      setSort={setSort}
      pageInfo={postsPageInfo}
    />
  );
};
