import {
  EngagementCampaignPostStatus,
  EngagementCampaignStatus,
  MarketplaceCampaignPostStatus,
  MarketplaceCampaignPostStatusForAdvertiser,
  MarketplaceCampaignPostStatusForSearch,
  MarketplaceCampaignStatus,
  PaymentRequestStatus,
  SocialAccountStatus,
  UserRoles,
  WarningReason
} from '../../__generated__/globalTypes';
import { PostStatusType } from '../../components/organisms/Post/PostDetail/types';

export const getMarketplaceStatusMessage = (status?: MarketplaceCampaignStatus | null) => {
  switch (status) {
    case MarketplaceCampaignStatus.REVIEWING:
      return 'Reviewing';

    case MarketplaceCampaignStatus.UPCOMING:
      return 'Upcoming';

    case MarketplaceCampaignStatus.ONGOING:
      return 'Ongoing';

    case MarketplaceCampaignStatus.SUSPENDED:
      return 'Over Budget';

    case MarketplaceCampaignStatus.FINISHED:
      return 'Finished';

    default:
      return '';
  }
};

export const getEngagementStatusMessage = (status?: EngagementCampaignStatus | null, userRole?: UserRoles | null) => {
  switch (status) {
    case EngagementCampaignStatus.DRAFT:
      return userRole === UserRoles.ADVERTISER ? 'Waiting Proposal' : 'Draft';

    case EngagementCampaignStatus.REVIEWING:
      return 'Reviewing';

    case EngagementCampaignStatus.UPCOMING:
      return 'Upcoming';

    case EngagementCampaignStatus.ONGOING:
      return 'Ongoing';

    case EngagementCampaignStatus.FINISHED:
      return 'Finished';

    case EngagementCampaignStatus.LOST:
      return 'Lost';

    case EngagementCampaignStatus.DEMO:
      return 'Demo';

    default:
      return '';
  }
};

export const getMarketplacePostStatusMessage = (
  status?:
    | MarketplaceCampaignPostStatus
    | MarketplaceCampaignPostStatusForSearch
    | MarketplaceCampaignPostStatusForAdvertiser
    | null
) => {
  switch (status) {
    case MarketplaceCampaignPostStatusForSearch.JOINED:
    case MarketplaceCampaignPostStatusForAdvertiser.JOINED:
      return 'Joined';

    case MarketplaceCampaignPostStatus.APPROVED:
    case MarketplaceCampaignPostStatusForSearch.APPROVED:
      return 'Approved';

    case MarketplaceCampaignPostStatus.EFFECTIVE:
    case MarketplaceCampaignPostStatusForSearch.EFFECTIVE:
    case MarketplaceCampaignPostStatusForAdvertiser.EFFECTIVE:
    case MarketplaceCampaignPostStatus.WARNING_SOLVED:
      return 'Effective';

    case MarketplaceCampaignPostStatus.WARNING:
    case MarketplaceCampaignPostStatusForSearch.WARNING:
    case MarketplaceCampaignPostStatusForAdvertiser.WARNING:
      return 'Warning';

    case MarketplaceCampaignPostStatus.INEFFECTIVE:
    case MarketplaceCampaignPostStatusForSearch.INEFFECTIVE:
    case MarketplaceCampaignPostStatusForAdvertiser.INEFFECTIVE:
      return 'Ineffective';

    case MarketplaceCampaignPostStatusForSearch.TRACKING:
    case MarketplaceCampaignPostStatusForAdvertiser.TRACKING:
      return 'Tracking';

    default:
      return '';
  }
};

export const getEngagementPostStatusMessage = (status?: PostStatusType, forUpdateStatus?: boolean) => {
  switch (status) {
    case EngagementCampaignPostStatus.REVIEWING:
      return 'Reviewing';

    case EngagementCampaignPostStatus.READY_TO_POST:
      return !!forUpdateStatus ? 'Approved' : 'Ready to Post';

    case EngagementCampaignPostStatus.EFFECTIVE:
      return 'Effective';

    case EngagementCampaignPostStatus.WARNING:
      return 'Warning';

    case EngagementCampaignPostStatus.WARNING_SOLVED:
      return !!forUpdateStatus ? 'Warning Solved' : 'Effective';

    case EngagementCampaignPostStatus.APPROVED:
      return 'Approved';

    case EngagementCampaignPostStatus.INEFFECTIVE:
      return 'Ineffective';

    case EngagementCampaignPostStatus.REJECT:
      return 'Rejected';

    default:
      return '';
  }
};

export const getWarningReasonStatusMessage = (reason: WarningReason | MarketplaceCampaignPostStatusForSearch) => {
  switch (reason) {
    case WarningReason.AD_FRAUD:
      return 'Ad fraud';
    case WarningReason.API_ERROR:
    case WarningReason.SCRAPING_ERROR:
      return 'Scrapping error';
    case WarningReason.DELETED_POST:
      return 'Deleted post';
    case WarningReason.INVALID_TOKEN:
      return 'Invalid token';
    case WarningReason.INVALID_CONTENT:
      return 'Invalid content';

    default:
      return '';
  }
};

export const getSocialAccountStatusMessage = (status?: SocialAccountStatus | null) => {
  switch (status) {
    case SocialAccountStatus.SIGNED_UP:
      return 'Signed Up';

    case SocialAccountStatus.SCRAPING:
      return 'Not Signed Up';

    default:
      return '';
  }
};

export const getPaymentRequestStatusMessage = (status?: PaymentRequestStatus | null) => {
  switch (status) {
    case PaymentRequestStatus.REVIEWING:
      return 'Reviewing';

    case PaymentRequestStatus.PAID:
      return 'Paid';

    case PaymentRequestStatus.REJECTED:
      return 'Rejected';

    case PaymentRequestStatus.FAILED:
      return 'Failed';

    default:
      return '';
  }
};
