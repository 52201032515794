export { default as DayPickerRange } from './DayPickerRange';
export { default as DayPickerSingle } from './DayPickerSingle';
export { default as DayPickerHandInput } from './DayPickerHandInput';

export enum SELECTED_RANGE {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  THIS_WEEK = 'This week',
  LAST_WEEK = 'Last week',
  LAST_SEVEN_DAYS = 'Last 7 days',
  LAST_TWO_WEEKS = 'Last two weeks',
  LAST_MONTH = 'Last month',
  THIS_MONTH = 'This month',
  ALL_TIME = 'All time',
  TODAY_TO_MONTH_AGO = 'Last 30 days',
  CUSTOM = 'Custom'
}
