import { format, getMonth, getYear, subDays, subYears } from 'date-fns';
import { DEFAULT_FNS_DATE_FORMAT } from '../libs/constant';

export const getNow = (dateFormat: string = 'yyyy-MM-DD HH:mm:ss') => format(new Date(), dateFormat);
export const getToday = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) => format(new Date(), dateFormat);
// note use 29 day, because today is not included
export const getThirtyDaysAgo = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) =>
  format(subDays(new Date(), 29), dateFormat);

export const getLastDay = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) => {
  const y = getYear(new Date());
  const m = getMonth(new Date());
  const lastDay = new Date(y, m + 1, 0);

  return format(lastDay, dateFormat);
};

export const getTwoYearsAgo = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) =>
  format(subYears(new Date(), 2), dateFormat);

export const hourIntervals = [
  '12 am',
  '1 am',
  '2 am',
  '3 am',
  '4 am',
  '5 am',
  '6 am',
  '7 am',
  '8 am',
  '9 am',
  '10 am',
  '11 am',
  '12 pm',
  '1 pm',
  '2 pm',
  '3 pm',
  '4 pm',
  '5 pm',
  '6 pm',
  '7 pm',
  '8 pm',
  '9 pm',
  '10 pm',
  '11 pm'
];

export const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
