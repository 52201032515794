import React, { useRef, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Slider, { Settings } from 'react-slick';
import { SerializedStyles } from '@emotion/serialize/types';
import SliderArrow from './SliderArrow';

const EACH_SLIDE_WIDTH = 176;

interface ImageSliderProps {
  data: any[];
  className?: string;
  slideWidth?: number;
  sliderArrowCss?: SerializedStyles;
  hasBgImage?: boolean;
  currentSlideIdx?: number;
  sliderLength?: number;
}

const ImageSlider: React.FC<ImageSliderProps> = props => {
  const sliderWrapperRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<Slider>(null);

  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [maxNumberOfCardsToShow, setMaxNumberOfCardsToShow] = useState<number>(0);

  const {
    children,
    data,
    className,
    slideWidth = EACH_SLIDE_WIDTH,
    sliderArrowCss,
    hasBgImage,
    currentSlideIdx,
    sliderLength = 0
  } = props;

  const settings: Settings = {
    className: 'slider variable-width',
    centerMode: false,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: false,
    arrows: false,
    afterChange: (currentSlide: number) => handleChangeSlide(currentSlide)
  };

  const handleChangeSlide = (currentSlide: number) => {
    const leftArrowVisible = currentSlide !== 0;

    const rightArrowVisible = currentSlide <= data.length - maxNumberOfCardsToShow;

    setShowLeftArrow(leftArrowVisible);

    setShowRightArrow(rightArrowVisible);
  };

  useEffect(() => {
    const wrapperWidth = sliderWrapperRef.current?.clientWidth || 0;

    const maxNumberOfCards = Math.ceil(wrapperWidth / slideWidth);

    setMaxNumberOfCardsToShow(maxNumberOfCards);

    setShowRightArrow(data.length > maxNumberOfCards);
  }, []);

  useEffect(() => {
    if (sliderRef.current && currentSlideIdx !== undefined && sliderLength > 8) {
      sliderRef.current.slickGoTo(currentSlideIdx);
    }
  }, [currentSlideIdx]);

  return (
    <SliderWrapper ref={sliderWrapperRef} className={className}>
      {showLeftArrow && (
        <SliderArrow
          type="prev"
          onClick={() => sliderRef.current?.slickPrev()}
          css={sliderArrowCss}
          hasBgImage={hasBgImage}
        />
      )}
      <Slider {...settings} ref={sliderRef}>
        {children}
      </Slider>
      {showRightArrow && (
        <SliderArrow
          type="next"
          onClick={() => sliderRef.current?.slickNext()}
          css={sliderArrowCss}
          hasBgImage={hasBgImage}
        />
      )}
    </SliderWrapper>
  );
};

const SliderWrapper = styled.div`
  position: relative;
`;

export default ImageSlider;
