import React, { useContext } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useMutation, ApolloError } from '@apollo/client';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AdminStore } from '../../../../Context';
import { setToken } from '../../../../libs/auth';
import { SignInSchema, UNEXPECTED_ERROR } from '../../../../libs/validation';
import SignInForm from './SignInForm';
import { TokenAuth } from './__generated__/TokenAuth';
import * as TOKEN_AUTH from './TokenAuth.graphql';
import { FormValues } from './types';

const SignInFormComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [doMutation] = useMutation<TokenAuth>(TOKEN_AUTH);
  const { dispatch } = useContext(AdminStore);

  const handleSubmit = async (payload: FormValues, { setSubmitting, setStatus }: FormikHelpers<FormValues>) => {
    const variables = {
      input: payload
    };

    await doMutation({ variables })
      .then(result => {
        if (!!result?.data?.tokenAuth) {
          const { token, refreshToken, userId, role, hash } = result.data.tokenAuth;

          setToken(token, refreshToken);

          dispatch({
            type: 'AUTH_USER',
            payload: {
              userId,
              role,
              hash,
              email: payload.email
            }
          });

          const redirectUrlBeforeSignIn = localStorage.getItem('redirectUrlBeforeSignIn');
          history.push(redirectUrlBeforeSignIn || '/analytics');
          localStorage.removeItem('redirectUrlBeforeSignIn');
        } else {
          setStatus(t(UNEXPECTED_ERROR));
        }
      })
      .catch((error: ApolloError) => {
        const graphQLerrors = error.graphQLErrors;
        if (graphQLerrors.length > 0 && ['NOT_FOUND', 'LOGIN_FAILED'].includes(graphQLerrors[0].message)) {
          setStatus(t('loginFailureMessage'));
        } else {
          setStatus(t(UNEXPECTED_ERROR));
        }
      });
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={SignInSchema}
    >
      <SignInForm />
    </Formik>
  );
};

export default SignInFormComponent;
