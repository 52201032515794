import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import { defaultInfluencerAvatar } from '../../../../libs/image';
import Dialog from '../../../molecules/Dialog';
import { ComparableAccountType } from '../types';
import { mainRed } from '../../../../libs/pallete';
import offlineIconWhite from '../../../../assets/icon/offlineWhite.svg';
import blockedAccountIcon from '../../../../assets/icon/blockedAccount.svg';
import * as StyledCards from '../../AnalyticsShared/AccountCardsStyled';
import { useQueryHelper } from '../../../../libs/hooks';
import * as DELETE_COMPARABLE_ACCOUNT from './DeleteCompareInstagramAccountAnalytics.graphql';
import * as ADD_COMPARABLE_ACCOUNT from './AddCompareInstagramAccountAnalytics.graphql';
import {
  DeleteCompareInstagramAccountAnalytics,
  DeleteCompareInstagramAccountAnalyticsVariables
} from './__generated__/DeleteCompareInstagramAccountAnalytics';
import {
  AddCompareInstagramAccountAnalytics,
  AddCompareInstagramAccountAnalyticsVariables
} from './__generated__/AddCompareInstagramAccountAnalytics';

interface AccountsCardsType {
  id: number;
  comparableAccounts: ComparableAccountType[];
  setComparableAccounts: (ids: ComparableAccountType[]) => void;
  maxCards?: number;
}
const AccountsCards = (props: AccountsCardsType) => {
  const { comparableAccounts, setComparableAccounts, id, maxCards } = props;
  const { t, enqueueSnackbar } = useQueryHelper();

  const [anchorEl, setAnchorEl] = useState<{
    anchor: null | HTMLElement;
    anchorId: number | null;
    elementId?: number | null;
    isDeletable?: boolean;
  }>({
    anchor: null,
    anchorId: null,
    elementId: null,
    isDeletable: undefined
  });
  const [deletingAccount, setDeletingAccount] = useState<ComparableAccountType | undefined>(undefined);
  const [isOpenAddAccount, setOpenAddAccount] = useState<boolean>(false);
  const [accountName, setAccountName] = useState<string>('');
  const [isHoverId, setIsHoverId] = useState<number>();

  const [deleteComparableAccount] = useMutation<
    DeleteCompareInstagramAccountAnalytics,
    DeleteCompareInstagramAccountAnalyticsVariables
  >(DELETE_COMPARABLE_ACCOUNT, { refetchQueries: ['InstagramAnalyticsCompareAccounts'] });
  const [addComparableAccount, { loading }] = useMutation<
    AddCompareInstagramAccountAnalytics,
    AddCompareInstagramAccountAnalyticsVariables
  >(ADD_COMPARABLE_ACCOUNT, { refetchQueries: ['InstagramAnalyticsCompareAccounts'] });

  const handleDotClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    anchorId: number | null,
    elementId?: number | null,
    isDeletable?: boolean
  ) => {
    event.stopPropagation();
    setAnchorEl({ anchor: event.currentTarget, anchorId, elementId, isDeletable });
  };

  const toggleShowHide = (anchorId: number | null, elementId?: number | null) => {
    setComparableAccounts(
      comparableAccounts.map(item => {
        if (item.id === anchorId && item.elementId === elementId) {
          return { ...item, isSelected: !item.isSelected };
        }

        return item;
      })
    );
  };

  const handleDeleteComparableAccount = async () => {
    if (deletingAccount) {
      try {
        await deleteComparableAccount({
          variables: {
            input: {
              compareAccountId: deletingAccount.id,
              mainAnalyticsAccountId: id
            }
          }
        });
        enqueueSnackbar(t('succeededInDeleting', { name: deletingAccount.username }), { variant: 'success' });
      } catch (error) {
        const message = error.message || 'failedToDelete';
        enqueueSnackbar(t(message), { variant: 'error' });
      } finally {
        setDeletingAccount(undefined);
      }
    }
  };
  const handleClickDelete = () => {
    setDeletingAccount(comparableAccounts.find(item => item.id === anchorEl.anchorId));
    setAnchorEl({ anchor: null, anchorId: null, elementId: null, isDeletable: undefined });
  };
  const handleAddComparableAccount = async () => {
    try {
      await addComparableAccount({
        variables: {
          input: {
            compareAccountIgUsername: accountName,
            mainAnalyticsAccountId: id
          }
        }
      });

      setOpenAddAccount(false);
      enqueueSnackbar(t('succeededInCreating', { name: accountName }), { variant: 'success' });
    } catch (error) {
      const message = error.message || 'failedToCreate';
      enqueueSnackbar(t(message), { variant: 'error' });
    } finally {
      setAccountName('');
    }
  };

  const maxComparableCards = maxCards || 5;

  return (
    <StyledCards.CardsWrapper>
      {comparableAccounts.map(account => {
        const isHidden = !account.isSelected;
        const isBlocked = account.blocked;
        const showEye = !isHidden && !isBlocked && isHoverId === account.id;

        return (
          <StyledCards.Card
            clickable={!isBlocked}
            key={`${account.elementId}_${account.id}`}
            onMouseEnter={() => setIsHoverId(account.id)}
            onMouseLeave={() => setIsHoverId(undefined)}
            onClick={() => {
              if (!isBlocked) {
                toggleShowHide(account.id, account.elementId);
              }
            }}
          >
            {account.isDeletable && (
              <StyledCards.VerticalDotsButton
                onClick={e => handleDotClick(e, account.id, account.elementId, account.isDeletable)}
                css={
                  isHidden
                    ? css`
                        color: #c5d0da;
                      `
                    : []
                }
              >
                {'\u2807'}
              </StyledCards.VerticalDotsButton>
            )}
            <StyledCards.AvatarWrapper>
              <StyledCards.Avatar
                src={defaultInfluencerAvatar(account.avatarUrl || null)}
                css={css`
                  ${isHidden ? 'opacity: 0.5' : ''}
                `}
              />
              {(isHidden || showEye) && <StyledCards.OverflowImage src={offlineIconWhite} />}
              {isBlocked && (
                <StyledCards.OverflowImage
                  src={blockedAccountIcon}
                  css={css`
                    zoom: unset;
                    top: 16px;
                    left: 0;
                  `}
                />
              )}
            </StyledCards.AvatarWrapper>

            <StyledCards.TitleWithLink
              onMouseEnter={() => setIsHoverId(undefined)}
              onMouseLeave={() => setIsHoverId(account.id)}
              title={account.username}
              href={account.profileUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              {account.username}
            </StyledCards.TitleWithLink>
          </StyledCards.Card>
        );
      })}
      {/* max number of compareColors */}
      {comparableAccounts.length <= maxComparableCards && (
        <StyledCards.Card
          css={css`
            padding: 0;
          `}
        >
          <StyledCards.AddAccout onClick={() => setOpenAddAccount(true)}>+</StyledCards.AddAccout>
        </StyledCards.Card>
      )}
      {/* Popover  */}
      <Menu
        anchorEl={anchorEl.anchor}
        open={Boolean(anchorEl.anchor)}
        onClose={() => setAnchorEl(prevState => ({ ...prevState, anchor: null }))}
        onExited={() => setAnchorEl(prevState => ({ ...prevState, anchorId: null }))}
      >
        {anchorEl.isDeletable && <MenuItem onClick={() => handleClickDelete()}>{t('Delete')}</MenuItem>}
      </Menu>
      {/* delete account */}
      <Dialog
        open={!!deletingAccount}
        title={t('Dialog.Delete comparable account')}
        execute="Delete"
        executeColor={mainRed}
        handleExecute={handleDeleteComparableAccount}
        onClose={() => setDeletingAccount(undefined)}
      >
        <StyledCards.DialogContentWrapper>
          <p>{t('Dialog.DeleteComparableAccountWarning')}</p>
          <StyledCards.DialogAccountWrapper>
            <StyledCards.DialogAvatar src={defaultInfluencerAvatar(deletingAccount?.avatarUrl || null)} />
            <span>{deletingAccount?.username || ''}</span>
          </StyledCards.DialogAccountWrapper>
        </StyledCards.DialogContentWrapper>
      </Dialog>
      {/* add account */}
      <Dialog
        open={isOpenAddAccount}
        title={t('Dialog.Add Account To Compare')}
        execute="Add"
        handleExecute={handleAddComparableAccount}
        onClose={() => setOpenAddAccount(false)}
        disabled={!accountName || loading}
        loading={loading}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            handleAddComparableAccount();
          }}
        >
          <StyledCards.DialogContentWrapper>
            <p>{t('Dialog.Please enter Instagram username that you wish to compare to')}</p>
            <StyledCards.DialogAccountWrapper>
              <StyledCards.StyledTextForm
                title="Instagram Account"
                name="instagramUsername"
                placeholder="Instagram Username"
                isTable={false}
                value={accountName}
                onChange={e => setAccountName(e.target.value)}
                autoFocus
              />
            </StyledCards.DialogAccountWrapper>
          </StyledCards.DialogContentWrapper>
        </form>
      </Dialog>
    </StyledCards.CardsWrapper>
  );
};

export default AccountsCards;
