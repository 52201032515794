import React, { useState } from 'react';
import css from '@emotion/css';
import { HeaderColumn, LinkButtonColumn, TextColumn } from '../../../atoms/List';
import { SliderComponents, SliderCss } from '../../../molecules/SliderTable';
import { EmptyTable } from '../../../molecules/Table';
import { defaultInfluencerAvatar } from '../../../../libs/image';
import warningIcon from '../../../../assets/icon/warning.svg';
import checkedIcon from '../../../../assets/icon/checkIcon.svg';
import Dialog from '../../../molecules/Dialog';
import editIcon from '../../../../assets/icon/edit_pen_grey.svg';
import { ConnectedIgAndFbAccounts, ReconnectButton } from '../Components';
import { useQueryHelper } from '../../../../libs/hooks';
import { AnalyticsAuthSocialAccountType, SocialAccountType } from '../../../../__generated__/globalTypes';
import { SUBSCRIPTIONS_TYPES } from '../../../../libs/constant';
import { StyledConnectedSocialMedia } from '../AnalyticsForm/AnalyticsAccountForm';
import { YouTubeDashboardModal, youtubeContent } from '../../AnalyticsShared/UpgradeToPremiumModal';
import { ReconnectAccountOuterProps } from './useReconnectAccountFromList';
import * as StyledComponents from './StyledComponents';
import { AccountListAnalyticsForAdvertiserV2_allAccountAnalyticsForAdvertiserV2_accounts } from './__generated__/AccountListAnalyticsForAdvertiserV2';
import { getReconnectSMType, getSMProfileUrls } from './helpers';

export interface AnalyticsListProps extends ReconnectAccountOuterProps {
  analyticsAccounts: AccountListAnalyticsForAdvertiserV2_allAccountAnalyticsForAdvertiserV2_accounts[];
}

const AnalyticsListAdvertiser = (props: AnalyticsListProps) => {
  const { t } = useQueryHelper();
  const {
    analyticsAccounts,
    setReconnectIgOpen,
    setReconnectYtOpen,
    openedReconnectAccountType,
    onReconnectClick,
    reconnectSuccessType,
    connectedIgAccountName,
    clearReconnectState,
    connectedFbPageName,
    chosenYtAccount
  } = props;

  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const [isYtModalOpen, setYtModalState] = useState(false);

  if (analyticsAccounts.length === 0) {
    return <EmptyTable colSpan={6} link={{ href: '/analytics/add', title: t('Analytics.AddAnAnalytics') }} />;
  }

  const renderConnectedSocialMediaAccount = (reconnectType: AnalyticsAuthSocialAccountType | '') => (
    <>
      {reconnectType === AnalyticsAuthSocialAccountType.FACEBOOK ? (
        <ConnectedIgAndFbAccounts igAccountName={connectedIgAccountName} fbAccountName={connectedFbPageName} />
      ) : null}
      {reconnectType === AnalyticsAuthSocialAccountType.YOUTUBE ? (
        <StyledConnectedSocialMedia
          socialMedia={SocialAccountType.YOUTUBE}
          accountName={chosenYtAccount.channelName}
          isTable={false}
          css={css`
            padding-left: 0;
          `}
        />
      ) : null}
    </>
  );

  const reconnectSMType = getReconnectSMType(openedReconnectAccountType);

  return (
    <>
      <SliderComponents.Wrapper css={[SliderCss.marginTop, SliderCss.clearPadding]}>
        <SliderComponents.Container>
          <SliderComponents.SliderSection>
            <SliderComponents.SliderTable>
              <thead>
                <SliderComponents.HeaderRow css={SliderCss.bgndColor}>
                  <HeaderColumn title="Account" />
                  <HeaderColumn title="Social Media" css={[SliderCss.paddingLeft8]} />
                  <HeaderColumn title="Plan" css={[SliderCss.paddingLeft8]} />
                  <HeaderColumn title="Edit" css={[SliderCss.paddingLeft8]} />
                </SliderComponents.HeaderRow>
              </thead>
              <tbody>
                {analyticsAccounts.map((analytic, i) => {
                  const { id, avatar, accountName, socialMediaAccounts, subscriptionPlan } = analytic;
                  const { instagram, youtube } = socialMediaAccounts;
                  const igAccountId = instagram?.id;
                  const { igProfileUrl, ytChannelUrl } = getSMProfileUrls(
                    instagram?.instagramAccountName,
                    youtube?.channelId
                  );

                  return (
                    <StyledComponents.StyledRow
                      key={id}
                      isRowHovered={i === hoverIndex}
                      onMouseOver={() => {
                        setHoverIndex(i);
                      }}
                      css={[SliderCss.paddingSides, SliderCss.rowHeight56]}
                    >
                      <StyledComponents.Account css={SliderCss.setMinWidth('250px')}>
                        <StyledComponents.Avatar src={defaultInfluencerAvatar(avatar)} />
                        {/* TODO: this redirect only for v1 IG accountId */}
                        {igAccountId ? (
                          <StyledComponents.TextLink
                            to={`/analytics/dashboard/${igAccountId}`}
                            onClick={() => (!igAccountId ? setYtModalState(true) : null)}
                            css={[SliderCss.getPaddingLeft('16px')]}
                          >
                            {accountName}
                          </StyledComponents.TextLink>
                        ) : (
                          <StyledComponents.BrandAccountName
                            onClick={() => setYtModalState(true)}
                            css={[SliderCss.getPaddingLeft('16px')]}
                          >
                            {accountName}
                          </StyledComponents.BrandAccountName>
                        )}
                      </StyledComponents.Account>

                      <StyledComponents.Media css={SliderCss.setWidth('100%')}>
                        <StyledComponents.MediaContent>
                          <StyledComponents.StyledSocialMedia
                            socialMedia={SocialAccountType.INSTAGRAM}
                            isDisconnected={!instagram}
                            isTable={false}
                            profileUrl={igProfileUrl}
                            needReconnect={instagram?.needReconnect}
                            onReconnect={() =>
                              setReconnectIgOpen({
                                businessAccountId: instagram?.businessAccountId || '',
                                accountId: String(id),
                                accountName,
                                fbPageId: instagram?.fbPageId || ''
                              })
                            }
                          />
                          <StyledComponents.StyledSocialMedia
                            socialMedia={SocialAccountType.YOUTUBE}
                            isDisconnected={!youtube}
                            isTable={false}
                            profileUrl={ytChannelUrl}
                            needReconnect={youtube?.needReconnect}
                            onReconnect={() =>
                              setReconnectYtOpen({
                                channelId: youtube?.channelId || '',
                                channelName: youtube?.channelName || ''
                              })
                            }
                          />
                        </StyledComponents.MediaContent>
                      </StyledComponents.Media>

                      <TextColumn
                        data={SUBSCRIPTIONS_TYPES.find(s => s.value === (subscriptionPlan as string))?.label || ''}
                        css={SliderCss.setMinWidth('150px')}
                      />
                      <LinkButtonColumn
                        icon={<StyledComponents.EditIcon src={editIcon} />}
                        title=""
                        href={`/analytics/${id}`}
                      />
                    </StyledComponents.StyledRow>
                  );
                })}
              </tbody>
            </SliderComponents.SliderTable>
          </SliderComponents.SliderSection>
        </SliderComponents.Container>
      </SliderComponents.Wrapper>
      {/* reconnect modals */}
      <Dialog
        open={!!openedReconnectAccountType}
        title={t('Dialog.Please reconnect account')}
        titleIcon={<StyledComponents.ReconnectIcon src={warningIcon} css={paddingRight8} />}
        onClose={clearReconnectState}
      >
        <StyledComponents.ReconnectText>{t('PleaseReconnectYourAccount')}</StyledComponents.ReconnectText>
        <StyledComponents.ReconnectAccount>
          {renderConnectedSocialMediaAccount(openedReconnectAccountType)}
          <ReconnectButton onClick={() => onReconnectClick(openedReconnectAccountType)} socialMedia={reconnectSMType}>
            {t('Button.Reconnect')}
          </ReconnectButton>
        </StyledComponents.ReconnectAccount>
      </Dialog>
      <Dialog
        open={!!reconnectSuccessType}
        title={t('Dialog.Successfully connected')}
        onClose={clearReconnectState}
        handleExecute={clearReconnectState}
        execute={t('Button.Save')}
        hasCancelButton={false}
      >
        <StyledComponents.ReconnectAccount>
          {renderConnectedSocialMediaAccount(reconnectSuccessType)}
          <img src={checkedIcon} />
        </StyledComponents.ReconnectAccount>
      </Dialog>
      {/* YouTube Dashboard Modal */}
      <Dialog
        open={isYtModalOpen}
        title={t(youtubeContent.title)}
        titleIcon={<StyledComponents.ReconnectIcon src={youtubeContent.icon} css={paddingRight8} />}
        onClose={() => setYtModalState(false)}
      >
        <YouTubeDashboardModal />
      </Dialog>
    </>
  );
};

const paddingRight8 = css`
  padding-right: 8px;
`;

export default AnalyticsListAdvertiser;
