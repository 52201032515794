import { MutationFunction } from '@apollo/client';
import { GraphQLError } from 'graphql';
import {
  CreateSocialAccountAnalyticsAdvertiserInputV2,
  EditSocialAccountAnalyticsAdvertiserInputV2
} from '../../../../__generated__/globalTypes';
import { FormValues } from './types';
import {
  EditSocialAccountAnalyticsAdvertiserV2,
  EditSocialAccountAnalyticsAdvertiserV2Variables
} from './__generated__/EditSocialAccountAnalyticsAdvertiserV2';
import {
  CreateSocialAccountAnalyticsAdvertiser,
  CreateSocialAccountAnalyticsAdvertiserVariables
} from './__generated__/CreateSocialAccountAnalyticsAdvertiser';

export type CreateAnalyticsAccountAdvMutation = MutationFunction<
  CreateSocialAccountAnalyticsAdvertiser,
  CreateSocialAccountAnalyticsAdvertiserVariables
>;
export type EditAnalyticsAccountAdvMutation = MutationFunction<
  EditSocialAccountAnalyticsAdvertiserV2,
  EditSocialAccountAnalyticsAdvertiserV2Variables
>;
interface CreateUpdateAnalyticsAccountProps {
  id?: string;
  createAccountAnalyticsAdv: CreateAnalyticsAccountAdvMutation;
  updateAccountAnalyticsAdv: EditAnalyticsAccountAdvMutation;
  youtubeChannelId?: string;
  fbPageId: string;
}

export const createUpdateAnalyticsAccountAdvertiser = async (
  props: CreateUpdateAnalyticsAccountProps,
  payload: FormValues
) => {
  const {
    id: accountIdFromUrl,
    youtubeChannelId,
    createAccountAnalyticsAdv,
    updateAccountAnalyticsAdv,
    fbPageId
  } = props;
  const { instagramUserId, accountName } = payload;

  const createInput: CreateSocialAccountAnalyticsAdvertiserInputV2 = {
    instagramUserId: instagramUserId || null,
    accountName,
    youtubeChannelId: youtubeChannelId || null
  };
  const updateInput: EditSocialAccountAnalyticsAdvertiserInputV2 = {
    brandAccountId: Number(accountIdFromUrl || ''),
    accountName,
    youtubeChannelId: youtubeChannelId || null,
    igFacebookPageId: fbPageId || null
  };

  if (!!accountIdFromUrl) {
    const { ok, error } = await updateAccountAnalyticsAdv({ variables: { input: updateInput } })
      .then(result => {
        if (result?.data?.editSocialAccountAnalyticsAdvertiserV2) {
          return { ok: result.data.editSocialAccountAnalyticsAdvertiserV2.ok, error: null };
        }

        return { ok: false, error: null };
      })
      .catch((e: GraphQLError) => ({ ok: false, error: e.message }));

    return { ok, error };
  } else {
    const { ok, error } = await createAccountAnalyticsAdv({ variables: { input: createInput } })
      .then(result => {
        if (result?.data?.createSocialAccountAnalyticsAdvertiserV2) {
          return { ok: result.data.createSocialAccountAnalyticsAdvertiserV2.ok, error: null };
        }

        return { ok: false, error: null };
      })
      .catch((e: GraphQLError) => ({ ok: false, error: e.message }));

    return { ok, error };
  }
};
