import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useField, useFormikContext } from 'formik';
import { LinkButton, SubmitButton } from '../../atoms/Button';
import ErrorMessage from '../../molecules/ErrorMessage';
import PasswordField from '../../molecules/PasswordField';
import { formatDynamicErrorMessage } from '../../../libs/validation';
import { FormValues } from './types';

const NewPasswordForm = () => {
  const { t } = useTranslation();
  const { handleSubmit, isSubmitting } = useFormikContext<FormValues>();

  const [passwordField, passwordMeta] = useField('password');
  const [passwordConfirmField, passwordConfirmMeta] = useField('passwordConfirm');

  return (
    <Form onSubmit={handleSubmit}>
      <Title>{t('Password.New Password')}</Title>
      <Annotation>{t('Password.Fill New Password')}</Annotation>

      <NewPassword title={t('Password.New Password')} placeholder="" error={!!passwordMeta.error} {...passwordField} />
      <Error dynamicError={formatDynamicErrorMessage(t, passwordMeta.error)} />

      <ConfirmPassword title={t('Password.Confirm Password')} placeholder="" {...passwordConfirmField} />
      <Error message={passwordConfirmMeta.error} />

      <ButtonContainer>
        <CancelButton title="Cancel" href="/" color="#6e7c89" bgcolor="transparent" hoverbgcolor="transparent" />
        <UpdateButton value="Update" isSubmitting={isSubmitting} bgcolor="#ff4b61" hoverbgcolor="#eb475d" />
      </ButtonContainer>
    </Form>
  );
};

const Form = styled.form`
  box-sizing: border-box;
  width: 429px;
  margin: 0 auto;
  padding: 48px 40px;
  background-color: #fff;
  border-radius: 3px;
`;

const Annotation = styled.p`
  padding: 16px 0 40px;
  color: #27313b;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 20px;
  text-align: center;
  color: #27313b;
  font-weight: normal;
`;

const NewPassword = styled(PasswordField)`
  width: 100%;

  & label {
    padding: 0 0 4px;
  }

  & input {
    line-height: 32px;
    border-radius: 3px;
    box-shadow: inset 0 1px 2px 0 rgba(39, 49, 59, 0.2);
    border: 1px solid #dee5ec;
  }
`;

const ConfirmPassword = styled(NewPassword)`
  padding-top: 24px;
`;

const Error = styled(ErrorMessage)`
  width: 100%;
`;

const UpdateButton = styled(SubmitButton)`
  line-height: 32px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  background-color: #ff2b52;
  flex: 1;

  &:hover {
    background-color: #ff5575;
  }

  &:active {
    background-color: #cc2241;
  }
`;

const CancelButton = styled(LinkButton)`
  line-height: 32px;
  border-radius: 3px;
  border: 1px solid #dee5ec;
  margin-right: 16px;
  font-weight: 600;
  font-size: 12px;
  flex: 1;

  &:hover {
    border-color: #c5d0da;
  }

  &:active {
    background-color: #f6f8fa;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

export default NewPasswordForm;
