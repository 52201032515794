import { FormikProps } from 'formik';
import { formatDynamicErrorMessage } from '../../libs/validation';

const useTranslateErrors = (props: any, t: any): FormikProps<{ email: string; password: string }> => {
  const { errors, status } = props;

  return {
    ...props,
    errors: {
      email: !errors.email ? errors.email : t(errors.email),
      password: !errors.password ? errors.password : formatDynamicErrorMessage(t, errors.password)
    },
    status: {
      message: !status ? null : t(status)
    }
  };
};
export default useTranslateErrors;
