import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useProviderResponse } from '../helpers';
import { manageLocalStorageItem } from '../../../libs/localStorage';
import PageLoading from '../../../components/molecules/PageLoading';
import { getCallbackUrl } from '../../../libs/auth';
import { AnalyticsRedirectType, QuerySocialAccountType } from '../../../__generated__/globalTypes';
import {
  SocialAuthConnectableInstagramAccountsAnalytics,
  SocialAuthConnectableInstagramAccountsAnalyticsVariables,
  SocialAuthConnectableInstagramAccountsAnalytics_socialAuthConnectableInstagramAccountsAnalytics_accounts
} from './__generated__/SocialAuthConnectableInstagramAccountsAnalytics';
import * as SOCIAL_AUTH_CONNECTABLE_IG_ACCOUNTS from './SocialAuthConnectableInstagramAccountsAnalytics.graphql';
import * as SOCIAL_AUTH_CONNECTABLE_YT_CHANNELS from './SocialAuthConnectableYouTubeChannelsAnalytics.graphql';
import {
  SocialAuthConnectableYouTubeChannelsAnalytics,
  SocialAuthConnectableYouTubeChannelsAnalyticsVariables,
  SocialAuthConnectableYouTubeChannelsAnalytics_socialAuthConnectableYouTubeChannelsAnalyticsV2
} from './__generated__/SocialAuthConnectableYouTubeChannelsAnalytics';

const AnalyticsSignInRedirect = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const providerResponse = useProviderResponse();

  const [socialAuthConnectableIgAccounts] = useMutation<
    SocialAuthConnectableInstagramAccountsAnalytics,
    SocialAuthConnectableInstagramAccountsAnalyticsVariables
  >(SOCIAL_AUTH_CONNECTABLE_IG_ACCOUNTS);
  const [socialAuthConnectableIgChannels] = useMutation<
    SocialAuthConnectableYouTubeChannelsAnalytics,
    SocialAuthConnectableYouTubeChannelsAnalyticsVariables
  >(SOCIAL_AUTH_CONNECTABLE_YT_CHANNELS);

  let connectableAccounts:
    | SocialAuthConnectableInstagramAccountsAnalytics_socialAuthConnectableInstagramAccountsAnalytics_accounts[]
    | undefined;
  let connectableYtChannels:
    | SocialAuthConnectableYouTubeChannelsAnalytics_socialAuthConnectableYouTubeChannelsAnalyticsV2[]
    | undefined;

  useEffect(() => {
    // later we can replace it with AnalyticsAuthSocialAccountType
    if (providerResponse.provider === QuerySocialAccountType.FACEBOOK) {
      fetchConnectableIgAccounts();
    } else if (providerResponse.provider === QuerySocialAccountType.YOUTUBE) {
      fetchConnectablIgChannels();
    }
  }, []);

  const advertiserId = manageLocalStorageItem('get', 'advertiserId');
  const accountManagerIds = manageLocalStorageItem('get', 'accountManagerIds');
  const accountName = manageLocalStorageItem('get', 'accountName');
  const inAppRedirectUrl = '/analytics/add';

  const variables: SocialAuthConnectableInstagramAccountsAnalyticsVariables = {
    input: {
      response: providerResponse.response,
      callbackUrl: getCallbackUrl(providerResponse.provider, AnalyticsRedirectType.ANALYTICS_SIGNIN)
    }
  };

  const fetchConnectableIgAccounts = async () => {
    try {
      const { data } = await socialAuthConnectableIgAccounts({ variables });
      connectableAccounts = data?.socialAuthConnectableInstagramAccountsAnalytics?.accounts;
    } catch (error) {
      enqueueSnackbar(t('Unable to get your connected accounts information'), { variant: 'error' });
    } finally {
      history.push({
        pathname: inAppRedirectUrl,
        state: {
          advertiserId,
          accountManagerIds: accountManagerIds ? accountManagerIds.split(', ') : undefined,
          accountName,
          connectableAccounts
        }
      });
    }
  };

  const fetchConnectablIgChannels = async () => {
    try {
      const { data } = await socialAuthConnectableIgChannels({ variables });
      connectableYtChannels = data?.socialAuthConnectableYouTubeChannelsAnalyticsV2;
    } catch (error) {
      enqueueSnackbar(t('Unable to get your connected accounts information'), { variant: 'error' });
    } finally {
      history.push({
        pathname: inAppRedirectUrl,
        state: {
          advertiserId,
          accountManagerIds: accountManagerIds ? accountManagerIds.split(', ') : undefined,
          connectableYtChannels,
          accountName
        }
      });
    }
  };

  return <PageLoading />;
};

export default AnalyticsSignInRedirect;
