import React from 'react';
import { useQuery } from '@apollo/client';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';
import AnalyticsContentWrapper, { ContentType } from '../../AnalyticsShared/AnalyticsContentWrapper';
import { useQueryHelper } from '../../../../libs/hooks';
import { AnalyticsPeriodWithId } from '../../AnalyticsShared/types';
import Overview from './HashtagOverview';
import * as IG_HASHTAG_OVERVIEW from './InstagramHashtagsOverview.graphql';
import {
  InstagramHashtagsOverview,
  InstagramHashtagsOverviewVariables
} from './__generated__/InstagramHashtagsOverview';

interface HashtagOverviewType extends AnalyticsPeriodWithId {
  selectedHashtagIds: number[];
  allHashtag: Record<number, string>;
}
const HashtagOverview = ({ period, id, selectedHashtagIds, allHashtag }: HashtagOverviewType) => {
  const { history, enqueueSnackbar, t } = useQueryHelper();
  const { data, loading, error } = useQuery<InstagramHashtagsOverview, InstagramHashtagsOverviewVariables>(
    IG_HASHTAG_OVERVIEW,
    {
      variables: { analyticsAccountId: id, startDate: period.startDate, endDate: period.endDate },
      fetchPolicy: 'cache-and-network'
    }
  );

  if (loading) {
    return (
      <LoaderWrapper>
        <CircularProgress
          size={64}
          thickness={2.4}
          css={css`
            margin: 240px auto;
            color: #e0e8ed;
          `}
        />
      </LoaderWrapper>
    );
  }

  if (!data?.instagramHashtagsOverview || error) {
    history.push('/analytics');
    const errorMsg = error?.message || 'Can not get analytics for current account';
    enqueueSnackbar(t(errorMsg), { variant: 'error' });

    return null;
  }

  return (
    <AnalyticsContentWrapper contentType={ContentType.Overview} tab="hashtag">
      <Overview
        instagramHashtagsOverview={data.instagramHashtagsOverview}
        id={id}
        selectedHashtagIds={selectedHashtagIds}
        allHashtag={allHashtag}
      />
    </AnalyticsContentWrapper>
  );
};

const LoaderWrapper = styled.div`
  background-color: #fff;
  text-align: center;
  height: 554px;
`;

export default HashtagOverview;
