import { SerializedStyles } from '@emotion/serialize';
import styled from '@emotion/styled';
import * as React from 'react';

interface StatusColumnProps extends StatusProps {
  className?: string;
  isTable?: boolean;
}

const StatusColumn: React.FC<StatusColumnProps> = props => {
  const { className, statusColor, children, isTable = true } = props;
  const CustomWrapper = isTable ? TableWrapper : Wrapper;

  return (
    <CustomWrapper className={className}>
      <TableData statusColor={statusColor}>{children}</TableData>
    </CustomWrapper>
  );
};

interface StatusProps {
  statusColor?: SerializedStyles;
}

const TableWrapper = styled.td`
  padding: 5px 12px;
  white-space: nowrap;

  @media (max-width: 840px) {
    height: auto;
    padding: 0;
  }
`;
const Wrapper = styled.div`
  padding: 5px 12px;
  white-space: nowrap;

  @media (max-width: 840px) {
    height: auto;
    padding: 0;
  }
`;

const TableData = styled.p<StatusProps>`
  width: max-content;
  min-width: 80px;
  margin: auto;
  padding: 5px 12px;
  background-color: rgba(23, 156, 215, 0.15);
  color: #179cd7;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
  text-align: center;
  ${props => props.statusColor}
`;

export default StatusColumn;
