type LocalStorageKey =
  | 'businessAccountId'
  | 'analyticsIgAccountId'
  | 'advertiserId'
  | 'accountManagerIds'
  | 'analyticsIgAccountName'
  | 'reportTableColumns'
  | 'reconnectStatus'
  | 'fbPageId'
  | 'accountName'
  | 'redirectBackRoute'
  | 'channelId';

export const manageLocalStorageItem = (method: 'set' | 'get' | 'remove', key: LocalStorageKey, value?: string) => {
  switch (method) {
    case 'set':
      return localStorage.setItem(key, value || '');
    case 'get':
      return localStorage.getItem(key);
    case 'remove':
      return localStorage.removeItem(key);

    default:
      return undefined;
  }
};
