import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import UnitCost from './UnitCost';

interface UnitCostColumnProps {
  post: number | null;
  like: number | null;
  view: number | null;
  share: number | null;
  comment: number | null;
  currency: string;
  follower: number | null;
  click: number | null;
  action: number | null;
  hasFbReactions: boolean;
}

const UnitCostColumn = (props: UnitCostColumnProps) => {
  const { post, like, view, share, comment, currency, follower, click, action, hasFbReactions } = props;

  return (
    <WrapperComponent>
      {!!post && <UnitCost data={post} currency={currency} unit="Post" />}
      {!!like && <UnitCost data={like} currency={currency} unit={hasFbReactions ? 'Reaction' : 'Like'} />}
      {!!view && <UnitCost data={view} currency={currency} unit="View" />}
      {!!share && <UnitCost data={share} currency={currency} unit="Share" />}
      {!!comment && <UnitCost data={comment} currency={currency} unit="Comment" />}
      {!!follower && <UnitCost data={follower} currency={currency} unit="Follower" />}
      {!!click && <UnitCost data={click} currency={currency} unit="Click" />}
      {!!action && <UnitCost data={action} currency={currency} unit="Action" />}
    </WrapperComponent>
  );
};

const WrapperComponent = ({ children }: { children: ReactNode[] }) => (
  <Wrapper>
    {children &&
      children
        .filter(el => !!el)
        .map((unit, i) => {
          if (i >= 2) {
            return null;
          }

          return unit;
        })}
  </Wrapper>
);

const Wrapper = styled.td`
  height: 40px;
  padding: 4px 0 8px 8px;
`;

export default UnitCostColumn;
