import React, { Fragment, useState, useEffect } from 'react';
import { Global, css } from '@emotion/core';
import { useApolloClient } from '@apollo/client';
import { formatIntNumber, formatNumber, formatPercent } from '../../../../libs/format';
import HighChart from '../../../molecules/HighChart';
import { CheckBoxIcon } from '../../../atoms/Icon';
import BlurOpacityWrapper from '../../../molecules/BlurOpacityWrapper';
import { getGrowthArrow, getGrowthPrefix } from '../../AnalyticsShared/utils';
import CarouselModal from '../../../molecules/CarouselPopup';
import { usePostDetailModal } from '../../AnalyticsShared/BasePostList/usePostDetailModal';
import FeedPostModal from '../../AnalyticsPost/AnalyticsFeedPostDetail/template';
import StoryPostModal from '../../AnalyticsPost/AnalyticsStoryPostDetail/template';
import StyledForModal from '../../AnalyticsPost/StyledComponents';
import { InstagramAnalyticsPostDetails_instagramAnalyticsPostDetails } from '../../AnalyticsPost/AnalyticsFeedPostDetail/__generated__/InstagramAnalyticsPostDetails';
import { InstagramAnalyticsStoryDetails_instagramAnalyticsStoryDetails } from '../../AnalyticsPost/AnalyticsStoryPostDetail/__generated__/InstagramAnalyticsStoryDetails';
import * as Styled from '../../AnalyticsShared/Overview/StyledComponents';
import { EngagementDataType } from '../../AnalyticsShared/Overview/helpers';
import { useQueryHelper } from '../../../../libs/hooks';
import { UnionToIntersection } from '../../../../libs/types';
import { InstagramDashboardOverview } from './__generated__/InstagramDashboardOverview';
import { colors, ChartColorTypes, allSelectableCharts } from './helpers';
import * as IG_POSTS_IN_DATE from './InstagramAnalyticsPostsInDate.graphql';
import {
  InstagramAnalyticsPostsInDate,
  InstagramAnalyticsPostsInDateVariables
} from './__generated__/InstagramAnalyticsPostsInDate';
import { getOptions } from './getDashboardOverviewOptions';

export type CombinedAccountsType = UnionToIntersection<
  | (InstagramAnalyticsPostDetails_instagramAnalyticsPostDetails & { type: string })
  | (InstagramAnalyticsStoryDetails_instagramAnalyticsStoryDetails & { type: string })
>;

const AnalyticsOverview = ({
  instagramDashboardOverview,
  id,
  allEngagements
}: InstagramDashboardOverview & { id: number; allEngagements: ChartColorTypes[] }) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const client = useApolloClient();
  const [dailyPostsData, setDailyPostsData] = useState<CombinedAccountsType[]>([]);

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
    setOpenPostDetail
  } = usePostDetailModal(dailyPostsData, 'overviewPopup');

  const fetchDailyPosts = async (postDate: string) => {
    try {
      const { data } = await client.query<InstagramAnalyticsPostsInDate, InstagramAnalyticsPostsInDateVariables>({
        query: IG_POSTS_IN_DATE,
        variables: { accountId: id, postDate }
      });

      const combinedPostsData = [
        ...(data?.allInstagramPostsInDate?.feedPosts?.map(feedPost => ({ ...feedPost, type: 'feedPost' })) || []),
        ...(data?.allInstagramPostsInDate?.storyPosts?.map(storyPost => ({ ...storyPost, type: 'storyPost' })) || [])
      ] as CombinedAccountsType[];

      setDailyPostsData(combinedPostsData);
      setOpenPostDetail(combinedPostsData[0]?.id || null);
    } catch (error) {
      enqueueSnackbar(t('Can not get daily posts data, please try again later '), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (dailyPostsData.length && openPostDetail) {
      handleClickPost(openPostDetail);
    }
  }, [dailyPostsData.length, openPostDetail]);

  const [selectedCharts, setSelectedCharts] = useState<ChartColorTypes[]>(['Follower', 'Post']);
  const { options, engagement } = instagramDashboardOverview
    ? getOptions(selectedCharts, instagramDashboardOverview, fetchDailyPosts, allEngagements)
    : { options: [] as Highcharts.Options, engagement: [] as EngagementDataType[] };

  const toggleSelectedCharts = (isSelected: boolean, name: ChartColorTypes) => {
    if (isSelected) {
      setSelectedCharts(selectedCharts.filter(selectedItem => selectedItem !== name));
    } else {
      setSelectedCharts([...selectedCharts, name]);
    }
  };

  const noData = engagement.every(item => !item.rate);
  const currentOverviewPostForModal = dailyPostsData.find(dailyPost => dailyPost.id === openPostDetail);
  const sortedEngagementCheckboxes = allEngagements.sort(
    (a, b) => allSelectableCharts.indexOf(a) - allSelectableCharts.indexOf(b)
  );

  return (
    <>
      <BlurOpacityWrapper header="" dataNotAvailable={noData}>
        <Styled.OverviewWrapper>
          <Styled.Engagement>
            {engagement.map(item => {
              const hasDecimals = item.title === 'Engagement rate';
              const isPositiveGrowth = item.growth === 0 ? undefined : item.growth > 0;
              const prefix = getGrowthPrefix(item.growth) || '';
              const arrow = getGrowthArrow(item.growth);
              const total = hasDecimals ? formatNumber(item.total) : formatIntNumber(item.total);
              const growth = hasDecimals ? formatNumber(item.growth) : formatIntNumber(item.growth);

              return (
                <Fragment key={item.title}>
                  <Styled.EngagementTile hasSecondRow={!!(engagement.length > 6)}>
                    <Styled.PartWithIcon>
                      <Styled.LeftPart>
                        <Styled.EngagementTitle>{t(item.title)}</Styled.EngagementTitle>
                        <Styled.TotalNumber>{total}</Styled.TotalNumber>
                      </Styled.LeftPart>
                      <Styled.EngagementTileIcon src={item.icon} />
                    </Styled.PartWithIcon>
                    <Styled.Growth isPositive={isPositiveGrowth}>{`${prefix}${growth}  ${arrow}  (${formatPercent(
                      item.rate
                    )})`}</Styled.Growth>
                  </Styled.EngagementTile>
                </Fragment>
              );
            })}
          </Styled.Engagement>
          <Styled.Chart>
            <Styled.LegendBar>
              {sortedEngagementCheckboxes.map(item => {
                const isChecked = selectedCharts.includes(item);
                const color = colors[item];

                return (
                  <Styled.LegendTileColored key={item} onClick={() => toggleSelectedCharts(isChecked, item)}>
                    <CheckBoxIcon checked={isChecked} />
                    <Styled.Text>{t(item)}</Styled.Text>
                    <span style={{ background: color }} />
                  </Styled.LegendTileColored>
                );
              })}
            </Styled.LegendBar>
            <Global
              styles={css`
                .highcharts-tooltip path {
                  fill: #27313b;
                }

                .highcharts-root {
                  padding-left: 4px;
                }
              `}
            />
            <HighChart options={options} />
          </Styled.Chart>
        </Styled.OverviewWrapper>
      </BlurOpacityWrapper>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <>
          <StyledForModal.PostDetailWrapper>
            {currentOverviewPostForModal?.type === 'feedPost' && (
              <FeedPostModal
                data={{
                  instagramAnalyticsPostDetails: currentOverviewPostForModal
                }}
                closeModal={() => handleClosePostDetail()}
              />
            )}
            {currentOverviewPostForModal?.type === 'storyPost' && (
              <StoryPostModal
                data={{
                  instagramAnalyticsStoryDetails: currentOverviewPostForModal
                }}
                closeModal={() => handleClosePostDetail()}
              />
            )}
          </StyledForModal.PostDetailWrapper>
        </>
      </CarouselModal>
    </>
  );
};

export default AnalyticsOverview;
