import styled from '@emotion/styled';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { SubmitButton } from '../../../atoms/Button';
import ErrorMessage from '../../../molecules/ErrorMessage';
import SingleSelectField from '../../../molecules/SingleSelectedField';
import MultiSelectField from '../../../molecules/MultiSelectField';
import { CircleItem } from '../../../molecules/CircleItem';
import { SocialMediaSignInButton, ConnectedIgAndFbAccounts, ReconnectButton, GreenCheckMark } from '../Components';
import { Option } from '../../../../libs/types';
import { useRedirectUrl } from '../../../../libs/hooks/useRedirectUrl';
import {
  AnalyticsRedirectType,
  AnalyticsAuthSocialAccountType,
  SocialAccountType
} from '../../../../__generated__/globalTypes';
import PageLoading from '../../../molecules/PageLoading';
import { manageLocalStorageItem } from '../../../../libs/localStorage';
import TextForm from '../../../molecules/TextForm';
import { useQueryHelper } from '../../../../libs/hooks';
import { isAdvertiserUser, isAgencyUser } from '../../../../libs/auth';
import { SocialMediaColumn } from '../../../atoms/List';
import checkIcon from '../../../../assets/icon/checkIcon.svg';
import { AnalyticsAccountBaseFormProps, FormValues } from './types';

interface AnalyticsAccountFormProps extends AnalyticsAccountBaseFormProps {
  allAdvertisers: Option[];
  allPicUsers: Option[];
  editAccountId?: string;
}
const AnalyticsAccountForm = (props: AnalyticsAccountFormProps) => {
  const {
    allAdvertisers,
    allPicUsers,
    isEdit,
    connectedIgAccount: {
      fbPageId,
      fbPageName,
      instagramAccountName,
      needReconnect,
      businessAccountId,
      id: connectedIgId
    },
    editAccountId,
    connectedYtChannel: { channelId, channelName, hasYtReconnect }
  } = props;

  const isAdvertiser = isAdvertiserUser();
  const isAgency = isAgencyUser();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { setFieldValue, isSubmitting, handleSubmit } = useFormikContext<FormValues>();

  const { getRedirectUrl, loading, setLoading } = useRedirectUrl(AnalyticsRedirectType.ANALYTICS_CONNECT);
  const { getRedirectUrl: getSignInRedirectUrl, loading: signInLoading, setLoading: setSignInLoading } = useRedirectUrl(
    AnalyticsRedirectType.ANALYTICS_SIGNIN
  );

  const [advertiserField, advertiserMeta] = useField<FormValues['advertiserId']>('advertiserId');
  const [accountManagersField, accountManagersMeta] = useField<FormValues['accountManagerIds']>('accountManagerIds');
  const [accountNameField, accountNameMeta] = useField<FormValues['accountName']>('accountName');

  const onReconnectClick = async (redirectedSocialMedia: AnalyticsAuthSocialAccountType) => {
    setLoading(true);
    try {
      const redirectUrl = await getRedirectUrl(redirectedSocialMedia);
      if (redirectUrl) {
        // Redirect to SocialMedia
        location.href = redirectUrl;
        manageLocalStorageItem('set', 'redirectBackRoute', editAccountId);
        if (redirectedSocialMedia === AnalyticsAuthSocialAccountType.FACEBOOK) {
          manageLocalStorageItem('set', 'businessAccountId', businessAccountId || '');
          manageLocalStorageItem('set', 'analyticsIgAccountId', editAccountId || '');
          manageLocalStorageItem('set', 'analyticsIgAccountName', instagramAccountName || '');
          manageLocalStorageItem('set', 'fbPageId', fbPageId || '');
        } else if (redirectedSocialMedia === AnalyticsAuthSocialAccountType.YOUTUBE) {
          manageLocalStorageItem('set', 'channelId', channelId || '');
        }
      }
    } catch (error) {
      console.error('error:', error);
      enqueueSnackbar(t('UnexpectedError'), { variant: 'error' });
      setLoading(false);
    }
  };

  const onSignInClick = async (redirectedSocialMedia: AnalyticsAuthSocialAccountType) => {
    setSignInLoading(true);
    try {
      const redirectUrl = await getSignInRedirectUrl(redirectedSocialMedia);
      if (redirectUrl) {
        manageLocalStorageItem('set', 'advertiserId', advertiserField.value);
        manageLocalStorageItem('set', 'accountManagerIds', accountManagersField.value.join(', '));
        manageLocalStorageItem('set', 'accountName', accountNameField.value);
        manageLocalStorageItem('set', 'redirectBackRoute', editAccountId || 'add');
        // Redirect to SocialMedia
        location.href = redirectUrl;
      }
    } catch (error) {
      console.error('error:', error);
      enqueueSnackbar(t('UnexpectedError'), { variant: 'error' });
      setSignInLoading(false);
    }
  };

  // derived props
  const hasConnectedIgAccount = !!connectedIgId;
  const hasAdvertiserSelector = !isAdvertiser;
  const hasPicSelector = !(isAdvertiser || isAgency);
  const hasConnectedYtAccount = channelId && channelName;

  if (loading || signInLoading) {
    return <PageLoading />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <FlexRowWithPadding>
          <FlexWrapper>
            <SectionTitleWrapper>
              <CircleItem bgColor="#ff2b52" text="1" size={24} />
              <span>{t(`Connect Social Accounts`)}</span>
            </SectionTitleWrapper>
            {hasConnectedIgAccount ? (
              <EditAccountsWrapper>
                <FlexRow>
                  <StyledConnectedIgAndFbAccounts
                    igAccountName={instagramAccountName || ''}
                    fbAccountName={fbPageName || ''}
                    reconnectSuccess={!needReconnect}
                  />
                  {needReconnect && (
                    <ReconnectButton onClick={() => onReconnectClick(AnalyticsAuthSocialAccountType.FACEBOOK)}>
                      {t('Button.Reconnect')}
                    </ReconnectButton>
                  )}
                </FlexRow>
                {needReconnect && <ReconnectMessage>{t('ReconnectAnalyticsMessage')}</ReconnectMessage>}
              </EditAccountsWrapper>
            ) : (
              <StyledIgSignInButton
                onClick={() => onSignInClick(AnalyticsAuthSocialAccountType.FACEBOOK)}
                socialMedia={SocialAccountType.INSTAGRAM}
              />
            )}
            {hasConnectedYtAccount ? (
              <EditAccountsWrapper>
                <FlexRow>
                  <StyledConnectedSocialMedia
                    socialMedia={SocialAccountType.YOUTUBE}
                    accountName={channelName}
                    isTable={false}
                  />
                  {hasYtReconnect ? (
                    <ReconnectButton
                      onClick={() => onReconnectClick(AnalyticsAuthSocialAccountType.YOUTUBE)}
                      socialMedia={SocialAccountType.YOUTUBE}
                    >
                      {t('Button.Reconnect')}
                    </ReconnectButton>
                  ) : (
                    <GreenCheckMark src={checkIcon} />
                  )}
                </FlexRow>
                {hasYtReconnect && <ReconnectMessage>{t('ReconnectAnalyticsMessage')}</ReconnectMessage>}
              </EditAccountsWrapper>
            ) : (
              <StyledIgSignInButton
                onClick={() => onSignInClick(AnalyticsAuthSocialAccountType.YOUTUBE)}
                socialMedia={SocialAccountType.YOUTUBE}
              />
            )}
          </FlexWrapper>
        </FlexRowWithPadding>

        <FlexRowWithPadding>
          <FlexWrapper>
            <SectionTitleWrapper>
              <CircleItem bgColor="#ff2b52" text="2" size={24} />
              <span>{t(`Connect Social Accounts`)}</span>
            </SectionTitleWrapper>
          </FlexWrapper>
        </FlexRowWithPadding>
        <FlexRowBottom>
          <FlexWrapper>
            <TextForm
              title="Account Name"
              isRequired
              placeholder="account name"
              error={!!accountNameMeta.error}
              {...accountNameField}
            />
            <ErrorMessage message={accountNameMeta.error} />
          </FlexWrapper>
          {hasAdvertiserSelector ? (
            <FlexWrapper>
              <SingleSelectField
                name={advertiserField.name}
                value={advertiserField.value}
                title="Advertiser"
                isRequired
                options={allAdvertisers}
                error={!!advertiserMeta.error}
                setFieldValue={setFieldValue}
                disabled={isEdit}
              />
              <ErrorMessage message={advertiserMeta.error} />
            </FlexWrapper>
          ) : null}
          <FlexWrapper>
            {hasPicSelector ? (
              <>
                <MultiSelectField
                  name={accountManagersField.name}
                  value={accountManagersField.value}
                  title="Account Management PIC"
                  isRequired
                  options={allPicUsers}
                  error={!!accountManagersMeta.error}
                  setFieldValue={setFieldValue}
                />
                <ErrorMessage message={accountManagersMeta.error} />
              </>
            ) : null}
          </FlexWrapper>
        </FlexRowBottom>

        <ButtonWrapper>
          <Submit value="Save" isSubmitting={isSubmitting} />
        </ButtonWrapper>
      </Container>
    </form>
  );
};

const Container = styled.section`
  margin: 24px 0 0 24px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
  border-radius: 3px;
  width: 680px;
`;

const FlexRow = styled.div`
  display: flex;
`;
const FlexRowWithPadding = styled(FlexRow)`
  padding: 24px;
`;
const FlexRowBottom = styled(FlexRow)`
  padding: 0 24px 40px;
`;
const FlexWrapper = styled.div`
  flex: 1;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
`;

const ButtonWrapper = styled.div`
  border-top: 1px solid #dee5ec;
  padding: 16px;
`;

const Submit = styled(SubmitButton)`
  width: 146px;
  margin-left: auto;
`;

const SectionTitleWrapper = styled.div`
  display: flex;

  & > span {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    color: #27313b;
  }
`;

const StyledIgSignInButton = styled(SocialMediaSignInButton)`
  margin-top: 24px;
  align-items: center;
`;

const StyledConnectedIgAndFbAccounts = styled(ConnectedIgAndFbAccounts)`
  width: 100%;

  & > div:first-of-type {
    padding-left: 0;
  }
`;

const EditAccountsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 12px 16px;
  border-radius: 3px;
  border: solid 1px #dee5ec;

  button {
    margin-left: 30px;
  }
`;

const ReconnectMessage = styled.span`
  display: flex;
  padding-top: 8px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #ff2b52;
`;
export const StyledConnectedSocialMedia = styled(SocialMediaColumn)`
  display: flex;
  padding-left: 0;
  width: 100%;

  img {
    margin: 0;
    padding-right: 8px;
  }
`;

export default AnalyticsAccountForm;
