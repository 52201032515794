import { css, SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import logoFile from '../../../assets/icon/anytag_white.svg';
import { APP_NAME } from '../../../libs/constant';
import { useLogo } from './useLogo';
import WithAnchor, { WithAnchorProps } from './WithAnchor';

interface LogoProps extends WithAnchorProps {
  width?: string;
  agencyLogoStyle?: SerializedStyles;
  anyTagLogoStyle?: SerializedStyles;
}

const Logo = ({ href, width, agencyLogoStyle, anyTagLogoStyle }: LogoProps) => {
  const { agencylogoUrl, loadingLogo } = useLogo();

  if (loadingLogo) {
    return null;
  }

  // Logo Image component
  const image = !!agencylogoUrl ? (
    <Image src={agencylogoUrl} alt={`${APP_NAME} Agency Logo`} />
  ) : (
    <Image src={logoFile} alt={`${APP_NAME} Influencer Marketing Platform`} />
  );

  // Style of Wrapper
  const wrapperStyle = css`
    max-width: ${width ? width : '100%'};
  `;
  const customLogoStyle = !!agencylogoUrl ? agencyLogoStyle : anyTagLogoStyle;

  return (
    <Wrapper css={[wrapperStyle, customLogoStyle]}>
      <WithAnchor href={href}>{image}</WithAnchor>
    </Wrapper>
  );
};

const Wrapper = styled.h1``;

const Image = styled.img`
  width: 100%;
`;

export default Logo;
