import css from '@emotion/css';
import { InstagramInteractionUserSortField } from '../../../../__generated__/globalTypes';
import { SliderCss } from '../../../molecules/SliderTable';

export const getUserAnalysisColumns = () => [
  {
    id: 1,
    title: 'Post',
    sort: InstagramInteractionUserSortField.POST,
    customCss: flexStart,
    help: 'interactionPost'
  },
  {
    id: 2,
    title: 'Tagged Post',
    sort: InstagramInteractionUserSortField.TAGGED_POST,
    customCss: SliderCss.twoLineHeader,
    help: 'interactionTaggedPost'
  },
  {
    id: 3,
    title: 'Mentioned Post',
    sort: InstagramInteractionUserSortField.MENTIONED_POST,
    customCss: SliderCss.twoLineHeader,
    help: 'interactionMentionedPost'
  },
  {
    id: 4,
    title: 'Avg Likes',
    sort: InstagramInteractionUserSortField.LIKE,
    customCss: flexStart,
    help: 'interactionLike'
  },
  {
    id: 5,
    title: 'Avg Tagged Likes',
    sort: InstagramInteractionUserSortField.TAGGED_LIKE,
    customCss: [SliderCss.twoLineHeader, setMinWidth('115px')],
    help: 'interactionTaggedLike'
  },
  {
    id: 6,
    title: 'Avg Mentioned Likes',
    sort: InstagramInteractionUserSortField.MENTIONED_LIKE,
    customCss: [SliderCss.twoLineHeader, setMinWidth('135px')],
    help: 'interactionMentionedLike'
  },
  {
    id: 7,
    title: 'Avg Comments',
    sort: InstagramInteractionUserSortField.COMMENT,
    customCss: flexStart,
    help: 'interactionComment'
  },
  {
    id: 8,
    title: 'Avg Tagged Comments',
    sort: InstagramInteractionUserSortField.TAGGED_COMMENT,
    customCss: [SliderCss.twoLineHeader, setMinWidth('120px')],
    help: 'interactionTaggedComment'
  },
  {
    id: 9,
    title: 'Avg Mentioned Comments',
    sort: InstagramInteractionUserSortField.MENTIONED_COMMENT,
    customCss: [SliderCss.twoLineHeader, setMinWidth('140px')],
    help: 'interactionMentionedComment'
  }
];

const flexStart = css`
  & > div {
    justify-content: flex-start;
  }
`;
const setMinWidth = (minWidth: string) => css`
  min-width: ${minWidth};
`;
