import * as yup from 'yup';
import { UserType } from '../types';

export const PositiveNumberSchema = yup
  .number()
  .typeError('invalidNumberMessage')
  .min(0, 'numberMustBePositiveMessage');

export const NameSchema = yup.string().required('requiredNameMessage');
export const EmailSchema = yup
  .string()
  .email('invalidEmailMessage')
  .required('requiredEmailMessage');

export const CountrySchema = yup.string().required('requiredCountryMessage');

export const CategoriesSchema = yup
  .array()
  .of(yup.string().required('requiredCategoriesMessage'))
  .required('requiredCategoriesMessage');

/**
 * Campaign
 */
export const AdvertiserSchema = yup.string().required('requiredAdvertiserMessage');
export const TitleSchema = yup
  .string()
  .max(250, (props: any) => `lessLetterMessage_${props.max}`)
  .required('requiredTitleMessage');
export const ServiceInformationSchema = yup.string().required('requiredServiceInformationMessage');
export const PeriodSchema = yup.object().shape({
  startDate: yup
    .date()
    .typeError('invalidDateMessage')
    .required('requiredPeriodMessage'),
  endDate: yup
    .date()
    .typeError('invalidDateMessage')
    .required('requiredPeriodMessage')
    .min(new Date().toISOString(), 'End Date must be same day or after today')
});
export const GendersSchema = yup
  .array()
  .of(yup.string())
  .required('requiredGendersMessage');
export const MinAgeSchema = yup.number().required('requiredMinAgeMessage');
export const MaxAgeSchema = yup.number().required('requiredMaxAgeMessage');
export const MinFollowersSchema = yup.number().required('requiredMinFollowersMessage');
export const MaxFollowersSchema = yup.number().required('requiredMaxFollowersMessage');
export const BudgetSchema = PositiveNumberSchema.required('requiredBudgetMessage');
export const UserTypeSchema = yup.string().required();
export const AdvertiserIdSchema = yup.number().when('userType', {
  is: UserType.ADVERTISER,
  then: yup.number().required('requiredAdvertiserMessage')
});
export const AdvertiserIdsSchema = yup.array().when('userType', {
  is: UserType.STAFF,
  then: yup.array().required('requiredAdvertiserMessage')
});
