import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import client from './apollo';
import Notification from './components/organisms/Notification';
import { AdminStoreProvider } from './Context';
import { usePseudoAttribute } from './libs/hooks/usePseudoAttribute';
import './libs/i18n';
import { Maintenance } from './pages';
import Routes from './Routes';

// tslint:disable-next-line:ordered-imports
import './assets/css/reset.css';

// Maintenance Mode
declare const MAINTENANCE: boolean;

export const root = document.getElementById('container');

const theme = createMuiTheme();

const App = () => {
  usePseudoAttribute(root);

  return (
    <ApolloProvider client={client}>
      <AdminStoreProvider>
        <MuiThemeProvider theme={theme}>
          <Notification>{MAINTENANCE ? <Maintenance /> : <Routes />}</Notification>
          <div id="portal-root" />
        </MuiThemeProvider>
      </AdminStoreProvider>
    </ApolloProvider>
  );
};

export default App;
