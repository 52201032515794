import { useContext } from 'react';
import { AdminStore, defaultAnalyticsAccountInfo } from '../../Context';
import { AnalyticsSubscriptionPlanType } from '../../__generated__/globalTypes';

export interface AnalyticsAccountProps {
  id: number;
  accountName: string;
  avatar: string;
  socialAccountUsername: string;
  subscriptionPlanId?: number;
  plan?: AnalyticsSubscriptionPlanType;
  availableFeatures?: {
    dashboardEnabled: boolean;
    hashtagsEnabled: boolean;
    compareEnabled: boolean;
    interactionEnabled: boolean;
  };
  capabilities?: {
    maxCompareAccounts: number;
    maxHashtags: number;
  };
}
export default () => {
  const {
    state: { analyticsAccountInfo },
    dispatch
  } = useContext(AdminStore);

  const updateAnalyticsAccountInfo = (props: AnalyticsAccountProps) => {
    dispatch({
      type: 'UPDATE_IG_ANALYTICS_HEADER',
      payload: {
        analyticsAccountInfo: props
      }
    });
  };

  const resetAnalyticsAccountInfo = () => {
    dispatch({
      type: 'UPDATE_IG_ANALYTICS_HEADER',
      payload: {
        analyticsAccountInfo: defaultAnalyticsAccountInfo
      }
    });
  };

  return { analyticsAccountInfo, updateAnalyticsAccountInfo, resetAnalyticsAccountInfo };
};
