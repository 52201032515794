import styled from '@emotion/styled';
import * as React from 'react';
import LanguageButton, { LanguageButtonProps } from './LanguageButton';

export interface LanguageSwitcherProps {
  className?: string;
  visible: boolean;
  languages: LanguageButtonProps[];
}

const LanguageSwitcher = (props: LanguageSwitcherProps) => {
  const { className, visible, languages } = props;

  return (
    <List className={className} visible={visible}>
      {languages.map(language => (
        <li key={language.title}>
          <LanguageButton title={language.title} onClick={language.onClick} />
        </li>
      ))}
    </List>
  );
};

const List = styled.ul<{ visible: boolean }>`
  width: 140px;
  position: absolute;
  bottom: 20px;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 2px;
  box-shadow: 0 4px 5px 0 rgba(220, 220, 230, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(220, 220, 230, 0.02);

  /* Fade In */
  display: ${props => (props.visible ? 'block' : 'none')};
  opacity: ${props => (props.visible ? '1' : '0')};
  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;

  @keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }
`;

export default LanguageSwitcher;
