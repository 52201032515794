import React from 'react';
import { useQuery } from '@apollo/client';
import { getPageInfo } from '../../../../libs/paging';
import { InstagramFeedPostFilterType } from '../../../../pages/InstagramFeedPost/types';
import ListIndicator from '../../../molecules/ListIndicator';
import { useQueryHelper } from '../../../../libs/hooks';
import { AllInstagramStoryPosts, AllInstagramStoryPostsVariables } from './__generated__/AllInstagramStoryPosts';
import InstagramFeedPostList, { InstagramStoryPostsSortAction } from './InstagramStoryPostList';
import * as ALL_INSTAGRAM_STORY_POSTS from './AllInstagramStoryPosts.graphql';
import { allInstagramStoryPostsVariables } from './utils';

export interface InstagramStoryPostListProps extends InstagramStoryPostsSortAction {
  accountId: number;
  currentPage: number;
  filter: InstagramFeedPostFilterType;
  goBackUrl: string;
}

const InstagramStoryPostListComponent = (props: InstagramStoryPostListProps) => {
  const { currentPage, sort, setSort, goBackUrl, accountId } = props;
  const { t, history, enqueueSnackbar } = useQueryHelper();

  const { data, loading, error } = useQuery<AllInstagramStoryPosts, AllInstagramStoryPostsVariables>(
    ALL_INSTAGRAM_STORY_POSTS,
    {
      variables: allInstagramStoryPostsVariables(props),
      fetchPolicy: 'cache-and-network',
      skip: !props.accountId
    }
  );

  if (loading) {
    return <ListIndicator />;
  }

  if (error) {
    history.push(goBackUrl);
    enqueueSnackbar(t(error.message), { variant: 'error' });
  }

  const instagramStoryPosts = data?.allInstagramStoryPosts || [];

  const pageInfo = getPageInfo(currentPage, data);

  return (
    <InstagramFeedPostList
      instagramStoryPosts={instagramStoryPosts}
      sort={sort}
      setSort={setSort}
      pageInfo={pageInfo}
      accountId={accountId}
    />
  );
};

export default InstagramStoryPostListComponent;
