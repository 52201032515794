import styled from '@emotion/styled';
import React from 'react';

export const MenuControlIcon = () => (
  <ControllerButton>
    <Icon className="material-icons">keyboard_arrow_down</Icon>
  </ControllerButton>
);

const ControllerButton = styled.div`
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  width: 35px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 32px;
  background-color: transparent;
  color: #6e7c89;
  font-size: 20px;
  font-weight: 500;
  pointer-events: none;
`;
