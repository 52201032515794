import React from 'react';
import styled from '@emotion/styled';

export interface CheckBoxIconProps extends IconProps {
  className?: string;
}

const CheckBoxIcon = (props: CheckBoxIconProps) => {
  const { className = '', checked, indeterminate, disabled, size } = props;
  const icon = indeterminate ? 'remove' : checked ? 'check' : 'check_box_outline_blank';

  return (
    <Icon className={`material-icons ${className}`} checked={checked} disabled={disabled} size={size}>
      {icon}
    </Icon>
  );
};

interface IconProps {
  checked: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  size?: string;
}

const Icon = styled.i<IconProps>`
  z-index: 2;
  ${({ size = '14px' }) => `
    width: ${size};
    height: ${size};
    font-size: ${size};
  `}
  font-weight: 600;
  color: ${({ checked, indeterminate, disabled }) =>
    checked || indeterminate ? '#fff' : disabled ? '#dee5ec' : '#fff'};
  background-color: ${({ checked, indeterminate, disabled }) =>
    disabled ? '#dee5ec' : checked || indeterminate ? '#3892e5' : '#fff'};
  border: solid 1px
    ${({ checked, indeterminate, disabled }) =>
      disabled ? '#c5d0da' : checked || indeterminate ? '#3892e5' : '#c5d0da'};
  border-radius: 3px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export default CheckBoxIcon;
