import styled from '@emotion/styled';
import * as React from 'react';

interface AmountColumnProps {
  className?: string;
  data: string;
  currency?: string;
}

const AmountColumn = (props: AmountColumnProps) => {
  const { className, data, currency } = props;

  return (
    <td className={className}>
      <TableData>
        <Cost>{data}</Cost>
        {currency && <Currency>{currency}</Currency>}
      </TableData>
    </td>
  );
};

const TableData = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
`;

const Cost = styled.h6`
  margin-right: 6px;
  font-size: 13px;
`;

const Currency = styled.p`
  color: #8694a2;
  font-size: 8px;
`;

export default AmountColumn;
