import { endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays, subWeeks } from 'date-fns';
import { DayModifiers } from 'react-day-picker';
import { Period, PickerDate, Range } from './types';

export const useRange = (setState: (range: Range) => void, initialPeriod?: Period) => {
  const today = new Date();
  const lastDayOfPrevMonth = subDays(startOfMonth(today), 1);

  const handleClickToday = () => {
    const range = {
      from: today,
      to: today,
      hoverTo: today,
      hoverFrom: today
    };
    setState(range);
  };

  const handleClickYesterday = () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    const range = {
      from: date,
      hoverFrom: date,
      to: date,
      hoverTo: date
    };
    setState(range);
  };

  const handleClickThisWeek = () => {
    const fistDayOdWeek = startOfWeek(today);
    const range = {
      from: fistDayOdWeek,
      hoverFrom: fistDayOdWeek,
      to: today,
      hoverTo: today
    };
    setState(range);
  };

  const handleClickLastSevenDays = () => {
    const sevenDaysAgo = subDays(today, 6);
    const range = {
      from: sevenDaysAgo,
      hoverFrom: sevenDaysAgo,
      to: today,
      hoverTo: today
    };
    setState(range);
  };

  const handleClickLastWeek = () => {
    const oneWeekAgo = subWeeks(today, 1);
    const startLastWeek = startOfWeek(oneWeekAgo);
    const endLastWeek = endOfWeek(oneWeekAgo);
    const range = {
      from: startLastWeek,
      hoverFrom: startLastWeek,
      to: endLastWeek,
      hoverTo: endLastWeek
    };
    setState(range);
  };

  const handleClickThisMonth = () => {
    const range = {
      from: startOfMonth(today),
      hoverTo: endOfMonth(today),
      to: endOfMonth(today),
      hoverFrom: startOfMonth(today)
    };
    setState(range);
  };
  const handleClickLastMonth = () => {
    const range = {
      from: startOfMonth(lastDayOfPrevMonth),
      hoverTo: lastDayOfPrevMonth,
      to: lastDayOfPrevMonth,
      hoverFrom: startOfMonth(lastDayOfPrevMonth)
    };
    setState(range);
  };
  const handleClickLastTwoWeeks = () => {
    const range = {
      from: subDays(today, 13),
      hoverTo: today,
      to: today,
      hoverFrom: subDays(today, 13)
    };
    setState(range);
  };

  const handleClickOneMonthAgo = () => {
    const range = {
      from: subDays(today, 29),
      hoverTo: today,
      to: today,
      hoverFrom: subDays(today, 29)
    };
    setState(range);
  };

  const handleClickAllTime = () => {
    const initialFrom = initialPeriod ? new Date(initialPeriod.startDate) : today;
    const initialTo = initialPeriod ? new Date(initialPeriod.endDate) : today;

    const range = {
      from: initialFrom,
      hoverTo: initialTo,
      to: initialTo,
      hoverFrom: initialFrom
    };
    setState(range);
  };

  return {
    handleClickToday,
    handleClickYesterday,
    handleClickLastMonth,
    handleClickLastTwoWeeks,
    handleClickThisWeek,
    handleClickLastSevenDays,
    handleClickLastWeek,
    handleClickOneMonthAgo,
    handleClickThisMonth,
    handleClickAllTime
  };
};

export const useHandlers = (state: Range, defaultInputState: Range, setState: (range: Range) => void) => {
  const { from, to, hoverTo, hoverFrom } = state;

  const isSelectingFirstDay = () => {
    const isRangeSelected = Boolean(from && to && hoverFrom && hoverTo);

    return !from || isRangeSelected;
  };

  const handleResetClick = () => {
    setState(defaultInputState);
  };

  const handleDayClick = (day: PickerDate, modifiers: DayModifiers) => {
    const clickedDayProp = Object.keys(modifiers);
    const isDayDisabled = clickedDayProp.some(prop => prop.includes('disabled'));

    if (isDayDisabled) {
      return;
    }

    if (!!from && !!to) {
      handleResetClick();
    }

    const isFirstDay = isSelectingFirstDay();
    if (isFirstDay) {
      setState({
        from: day,
        to: null,
        hoverTo: null,
        hoverFrom: day
      });
    } else {
      setState({ ...state, to: day, hoverTo: day });
    }
  };

  const handleDayMouseEnter = (day: PickerDate, modifiers: DayModifiers) => {
    const clickedDayProp = Object.keys(modifiers);
    const isDayDisabled = clickedDayProp.some(prop => prop.includes('disabled'));

    if (isDayDisabled) {
      return;
    }

    const isSelectFistDay = isSelectingFirstDay();
    // return if both are selected/unselected
    if ((!from && !to) || (!!from && !!to)) {
      return;
    }

    if (!isSelectFistDay) {
      setState({ ...state, to: null, hoverTo: day });
    } else {
      // if hovered_day is in the past
      setState({ from: null, hoverFrom: day, to: to || state.hoverFrom, hoverTo: to || state.hoverFrom });
    }
  };

  return {
    handleResetClick,
    handleDayClick,
    handleDayMouseEnter
  };
};
