import React, { useState, useEffect } from 'react';
import { Global, css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import HighChart from '../../../molecules/HighChart';
import { CheckBoxIcon } from '../../../atoms/Icon';
import BlurOpacityWrapper from '../../../molecules/BlurOpacityWrapper';
import * as Styled from '../../AnalyticsShared/Overview/StyledComponents';
import { usePostDetailModal } from '../../AnalyticsShared/BasePostList/usePostDetailModal';
import CompareDailyPostDetail from '../../AnalyticsPost/CompareDailyPostsDetail';
import { parseUniqId } from '../../AnalyticsShared/Overview/helpers';
import ComparableAccountsTable from './AccountsTable';
import { InstagramCompareOverview_instagramCompareOverview } from './__generated__/InstagramCompareOverview';
import { AccountInfo, AvatarAndNameType } from './types';
import {
  getOptions,
  allCompareCharts,
  CompareChartSeriesTypes,
  getCompareEngagementData,
  composeComparableAccountData,
  HIDDEN_KEY
} from './compareHelpers';
import { ComparableAccountsSelectors } from './ComparableAccountsSelectors';

interface CompareOverViewType {
  compareOverviewData: InstagramCompareOverview_instagramCompareOverview | null | undefined;
  accountsAvatarAndName: AvatarAndNameType[];
  visibleAccountsIds: number[];
  id: number;
  isMainAccountVisible: boolean;
}
const CompareOverView = ({
  compareOverviewData,
  accountsAvatarAndName,
  visibleAccountsIds,
  id,
  isMainAccountVisible
}: CompareOverViewType) => {
  const { visibleAccountsData, allComparableAccountsData } = composeComparableAccountData(
    accountsAvatarAndName,
    isMainAccountVisible,
    visibleAccountsIds,
    compareOverviewData
  );

  const { t } = useTranslation();
  const [postDay, setPostDay] = useState('');
  const firstFiveAccountsForGraph = accountsAvatarAndName.slice(0, 5);
  const [uniqIdForGraph, setUniqIdForGraph] = useState<string[]>(firstFiveAccountsForGraph.map(acc => acc.uniqId));

  // Recalculate selected dropdowns options on hide/show accounts
  useEffect(() => {
    setUniqIdForGraph(firstFiveAccountsForGraph.map(acc => acc.uniqId));
  }, [visibleAccountsData.length]);

  // Remove empty rows
  const selectedAccountsUniqIds = uniqIdForGraph.filter(uniqId => !uniqId.includes(HIDDEN_KEY));

  const { openPostDetail, handleClosePostDetail, setOpenPostDetail } = usePostDetailModal(null, 'overviewPopup');

  const fetchDailyPosts = (postDate: string) => {
    setPostDay(postDate);
    // we don't need to set uniq post id due to it is carousel with no siblings
    setOpenPostDetail(1);
  };

  const [selectedCharts, setSelectedCharts] = useState<CompareChartSeriesTypes[]>(['Follower']);
  const { options } = compareOverviewData
    ? getOptions(
        selectedCharts,
        uniqIdForGraph.map(uniqId =>
          !uniqId.includes(HIDDEN_KEY)
            ? ({
                ...accountsAvatarAndName.find(av => av.uniqId === uniqId),
                ...visibleAccountsData.find(acc => acc.uniqId === uniqId)
              } as AccountInfo)
            : null
        ),
        fetchDailyPosts
      )
    : { options: [] as Highcharts.Options };
  const engagement = getCompareEngagementData(visibleAccountsData, accountsAvatarAndName);

  const toggleSelectedCharts = (isSelected: boolean, name: CompareChartSeriesTypes, isFollowerItem: boolean) => {
    if (isSelected) {
      setSelectedCharts(selectedCharts.filter(selectedItem => selectedItem !== name));
    } else if (isFollowerItem) {
      setSelectedCharts([...selectedCharts, name]);
    } else {
      // can select only one of 'Post' | 'Like' | 'Commnet' | 'Engagement'
      setSelectedCharts(selectedCharts.includes('Follower') ? ['Follower', name] : [name]);
    }
  };
  const noData =
    !compareOverviewData ||
    !allComparableAccountsData.length ||
    // @ts-ignore -> data not exist on HighChart SeriesType
    options.series?.every(item => item.data.every(el => !el));

  return (
    <>
      <BlurOpacityWrapper
        header=" "
        dataNotAvailable={noData}
        css={css`
          box-shadow: none;
          border-radius: 0;
          border-bottom: 1px solid #dee5ef;
        `}
      >
        <Styled.Chart>
          <Styled.LegendBar>
            {allCompareCharts.map(item => {
              const isChecked = selectedCharts.includes(item);
              const isFollowerItem = item === 'Follower';

              return (
                <Styled.LegendTile
                  key={item}
                  onClick={() => toggleSelectedCharts(isChecked, item, isFollowerItem)}
                  css={
                    isFollowerItem
                      ? css`
                          padding-right: 10px;
                          border-right: 1px solid #dee5ef;
                        `
                      : []
                  }
                >
                  <CheckBoxIcon checked={isChecked} />
                  <Styled.Text>{t(item)}</Styled.Text>
                </Styled.LegendTile>
              );
            })}
            {/* accounts dropdowns */}
            <ComparableAccountsSelectors
              accountsAvatarAndName={accountsAvatarAndName}
              uniqIdForGraph={uniqIdForGraph}
              setUniqIdForGraph={setUniqIdForGraph}
            />
          </Styled.LegendBar>
          <Global
            styles={css`
              .highcharts-tooltip path {
                fill: #27313b;
              }

              .highcharts-root {
                padding-left: 4px;
              }
            `}
          />
          <HighChart options={options} />
        </Styled.Chart>
      </BlurOpacityWrapper>
      <ComparableAccountsTable engagement={engagement} />
      {postDay && (
        <CompareDailyPostDetail
          accountId={id}
          comparableAccountIds={selectedAccountsUniqIds.map(el => parseUniqId(el))}
          postDate={postDay}
          openPostDetail={!!openPostDetail}
          handleClosePostDetail={handleClosePostDetail}
        />
      )}
    </>
  );
};

export default CompareOverView;
