import styled from '@emotion/styled';
import React from 'react';

interface CircleTypes {
  text: string;
  bgColor: string;
  size: number;
}

export const CircleItem = (props: CircleTypes) => {
  const { text, bgColor, size } = props;

  return (
    <Circle size={size} bgColor={bgColor}>
      <span>{text}</span>
    </Circle>
  );
};

const Circle = styled.div<{ bgColor: string; size: number }>`
  margin-right: 8px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: ${({ bgColor }) => bgColor};
  text-align: center;
  border-radius: 50%;
  position: relative;
  vertical-align: middle;

  span {
    line-height: ${({ size }) => size}px;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
  }
`;
