import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Formik, FormikHelpers } from 'formik';
import { useMutation, ApolloError } from '@apollo/client';
import { RefreshPasswordSchema, UNEXPECTED_ERROR } from '../../../libs/validation';
import { FormValues, SubmissionStatus } from './types';
import RefreshPasswordForm from './RefreshPasswordForm';
import { RefreshPassword, RefreshPasswordVariables } from './__generated__/RefreshPassword';
import * as REFRESH_PASSWORD from './RefreshPassword.graphql';

const RefreshPasswordFormComponent = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [doMutation] = useMutation<RefreshPassword, RefreshPasswordVariables>(REFRESH_PASSWORD);

  const handleSubmit = async (payload: FormValues, { setSubmitting, setStatus }: FormikHelpers<FormValues>) => {
    const variables = {
      input: payload
    };

    await doMutation({ variables })
      .then(result => {
        if (!!result?.data?.refreshPassword) {
          enqueueSnackbar(t('succeededInRequest'), { variant: 'success' });
          setStatus(SubmissionStatus.OK);
        } else {
          setStatus(t(UNEXPECTED_ERROR));
        }
      })
      .catch((error: ApolloError) => {
        enqueueSnackbar(t('failedToRequest'), { variant: 'error' });

        const errorMessage = error?.message || UNEXPECTED_ERROR;
        console.error(errorMessage);
        enqueueSnackbar(t(errorMessage), { variant: 'error' });

        setStatus(t(errorMessage));
      });

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={RefreshPasswordSchema}
    >
      <RefreshPasswordForm />
    </Formik>
  );
};

export default RefreshPasswordFormComponent;
