import { SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { Option } from '../../../../libs/types';
import { DisabledProps } from '../types';
import InputBox from './InputBox';
import { useClickOutside } from './useClickOutside';
import { useMenuOpen } from './useMenuOpen';
import { useSearch } from './useSearch';

type ValueType = Option | Option[] | null;
enum KeyName {
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
  ENTER = 'Enter'
}
interface ItemsType<V = ValueType> {
  selectedValue: V;
  setSelectedValue: (value: V) => void;
  removeValue: (item: Option) => void;
}

interface RenderSelectedValueProps<V = ValueType> {
  selectedValue: V;
}

type SearchType = ReturnType<typeof useSearch>;
export type MenuType = ReturnType<typeof useMenuOpen>;
interface RenderMenuProps<V = ValueType> {
  items: ItemsType<V>;
  search: SearchType;
  menu: MenuType;
  cursor: Option | null;
  setCursor: (option: Option | null) => void;
}
export interface BaseSelectorProps<V = ValueType> {
  className?: string;
  name: string;
  options: Option[];
  error?: boolean;
  disabled?: boolean;
  disabledInputBox?: boolean;
  hideInput?: boolean;
  placeholder?: string;
  items: ItemsType<V>;
  onClose?: () => void;
  onClickInputBox?: (menu: MenuType) => void;
  MenuControlIcon?: React.ComponentType<DisabledProps>;
  renderSelectedValue: (props: RenderSelectedValueProps<V>) => React.ReactNode;
  renderMenu: (props: RenderMenuProps<V>) => React.ReactNode;
  isAlwaysOpen?: boolean;
  dropdownCss?: SerializedStyles;
  setSelectedValue?: (item: Option | null) => void;
}

const BaseSelector = <V extends Option | Option[] | null>(props: BaseSelectorProps<V>) => {
  const {
    className,
    items,
    error,
    disabled,
    options,
    disabledInputBox,
    placeholder,
    hideInput,
    onClose,
    onClickInputBox,
    MenuControlIcon,
    renderSelectedValue,
    renderMenu,
    isAlwaysOpen = false,
    dropdownCss,
    setSelectedValue
  } = props;

  const initialCursor = items.selectedValue || null;

  const [cursor, setCursor] = useState<any | null>(initialCursor);

  // Search items via InputText
  const search = useSearch(options);

  // Menu StateHandler -> for cases where menu should be always open we are setting default menu state to open (it will help us to close it properly on click outside)
  const menu = useMenuOpen(isAlwaysOpen);

  // Close menu if click outside of selector
  const { ref: selectorRef, clickedOutside } = useClickOutside();
  useEffect(() => {
    if (clickedOutside) {
      menu.setIsOpen(false);
    }
  }, [clickedOutside]);

  // Ref for input area
  const inputRef = useRef<HTMLInputElement>(null);

  const isFirstRun = useRef(true);
  useEffect(() => {
    // Skip first run
    if (isFirstRun.current) {
      isFirstRun.current = false;

      return;
    }
    if (!menu.isOpen) {
      search.setSearchText('');
    }
    if (!menu.isOpen && onClose) {
      onClose();
    }
  }, [menu.isOpen]);

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    search.setSearchText(e.target.value);
  };

  const handleKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Backspace' && !search.searchText) {
      if (items.selectedValue instanceof Array) {
        items.removeValue(items.selectedValue[items.selectedValue.length - 1]);
      } else {
        items.removeValue(items.selectedValue as Option);
      }
    }

    const { filteredValue } = search;

    if (key === KeyName.ARROW_UP) {
      setCursor((prevState: Option | null) => {
        const index = filteredValue.findIndex(o => prevState && o.value === prevState.value);

        return index === -1 ? null : index - 1 > -1 ? filteredValue[index - 1] : null;
      });
    }

    if (key === KeyName.ARROW_DOWN) {
      setCursor((prevState: Option | null) => {
        const index = filteredValue.findIndex(o => prevState && o.value === prevState.value);

        return index === -1
          ? filteredValue[0]
          : index + 1 < filteredValue.length
          ? filteredValue[index + 1]
          : filteredValue[filteredValue.length - 1];
      });
    }

    if (key === KeyName.ENTER && setSelectedValue && cursor?.value) {
      setSelectedValue(cursor);
      menu.setIsOpen(false);
      // lost focus from input which trigger onBlur and form submit
      // eslint-disable-next-line no-unused-expressions
      inputRef?.current?.blur();
    }
  };

  const handleClickInput = (e: React.MouseEvent<HTMLInputElement>) => {
    e.persist();
    e.stopPropagation();
    menu.setIsOpen(true);
  };

  const handleClickInputBox = () => {
    if (disabledInputBox) {
      return;
    }
    if (!!onClickInputBox) {
      onClickInputBox(menu);
    } else {
      menu.toggle();
    }
    if (!menu.isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <Wrapper ref={selectorRef} css={dropdownCss}>
      <InputBox
        className={className}
        isMenuOpen={menu.isOpen}
        isError={error}
        hideInput={hideInput}
        disabled={disabled}
        searchText={search.searchText}
        inputRef={inputRef}
        placeholder={placeholder}
        handleChange={handleSearchTextChange}
        handleKeyDown={handleKeyDown}
        handleClickInput={handleClickInput}
        handleClickInputBox={handleClickInputBox}
        MenuControlIcon={MenuControlIcon}
        renderSelectedValue={() => renderSelectedValue({ selectedValue: items.selectedValue })}
      />
      {(menu.isOpen || isAlwaysOpen) && (
        <MenuWrapper css={dropdownCss}>{renderMenu({ items, search, menu, cursor, setCursor })}</MenuWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const MenuWrapper = styled.div`
  z-index: 10;
  position: absolute;
  background-color: white;
  width: inherit;
  transition: opacity 0.1s ease;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
`;

export default BaseSelector;
