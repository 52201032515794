import styled from '@emotion/styled';
import * as React from 'react';

export interface ButtonProps {
  title: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const LanguageButton = (props: ButtonProps) => {
  const { title, onClick } = props;

  return (
    <StyledButton onClick={onClick}>
      <ButtonTitle>{title}</ButtonTitle>
      <Icon className="material-icons">keyboard_arrow_down</Icon>
    </StyledButton>
  );
};

const ButtonTitle = styled.span`
  color: #6e7c89;
  font-size: 12px;
  line-height: 16px;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  margin-left: 14px;
  cursor: pointer;

  &:hover ${ButtonTitle} {
    text-decoration: underline;
  }
`;

const Icon = styled.i`
  margin-left: 6px;
  color: #6e7c89;
  font-size: 14px;
`;

export default LanguageButton;
