import styled from '@emotion/styled';
import * as React from 'react';
import EmailForm from '../../components/organisms/RefreshPasswordForm';
import SignInSignUpHeader from '../../components/organisms/SignIn/SignInSignUpHeader';

const Template = () => (
  <Container>
    <SignInHeaderWrapper />
    <EmailForm />
  </Container>
);

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  background-color: #27313b;
  padding-top: 82px;
`;

const SignInHeaderWrapper = styled(SignInSignUpHeader)`
  padding-bottom: 24px;
  width: auto;
`;

export default Template;
