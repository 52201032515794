import { QueryHookOptions } from '@apollo/client';
import { LIMIT } from './constant';
import { INITIAL_PAGE_NUMBER } from './constant';
import { getParamsFromSearch } from './utils';

export { usePaging } from './usePaging';

/**
 * Create QueryParameter from page number
 */
export const initialPagingParam = {
  p: INITIAL_PAGE_NUMBER
};
export const getPagingParams = (page: number) => (page >= INITIAL_PAGE_NUMBER ? { p: page } : {});

export const getLocation = (pathname: string, current: object = {}, params: object = {}) => {
  const newSearch =
    '?' +
    new URLSearchParams({
      ...current,
      ...params
    }).toString();

  return { pathname, search: newSearch };
};

export const getLocationFromSearch = (pathname: string, search: string, params: object = {}) => {
  const current = getParamsFromSearch(search);

  return getLocation(pathname, current, params);
};

export const getOffset = (currentPage: number, limit: number | undefined = LIMIT) => limit * (currentPage - 1);

interface GetTotalNumber {
  getTotalNumber: {
    totalNumber: number;
  } | null;
}

export interface PageInfo {
  firstIndex: number;
  lastIndex: number;
  totalCount: number;
  currentPage: number;
  totalPages: number;
}

export const getPageInfo = <TData extends GetTotalNumber, TVariables>(
  currentPage: number,
  data?: QueryHookOptions<TData, TVariables> & Partial<TData>,
  limit: number | undefined = LIMIT
): PageInfo => {
  const totalCount = data?.getTotalNumber?.totalNumber || 0;
  const totalPages = totalCount > 0 ? Math.ceil(totalCount / limit) : 0;

  const firstIndex = getOffset(currentPage, limit) + 1;
  const lastIndex = Math.min(firstIndex + limit - 1, totalCount);

  return {
    firstIndex,
    lastIndex,
    currentPage,
    totalCount,
    totalPages
  };
};
