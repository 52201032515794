import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { checkSignedIn } from '../../../libs/auth';
import Layout, { LayoutProps } from '../Layout';

// type AppRouteProps = RouteProps & LayoutProps;
interface AppRouteProps extends RouteProps, LayoutProps {
  component: React.ComponentClass<any> | React.FC<any>;
}

const AppRoute = ({ component: Component, menuTitle, ...rest }: AppRouteProps) => {
  const isSignedIn = checkSignedIn();

  if (!isSignedIn) {
    const { pathname = '', search = '' } = rest.location || {};
    localStorage.setItem('redirectUrlBeforeSignIn', pathname + search);
  }

  return (
    <Route
      {...rest}
      render={props =>
        isSignedIn ? (
          <Layout menuTitle={menuTitle}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default AppRoute;
