import styled from '@emotion/styled';
import { SliderComponents } from '../../molecules/SliderTable';
import * as List from '../../atoms/List';

const StyledRow = styled(SliderComponents.Row)`
  height: 64px;

  td {
    border-top: 1px solid #efefef;
    padding: 0 8px;
  }
`;

const StickyTableRow = styled(SliderComponents.TitleWrapper)`
  height: 72px;
  display: flex;
  padding: 0;
  flex-direction: row;
  justify-content: unset;
`;

const NumberColumn = styled(List.TextColumn)`
  & div {
    justify-content: flex-end;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 24px 0;
  padding: 15px 16px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
  border-top: none;
  border-bottom: none;
`;

const PostDetailWrapper = styled.div`
  display: flex;
  width: 970px;
  min-height: 472px;
  margin: 0 16px;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.24), 0 0 12px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  color: #27313b;
`;

export default {
  StyledRow,
  NumberColumn,
  StickyTableRow,
  ButtonWrapper,
  PostDetailWrapper
};
