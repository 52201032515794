import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import CheckBoxIcon, { CheckBoxIconProps } from '../Icon/CheckBoxIcon';

interface CheckBoxProps extends CheckBoxIconProps {
  className?: string;
  name?: string;
  onChange?: (e?: any) => void;
  label?: string | ReactNode;
}

const CheckBox = (props: CheckBoxProps) => {
  const { className, name = '', onChange, label, checked, indeterminate, disabled, size } = props;

  return (
    <Wrapper className={className} disabled={disabled}>
      <IconWrapper checked={checked}>
        <HiddenCheckbox
          type="checkbox"
          name={name}
          onChange={onChange}
          disabled={disabled}
          checked={checked}
          value={checked.toString()}
        />
        <Icon checked={checked} indeterminate={indeterminate} disabled={disabled} size={size} />
      </IconWrapper>
      <Label checked={checked} indeterminate={indeterminate} disabled={disabled}>
        {label}
      </Label>
    </Wrapper>
  );
};

const Wrapper = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const IconWrapper = styled.span<{ checked: boolean }>`
  display: flex;
  user-select: none;

  &:focus-within > i {
    border: solid 1px ${props => (props.checked ? '#2a78c0' : '#3892e5')};
  }
`;

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const Icon = styled(CheckBoxIcon)`
  margin-right: 6px;
  position: relative;
`;

const Label = styled.span<{ checked: boolean; disabled?: boolean; indeterminate?: boolean }>`
  color: ${({ checked, indeterminate, disabled }) =>
    disabled ? '#c5d0da' : checked || indeterminate ? '#3892e5' : '#27313b'};
`;

export default CheckBox;
