import React from 'react';
import Dialog from '../../components/molecules/Dialog';
import warningIcon from '../../assets/icon/warning.svg';
import { useQueryHelper } from '../../libs/hooks';
import Template from './template';
import * as StyledComponents from './StyledComponents';
import { getSelectAccountModalContent } from './helpers';
import { useConnectedAccountFromRouterState } from './useConnectedAccountFromRouterState';

const AnalyticsAdd = () => {
  const { t } = useQueryHelper();
  const { state, closeIgModal, handleSelectAccountId } = useConnectedAccountFromRouterState();

  const {
    connectableIgAccounts,
    connectableYtChannels,
    connectedIgAccountId,
    connectedYtChannelId,
    isModalOpen,
    advertiserId,
    accountManagerIds,
    accountName
  } = state;
  // derived state -> refactor if possible
  const chosenConnectedIgAccount =
    connectedIgAccountId && connectableIgAccounts?.length
      ? connectableIgAccounts.find(acc => acc.igAccount.id === connectedIgAccountId)
      : undefined;
  const chosenConnectedYtChannel =
    connectedYtChannelId && connectableYtChannels?.length
      ? connectableYtChannels.find(channel => channel.channelId === connectedYtChannelId)
      : undefined;
  const connectedIgAccountName = chosenConnectedIgAccount?.igAccount.name || '';
  const connectedfbPageName = chosenConnectedIgAccount?.page.name || '';
  const hasConnectableAccounts = connectableIgAccounts?.length || connectableYtChannels?.length;
  const modalTitle = connectableIgAccounts?.length ? 'Choose Instagram Account' : 'Choose Youtube Channel';
  const unableToSignIn = connectableIgAccounts === undefined ? 'NoInstagramAccountLinked' : 'NoYouTubeChannelLinked';

  return (
    <>
      <Template
        connectedIgAccount={{
          fbPageName: connectedfbPageName,
          instagramAccountName: connectedIgAccountName,
          id: connectedIgAccountId,
          // add those below just for type consistency
          fbPageId: '',
          needReconnect: false,
          businessAccountId: ''
        }}
        connectedYtChannel={{
          channelName: chosenConnectedYtChannel?.channelName || '',
          channelId: chosenConnectedYtChannel?.channelId || '',
          hasYtReconnect: false
        }}
        formValues={{
          advertiserId,
          accountManagerIds,
          accountName
        }}
      />
      <Dialog
        open={Boolean(isModalOpen && hasConnectableAccounts)}
        title={t(modalTitle)}
        execute={t('Button.Add')}
        handleExecute={closeIgModal}
        onClose={closeIgModal}
      >
        {getSelectAccountModalContent({
          connectableIgAccounts,
          connectableYtChannels,
          connectedIgAccountId,
          connectedYtChannelId,
          handleSelectAccountId
        })}
      </Dialog>
      {/* Warning Modal when empty response */}
      <Dialog
        open={Boolean(isModalOpen && !hasConnectableAccounts)}
        titleIcon={<StyledComponents.ReconnectIcon src={warningIcon} />}
        title={t('Unable to Sign In')}
        onClose={closeIgModal}
      >
        <StyledComponents.WarningModalText>{t(unableToSignIn)}</StyledComponents.WarningModalText>
      </Dialog>
    </>
  );
};

export default AnalyticsAdd;
