import styled from '@emotion/styled';

export const MainWrapper = styled.div`
  padding: 24px 16px;
`;

export const Title = styled.p`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #27313b;
  margin-bottom: 24px;
`;
