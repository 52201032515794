import React from 'react';
import { SortType } from '../../libs/filter';
import { useUpdateDocumentTitle } from '../../libs/hooks';
import { InstagramStoryPostSortField } from '../../__generated__/globalTypes';
import InstagramFeedPostFilter from '../../components/organisms/AnalyticsShared/BasePostListFilter';
import InstagramStoryPostList from '../../components/organisms/AnalyticsPost/InstagramStoryPostList';
import { InstagramStoryPostFilterType } from './types';

interface TemplateProps {
  accountId: number;
  accountName: string;
  currentPage: number;
  filter: InstagramStoryPostFilterType;
  setFilter: (filter: InstagramStoryPostFilterType) => void;
  sort: SortType<InstagramStoryPostSortField>;
  setSort: (sort: SortType<InstagramStoryPostSortField>) => void;
}

const Template = (props: TemplateProps) => {
  const { currentPage, accountName, accountId, filter, setFilter, sort, setSort } = props;
  const goBackUrl = `/analytics/dashboard/${accountId}`;

  useUpdateDocumentTitle({
    title: !!accountName ? 'documentTitle.StoryPostListWithAccountName' : 'documentTitle.Story Post List',
    interpolation: {
      title: accountName
    },
    appHeader: !!accountName ? 'appHeader.StoryPostListWithAccountName' : 'appHeader.Story Post List',
    href: goBackUrl
  });

  return (
    <>
      <InstagramFeedPostFilter filter={filter} setFilter={setFilter} />
      <InstagramStoryPostList
        accountId={accountId}
        currentPage={currentPage}
        filter={filter}
        sort={sort}
        setSort={setSort}
        goBackUrl={goBackUrl}
      />
    </>
  );
};

export default Template;
