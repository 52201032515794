import { useState } from 'react';

export const useDialog = () => {
  const [isOpening, setIsOpening] = useState(false);

  const open = () => {
    setIsOpening(true);
  };
  const close = () => {
    setIsOpening(false);
  };

  return {
    isOpening,
    setIsOpening,
    open,
    close
  };
};
