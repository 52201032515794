import { withStyles } from '@material-ui/core';
import { SnackbarProvider, WithSnackbarProps } from 'notistack';
import * as React from 'react';
import * as Icons from '../../atoms/Icon';

const iconVariant = {
  success: <Icons.SuccessIcon />,
  error: <Icons.ErrorIcon />,
  warning: <Icons.ErrorIcon />, // TODO: Implement warning icon
  info: <Icons.InfoIcon />
};

const styles = {
  success: { backgroundColor: 'rgba(49,49,49,.9)', fontSize: '13px', transition: 'opacity 0.3s ease-in-out' },
  error: { backgroundColor: 'rgba(49,49,49,.9)', fontSize: '13px', transition: 'opacity 0.3s ease-in-out' },
  warning: { backgroundColor: 'rgba(49,49,49,.9)', fontSize: '13px', transition: 'opacity 0.3s ease-in-out' },
  info: { backgroundColor: 'rgba(49,49,49,.9)', fontSize: '13px', transition: 'opacity 0.3s ease-in-out' }
};

interface NotificationProps {
  classes: any;
  children: JSX.Element;
}

const Notification = ({ children, classes }: NotificationProps) => {
  const notistackRef = React.createRef<WithSnackbarProps>();
  const onClickDismiss = (key?: string | number) => () => notistackRef.current?.closeSnackbar(key);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info
      }}
      iconVariant={iconVariant}
      ref={notistackRef}
      action={key => <Icons.Icon icon="clear" withText={false} onClick={onClickDismiss(key)} />}
    >
      {children}
    </SnackbarProvider>
  );
};

export default withStyles(styles)(Notification);
