import { FormikHandlers } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Omit } from '../../../libs/types';
import Label from '../../atoms/Label';
import { MultiSelector } from '../Selector';
import { MultiSelectorProps } from '../Selector/MultiSelector';

export interface Option {
  label: string;
  value: string;
}

export interface MultiSelectFieldProps extends Omit<MultiSelectorProps, 'onChange' | 'onClose'> {
  className?: string;
  title?: string;
  isRequired?: boolean;
  help?: string;
  setFieldValue: (field: any, value: string[]) => void;
  handleSubmit?: FormikHandlers['handleSubmit'];
  initialValues?: string[];
}

const MultiSelectField = (props: MultiSelectFieldProps) => {
  const { t } = useTranslation();
  const {
    className,
    name,
    title,
    isRequired,
    placeholder,
    value,
    options,
    help,
    error,
    hasSelectAll,
    handleSubmit,
    setFieldValue,
    canOnlyAddMore,
    initialValues
  } = props;
  const disabled = props.disabled || options.length === 0;

  const handleSetValues = (items: string[]) => {
    setFieldValue(name, items);
  };
  const handleClose = async () => {
    if (handleSubmit) {
      await Promise.resolve();
      handleSubmit();
    }
  };

  return (
    <div className={className}>
      {title && <Label title={t(`Selector.${title}`)} isRequired={isRequired} help={help} />}
      <MultiSelector
        options={options}
        name={name}
        disabled={disabled}
        placeholder={disabled ? '' : !!placeholder ? t(`Selector.${placeholder}`) : t('Selector.Please Select')}
        value={value}
        error={error}
        hasSelectAll={hasSelectAll}
        onChange={handleSetValues}
        onClose={handleClose}
        canOnlyAddMore={canOnlyAddMore}
        initialValues={initialValues}
      />
    </div>
  );
};

export default MultiSelectField;
