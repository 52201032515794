import { AnalyticsAuthSocialAccountType, SocialAccountType } from '../../../../__generated__/globalTypes';

// TODO: this is fragile, later on we will support FB account as well, we need to discuss with BE
export const getReconnectSMType = (openAccType: AnalyticsAuthSocialAccountType | '') => {
  switch (openAccType) {
    case AnalyticsAuthSocialAccountType.FACEBOOK:
      return SocialAccountType.INSTAGRAM;
    case AnalyticsAuthSocialAccountType.YOUTUBE:
      return SocialAccountType.YOUTUBE;

    default:
      return undefined;
  }
};

export const getSMProfileUrls = (igAccountName?: string, ytChannelId?: string) => {
  const igProfileUrl = igAccountName ? `https://instagram.com/${igAccountName}/` : '';
  const ytChannelUrl = ytChannelId ? `https://youtube.com/channel/${ytChannelId}/` : '';

  return {
    igProfileUrl,
    ytChannelUrl
  };
};
