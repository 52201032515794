import * as yup from 'yup';

const BudgetSchema = yup.string().required('requiredBudgetMessage');
const InfluencerCostSchema = yup.string().required('requiredInfluencerCostMessage');
const ProfitSchema = yup.string().required('requiredProfitMessage');

const MarginRateSchema = yup.string().test('profitRate', 'notMoreThanHundred', val => parseInt(val, 0) <= 100);
const InfluencerAgencyRateSchema = yup
  .string()
  .test('influencerAgencyRate', 'notMoreThanHundred', val => (!!val ? parseInt(val, 0) <= 100 : true));
const AgencyRateSchema = yup
  .string()
  .test('agencyRate', 'notMoreThanHundred', val => (!!val ? parseInt(val, 0) <= 100 : true));
const RequirementsSchema = yup.string().required('requiredRequirementsMessage');
const ReasonSchema = yup.string().required('requiredReasonMessage');

export default yup.object().shape({
  budget: BudgetSchema,
  influencerCost: InfluencerCostSchema,
  profit: ProfitSchema,
  marginRate: MarginRateSchema,
  agencyRate: AgencyRateSchema,
  influencerAgencyRate: InfluencerAgencyRateSchema,
  requirements: RequirementsSchema,
  reason: ReasonSchema
});
