import styled from '@emotion/styled';
import * as React from 'react';
import { formatPrice } from '../../../libs/format';

interface UnitCostProps {
  data: number | null;
  currency: string;
  unit: string;
}

const UnitCost = (props: UnitCostProps) => {
  const { data, currency, unit } = props;

  return (
    <TableData>
      <Cost>{formatPrice(data, currency)}</Cost>
      <Unit>
        {currency}&nbsp;/&nbsp;{unit}
      </Unit>
    </TableData>
  );
};

const TableData = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  justify-content: flex-end;
`;

const Cost = styled.h6`
  margin-right: 6px;
  font-size: 13px;
`;

const Unit = styled.p`
  color: #8694a2;
  font-size: 8px;
  min-width: 57px;
`;

export default UnitCost;
