import styled from '@emotion/styled';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface EmptyColumnProps {
  className?: string;
  colSpan?: number;
  link?: {
    href: LocationDescriptor;
    title: string;
  };
}

const EmptyColumn = (props: EmptyColumnProps) => {
  const { t } = useTranslation();
  const { className, link, colSpan } = props;

  return (
    <Wrapper className={className} colSpan={colSpan}>
      <Title>{t('General.Nothing has been found')}</Title>
      <TableData>
        {link ? t('General.Please try a different query or') : t('General.Please try a different query')}
        {link && <StyledLink to={link.href}>{link.title}</StyledLink>}
      </TableData>
    </Wrapper>
  );
};

const Wrapper = styled.td`
  height: 360px;
  text-align: center;
`;

const Title = styled.h6`
  color: #757575;
  font-size: 20px;
  font-weight: 500;
`;

const TableData = styled.p`
  margin-top: 8px;
  color: #757575;
  font-size: 14px;
`;

const StyledLink = styled(Link)`
  display: inline;
  color: #179cd7;
  text-decoration: underline;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #1686b9;
  }
`;

export default EmptyColumn;
