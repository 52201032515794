import styled from '@emotion/styled';
import * as React from 'react';
import PrivacyContent from '../../components/organisms/PrivacyContent';
import SignInFooter from '../../components/organisms/Footer';
import { useUpdateDocumentTitle } from '../../libs/hooks/index';
import Logo from '../../components/organisms/Logo';

const Template = () => {
  useUpdateDocumentTitle({ title: 'documentTitle.Privacy', appHeader: '', href: '' });

  return (
    <Wrapper>
      <LogoWrapper>
        <Logo href={'/'} width="185px" />
      </LogoWrapper>
      <PrivacyContent />
      <SignInFooter />
    </Wrapper>
  );
};

const LogoWrapper = styled.div`
  width: 856px;
  margin: 24px 0;
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: #27313b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 85px;
`;

export default Template;
