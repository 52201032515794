import { useQuery } from '@apollo/client';
import React from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { History } from 'history';
import { AnalyticsPeriodWithId } from '../../AnalyticsShared/types';
import ListIndicator from '../../../molecules/ListIndicator';
import {
  InstagramDashboardPostAnalytics,
  InstagramDashboardPostAnalyticsVariables
} from './__generated__/InstagramDashboardPostAnalytics';
import * as INSTAGRAM_POST_ANALYTICS from './InstagramDashboardPostAnalytics.graphql';
import AnalyticsPosts from './template';

interface AnalyticsPostsType extends AnalyticsPeriodWithId {
  history: History;
}
const AnalyticsPostsComponents = ({ period, id, history }: AnalyticsPostsType) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading, error } = useQuery<InstagramDashboardPostAnalytics, InstagramDashboardPostAnalyticsVariables>(
    INSTAGRAM_POST_ANALYTICS,
    {
      variables: {
        accountId: id,
        startDate: period.startDate,
        endDate: period.endDate
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  if (loading) {
    return <ListIndicator />;
  }

  if (!data?.instagramDashboardPostAnalytics || error) {
    if (error) {
      enqueueSnackbar(t('Can not get analytics for current account'), { variant: 'error' });
    }
    history.push('/analytics');

    return null;
  }

  return <AnalyticsPosts {...data.instagramDashboardPostAnalytics} id={id} />;
};

export default AnalyticsPostsComponents;
