const MAX_LENGTH_OF_PAGER = 5;

export const getPageNumbers = (currentPage: number, totalPages: number): number[] => {
  const length = Math.min(totalPages, MAX_LENGTH_OF_PAGER);
  const slide = Math.floor(length / 2);

  let startIndex: number;
  startIndex = Math.max(1, currentPage - slide);
  startIndex = Math.min(startIndex, totalPages - length + 1);

  const AllPageNumbers = Array.from(Array(length), (_, n) => n + startIndex);

  return AllPageNumbers.filter(n => n < totalPages);
};
