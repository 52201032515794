import { css } from '@emotion/core';

const clearMargin = css`
  margin: 0;
`;
const clearBorder = css`
  border: 0;
`;
const marginRight8 = css`
  margin-right: 8px;
`;
const clearLeftRightBorder = css`
  border-left-style: none;
  border-right-style: none;
`;
const customHeaderStyle = css`
  border-bottom: 1px solid #dee5ec;
  & > div {
    padding-right: 16px;
    border-top-style: none;
    justify-content: flex-start;
  }
`;
const rightAlignedCol = css`
  td > div {
    justify-content: flex-end;
  }
`;
const columnPadding = css`
  padding: 0 16px;
`;
const width385 = css`
  width: 385px;
`;
const width160 = css`
  width: 160px;
`;
const width100 = css`
  width: 100%;
`;
const hashtagTableCol = css`
  height: 42px;

  td:first-of-type {
    border-left: 1px solid #efefef;
  }

  td:last-of-type {
    border-right: 1px solid #efefef;
  }
`;
const padding16 = css`
  td {
    padding: 16px 0 16px 16px;
  }
`;
const feedPostHeaderStyle = css`
  height: 58px;
  font-size: 16px;
  font-weight: normal;
  color: #27313b;
`;
const heightAuto = css`
  height: auto;
`;
const marginTop24 = css`
  margin-top: 24px;
`;
const paddingLeft8 = css`
  & > div {
    padding-left: 8px;
  }
`;
const htDialogWidth = css`
  width: 650px;
`;
const overflowText = css`
  div {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default {
  clearMargin,
  clearBorder,
  columnPadding,
  customHeaderStyle,
  clearLeftRightBorder,
  feedPostHeaderStyle,
  width385,
  width160,
  hashtagTableCol,
  width100,
  padding16,
  rightAlignedCol,
  heightAuto,
  marginRight8,
  marginTop24,
  paddingLeft8,
  htDialogWidth,
  overflowText
};
