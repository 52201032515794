import React from 'react';
import styled from '@emotion/styled';
import { SliderComponents, SliderCss, StyledComponents } from '../../../molecules/SliderTable';
import { HeaderColumn } from '../../../atoms/List';
import CustomCss from '../AnalyticsHashtagPosts/CustomCss';
import { TableGrowthSection } from '../../AnalyticsCompare/OverView/GrowthSection';
import { EngagementDataType } from './hashtagHelpers';

const HashtagOverviewTable = ({ engagement }: { engagement: EngagementDataType[] }) => (
  <SliderComponents.Wrapper css={[SliderCss.clearPadding, CustomCss.clearMargin, CustomCss.clearBorder]}>
    <SliderComponents.Container>
      <SliderComponents.SliderSection>
        <SliderComponents.SliderTable>
          <thead>
            <SliderComponents.HeaderRow css={[SliderCss.bgndColor]}>
              <SliderComponents.StickyHeaderRowWrapper>
                <StyledHeader title="Hashtag" css={[SliderCss.clearPadding]} isTable={false} />
              </SliderComponents.StickyHeaderRowWrapper>
              <StyledHeader title="Posts" help="hashtagOverviewPosts" />
              <StyledHeader
                title="Likes"
                css={[SliderCss.clearPadding, SliderCss.paddingLeft]}
                help="hashtagOverviewLikes"
              />
              <StyledHeader
                title="Comments"
                css={[SliderCss.clearPadding, SliderCss.paddingLeft]}
                help="hashtagOverviewComments"
              />
            </SliderComponents.HeaderRow>
          </thead>

          <tbody>
            {engagement.map(item => {
              const { hashtags, comments, posts, likes } = item;

              return (
                <StyledComponents.StyledRowNew css={[SliderCss.paddingSides, SliderCss.rowHeight62]} key={hashtags.id}>
                  <SliderComponents.StickyBodyRowWrapper>
                    <HashtagNameWrapper>
                      <HashtagName>{hashtags.name}</HashtagName>
                    </HashtagNameWrapper>
                  </SliderComponents.StickyBodyRowWrapper>
                  <TableGrowthSection {...posts} hasHyphen />
                  <TableGrowthSection {...likes} />
                  <TableGrowthSection {...comments} />
                </StyledComponents.StyledRowNew>
              );
            })}
          </tbody>
        </SliderComponents.SliderTable>
      </SliderComponents.SliderSection>
    </SliderComponents.Container>
  </SliderComponents.Wrapper>
);

const StyledHeader = styled(HeaderColumn)`
  & > div {
    justify-content: flex-start;
  }
`;
const HashtagNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const HashtagName = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: rgb(39, 49, 59);
`;

export default HashtagOverviewTable;
