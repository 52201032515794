import React, { useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import ListIndicator from '../../molecules/ListIndicator';
import { AnalyticsPeriodWithId } from '../AnalyticsShared/types';
import { useInstagramAnalyticsHeader, useQueryHelper } from '../../../libs/hooks';
import compareBlur from '../../../assets/img/compareBlur.jpg';
import { UpgradeToPremiumModal } from '../AnalyticsShared/UpgradeToPremiumModal';
import { BlurImage } from '../AnalyticsShared/SharedComponents';
import Audience from './Audience';
import AccountsCards from './AccountsCards';
import AnalyticsComparePosts from './AnalyticsComparePosts';
import { ComparableAccountType } from './types';
import CompareOverViewSection from './OverView';
import * as COMPARABLE_ACCOUNTS_LIST from './InstagramAnalyticsCompareAccounts.graphql';
import {
  InstagramAnalyticsCompareAccounts,
  InstagramAnalyticsCompareAccountsVariables
} from './__generated__/InstagramAnalyticsCompareAccounts';

interface AnalyticsCompareType extends ComparableAccountType, AnalyticsPeriodWithId {}
const AnalyticsCompare = (props: AnalyticsCompareType) => {
  const { username, avatarUrl, id, period, profileUrl } = props;
  const defaultAccount = {
    username,
    avatarUrl,
    id,
    isSelected: true,
    profileUrl,
    uniqId: `${id}_main`,
    blocked: false
  };
  const {
    analyticsAccountInfo: { availableFeatures, capabilities }
  } = useInstagramAnalyticsHeader();
  const { t, history, enqueueSnackbar } = useQueryHelper();
  const hasFreePlan = !availableFeatures?.compareEnabled;
  const [loading, setLoading] = useState(true && !hasFreePlan);
  // maybe it is an issue with Apollo, if we are using loading from useQuery we are having loading false and child components get rendered
  const { error } = useQuery<InstagramAnalyticsCompareAccounts, InstagramAnalyticsCompareAccountsVariables>(
    COMPARABLE_ACCOUNTS_LIST,
    {
      variables: { accountToCompareWithId: id },
      fetchPolicy: 'cache-and-network',
      skip: hasFreePlan,
      onCompleted: useCallback(({ instagramCompareGetAccounts }: InstagramAnalyticsCompareAccounts) => {
        setComparableAccounts([
          defaultAccount,
          ...(instagramCompareGetAccounts?.accounts?.map(acc => ({
            ...acc,
            isSelected: true,
            isDeletable: true,
            uniqId: `${acc.id}_${acc.elementId}`
          })) || [])
        ]);
        setLoading(false);
      }, []),
      notifyOnNetworkStatusChange: true
    }
  );
  const [comparableAccounts, setComparableAccounts] = useState<ComparableAccountType[]>([]);

  const handleRedirectToAnalytics = () => {
    history.push('/analytics');
    enqueueSnackbar(t('Can not find comparable accounts information'), { variant: 'error' });
  };

  if (loading) {
    return <StyledLoadingIndicator />;
  }

  if (error) {
    handleRedirectToAnalytics();
  }

  const comparableAccountIds = comparableAccounts.filter(acc => acc.elementId !== undefined).map(acc => acc.id);
  const accountsAvatarAndName = comparableAccounts.map(acc => ({
    name: acc.username,
    avatar: acc.avatarUrl,
    id: acc.id,
    isSelected: acc.isSelected,
    elementId: acc.elementId,
    uniqId: `${acc.id}_${acc.elementId === undefined ? 'main' : acc.elementId}`,
    blocked: acc.blocked
  }));

  const visibleAccountsAvatarAndName = accountsAvatarAndName.filter(acc => !!acc.isSelected && !acc.blocked);
  const visibleComparableAccountsIds = visibleAccountsAvatarAndName
    .filter(acc => acc.elementId !== undefined && !acc.blocked)
    .map(acc => acc.id);
  const isMainAccountVisible = !!visibleAccountsAvatarAndName.find(acc => acc.elementId === undefined)?.isSelected;

  return hasFreePlan ? (
    <>
      <BlurImage src={compareBlur} />
      <UpgradeToPremiumModal page="compare" />
    </>
  ) : (
    <>
      <AccountsCards
        id={id}
        comparableAccounts={comparableAccounts}
        setComparableAccounts={setComparableAccounts}
        maxCards={capabilities?.maxCompareAccounts}
      />
      <CompareOverViewSection
        period={period}
        comparableAccountIds={comparableAccountIds}
        id={id}
        accountsAvatarAndName={visibleAccountsAvatarAndName}
        visibleAccountsIds={visibleComparableAccountsIds}
        isMainAccountVisible={isMainAccountVisible}
      />
      <Audience
        period={period}
        comparableAccountIds={comparableAccountIds}
        id={id}
        accountsAvatarAndName={visibleAccountsAvatarAndName}
        visibleAccountsIds={visibleComparableAccountsIds}
        isMainAccountVisible={isMainAccountVisible}
      />
      <AnalyticsComparePosts
        period={period}
        id={id}
        comparableAccountIds={comparableAccountIds}
        accountsAvatarAndName={visibleAccountsAvatarAndName}
        visibleAccountsIds={visibleComparableAccountsIds}
        isMainAccountVisible={isMainAccountVisible}
      />
    </>
  );
};

const StyledLoadingIndicator = styled(ListIndicator)`
  margin: 0;
`;

export default AnalyticsCompare;
