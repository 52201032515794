import styled from '@emotion/styled';
import { RadioIcon } from '../../components/atoms/RadioButton';

export const StyledRadioIcon = styled(RadioIcon)`
  margin-right: 12px;
`;
export const InstagramWrapper = styled.div<{ selected?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: ${({ selected }) => (selected ? '10px' : '11px')};
  border-width: ${({ selected }) => (selected ? '3px' : '1px')};
  border-color: ${({ selected }) => (selected ? '#3892e5' : '#dee5ec')};
  border-style: solid;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  margin-top: 8px;
  position: relative;
`;
export const Icon = styled.i<{ disabled: boolean; checked: boolean }>`
  margin-right: 16px;
  position: relative;
  z-index: 2;
  color: ${({ checked }) => (checked ? '#179cd7' : '#d5d5d5')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  font-size: 20px;
  line-height: 20px;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
`;
export const ModalText = styled.p`
  padding: 12px 0 24px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #27313b;
`;
export const AccountsWrapper = styled.div`
  min-width: 450px;
`;
export const ConnectableAccount = styled.div`
  margin-bottom: 8px;
`;
export const DisabledCover = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  background: rgba(39, 49, 59, 0.6);
  border-radius: 5px;
`;
export const CoverText = styled.p`
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
  text-align: center;
`;
export const ReconnectIcon = styled.img`
  padding-right: 8px;
`;
export const WarningModalText = styled(ModalText)`
  padding-bottom: 0;
`;
