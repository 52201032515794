import { useContext } from 'react';
import { AdminStore } from '../../Context';

interface UpdateLayoutProps {
  hasStickyHeader?: boolean;
}
export default () => {
  const {
    state: { globalLayout },
    dispatch
  } = useContext(AdminStore);

  const updateGlobalLayout = (props: UpdateLayoutProps) => {
    dispatch({
      type: 'UPDATE_GLOBAL_LAYOUT',
      payload: {
        globalLayout: (Object.keys(props) as Array<keyof UpdateLayoutProps>).reduce(
          (updatedProps, currentKey) => ({
            ...updatedProps,
            ...(currentKey !== undefined && { [currentKey]: props[currentKey] })
          }),
          {}
        )
      }
    });
  };

  return { globalLayout, updateGlobalLayout };
};
