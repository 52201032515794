import React from 'react';
import { useHistory } from 'react-router';
import { UserRoles } from '../../__generated__/globalTypes';
import { useAuthData } from '../hooks/useAuthData';

export const checkPermission = (permission: UserRoles[]) => (RenderedComponent: any) => (props: any) => {
  const history = useHistory();
  const { auth } = useAuthData();

  if (!auth.role || !permission.includes(auth.role)) {
    history.push('/notfound');
  }

  return <RenderedComponent {...props} />;
};

export const withAdminStaff = checkPermission([UserRoles.ADMINISTRATOR, UserRoles.STAFF]);
export const withAdminStaffAgency = checkPermission([UserRoles.ADMINISTRATOR, UserRoles.STAFF, UserRoles.AGENCY]);
export const withAdminStaffAdvertiserAgency = checkPermission([
  UserRoles.ADMINISTRATOR,
  UserRoles.STAFF,
  UserRoles.ADVERTISER,
  UserRoles.AGENCY
]);
export const withAdvertiser = checkPermission([UserRoles.ADVERTISER]);
