import styled from '@emotion/styled';
import * as React from 'react';
import { COLORS } from '../../../libs/theme';
export { default as CheckBoxIcon } from './CheckBoxIcon';

interface BaseProps {
  color?: string;
  withText?: boolean;
}
const I = styled.i<BaseProps>`
  margin: ${props => (!!props.withText ? '0 10px 0 0' : '4px 0 0 0')};
  color: ${props => (!!props.color ? props.color : '#fff')};
  font-size: 20px;
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

interface IconProps extends BaseProps {
  icon: string;
  onClick?: () => void;
  className?: string;
}

export const Icon = (props: IconProps) => {
  const { icon, color, withText, onClick, className } = props;

  return (
    <I className={`${className} material-icons`} color={color} withText={withText} onClick={onClick}>
      {icon}
    </I>
  );
};

export const SuccessIcon = () => <Icon icon="check" color={COLORS.green.base} withText={true} />;
export const ErrorIcon = () => <Icon icon="error_outline" color={COLORS.red.base} withText={true} />;
export const InfoIcon = () => <Icon icon="info" color={COLORS.blue.base} withText={true} />;
export const ArrowIcon = ({ rotate }: { rotate: boolean }) => (
  <svg
    viewBox="0 0 20 20"
    preserveAspectRatio="none"
    width={16}
    fill="transparent"
    stroke="#979797"
    strokeWidth="1.1px"
    transform={rotate ? 'rotate(180)' : ''}
  >
    <path d="M1,6 L10,15 L19,6" />
  </svg>
);
export const PasswordVisibilityIcon = styled(Icon)`
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 24px;
`;
