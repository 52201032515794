import React from 'react';
import css from '@emotion/css';
import { PageInfo } from '../../../../libs/paging';
import { SortActionType } from '../../../../libs/filter';
import AnayticsFeedPostDetail from '../AnalyticsFeedPostDetail';
import { formatIntNumber, formatDate, formatPercent } from '../../../../libs/format';
import { InstagramFeedPostSortField, ORDER } from '../../../../__generated__/globalTypes';
import * as List from '../../../atoms/List';
import CarouselModal from '../../../molecules/CarouselPopup';
import { SliderComponents, SliderCss, StyledComponents } from '../../../molecules/SliderTable';
import PostColumn from '../PostColumn';
import { usePostDetailModal } from '../../AnalyticsShared/BasePostList/usePostDetailModal';
import { getAllPostsHeaders } from '../postsHelpers';
import { AllInstagramFeedPosts_allInstagramFeedPosts } from './__generated__/AllInstagramFeedPosts';

export type InstagramFeedPostsSortAction = SortActionType<InstagramFeedPostSortField>;

interface InstagramFeedPostListProps extends InstagramFeedPostsSortAction {
  instagramFeedPosts: AllInstagramFeedPosts_allInstagramFeedPosts[];
  pageInfo: PageInfo;
  hasViewColumn?: boolean;
}

const InstagramFeedPostList = (props: InstagramFeedPostListProps) => {
  const {
    sort: { field, order },
    setSort,
    instagramFeedPosts,
    pageInfo: { firstIndex, lastIndex, totalCount, currentPage, totalPages },
    hasViewColumn = false
  } = props;
  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev
  } = usePostDetailModal(instagramFeedPosts);

  return (
    <>
      <StyledComponents.ButtonWrapper css={SliderCss.boderTop}>
        <SliderComponents.PageCount total={totalCount} countLabel="Posts" />
      </StyledComponents.ButtonWrapper>
      <SliderComponents.Wrapper css={[SliderCss.marginTop, SliderCss.clearPadding]}>
        <SliderComponents.Container>
          <SliderComponents.SliderSection>
            <SliderComponents.SliderTable>
              <thead>
                <SliderComponents.HeaderRow css={SliderCss.bgndColor}>
                  <SliderComponents.StyckyHeaderDivWrapper>
                    <List.HeaderColumn
                      title="Post"
                      css={[SliderCss.clearPadding, SliderCss.width245, SliderCss.flexStart]}
                      isTable={false}
                    />
                  </SliderComponents.StyckyHeaderDivWrapper>
                  {/* slider */}
                  {getAllPostsHeaders(hasViewColumn).map(col => (
                    <List.HeaderColumn
                      key={col.columnName}
                      title={col.title}
                      help={col.help}
                      activeAsc={field === col.orderField && order === ORDER.ASC}
                      activeDesc={field === col.orderField && order === ORDER.DESC}
                      sortAsc={() => setSort({ field: col.orderField, order: ORDER.ASC })}
                      sortDesc={() => setSort({ field: col.orderField, order: ORDER.DESC })}
                      css={col.css}
                    />
                  ))}
                </SliderComponents.HeaderRow>
              </thead>
              <tbody>
                {instagramFeedPosts.map(item => {
                  const {
                    views,
                    impressions,
                    reach,
                    engagementRate,
                    engagement,
                    likes,
                    comments,
                    positiveRate,
                    negativeRate,
                    saved,
                    postDate,
                    id,
                    thumbNail,
                    content
                  } = item;

                  return (
                    <StyledComponents.StyledRowNew key={id} css={[SliderCss.paddingSides, SliderCss.rowHeight62]}>
                      <SliderComponents.StickyBodyRowWrapper css={SliderCss.clearPadding}>
                        <PostColumn
                          key={id}
                          id={id}
                          src={thumbNail}
                          title={content}
                          onClick={() => handleClickPost(id)}
                        />
                      </SliderComponents.StickyBodyRowWrapper>
                      {/* sliding section */}
                      {hasViewColumn ? (
                        <StyledComponents.NumberColumn data={formatIntNumber(views)} />
                      ) : (
                        <>
                          <StyledComponents.NumberColumn data={formatIntNumber(impressions)} />
                          <StyledComponents.NumberColumn data={formatIntNumber(reach)} />
                        </>
                      )}
                      <List.AmountColumn
                        data={formatPercent(engagementRate, false, 2)}
                        currency="%"
                        css={rateCurrencyFontSize}
                      />
                      <StyledComponents.NumberColumn data={formatIntNumber(engagement)} />
                      <StyledComponents.NumberColumn data={formatIntNumber(likes)} />
                      <StyledComponents.NumberColumn data={formatIntNumber(comments)} />
                      <List.AmountColumn
                        data={formatPercent(positiveRate, false, 2)}
                        currency="%"
                        css={rateCurrencyFontSize}
                      />
                      <List.AmountColumn
                        data={formatPercent(negativeRate, false, 2)}
                        currency="%"
                        css={rateCurrencyFontSize}
                      />
                      {!hasViewColumn && <StyledComponents.NumberColumn data={formatIntNumber(saved)} />}
                      <List.TextColumn data={formatDate(postDate)} css={rightAlignedCol} />
                    </StyledComponents.StyledRowNew>
                  );
                })}
              </tbody>
            </SliderComponents.SliderTable>
          </SliderComponents.SliderSection>
        </SliderComponents.Container>
      </SliderComponents.Wrapper>
      <SliderComponents.Pager
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        first={firstIndex}
        last={lastIndex}
      />
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <AnayticsFeedPostDetail closeModal={handleClosePostDetail} hasViewColumn={hasViewColumn} />
      </CarouselModal>
    </>
  );
};

const rateCurrencyFontSize = css`
  div > p {
    font-size: 12px;
  }
`;
const rightAlignedCol = css`
  & div {
    justify-content: flex-end;
  }
`;

export default InstagramFeedPostList;
