import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckBox } from '../../../atoms/CheckBox';

interface SelectAllProps {
  className?: string;
  disabled?: boolean;
  isSelectedAll: boolean;
  isSelectedNoting: boolean;
  handleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  label?: string;
}

const SelectAll = (props: SelectAllProps) => {
  const { className, disabled, isSelectedAll, isSelectedNoting, handleClick, label = 'Select all' } = props;
  const { t } = useTranslation();

  return (
    <StyledCheckBox
      className={className}
      label={t(label)}
      name="Select all"
      disabled={disabled}
      checked={!isSelectedNoting}
      indeterminate={!isSelectedNoting && !isSelectedAll}
      onChange={handleClick}
    />
  );
};

const StyledCheckBox = styled(CheckBox)`
  padding: 10px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: white;
  border-bottom: 1px solid #e4e4e4;
`;

export default SelectAll;
