import * as React from 'react';
import InputPassword, { InputPasswordProps } from '../../atoms/InputPassword';
import Label from '../../atoms/Label';

interface PasswordFieldProps extends InputPasswordProps {
  className?: string;
  title: string;
  isRequired?: boolean;
  help?: string;
  isDisabled?: boolean;
  type?: 'text' | 'password';
}

const PasswordField = (props: PasswordFieldProps) => {
  const { className, title, isRequired, help, placeholder, error, isDisabled, type, ...field } = props;

  return (
    <div className={className}>
      <Label title={title} isRequired={isRequired} help={help} />
      <InputPassword placeholder={placeholder} error={error} isDisabled={isDisabled} type={type} {...field} />
    </div>
  );
};

export default PasswordField;
