import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import NotFoundImg from '../../assets/img/notfound.jpg';
import { APP_NAME } from '../../libs/constant';
import { useUpdateDocumentTitle } from '../../libs/hooks';

const Template = () => {
  const title = 'documentTitle.Maintenance';
  useUpdateDocumentTitle({ title, appHeader: '', href: '' });
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Shade className="error_wrapper">
        <TextWrapper>
          <Header>{t('Sentence.System Maintenance')}</Header>
          <SubHeader>{t('Sentence.Thank you for using')}</SubHeader>
          <Text>{t(`Sentence.${APP_NAME} is temporarily down for maintenance`)}</Text>
          <StyledLink href="https://casting-asia.com/" target="_blank">
            {t(`Sentence.Go to the ${APP_NAME} Site`)}
          </StyledLink>
        </TextWrapper>
      </Shade>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: url(${NotFoundImg}) no-repeat;
  background-size: cover;
  background-position: center;
`;

const Shade = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
`;

const TextWrapper = styled.div`
  max-width: 740px;
  margin: 0 auto;
  text-align: center;
  color: #3b4146;
  padding: 0 5%;
`;

const Header = styled.h1`
  font-size: 64px;
  font-weight: 600;
  text-align: center;
  text-shadow: 1px 1px 6px #f0f0f0;

  @media (max-width: 850px) {
    font-size: 40px;
  }
`;

const SubHeader = styled.h2`
  margin-top: 20px;
  padding: 0 24px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.4em;
  text-shadow: 1px 1px 6px #f0f0f0;

  @media (max-width: 850px) {
    font-size: 20px;
  }
`;

const Text = styled.h3`
  margin-top: 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5em;
  text-shadow: 1px 1px 6px #f0f0f0;

  @media (max-width: 850px) {
    font-size: 15px;
  }
`;

const StyledLink = styled.a`
  width: 360px;
  margin: 54px auto 0;
  background-color: #ff4b61;
  border-radius: 2px;
  color: #fff;
  font-size: 17px;
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 58px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #e1485e;
  }

  @media (max-width: 850px) {
    width: 100%;
    margin: 40px auto 0;
    font-size: 15px;
    line-height: 48px;
  }
`;

export default Template;
