import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../../assets/icon/anytag_white.svg';

const InitialLoading = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <img src={logo} width="185px" alt="" />
      <Text>{t('Loading')}...</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #27313b;
`;

const Text = styled.span`
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.29;
  color: #fff;
`;

export default InitialLoading;
