import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ApplyProps {
  className?: string;
  handleClick: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}

const Apply = (props: ApplyProps) => {
  const { className, handleClick } = props;
  const { t } = useTranslation();

  return (
    <ApplyWrapper className={className}>
      <ApplyButton onClick={handleClick}>{t('Apply')}</ApplyButton>
    </ApplyWrapper>
  );
};

const ApplyWrapper = styled.div`
  border-top: 1px solid #e4e4e4;
  padding: 8px 0;
`;

const ApplyButton = styled.button`
  margin: 0 12px 0 auto;
  width: 120px;
  height: 32px;
  font-size: 12px;
  color: #3b4146;
  border-radius: 1px;
  background-color: #f9f9f9;
  border: 1px solid #d1d9de;
  cursor: pointer;

  &:hover {
    background-color: #dbdbdb;
  }
`;

export default Apply;
