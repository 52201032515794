import * as React from 'react';
import AnalyticsForm from '../../components/organisms/Analytics/AnalyticsForm';
import { useUpdateDocumentTitle } from '../../libs/hooks/index';
import { AnalyticsSubscriptionPlanType } from '../../__generated__/globalTypes';

interface AnalyticsEditProps {
  formValues: {
    advertiserId: string;
    accountManagerIds: string[];
    accountName: string;
  };
  connectedIgAccount: {
    fbPageName: string;
    fbPageId: string;
    instagramAccountName: string;
    needReconnect: boolean;
    businessAccountId: string;
    id: number;
  };
  connectedYtChannel: {
    channelId: string;
    channelName: string;
    hasYtReconnect?: boolean;
  };
  subscriptionType?: AnalyticsSubscriptionPlanType;
}
const Template = (props: AnalyticsEditProps) => {
  useUpdateDocumentTitle({
    title: 'documentTitle.EditAccount',
    appHeader: 'appHeader.Edit Account',
    href: '/analytics'
  });

  return (
    <>
      <AnalyticsForm {...props} isEdit />
      {/* TODO: we don't need it now because there is no BE for it */}
      {/* <AdvertiserSubscriptionForm subscriptionType={props.subscriptionType} /> */}
    </>
  );
};

export default Template;
