import React from 'react';
import { useQuery } from '@apollo/client';
import { getPageInfo } from '../../../../libs/paging';
import { InstagramFeedPostFilterType } from '../../../../pages/InstagramFeedPost/types';
import ListIndicator from '../../../molecules/ListIndicator';
import { useQueryHelper } from '../../../../libs/hooks';
import { AllInstagramFeedPosts, AllInstagramFeedPostsVariables } from './__generated__/AllInstagramFeedPosts';
import InstagramFeedPostList, { InstagramFeedPostsSortAction } from './InstagramFeedPostList';
import * as ALL_INSTAGRAM_FEED_POSTS from './AllInstagramFeedPosts.graphql';
import { allInstagramFeedPostsVariables } from './utils';

export interface InstagramFeedPostListProps extends InstagramFeedPostsSortAction {
  accountId: number;
  currentPage: number;
  filter: InstagramFeedPostFilterType;
  goBackUrl?: string;
}

const InstagramFeedPostListComponent = (props: InstagramFeedPostListProps) => {
  const { currentPage, sort, setSort, goBackUrl } = props;
  const { t, history, enqueueSnackbar } = useQueryHelper();

  const { data, loading, error } = useQuery<AllInstagramFeedPosts, AllInstagramFeedPostsVariables>(
    ALL_INSTAGRAM_FEED_POSTS,
    {
      variables: allInstagramFeedPostsVariables(props),
      skip: !props.accountId
    }
  );

  if (loading) {
    return <ListIndicator />;
  }

  if (error) {
    history.push(goBackUrl || '/analytics');
    enqueueSnackbar(t(error.message), { variant: 'error' });
  }

  const instagramFeedPosts = data?.allInstagramFeedPosts || [];

  const pageInfo = getPageInfo(currentPage, data);

  return (
    <InstagramFeedPostList instagramFeedPosts={instagramFeedPosts} sort={sort} setSort={setSort} pageInfo={pageInfo} />
  );
};

export default InstagramFeedPostListComponent;
