import React from 'react';
import { useTranslation } from 'react-i18next';
import SocialAccount from '../../components/molecules/SelectAccount';
import { QuerySocialAccountType } from '../../__generated__/globalTypes';
import * as StyledComponents from './StyledComponents';
import { ConnectableIgAccount, ConnectableYtChannel } from './types';

export interface SelectConnectedAccountIdType {
  isUsed: boolean;
  id: number | string;
  connectedAccountIdKey: 'connectedIgAccountId' | 'connectedYtChannelId';
}
interface SelectAccountModalProps {
  connectableIgAccounts?: ConnectableIgAccount[];
  connectableYtChannels?: ConnectableYtChannel[];
  connectedIgAccountId: number;
  connectedYtChannelId: string;
  handleSelectAccountId: (props: SelectConnectedAccountIdType) => void;
}
export const getSelectAccountModalContent = ({
  connectableIgAccounts,
  connectableYtChannels,
  connectedIgAccountId,
  connectedYtChannelId,
  handleSelectAccountId
}: SelectAccountModalProps) => {
  const { t } = useTranslation();

  if (connectableYtChannels?.length) {
    return (
      <>
        <StyledComponents.ModalText>{t('Select an YouTube channel to connect to')}</StyledComponents.ModalText>
        <StyledComponents.AccountsWrapper>
          {connectableYtChannels.map(({ avatar, channelId, channelName, isUsed, subscribersCount }) => {
            const isChecked = connectedYtChannelId === channelId;

            return (
              <StyledComponents.ConnectableAccount key={channelId}>
                <StyledComponents.InstagramWrapper
                  selected={isChecked}
                  onClick={() =>
                    handleSelectAccountId({ isUsed, id: channelId, connectedAccountIdKey: 'connectedYtChannelId' })
                  }
                  disabled={isUsed}
                >
                  <StyledComponents.StyledRadioIcon
                    isChecked={isChecked}
                    onClick={() =>
                      handleSelectAccountId({ isUsed, id: channelId, connectedAccountIdKey: 'connectedYtChannelId' })
                    }
                  />
                  <SocialAccount
                    socialMedia={QuerySocialAccountType.YOUTUBE}
                    src={avatar}
                    username={channelName}
                    followers={subscribersCount}
                  />
                  {isUsed && (
                    <StyledComponents.DisabledCover>
                      <StyledComponents.CoverText>{t('This account already exists')}</StyledComponents.CoverText>
                    </StyledComponents.DisabledCover>
                  )}
                </StyledComponents.InstagramWrapper>
              </StyledComponents.ConnectableAccount>
            );
          })}
        </StyledComponents.AccountsWrapper>
      </>
    );
  }

  return (
    <>
      <StyledComponents.ModalText>{t('Select an Instagram account to connect to')}</StyledComponents.ModalText>
      <StyledComponents.AccountsWrapper>
        {connectableIgAccounts?.map(({ page, igAccount, isAlreadyUsed }) => {
          const isChecked = connectedIgAccountId === igAccount.id;

          return (
            <StyledComponents.ConnectableAccount key={page.id}>
              <SocialAccount
                socialMedia={QuerySocialAccountType.FACEBOOK}
                src={page.avatar}
                username={page.name}
                followers={page.followersCount}
              />
              <StyledComponents.InstagramWrapper
                selected={isChecked}
                onClick={() =>
                  handleSelectAccountId({
                    isUsed: isAlreadyUsed,
                    id: igAccount.id,
                    connectedAccountIdKey: 'connectedIgAccountId'
                  })
                }
                disabled={isAlreadyUsed}
              >
                <StyledComponents.StyledRadioIcon
                  isChecked={isChecked}
                  onClick={() =>
                    handleSelectAccountId({
                      isUsed: isAlreadyUsed,
                      id: igAccount.id,
                      connectedAccountIdKey: 'connectedIgAccountId'
                    })
                  }
                />

                <SocialAccount
                  socialMedia={QuerySocialAccountType.INSTAGRAM}
                  src={igAccount.avatar}
                  username={igAccount.name}
                  followers={igAccount.followersCount}
                />
                {isAlreadyUsed && (
                  <StyledComponents.DisabledCover>
                    <StyledComponents.CoverText>{t('This account already exists')}</StyledComponents.CoverText>
                  </StyledComponents.DisabledCover>
                )}
              </StyledComponents.InstagramWrapper>
            </StyledComponents.ConnectableAccount>
          );
        })}
      </StyledComponents.AccountsWrapper>
    </>
  );
};
