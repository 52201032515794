import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as InputText from '../../atoms/InputText';
import Label from '../../atoms/Label';
import { SocialMediaColumn } from '../../atoms/List';
import { CampaignSocialMediaType } from '../../../__generated__/globalTypes';

interface TextFormProps extends InputText.TextProps {
  className?: string;
  title: string;
  isRequired?: boolean;
  help?: React.ReactNode;
  socialMedia?: CampaignSocialMediaType;
  isTable?: boolean;
  autoFocus?: boolean;
}

const TextForm = (props: TextFormProps) => {
  const { t } = useTranslation();
  const {
    className,
    title,
    isRequired,
    help,
    placeholder,
    error,
    disabled,
    socialMedia,
    isTable,
    autoFocus,
    ...field
  } = props;

  return (
    <div className={className}>
      <LabelWrapper>
        {socialMedia && <SocialMediaColumn socialMedia={socialMedia} css={iconCss} isTable={isTable} />}
        {!!title && <Label title={t(`TextForm.${title}`)} isRequired={isRequired} help={help} />}
      </LabelWrapper>
      <InputText.Text placeholder={placeholder} error={error} disabled={disabled} autoFocus={autoFocus} {...field} />
    </div>
  );
};

const iconCss = css`
  padding: 0 4px 4px 0;
`;

const LabelWrapper = styled.div`
  display: flex;
`;

export default TextForm;
