import React from 'react';
import { BlurImage } from '../AnalyticsShared/SharedComponents';
import { AnalyticsPeriodWithId } from '../AnalyticsShared/types';
import interactionBlur from '../../../assets/img/intaractionPageBlur.jpg';
import { UpgradeToPremiumModal } from '../AnalyticsShared/UpgradeToPremiumModal';
import { useInstagramAnalyticsHeader } from '../../../libs/hooks';
import InteractionPosts from './InteractionPosts';
import InteractionOverview from './Overview';

const AnalyticsCompare = ({ period, id }: AnalyticsPeriodWithId) => {
  const {
    analyticsAccountInfo: { availableFeatures }
  } = useInstagramAnalyticsHeader();
  const hasFreePlan = !availableFeatures?.interactionEnabled;

  return hasFreePlan ? (
    <>
      <BlurImage src={interactionBlur} />
      <UpgradeToPremiumModal page="interaction" />
    </>
  ) : (
    <>
      <InteractionOverview period={period} id={id} />
      <InteractionPosts period={period} id={id} />
    </>
  );
};

export default AnalyticsCompare;
