import React, { useRef } from 'react';
import useDimensions from '../../../../libs/hooks/useDimensions';
import AnalyticsContentWrapper, { ContentType } from '../../AnalyticsShared/AnalyticsContentWrapper';
import AgeBreakdownChart from '../AgeBreakdownChart';
import GenderBreakdownChart from '../GenderBreakdownChart';
import AreaBreakdownChart from '../AreaBreakdownChart';
import * as Styled from '../StyledComponents';
import { InstagramDashboardFollowerAnalytics_instagramDashboardFollowerAnalytics } from './__generated__/InstagramDashboardFollowerAnalytics';

interface AudienceChartType extends InstagramDashboardFollowerAnalytics_instagramDashboardFollowerAnalytics {
  avatar: string;
}
const AudienceChart = (props: AudienceChartType) => {
  const { followerAgeRate, followerRegionRate, followerGenderRate, avatar } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const { width } = useDimensions(containerRef);

  const chartWidth = (width - 16 * 6) / 3; // Each wrapper have 16px padding on both side

  return (
    <AnalyticsContentWrapper contentType={ContentType.Audience} tab="dashboard">
      <Styled.ChartContainer ref={containerRef}>
        <Styled.ChartWrapper>
          <GenderBreakdownChart {...followerGenderRate} width={chartWidth} />
        </Styled.ChartWrapper>
        <Styled.ChartWrapper>
          <AgeBreakdownChart {...followerAgeRate} width={chartWidth} />
        </Styled.ChartWrapper>
        <Styled.ChartWrapper>
          <AreaBreakdownChart followerRegionRate={followerRegionRate || []} width={chartWidth} avatar={avatar} />
        </Styled.ChartWrapper>
      </Styled.ChartContainer>
    </AnalyticsContentWrapper>
  );
};

export default AudienceChart;
