import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useQuery } from '@apollo/client';
import { COUNTRY_IDS } from '../../../../libs/constant';
import { AnalyticsAdminFilterTypeV2 } from '../types';
import { getOptions } from '../../../../libs/form';
import { AllAdvertisersForAnalyticsSearch } from './__generated__/AllAdvertisersForAnalyticsSearch';
import * as ALL_ADVERTISERS from './AllAdvertisersForSearch.graphql';
import { FormValues } from './types';
import AnalyticsAdminFilter from './AnalyticsAdminFilter';

export interface AnalyticsFilterComponentProps {
  filter: AnalyticsAdminFilterTypeV2;
  setFilter: (filter: AnalyticsAdminFilterTypeV2) => void;
}

const AnalyticsFilterComponent = (props: AnalyticsFilterComponentProps) => {
  const { filter, setFilter } = props;
  const { data } = useQuery<AllAdvertisersForAnalyticsSearch>(ALL_ADVERTISERS, { fetchPolicy: 'cache-and-network' });

  const handleSubmit = (payload: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const { keyword, countryId, advertiserId, subscriptionPlan } = payload;

    setFilter({ keyword, countryId, advertiserId, subscriptionPlan });
    setSubmitting(false);
  };

  const allAdvertisers = data ? getOptions(data.allAdvertisersForSearch) : [];

  return (
    <Formik
      initialValues={{
        keyword: filter.keyword || '',
        countryId: filter.countryId || '',
        advertiserId: filter.advertiserId || '',
        subscriptionPlan: filter.subscriptionPlan || ''
      }}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <AnalyticsAdminFilter allCountries={COUNTRY_IDS} allAdvertisers={allAdvertisers} />
    </Formik>
  );
};

export default AnalyticsFilterComponent;
