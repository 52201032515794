import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import * as StyledCards from '../../AnalyticsShared/AccountCardsStyled';
import Dialog from '../../../molecules/Dialog';
import { mainRed } from '../../../../libs/pallete';
import offlineIcon from '../../../../assets/icon/hide.svg';
import blockedAccountIcon from '../../../../assets/icon/blockedAccount.svg';
import { HashtageSetterType, HashtagType } from '../types';
import { useQueryHelper } from '../../../../libs/hooks';
import { formatHashTag } from '../../../../libs/format';
import * as DELETE_HASHTAG from './DeleteInstagramHashtagAnalytics.graphql';
import * as ADD_HASHTAG from './AddInstagramHashtagAnalytics.graphql';
import {
  DeleteInstagramHashtagAnalytics,
  DeleteInstagramHashtagAnalyticsVariables
} from './__generated__/DeleteInstagramHashtagAnalytics';
import {
  AddInstagramHashtagAnalytics,
  AddInstagramHashtagAnalyticsVariables
} from './__generated__/AddInstagramHashtagAnalytics';

interface HashtagCardsProps extends HashtageSetterType {
  id: number;
  maxHashtags?: number;
}
const HashtagCards = ({ hashtagData, setHashtagData, id: analyticsAccountId, maxHashtags }: HashtagCardsProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const [anchorEl, setAnchorEl] = useState<{ anchor: null | HTMLElement; anchorId: number | null }>({
    anchor: null,
    anchorId: null
  });
  const [deletingHashtag, setDeletingHashtag] = useState<HashtagType | undefined>(undefined);
  const [isOpenAddHashtag, setOpenAddHashtag] = useState<boolean>(false);
  const [hashtagForDialog, setHashtagForDialog] = useState<string | null>('');
  const [isHoverId, setIsHoverId] = useState<number>();

  const [deleteHashtag] = useMutation<DeleteInstagramHashtagAnalytics, DeleteInstagramHashtagAnalyticsVariables>(
    DELETE_HASHTAG,
    { refetchQueries: ['InstagramAnalyticsHashtags'] }
  );
  const [addHashtag, { loading }] = useMutation<AddInstagramHashtagAnalytics, AddInstagramHashtagAnalyticsVariables>(
    ADD_HASHTAG,
    { refetchQueries: ['InstagramAnalyticsHashtags'] }
  );

  const handleDotClick = (event: React.MouseEvent<HTMLButtonElement>, anchorId: number | null) => {
    event.stopPropagation();
    setAnchorEl({ anchor: event.currentTarget, anchorId });
  };

  const toggleShowHide = (anchorId: number | null) => {
    setHashtagData(
      hashtagData.map(item => {
        if (item.id === anchorId) {
          return { ...item, isSelected: !item.isSelected };
        }

        return item;
      })
    );
  };
  const handleDeleteComparableHashtag = async () => {
    const hashtagId = deletingHashtag?.id;

    if (hashtagId) {
      try {
        await deleteHashtag({
          variables: {
            input: {
              analyticsAccountId,
              hashtagId
            }
          }
        });
        enqueueSnackbar(t('succeededInDeleting', { name: deletingHashtag?.hashTag }), { variant: 'success' });
      } catch (error) {
        const message = error.message || 'failedToDelete';
        enqueueSnackbar(t(message), { variant: 'error' });
      } finally {
        setDeletingHashtag(undefined);
      }
    }
  };
  const handleClickDelete = () => {
    setDeletingHashtag(hashtagData.find(item => item.id === anchorEl.anchorId));
    setAnchorEl({ anchor: null, anchorId: null });
  };
  const handleAddComparableHashtag = async () => {
    try {
      if (hashtagForDialog) {
        await addHashtag({
          variables: {
            input: {
              hashtag: hashtagForDialog,
              analyticsAccountId
            }
          }
        });

        setOpenAddHashtag(false);
        enqueueSnackbar(t('succeededInCreating', { name: hashtagForDialog }), { variant: 'success' });
      }
    } catch (error) {
      const message = error.message || 'failedToCreate';
      enqueueSnackbar(t(message), { variant: 'error' });
    } finally {
      setHashtagForDialog('');
    }
  };

  const maxHashtagsLength = maxHashtags || 5;

  return (
    <StyledCards.CardsWrapper>
      {hashtagData.map(hashtag => {
        const isHidden = !hashtag.isSelected;
        const isBlocked = hashtag.blocked;
        const showOverflowWrapper = isHidden || isBlocked;
        const showEye = !isHidden && !isBlocked && isHoverId === hashtag.id;

        return (
          <StyledCards.Card
            clickable={!isBlocked}
            onMouseEnter={() => setIsHoverId(hashtag.id)}
            onMouseLeave={() => setIsHoverId(undefined)}
            onClick={() => {
              if (!isBlocked) {
                toggleShowHide(hashtag.id);
              }
            }}
            key={hashtag.id}
            css={css`
              justify-content: center;
              position: relative;
            `}
          >
            {!isHidden && (
              <StyledCards.VerticalDotsButton
                onClick={e => handleDotClick(e, hashtag.id)}
                css={
                  isHidden
                    ? css`
                        color: #c5d0da;
                      `
                    : []
                }
              >
                {'\u2807'}
              </StyledCards.VerticalDotsButton>
            )}
            <StyledCards.TitleWithLink
              title={hashtag.hashTag}
              onMouseEnter={() => setIsHoverId(undefined)}
              onMouseLeave={() => setIsHoverId(hashtag.id)}
              href={`https://www.instagram.com/explore/tags/${formatHashTag(hashtag.hashTag)}/`}
              target="_blank"
              rel="noreferrer noopener"
              css={
                showEye
                  ? css`
                      z-index: 2;
                    `
                  : []
              }
            >
              {hashtag.hashTag}
            </StyledCards.TitleWithLink>
            {(showOverflowWrapper || showEye) && (
              <OverflowImageWrapper>
                <StyledCards.VerticalDotsButton
                  onClick={e => handleDotClick(e, hashtag.id)}
                  css={
                    isHidden || showEye
                      ? css`
                          color: #c1cbd5;
                        `
                      : []
                  }
                >
                  {'\u2807'}
                </StyledCards.VerticalDotsButton>
                <HiddenIcon src={isHidden || showEye ? offlineIcon : blockedAccountIcon} />
              </OverflowImageWrapper>
            )}
          </StyledCards.Card>
        );
      })}
      {hashtagData.length < maxHashtagsLength && (
        <StyledCards.Card
          css={css`
            padding: 0;
          `}
        >
          <StyledCards.AddAccout onClick={() => setOpenAddHashtag(true)}>+</StyledCards.AddAccout>
        </StyledCards.Card>
      )}
      {/* Popover  */}
      <Menu
        anchorEl={anchorEl.anchor}
        open={Boolean(anchorEl.anchor)}
        onClose={() => setAnchorEl({ anchor: null, anchorId: null })}
      >
        <MenuItem onClick={() => handleClickDelete()}>{t('Delete')}</MenuItem>
      </Menu>
      {/* delete hashtag */}
      <Dialog
        open={!!deletingHashtag}
        title={t('Dialog.Delete Hashtag')}
        execute="Delete"
        executeColor={mainRed}
        handleExecute={handleDeleteComparableHashtag}
        onClose={() => setDeletingHashtag(undefined)}
      >
        <StyledCards.DialogContentWrapper>
          <p>{t('Dialog.DeleteComparableHashtagWarning')}</p>
          <StyledCards.DialogAccountWrapper>
            <span>{deletingHashtag?.hashTag || ''}</span>
          </StyledCards.DialogAccountWrapper>
        </StyledCards.DialogContentWrapper>
      </Dialog>
      {/* add hashtag */}
      <Dialog
        open={isOpenAddHashtag}
        title={t('Dialog.Add Hashtag')}
        execute="Add"
        handleExecute={handleAddComparableHashtag}
        onClose={() => setOpenAddHashtag(false)}
        disabled={!hashtagForDialog || loading}
        loading={loading}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            handleAddComparableHashtag();
          }}
        >
          <StyledCards.DialogContentWrapper>
            <p>{t('Dialog.Please enter the hashtag that you wish to monitor')}</p>
            <StyledCards.DialogAccountWrapper>
              <StyledCards.StyledTextForm
                title="Hashtag"
                placeholder="Hashtag"
                isTable={false}
                value={hashtagForDialog}
                onChange={e => setHashtagForDialog(formatHashTag(e.target.value))}
                autoFocus
              />
            </StyledCards.DialogAccountWrapper>
          </StyledCards.DialogContentWrapper>
        </form>
      </Dialog>
    </StyledCards.CardsWrapper>
  );
};

const HiddenIcon = styled.img``;
const OverflowImageWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 104px;
  height: 110px;
  border-radius: 3px;
  background-color: rgb(39, 49, 59, 0.5);
`;

export default HashtagCards;
