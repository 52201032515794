import css from '@emotion/css';
import {
  CampaignSocialMediaType,
  QuerySocialAccountType,
  SocialAccountType,
  AnalyticsAuthSocialAccountType
} from '../../__generated__/globalTypes';
import facebook from '../../assets/icon/facebook.svg';
import facebookGray from '../../assets/icon/facebookGray.svg';
import facebookWhite from '../../assets/icon/facebookWhite.svg';
import instagram from '../../assets/icon/instagram.svg';
import instagram_story from '../../assets/icon/instagram_story.svg';
import instagramGray from '../../assets/icon/instagramGray.svg';
import instagramWhite from '../../assets/icon/instagramWhite.svg';
import twitter from '../../assets/icon/twitter.svg';
import twitterGray from '../../assets/icon/twitterGray.svg';
import twitterWhite from '../../assets/icon/twitterWhite.svg';
import youtube from '../../assets/icon/youtube.svg';
import youtubeGray from '../../assets/icon/youtubeGray.svg';
import youtubeWhite from '../../assets/icon/youtubeWhite.svg';
import facebookConnect from '../../assets/icon/facebookConnect.svg';
import { DisabledBox, FacebookBox, InstagramBox, TwitterBox, YoutubeBox } from '../../components/atoms/Social';

export const getSocialMediaType = (socialMedia: string): CampaignSocialMediaType | null => {
  switch (socialMedia.toLowerCase()) {
    case 'facebook':
      return CampaignSocialMediaType.FACEBOOK;
    case 'instagram':
      return CampaignSocialMediaType.INSTAGRAM;
    case 'instagram_story':
      return CampaignSocialMediaType.INSTAGRAM_STORY;
    case 'twitter':
      return CampaignSocialMediaType.TWITTER;
    case 'youtube':
      return CampaignSocialMediaType.YOUTUBE;
    default:
      return null;
  }
};

export const switchText = (
  socialMedia: CampaignSocialMediaType | QuerySocialAccountType | SocialAccountType
): string => {
  switch (socialMedia) {
    case CampaignSocialMediaType.FACEBOOK:
    case QuerySocialAccountType.FACEBOOK:
      return 'Facebook';
    case CampaignSocialMediaType.INSTAGRAM:
    case QuerySocialAccountType.INSTAGRAM:
      return 'Instagram';
    case CampaignSocialMediaType.INSTAGRAM_STORY:
    case QuerySocialAccountType.INSTAGRAM_BUSINESS:
      return 'Instagram Story';
    case CampaignSocialMediaType.TWITTER:
    case QuerySocialAccountType.TWITTER:
      return 'Twitter';
    case CampaignSocialMediaType.YOUTUBE:
    case QuerySocialAccountType.YOUTUBE:
      return 'YouTube';
    case QuerySocialAccountType.FACEBOOK_PAGE:
      return 'Facebook Page';

    default:
      return '';
  }
};

export const switchCountUnit = (socialMedia: SocialAccountType): string =>
  socialMedia === SocialAccountType.INSTAGRAM ? 'Followers' : 'Subscribers';

export const switchImage = (socialMedia: CampaignSocialMediaType | SocialAccountType): any => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
      return facebook;

    case SocialAccountType.INSTAGRAM:
      return instagram;

    case CampaignSocialMediaType.INSTAGRAM_STORY:
      return instagram_story;

    case SocialAccountType.TWITTER:
      return twitter;

    case SocialAccountType.YOUTUBE:
      return youtube;

    default:
      return null;
  }
};

// TODO: Refactor
export const switchImageWhite = (socialMedia: SocialAccountType): any => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
      return facebookWhite;

    case SocialAccountType.INSTAGRAM:
      return instagramWhite;

    case SocialAccountType.TWITTER:
      return twitterWhite;

    case SocialAccountType.YOUTUBE:
      return youtubeWhite;

    default:
      return null;
  }
};

// TODO: Refactor
export const switchBackground = (socialMedia: SocialAccountType, disabled?: boolean): any => {
  switch (socialMedia) {
    case !disabled && SocialAccountType.FACEBOOK:
    case !disabled && SocialAccountType.FACEBOOK_PAGE:
      return FacebookBox;

    case !disabled && SocialAccountType.INSTAGRAM:
      return InstagramBox;

    case !disabled && SocialAccountType.TWITTER:
      return TwitterBox;

    case !disabled && SocialAccountType.YOUTUBE:
      return YoutubeBox;

    default:
      return DisabledBox;
  }
};

// TODO: Refactor
export const switchImageActive = (socialMedia: CampaignSocialMediaType | SocialAccountType, isActive: boolean): any => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
      return isActive ? facebook : facebookGray;

    case SocialAccountType.INSTAGRAM:
      return isActive ? instagram : instagramGray;

    case SocialAccountType.TWITTER:
      return isActive ? twitter : twitterGray;

    case SocialAccountType.YOUTUBE:
      return isActive ? youtube : youtubeGray;

    case CampaignSocialMediaType.INSTAGRAM_STORY:
      return instagram_story;

    default:
      return null;
  }
};

export const switchLinkText = (socialMedia: SocialAccountType | CampaignSocialMediaType): string => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
    case CampaignSocialMediaType.FACEBOOK:
      return 'Facebook';
    case SocialAccountType.INSTAGRAM:
    case CampaignSocialMediaType.INSTAGRAM:
    case CampaignSocialMediaType.INSTAGRAM_STORY:
      return 'Instagram';
    case SocialAccountType.YOUTUBE:
    case CampaignSocialMediaType.YOUTUBE:
      return 'YouTube';
    case SocialAccountType.TWITTER:
    case CampaignSocialMediaType.TWITTER:
      return 'Twitter';

    default:
      return '';
  }
};

export const getQuerySocialAccountType = (socialMedia?: string | null): QuerySocialAccountType | null => {
  if (!socialMedia) {
    return null;
  }

  switch (socialMedia.toLowerCase()) {
    case 'facebook':
      return QuerySocialAccountType.FACEBOOK;
    case 'facebook_page':
      return QuerySocialAccountType.FACEBOOK_PAGE;
    case 'instagram':
      return QuerySocialAccountType.INSTAGRAM;
    case 'instagram_business':
      return QuerySocialAccountType.INSTAGRAM_BUSINESS;
    case 'twitter':
      return QuerySocialAccountType.TWITTER;
    case 'youtube':
      return QuerySocialAccountType.YOUTUBE;

    default:
      return null;
  }
};

export const getQuerySocialAccountTypeList = (
  socialMedias?: string,
  init: QuerySocialAccountType[] = []
): QuerySocialAccountType[] => {
  if (!socialMedias) {
    return init;
  }

  return socialMedias
    .split(',')
    .map(socialMedia => {
      switch (socialMedia.toLowerCase()) {
        case 'facebook':
          return QuerySocialAccountType.FACEBOOK;
        case 'facebook_page':
          return QuerySocialAccountType.FACEBOOK_PAGE;
        case 'instagram':
          return QuerySocialAccountType.INSTAGRAM;
        case 'instagram_business':
          return QuerySocialAccountType.INSTAGRAM_BUSINESS;
        case 'twitter':
          return QuerySocialAccountType.TWITTER;
        case 'youtube':
          return QuerySocialAccountType.YOUTUBE;
        default:
          return null;
      }
    })
    .filter(socialMedia => !socialMedia) as QuerySocialAccountType[];
};

export const switchSocialBackgroundColor = (socialMedia: SocialAccountType | string): string => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
      return '#4460a0';
    case SocialAccountType.INSTAGRAM:
      return 'linear-gradient(94deg, #d82e7c 0%, #ff8404 99%);';
    case SocialAccountType.TWITTER:
      return '#00aaec';
    case SocialAccountType.YOUTUBE:
      return '#ce1312';
    default:
      return '#4460a0'; // For Facebook pages
  }
};

export const switchFollowersGrowthLineColor = (socialMedia: SocialAccountType | string): string => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
      return '#4e6baa';
    case SocialAccountType.INSTAGRAM:
      return '#ff2b52';
    case SocialAccountType.TWITTER:
      return '#00aaec';
    case SocialAccountType.YOUTUBE:
      return '#ce1312';
    default:
      return '#4e6baa'; // For Facebook pages
  }
};

export const switchSocialMediaName = (socialMedia: QuerySocialAccountType | AnalyticsAuthSocialAccountType): string => {
  switch (socialMedia) {
    case QuerySocialAccountType.FACEBOOK:
      return 'facebook';
    case QuerySocialAccountType.INSTAGRAM:
      return 'instagram';
    case QuerySocialAccountType.TWITTER:
      return 'twitter';
    case QuerySocialAccountType.YOUTUBE:
      return 'youtube';
    default:
      return '';
  }
};

export const switchSocialConnectIcon = (socialMedia?: QuerySocialAccountType): string | undefined => {
  switch (socialMedia) {
    case QuerySocialAccountType.FACEBOOK:
      return facebookConnect;
    case QuerySocialAccountType.INSTAGRAM:
      return instagramWhite;
    case QuerySocialAccountType.YOUTUBE:
      return youtubeWhite;

    default:
      return undefined;
  }
};
export const switchSocialConnectColor = (socialMedia: QuerySocialAccountType | SocialAccountType): string => {
  switch (socialMedia) {
    case QuerySocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
      return '#4267b2';
    case QuerySocialAccountType.INSTAGRAM:
      return 'transparent';
    case QuerySocialAccountType.TWITTER:
      return '#38a1f3';
    default:
      return '#c4302b';
  }
};
export const switchConnectSocialAccountBackground = (socialMedia: SocialAccountType, disabled?: boolean) => {
  switch (socialMedia) {
    case !disabled && SocialAccountType.INSTAGRAM:
      return css`
        background-image: linear-gradient(to right, #ae3bb0, #ff6641);
      `;
    case !disabled && SocialAccountType.YOUTUBE:
      return css`
        background-color: #ce1312;
      `;
    case !disabled && SocialAccountType.FACEBOOK:
      return css`
        background-color: #4460a0;
      `;
    case !disabled && SocialAccountType.TWITTER:
      return css`
        background-color: #169cd7;
      `;

    default:
      return css`
        background-color: #dee5ec;
      `;
  }
};
