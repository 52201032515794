import styled from '@emotion/styled';
import * as React from 'react';
import { FORM } from '../../../libs/theme';

interface FieldType {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  value?: any;
  name?: string;
}

export interface TextProps extends FieldType, InputProps {
  className?: string;
  placeholder: string;
  type?: string;
  hideClear: boolean;
  onClear: () => void;
}

const TextWithClearButton = (props: TextProps) => {
  const { className, placeholder, type, hideClear, value, onClear, ...field } = props;
  const inputType = !!type ? type : 'text';

  return (
    <Wrapper className={className}>
      <Input type={inputType} placeholder={placeholder} disabled={true} value={value} withValue={!!value} {...field} />

      {!!value && !hideClear && (
        <ClearIcon onClick={onClear} className="material-icons">
          clear
        </ClearIcon>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const ClearIcon = styled.i`
  color: #27313b;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 12px;
  position: absolute;
  right: 1px;
  top: 1px;
  background-color: transparent;
`;

interface InputProps {
  error?: boolean;
  disabled?: boolean;
}

const Input = styled.input<InputProps & { withValue: boolean }>`
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  padding: 0 12px;
  border: 1px solid transparent;
  border-color: ${props => (props.error ? FORM.ERROR : FORM.BORDER)};
  border-radius: 3px;
  font-size: 12px;
  line-height: 32px;
  background-color: ${({ withValue }) => (withValue ? '#f6f8fa' : 'transparent')};
  border-style: ${({ withValue }) => (withValue ? 'solid' : 'dashed')};

  &:focus {
    border-color: #179cd7;
  }
`;

export default TextWithClearButton;
