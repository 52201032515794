import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DisabledProps } from '../types';

interface InputBoxProps extends InputBoxWrapperProps {
  className?: string;
  searchText: string;
  placeholder?: string;
  hideInput?: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleClickInput: (e: React.MouseEvent<HTMLInputElement>) => void;
  handleClickInputBox: () => void;
  MenuControlIcon?: React.ComponentType;
  renderSelectedValue: () => React.ReactNode;
}

const InputBox = (props: InputBoxProps) => {
  const {
    isMenuOpen,
    isError,
    className,
    disabled,
    searchText,
    hideInput,
    placeholder,
    inputRef,
    handleChange,
    handleKeyDown,
    handleClickInput,
    handleClickInputBox,
    MenuControlIcon,
    renderSelectedValue
  } = props;
  const { t } = useTranslation();

  return (
    <InputBoxWrapper
      className={className}
      isMenuOpen={isMenuOpen}
      isError={isError}
      disabled={disabled}
      onClick={handleClickInputBox}
    >
      <InputWrapper>
        {renderSelectedValue()}
        {!hideInput && (
          <Input
            hideInput={hideInput}
            value={searchText}
            ref={inputRef}
            disabled={disabled}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onClick={handleClickInput}
            placeholder={disabled ? '' : placeholder === undefined ? t('Selector.Please Select') : placeholder}
          />
        )}
      </InputWrapper>
      {!!MenuControlIcon && <MenuControlIcon />}
    </InputBoxWrapper>
  );
};

interface InputBoxWrapperProps extends DisabledProps {
  isMenuOpen: boolean;
  isError?: boolean;
}

const InputBoxWrapper = styled.div<InputBoxWrapperProps>`
  min-height: 30px;
  background-color: ${({ disabled }) => (disabled ? '#f2f2f2' : '#fff')};
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-style: solid;
  position: relative;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: ${({ isMenuOpen }) => (isMenuOpen ? 0 : '3px')};
  border-bottom-left-radius: ${({ isMenuOpen }) => (isMenuOpen ? 0 : '3px')};
  border-color: ${({ isError, isMenuOpen }) => (isError ? 'tomato' : isMenuOpen ? '#179cd7' : '#dee5ec')};
`;

const InputWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  /* approx input text container width excluding dropdown arrow */
  width: calc(100% - 30px);
`;

const Input = styled.input<DisabledProps & { hideInput?: boolean }>`
  flex: 1;
  display: inline-block;
  width: 100%;
  height: 0;
  min-height: 30px;
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  line-height: 30px;
  word-wrap: break-word;
  white-space: normal;
  margin-left: 6px;
  padding: 0 2em 0 0.5em;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  box-shadow: none;
  border: none;
  border-radius: 3px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
`;

export default InputBox;
