import BaseDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/styles/makeStyles';
import * as React from 'react';
import { css, CSSObject, SerializedStyles } from '@emotion/core';
import { DialogContent } from '@material-ui/core';
import * as Buttons from '../../atoms/Button';
import { mainWhite, mainBlue, PalleteColorType } from '../../../libs/pallete';

interface RestButtonsOptions {
  title: string;
  callback: () => void | Promise<void>;
  disabled?: boolean;
  colors: PalleteColorType;
  css?: CSSObject[] | SerializedStyles;
  isVisible: boolean;
}
export interface DialogProps {
  open: boolean;
  title: string;
  cancel?: string;
  execute?: string;
  disabled?: boolean;
  handleExecute?: (e: any) => void;
  onClose: () => void;
  executeColor?: PalleteColorType;
  restButtons?: RestButtonsOptions[];
  titleIcon?: React.ReactNode;
  hasCancelButton?: boolean;
  disableScrollLock?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  loading?: boolean;
}

const useStyles = makeStyles({
  titleRoot: {
    padding: 0,

    '& h2': {
      fontSize: '20px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.2,
      display: 'flex'
    }
  },
  actionsRoot: {
    borderTop: '1px solid #dee5ec',
    padding: '16px',
    backgroundColor: '#f6f8fa'
  },
  contentRoot: {
    padding: '32px',

    '&:first-child': {
      paddingTop: '32px'
    }
  }
});

const Dialog: React.FC<DialogProps> = props => {
  const {
    open,
    title,
    cancel,
    execute,
    disabled,
    handleExecute,
    onClose,
    children,
    executeColor,
    restButtons,
    titleIcon,
    hasCancelButton = true,
    disableScrollLock = false,
    maxWidth = 'sm',
    loading
  } = props;
  const { contentRoot, titleRoot, actionsRoot } = useStyles();
  const executeButtonColor = executeColor || mainBlue;

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableScrollLock={disableScrollLock}
      maxWidth={maxWidth}
    >
      <DialogContent classes={{ root: contentRoot }}>
        {!!title && (
          <DialogTitle id="alert-dialog-title" classes={{ root: titleRoot }}>
            {titleIcon}
            {title}
          </DialogTitle>
        )}
        {children}
      </DialogContent>
      <DialogActions classes={{ root: actionsRoot }}>
        {hasCancelButton && (
          <Buttons.Button
            onClick={onClose}
            title={cancel || 'Cancel'}
            hoverbgcolor={mainWhite.hoverBorder}
            bgcolor={mainWhite.normal}
            color={mainWhite.textColor}
            css={[btnPadding]}
          />
        )}
        {!!handleExecute && (
          <Buttons.Button
            title={execute || 'OK'}
            onClick={handleExecute}
            disabled={disabled}
            hoverbgcolor={executeButtonColor.hoverBorder}
            bgcolor={executeButtonColor.normal}
            color={executeButtonColor.textColor}
            css={[btnPadding]}
            loading={loading}
          />
        )}
        {restButtons?.map(restButton =>
          !!restButton?.isVisible ? (
            <Buttons.Button
              key={restButton.title}
              title={restButton.title}
              onClick={restButton.callback}
              disabled={!!restButton.disabled}
              hoverbgcolor={restButton.colors.hoverBorder}
              bgcolor={restButton.colors.normal}
              color={restButton.colors.textColor}
              css={[btnPadding, restButton.css]}
            />
          ) : null
        )}
      </DialogActions>
    </BaseDialog>
  );
};

export default Dialog;

const btnPadding = css`
  padding: 8px 24px;
`;
