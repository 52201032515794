import * as yup from 'yup';
import { AnalyticsSubscriptionPlanType } from '../../__generated__/globalTypes';

// Advertiser Subscription Schema
export default yup.object().shape({
  accountManagerPlan: yup
    .string()
    .oneOf(Object.values(AnalyticsSubscriptionPlanType))
    .required()
});
