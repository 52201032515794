import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';
import { css } from '@emotion/core';
import { SocialAccountType } from '../../../__generated__/globalTypes';
import { switchBackground, switchImageWhite } from '../../../libs/SocialMedia';
import reconnectIcon from '../../../assets/icon/yellow_warning.svg';

const useStyles = makeStyles({
  tooltip: {
    fontSize: '12px',
    maxWidth: 'none'
  }
});

interface SocialMediaBoxProps {
  socialMedia: SocialAccountType;
  needReconnect?: boolean;
  isDisconnected?: boolean;
  onReconnect?: () => void;
  className?: string;
  isTable?: boolean;
  isActive?: boolean;
  exclusive?: boolean;
  profileUrl?: string;
}

const SocialMediaBox = (props: SocialMediaBoxProps) => {
  const {
    socialMedia,
    className,
    onReconnect,
    needReconnect = false,
    isDisconnected = false,
    isTable = true,
    exclusive = false,
    profileUrl
  } = props;
  const MediaWrapper = isTable ? TableData : Wrapper;
  const src = switchImageWhite(socialMedia);
  const Background = switchBackground(socialMedia, isDisconnected);
  const { t } = useTranslation();
  const classes = useStyles();
  const hasProfileUrlLink = profileUrl && !needReconnect;

  return (
    <MediaWrapper className={className}>
      <Tooltip
        arrow
        classes={classes}
        placement="top"
        title={isDisconnected ? t('Disconnected') : socialMedia.charAt(0) + socialMedia.substring(1).toLowerCase()}
      >
        <Background exclusive={exclusive}>
          {hasProfileUrlLink ? (
            <Anchor href={profileUrl} target="_blank" rel="noreferrer" css={displayFlexFullWidth}>
              <Image src={src} alt={socialMedia} />
            </Anchor>
          ) : (
            <div css={displayFlexFullWidth} onClick={!isDisconnected ? onReconnect : undefined}>
              <Image src={src} alt={socialMedia} />
            </div>
          )}
          {needReconnect && (
            <NeedReconnect>
              <ReconnectIcon src={reconnectIcon} onClick={onReconnect} />
            </NeedReconnect>
          )}
        </Background>
      </Tooltip>
    </MediaWrapper>
  );
};

const TableData = styled.td`
  cursor: pointer;
  padding: 0 12px;
  height: 24px;
  width: 48px;
  position: relative;
`;
const Wrapper = styled.div`
  cursor: pointer;
  padding: 0 12px;
  height: 24px;
  width: 48px;
  position: relative;
`;
const Image = styled.img`
  width: 17px;
  margin: 0 auto;
`;
const Anchor = styled.a`
  color: #3892e5;
  font-weight: normal;

  &:hover {
    text-decoration: underline;
  }
`;

const NeedReconnect = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
`;

export const ReconnectIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const displayFlexFullWidth = css`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export default SocialMediaBox;
