import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import * as List from '../../../atoms/List';
import CarouselModal from '../../../molecules/CarouselPopup';
import { SliderComponents, SliderCss, StyledComponents } from '../../../molecules/SliderTable';
import HashtagFeedPostDetail from '../../AnalyticsPost/HashtagFeedPostDetail';
import { OpacityContentWrapper } from '../../AnalyticsShared/SharedComponents';
import * as DashboardStyledComponents from '../../AnalyticsDashboard/StyledComponents';
import { usePostDetailModal } from '../../AnalyticsShared/BasePostList/usePostDetailModal';
import AnalyticsContentWrapper, { ContentType } from '../../AnalyticsShared/AnalyticsContentWrapper';
import CustomCss from './CustomCss';

interface HashtagPostDataType {
  id: number;
  hashtag: string;
  feedPosts:
    | {
        id: number;
        likes: number | null;
        comments: number | null;
        thumbNail: string | null;
      }[]
    | null;
}

interface TemplateProps {
  id: number;
  data: HashtagPostDataType[];
}

const Template = ({ id, data }: TemplateProps) => {
  const { t } = useTranslation();

  const [clickedThumbnailInfo, setClickedThumbnailInfo] = useState<{
    postId: number;
    feedPosts: { id: number }[];
  } | null>(null);

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev
  } = usePostDetailModal(clickedThumbnailInfo?.feedPosts || []);

  const onClickPostThumbnail = (clickedPostId: number, postIds: { id: number }[]) => {
    setClickedThumbnailInfo({ postId: clickedPostId, feedPosts: postIds });
  };

  const handleCloseModal = () => {
    handleClosePostDetail();
    setClickedThumbnailInfo(null);
  };

  useEffect(() => {
    if (clickedThumbnailInfo) {
      handleClickPost(clickedThumbnailInfo.postId);
    }
  }, [clickedThumbnailInfo?.feedPosts, clickedThumbnailInfo?.postId]);

  return (
    <>
      <AnalyticsContentWrapper contentType={ContentType.Posts} tab="hashtag">
        <StyledOpacityWrapper dataNotAvailable={!data?.length}>
          {!!data?.length && (
            <>
              <SliderComponents.Wrapper css={[SliderCss.clearPadding, CustomCss.clearMargin, CustomCss.clearBorder]}>
                <SliderComponents.Container>
                  <SliderComponents.SliderSection>
                    <SliderComponents.SliderTable css={[CustomCss.width100]}>
                      <tbody>
                        {data.map((item, index) => (
                          <StyledComponents.StyledRowNew
                            css={[CustomCss.padding16, CustomCss.topBorderNone]}
                            key={index}
                          >
                            <List.TextColumn
                              data={item.hashtag}
                              css={[CustomCss.hashtagColumStyle, CustomCss.width136]}
                            />

                            <List.FeedPostsColumn
                              data={item.feedPosts || []}
                              onClick={postId =>
                                onClickPostThumbnail(
                                  postId,
                                  item.feedPosts?.map(feedPost => ({ id: feedPost.id })) || []
                                )
                              }
                            />
                          </StyledComponents.StyledRowNew>
                        ))}
                        <StyledComponents.StyledRowNew css={[CustomCss.rightAlignedCol, CustomCss.heightAuto]}>
                          <List.TextColumn data="" />

                          <List.TextColumn
                            data={
                              <DashboardStyledComponents.MoreText
                                to={`/analytics/dashboard/${id}/instagram_hashtag_feed_post`}
                                css={CustomCss.marginRight8}
                              >
                                <span>{t('Button.More')}</span>{' '}
                                <DashboardStyledComponents.Arrow>{'\u2192'}</DashboardStyledComponents.Arrow>
                              </DashboardStyledComponents.MoreText>
                            }
                          />
                        </StyledComponents.StyledRowNew>
                      </tbody>
                    </SliderComponents.SliderTable>
                  </SliderComponents.SliderSection>
                </SliderComponents.Container>
              </SliderComponents.Wrapper>
            </>
          )}
        </StyledOpacityWrapper>
      </AnalyticsContentWrapper>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleCloseModal}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <HashtagFeedPostDetail closeModal={handleClosePostDetail} />
      </CarouselModal>
    </>
  );
};

const StyledOpacityWrapper = styled(OpacityContentWrapper)`
  min-height: 200px;
`;

export default Template;
