import styled from '@emotion/styled';
import * as React from 'react';
import checkIcon from '../../../assets/icon/checkIcon.svg';
import checkIconGray from '../../../assets/icon/checkIconGray.svg';
import closeIcon from '../../../assets/icon/closeIcon.svg';
import closeIconGray from '../../../assets/icon/closeIconGray.svg';

interface PaymentStatusColumnProps extends StyledButtonProps {
  icon: PaymentIcon;
  handleClick?: (props: any) => void;
}

export enum PaymentIcon {
  PAID = 'paid',
  FAILED = 'failed'
}

const getIcon = (icon: PaymentIcon, disabled: boolean) => {
  if (disabled) {
    return icon === PaymentIcon.PAID ? checkIconGray : closeIconGray;
  } else {
    return icon === PaymentIcon.PAID ? checkIcon : closeIcon;
  }
};

const PaymentStatusColumn = (props: PaymentStatusColumnProps) => {
  const { icon, hoverbgcolor, handleClick, disabled } = props;

  const paymentIcon = getIcon(icon, !!disabled);

  return (
    <TableData>
      <StyledButton disabled={disabled} hoverbgcolor={hoverbgcolor} onClick={handleClick}>
        <Container disabled={!!disabled}>
          <Icon src={paymentIcon} />
        </Container>
      </StyledButton>
    </TableData>
  );
};

const TableData = styled.td`
  width: 36px;
  padding: 0 6px;
`;

interface StyledButtonProps {
  disabled?: boolean;
  hoverbgcolor?: string;
}

const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  color: #8694a2;
  transition: color 0.3s ease-in-out;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const Container = styled.div<{ disabled: boolean }>`
  border: 1px solid #dee5ec;
  padding: 4px;
  border-radius: 3px;
  background-color: ${props => (props.disabled ? '#dee5ec' : '#fff')};

  &:hover {
    border-color: ${props => (props.disabled ? '#dee5ec' : '#c5d0da')};
  }
`;

const Icon = styled.img`
  width: 22px;
`;

export default PaymentStatusColumn;
