import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { BaseComponents, BaseCss } from '../../../molecules/BaseFilterComponents';
import SearchForm from '../../../molecules/SearchForm';
import { PalleteButton } from '../../../atoms/Button';
import { mainRed } from '../../../../libs/pallete';
import SingleSelectField from '../../../molecules/SingleSelectedField';
import { SUBSCRIPTIONS_TYPES } from '../../../../libs/constant';
import { FormValues } from './types';

const AnalyticsFilter = () => {
  const { handleSubmit, handleBlur, handleChange, setFieldValue } = useFormikContext<FormValues>();

  const [keywordField] = useField('keyword');
  const [subscriptionPlanField] = useField('subscriptionPlan');

  const handleBlurAndSubmit = (e: any) => {
    handleBlur(e);
    handleSubmit(e);
  };

  return (
    <form onSubmit={handleSubmit}>
      <BaseComponents.Container css={[paddingBottom15]}>
        <BaseComponents.Wrapper css={[BaseCss.noMarginBottom, inlineFlex, BaseCss.setWidth('100%')]}>
          <Keyword
            title="Search"
            placeholder="Search by account name"
            help="Account Name, Social Media Analytics can be only search in this field"
            value={keywordField.value}
            name="keyword"
            onBlur={handleBlurAndSubmit}
            onChange={handleChange}
            onEnterKeyPress={handleSubmit}
          />
          <SingleSelectField
            css={[BaseCss.marginRight]}
            name={subscriptionPlanField.name}
            value={subscriptionPlanField.value}
            title="Plan"
            options={SUBSCRIPTIONS_TYPES}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
          />
          <LeftAligner>
            <Button
              text="add account"
              width="140px"
              height="32px"
              palleteColor={mainRed}
              borderRadius="3px"
              href="/analytics/add"
              prefixIcon={<Icon className="material-icons">add</Icon>}
              textClassName={css`
                padding-left: 0;
              `}
            />
          </LeftAligner>
        </BaseComponents.Wrapper>
      </BaseComponents.Container>
    </form>
  );
};

const paddingBottom15 = css`
  padding-bottom: 15px;
`;

const LeftAligner = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const inlineFlex = css`
  display: inline-flex;
`;

const Keyword = styled(SearchForm)`
  margin-right: 16px;

  div {
    width: 400px;
  }
`;
const Button = styled(PalleteButton)`
  align-self: flex-end;
`;
const Icon = styled.i`
  margin-right: 4px;
  font-size: 12px;
  font-weight: 600;
`;

export default AnalyticsFilter;
