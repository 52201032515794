import { FormikHandlers } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Omit } from '../../../libs/types';
import Label from '../../atoms/Label';
import { SingleSelector } from '../Selector';
import { SingleSelectorProps } from '../Selector/SingleSelector';

export interface Option {
  label: string;
  value: string;
}

export interface SingleSelectFieldProps extends Omit<SingleSelectorProps, 'onChange' | 'onClose'> {
  className?: string;
  title?: string;
  isRequired?: boolean;
  help?: string;
  handleSubmit?: FormikHandlers['handleSubmit'];
  setFieldValue: (field: any, value: string) => void;
  hideDeselectOption?: boolean;
  hasNoPlaceholder?: boolean;
}

const SingleSelectField = (props: SingleSelectFieldProps) => {
  const { t } = useTranslation();
  const {
    className,
    name,
    title,
    isRequired,
    placeholder,
    value,
    options,
    help,
    error,
    handleSubmit,
    setFieldValue,
    hideDeselectOption
  } = props;
  const disabled = props.disabled || options.length === 0;
  const hasNoPlaceholder = disabled || !!props.hasNoPlaceholder;

  const handleClose = async () => {
    if (handleSubmit) {
      await Promise.resolve();
      handleSubmit();
    }
  };
  const handleSetValues = (item: string) => {
    setFieldValue(name, item);
  };

  return (
    <div className={className}>
      {title && <Label title={t(`Selector.${title}`)} isRequired={isRequired} help={help} />}
      <SingleSelector
        options={options}
        name={name}
        disabled={disabled}
        placeholder={hasNoPlaceholder ? '' : !!placeholder ? t(`Selector.${placeholder}`) : t('Selector.Please Select')}
        value={value}
        error={error}
        onChange={handleSetValues}
        onClose={handleClose}
        hideDeselectOption={hideDeselectOption}
      />
    </div>
  );
};

export default SingleSelectField;
