import React from 'react';
import { css } from '@emotion/core';
import { SortType } from '../../libs/filter';
import { useUpdateDocumentTitle } from '../../libs/hooks';
import { InstagramFeedPostSortField } from '../../__generated__/globalTypes';
import { BaseComponents } from '../../components/molecules/BaseFilterComponents';
import BaseHeaderTab from '../../components/organisms/AnalyticsShared/BaseHeaderTab';
import { ComparableAccountType } from '../../components/organisms/AnalyticsCompare/types';
import InstagramCompareFeedPostFilter from '../../components/organisms/AnalyticsShared/BasePostListFilter';
import InstagramCompareFeedPostList from '../../components/organisms/AnalyticsPost/InstagramCompareFeedPostList';
import { InstagramCompareFeedPostFilterType } from './types';

interface TemplateProps {
  /* Main account id from the url */
  accountId: number;
  accountName: string;
  currentPage: number;
  filter: InstagramCompareFeedPostFilterType;
  setFilter: (filter: InstagramCompareFeedPostFilterType) => void;
  sort: SortType<InstagramFeedPostSortField>;
  setSort: (sort: SortType<InstagramFeedPostSortField>) => void;
  comparableAccounts: ComparableAccountType[];
  onChangeTab: (accountId: string, elementId?: number) => void;
  selectedAccountId: number;
  selectedElementId?: number;
}

const Template = (props: TemplateProps) => {
  const {
    accountName,
    accountId,
    filter,
    setFilter,
    currentPage,
    sort,
    setSort,
    comparableAccounts,
    onChangeTab,
    selectedAccountId,
    selectedElementId
  } = props;

  useUpdateDocumentTitle({
    title: !!accountName ? 'documentTitle.PostListWithAccountName' : 'documentTitle.Post List',
    interpolation: {
      title: accountName
    },
    appHeader: !!accountName ? 'appHeader.PostListWithAccountName' : 'appHeader.Post List',
    href: `/analytics/dashboard/${accountId}/compare`
  });

  return (
    <>
      <BaseHeaderTab
        items={comparableAccounts.map(acc => ({
          label: acc.username,
          value: acc.id.toString(),
          avatar: acc.avatarUrl,
          elementId: acc.elementId
        }))}
        selectedAccountId={selectedAccountId.toString()}
        selectedElementId={selectedElementId}
        onChange={onChangeTab}
        hasLabel={false}
      />
      <BaseComponents.Container css={containerCss}>
        <InstagramCompareFeedPostFilter filter={filter} setFilter={setFilter} wrapWithContainer={false} />
      </BaseComponents.Container>
      <InstagramCompareFeedPostList
        accountToCompareWithId={accountId}
        accountId={selectedAccountId}
        currentPage={currentPage}
        filter={filter}
        sort={sort}
        setSort={setSort}
        selectedElementId={selectedElementId}
      />
    </>
  );
};

const containerCss = css`
  margin-top: 1px;
  padding-bottom: 15px;
  border-top-left-radius: 0;
`;

export default Template;
