import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface OperationButtonProps extends StyleProps {
  className?: string;
  type?: 'reset' | 'button' | 'submit';
  title: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const OperationButton = (props: OperationButtonProps) => {
  const { t } = useTranslation();
  const { className, title, disabled, onClick } = props;
  const type = !!props.type ? props.type : 'button';

  return (
    <StyledButton type={type} className={className} disabled={disabled} onClick={onClick}>
      {t(`Button.${title}`).toUpperCase()}
    </StyledButton>
  );
};

interface StyleProps {
  disabled?: boolean;
}

const StyledButton = styled.button<StyleProps>`
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee5ec;
  border-radius: 3px;
  color: #6e7c89;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s ease-out;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  &:hover {
    border: solid 1px #c5d0da;
  }

  &:active {
    background-color: #f6f8fa;
  }
`;

export default OperationButton;
