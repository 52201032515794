import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SocialAccountType, CampaignSocialMediaType } from '../../../../../__generated__/globalTypes';
import { formatIntNumber, formatPercent } from '../../../../../libs/format';
import { switchImageActive } from '../../../../../libs/SocialMedia';
import { InstagramEngagementInfoProps } from '../types';

const InstagramEngagementInfo = (props: InstagramEngagementInfoProps) => {
  const { t } = useTranslation();
  const { postUrl, sentiment, analysis, storyData, taggedPost, hasViewColumn } = props;

  return (
    <MainContainer>
      <Title>
        {t('General.Engagement')}
        <Anchor href={postUrl as string} target="_blank" rel="noreferrer">
          {`View on ${CampaignSocialMediaType.INSTAGRAM}`}
        </Anchor>
      </Title>
      <EngagementContainer>
        <Engagement>
          <Icon src={switchImageActive(SocialAccountType.INSTAGRAM, true)} alt="" />
          <CountWrapper>
            {analysis && (
              <>
                <Wrapper>
                  <Value>{formatIntNumber(analysis.likes || null)}</Value>
                  <Unit>{t('General.Likes')}</Unit>
                </Wrapper>
                <Wrapper>
                  <Value>{formatIntNumber(analysis.comments || null)}</Value>
                  <Unit>{t('General.Comments')}</Unit>
                </Wrapper>

                {analysis.views !== undefined && (
                  <Wrapper>
                    <Value>{formatIntNumber(analysis.views || null)}</Value>
                    <Unit>{t('General.View')}</Unit>
                  </Wrapper>
                )}
                {!hasViewColumn && (
                  <>
                    {analysis.saves !== undefined && (
                      <Wrapper>
                        <Value>{formatIntNumber(analysis.saves || null)}</Value>
                        <Unit>{t('General.Save')}</Unit>
                      </Wrapper>
                    )}

                    {analysis.reach !== undefined && (
                      <Wrapper>
                        <Value>{formatIntNumber(analysis.reach || null)}</Value>
                        <Unit>{t('General.Reach')}</Unit>
                      </Wrapper>
                    )}

                    {analysis.impressions !== undefined && (
                      <Wrapper>
                        <Value>{formatIntNumber(analysis.impressions || null)}</Value>
                        <Unit>{t('General.Imps')}</Unit>
                      </Wrapper>
                    )}
                  </>
                )}
              </>
            )}

            {sentiment && (
              <>
                <Wrapper>
                  <Value>{formatPercent(sentiment.positiveRate, true, 2)}</Value>
                  <Unit>{t('General.positive rate')}</Unit>
                </Wrapper>

                <Wrapper>
                  <Value>{formatPercent(sentiment.negativeRate, true, 2)}</Value>
                  <Unit>{t('General.negative rate')}</Unit>
                </Wrapper>
              </>
            )}

            {storyData && (
              <>
                <Wrapper>
                  <Value>{formatIntNumber(storyData.comments)}</Value>
                  <Unit>{t('General.Comments')}</Unit>
                </Wrapper>

                <Wrapper>
                  <Value>{formatIntNumber(storyData.reach)}</Value>
                  <Unit>{t('General.Reach')}</Unit>
                </Wrapper>

                <Wrapper>
                  <Value>{formatIntNumber(storyData.impressions)}</Value>
                  <Unit>{t('General.Imps')}</Unit>
                </Wrapper>

                <Wrapper>
                  <Value>{formatIntNumber(storyData.exits)}</Value>
                  <Unit>{t('General.Exits')}</Unit>
                </Wrapper>

                <Wrapper>
                  <Value>{formatIntNumber(storyData.tapsForward)}</Value>
                  <Unit>{t('General.Tapforward')}</Unit>
                </Wrapper>

                <Wrapper>
                  <Value>{formatIntNumber(storyData.tapsBack)}</Value>
                  <Unit>{t('General.Tap back')}</Unit>
                </Wrapper>
              </>
            )}

            {taggedPost && (
              <>
                <Wrapper>
                  <Value>{formatIntNumber(taggedPost.likes || null)}</Value>
                  <Unit>{t('General.Likes')}</Unit>
                </Wrapper>
                <Wrapper>
                  <Value>{formatIntNumber(taggedPost.comments)}</Value>
                  <Unit>{t('General.Comments')}</Unit>
                </Wrapper>
              </>
            )}
          </CountWrapper>
        </Engagement>
      </EngagementContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  margin-bottom: 16px;
`;

const EngagementContainer = styled.div`
  padding-bottom: 16px;
`;

const Engagement = styled.div`
  display: flex;
  align-items: flex-start;

  div:not(:last-of-type) {
    border-right: 1px solid #dee5ec;
  }
`;

const Icon = styled.img`
  width: 22px;
  margin-right: 12px;
`;

const Wrapper = styled.div`
  padding: 0 8px;
`;

const Value = styled.h6`
  font-weight: 600;
  font-size: 13px;
  color: #27313b;
`;

const Unit = styled.p`
  margin-top: 1px;
  color: #6e7c89;
  font-size: 10px;
  white-space: nowrap;
`;

const CountWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Anchor = styled.a`
  margin-left: 17px;
  color: #179cd7;
  font-size: 12px;
  font-weight: normal;

  &:hover {
    text-decoration: underline;
  }
`;
const Title = styled.h6`
  display: flex;
  margin-bottom: 8px;
  color: #27313b;
  font-size: 12px;
  font-weight: 600;
`;

export default InstagramEngagementInfo;
