import { css } from '@emotion/core';

const clearMargin = css`
  margin: 0;
`;
const marginRight8 = css`
  margin-right: 8px;
`;
const clearBorder = css`
  border: 0;
`;
const customHeaderStyle = css`
  border-bottom: 1px solid #dee5ec;
  height: 58px;
  font-size: 16px;
  font-weight: normal;
  color: #27313b;
  & > div {
    padding-right: 16px;
    border-top-style: none;
    justify-content: flex-start;
  }
`;
const rightAlignedCol = css`
  td > div {
    justify-content: flex-end;
  }
`;
const width136 = css`
  width: 136px;
`;
const width100 = css`
  width: 100%;
`;
const padding16 = css`
  td {
    padding: 16px 0 16px 16px;
  }
`;
const heightAuto = css`
  height: auto;
`;
const hashtagColumStyle = css`
  border-right: 1px solid #dee5ec;

  & > div {
    word-break: break-all;
  }
`;
const topBorderNone = css`
  &:first-of-type {
    td {
      border-top-style: none;
    }
  }
`;

export default {
  clearMargin,
  width100,
  hashtagColumStyle,
  width136,
  customHeaderStyle,
  clearBorder,
  padding16,
  rightAlignedCol,
  heightAuto,
  marginRight8,
  topBorderNone
};
