import React from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import ListIndicator from '../../../molecules/ListIndicator';
import { AvatarAndNameType } from '../OverView/types';
import { AnalyticsPeriodWithId } from '../../AnalyticsShared/types';
import { InstagramComparePosts, InstagramComparePostsVariables } from './__generated__/InstagramComparePosts';
import Template from './template';
import * as IG_COMPARE_POSTS from './InstagramComparePosts.graphql';

interface AnalyticsComparePostsType extends AnalyticsPeriodWithId {
  comparableAccountIds: number[];
  accountsAvatarAndName: AvatarAndNameType[];
  visibleAccountsIds: number[];
  isMainAccountVisible: boolean;
}
const AnalyticsComparePosts = ({
  period,
  id,
  comparableAccountIds,
  accountsAvatarAndName,
  visibleAccountsIds,
  isMainAccountVisible
}: AnalyticsComparePostsType) => {
  const { data, loading } = useQuery<InstagramComparePosts, InstagramComparePostsVariables>(IG_COMPARE_POSTS, {
    variables: { accountId: id, comparableAccountIds, ...period }
  });

  if (loading) {
    return <StyledLoadingIndicator />;
  }
  const comparedPostsData = data?.instagramComparePosts;

  if (!comparedPostsData) {
    return null;
  }

  const combinedAccounts = [
    ...(isMainAccountVisible ? [comparedPostsData.mainAccount] : []),
    ...comparedPostsData.compareAccounts.filter(acc => visibleAccountsIds.includes(acc.id))
  ];
  const accountsWithAvatar = accountsAvatarAndName.map((acc, i) => ({ ...acc, ...combinedAccounts[i] }));

  return <Template accountsWithAvatar={accountsWithAvatar} id={id} />;
};

const StyledLoadingIndicator = styled(ListIndicator)`
  margin: 0;
`;

export default AnalyticsComparePosts;
