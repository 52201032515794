import React from 'react';
import { useHistory } from 'react-router';
import { AnalyticsPeriodWithId } from '../AnalyticsShared/types';
import AnalyticsOverview from './Overview';
import AudienceChart from './AudienceChart';
import AnalyticsPosts from './AnalyticsPosts';

interface AnalyticsDashboardType extends AnalyticsPeriodWithId {
  avatar: string;
}
const AnalyticsDashboard = ({ period, avatar, id }: AnalyticsDashboardType) => {
  const history = useHistory();

  return (
    <>
      <AnalyticsOverview period={period} id={+id} />
      <AudienceChart period={period} id={+id} history={history} avatar={avatar} />
      <AnalyticsPosts period={period} id={+id} history={history} />
    </>
  );
};

export default AnalyticsDashboard;
