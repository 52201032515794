export interface PalleteColorType {
  normal: string;
  hover: string;
  click: string;
  disable: string;
  hoverBorder?: string;
  textColor?: string;
  hoverFilter?: string;
  borderColor?: string;
}

export const mainRed: PalleteColorType = {
  normal: '#ff2b52',
  hover: '#ff5575',
  click: '#cc2241',
  disable: '#dee5ec'
};

export const mainWhite: PalleteColorType = {
  normal: '#fff',
  hover: '#fff',
  hoverBorder: '#c5d0da',
  click: '#f6f8fa',
  disable: '#dee5ec',
  textColor: '#6e7c89',
  borderColor: '#dee5ec'
};

export const mainOrange: PalleteColorType = {
  normal: '#ffb63d',
  hover: '#ffc463',
  click: '#cc9130',
  disable: '#dee5ec'
};

export const mainBlue: PalleteColorType = {
  normal: '#3892e5',
  hover: '#5fa7ea',
  click: '#2c74b7',
  disable: '#dee5ec'
};

export const mainPurple: PalleteColorType = {
  normal: '#966acc',
  hover: '#966acc90',
  click: '#966acc',
  disable: '#dee5ec'
};
