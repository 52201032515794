import React from 'react';
import { Redirect } from 'react-router';
import { SocialAccountType } from '../../../../__generated__/globalTypes';
import PostDetail from '../../AnalyticsDashboard/PostDetail';
import { InstagramAnalyticsPostDetails } from './__generated__/InstagramAnalyticsPostDetails';

interface TemplateProps {
  closeModal: () => void;
  data: InstagramAnalyticsPostDetails | undefined;
  accountType?: SocialAccountType;
  hasViewColumn?: boolean;
}

const Template = (props: TemplateProps) => {
  const { data, closeModal, hasViewColumn } = props;
  const accountType = props.accountType || SocialAccountType.INSTAGRAM;

  if (!data || !data.instagramAnalyticsPostDetails) {
    return <Redirect to="/404" />;
  }

  const {
    instagramAnalyticsPostDetails: {
      avatarUrl,
      instagramAccountName,
      followers,
      content,
      commentList,
      postImageUrl,
      postUrl,
      analysis,
      sentiment
    }
  } = data;

  return (
    <PostDetail
      avatarUrl={avatarUrl}
      accountType={accountType}
      closeModal={closeModal}
      accountName={instagramAccountName}
      followers={followers || 0}
      content={content}
      commentList={commentList || []}
      postImageUrl={postImageUrl}
      engagement={{
        postUrl,
        analysis,
        sentiment,
        hasViewColumn,
        storyData: null
      }}
    />
  );
};

export default Template;
