import React from 'react';
import { LocationDescriptor } from 'history';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export interface WithAnchorProps {
  href?: LocationDescriptor;
}

const WithAnchor: React.FC<WithAnchorProps> = props => {
  const { href, children } = props;

  return href ? <Anchor to={href}>{children}</Anchor> : <>{children}</>;
};

const Anchor = styled(Link)`
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export default WithAnchor;
