import { css, SerializedStyles } from '@emotion/core';

const positionStickyCss = css`
  position: sticky;
  top: 0;
  background-color: #fff;
  box-shadow: 0 2px 0 0 #e6ecf0;
`;
const mainHeaderItem = css`
  ${positionStickyCss}
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  width: 190px;
`;
const firstCellCss = css`
  font-size: 14px;
  font-weight: normal;
  padding-left: 16px;
  color: inherit;
  text-align: left;
`;
const subTitleRowCss = css`
  position: sticky;
  height: 50px;
  background-color: #f6f8fa;
  border-top: 1px solid #e6ecf0;
`;
const subTitleCellCss = css`
  ${firstCellCss}
  font-size: 11px;
  font-weight: 600;
`;
const tableRowCss = css`
  height: 50px;
  border-top: 1px solid #e6ecf0;
`;
const greyColor = css`
  color: #c5d0da;
`;

interface MainHeadingType {
  id: number;
  title: string;
  css: SerializedStyles;
}
interface RowItemType {
  id: number;
  content: string;
  css?: SerializedStyles | SerializedStyles[];
  colSpan?: number;
}
interface BodyItemsType {
  id: number;
  css: SerializedStyles;
  rowItems: RowItemType[];
}
interface KeyFeaturesDataType {
  mainHeading: MainHeadingType[];
  bodyItems: BodyItemsType[];
}
export const keyFeaturesData: KeyFeaturesDataType = {
  mainHeading: [
    {
      id: 1,
      title: 'Key Features',
      css: css`
        ${positionStickyCss}
        padding-left: 25px;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.22;
      `
    },
    {
      id: 2,
      title: 'Free',
      css: mainHeaderItem
    },
    { id: 3, title: 'Trial', css: mainHeaderItem },
    { id: 4, title: 'Basic', css: mainHeaderItem },
    { id: 5, title: 'Enterprise', css: mainHeaderItem }
  ],
  bodyItems: [
    {
      id: 1,
      css: subTitleRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Features included in Dashboard',
          css: subTitleCellCss,
          colSpan: 5
        }
      ]
    },
    // rows with marks
    {
      id: 2,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Daily Engagement Growth Analysis',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u2713'
        },
        {
          id: 3,
          content: '\u2713'
        },
        {
          id: 4,
          content: '\u2713'
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    {
      id: 3,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Follower Attribute Analysis',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u2713'
        },
        {
          id: 3,
          content: '\u2713'
        },
        {
          id: 4,
          content: '\u2713'
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    {
      id: 4,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Post Engagement Analysis',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u2713'
        },
        {
          id: 3,
          content: '\u2713'
        },
        {
          id: 4,
          content: '\u2713'
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    {
      id: 5,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Comment Sentiment Analysis',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u2713'
        },
        {
          id: 3,
          content: '\u2713'
        },
        {
          id: 4,
          content: '\u2713'
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    {
      id: 6,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Posting habits analysis',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u2713'
        },
        {
          id: 3,
          content: '\u2713'
        },
        {
          id: 4,
          content: '\u2713'
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    {
      id: 7,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Post list',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u2713'
        },
        {
          id: 3,
          content: '\u2713'
        },
        {
          id: 4,
          content: '\u2713'
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    // sub-title row
    {
      id: 8,
      css: subTitleRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Features included in Compare',
          css: subTitleCellCss,
          colSpan: 5
        }
      ]
    },
    // rows with marks
    {
      id: 9,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Daily Engagement Comparison with other accounts',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 3,
          content: '\u2713'
        },
        {
          id: 4,
          content: '\u2713'
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    {
      id: 10,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Comparative analysis of follower attributes with other accounts',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 3,
          content: '\u2713'
        },
        {
          id: 4,
          content: '\u2713'
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    {
      id: 11,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Comparative analysis of post engagement with other accounts',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 3,
          content: '\u2713'
        },
        {
          id: 4,
          content: '\u2713'
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    {
      id: 12,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Comment sentiment comparison analysis with other accounts',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 3,
          content: '\u2713'
        },
        {
          id: 4,
          content: '\u2713'
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    {
      id: 13,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Posting habits comparison with other accounts',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 3,
          content: '\u2713'
        },
        {
          id: 4,
          content: '\u2713'
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    {
      id: 14,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: `List of each other account's posts`,
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 3,
          content: '\u2713'
        },
        {
          id: 4,
          content: '\u2713'
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    // sub-title row
    {
      id: 15,
      css: subTitleRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Features included in Hashtag',
          css: subTitleCellCss,
          colSpan: 5
        }
      ]
    },
    // rows with marks
    {
      id: 16,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Daily Engagement Growth Analysis of Hashtags',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 3,
          content: '\u2713'
        },
        {
          id: 4,
          content: '\u2713'
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    {
      id: 17,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'List of posts with the hashtag',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 3,
          content: '\u2713'
        },
        {
          id: 4,
          content: '\u2713'
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    // sub-title row
    {
      id: 18,
      css: subTitleRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Features included in Interaction',
          css: subTitleCellCss,
          colSpan: 5
        }
      ]
    },
    // rows with marks
    {
      id: 19,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Daily Engagement Growth for posts containing tags and mentions',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 3,
          content: '\u2713'
        },
        {
          id: 4,
          content: '\u2713'
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    {
      id: 20,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'A list of the most frequent users who post with tags and mentions',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 3,
          content: '\u2713'
        },
        {
          id: 4,
          content: '\u2713'
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    {
      id: 21,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'List of posts containing tags and mentions',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 3,
          content: '\u2713'
        },
        {
          id: 4,
          content: '\u2713'
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    // sub-title row
    {
      id: 22,
      css: subTitleRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Support included in Enterprise',
          css: subTitleCellCss,
          colSpan: 5
        }
      ]
    },
    // rows with marks
    {
      id: 23,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Post caption and hashtag creation',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 3,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 4,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    {
      id: 24,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Creating and Editing Post Images (image planning/shooting/editing costs)',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 3,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 4,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    {
      id: 25,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Photo selection and application for permission to use',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 3,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 4,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    {
      id: 26,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Post operation',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 3,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 4,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    },
    {
      id: 27,
      css: tableRowCss,
      rowItems: [
        {
          id: 1,
          content: 'Create a report',
          css: firstCellCss
        },
        {
          id: 2,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 3,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 4,
          content: '\u00D7',
          css: greyColor
        },
        {
          id: 5,
          content: '\u2713'
        }
      ]
    }
  ]
};
