type ParamsType<T extends {}> = { [K in keyof T]: T[K] | undefined };

export const getParamsFromSearch = <T extends {}>(search: string): ParamsType<T> => {
  const params = new URLSearchParams(search);

  const result = Array.from(params.entries()).reduce((previous, current) => {
    const [key, value] = current;

    return { ...previous, [key]: value };
  }, {} as ParamsType<T>);

  return result;
};
