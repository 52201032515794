import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import logoBlack from '../../assets/icon/anytag_black.svg';
import instagramIcon from '../../assets/icon/instagram_gradient.svg';
import hashtagIcon from '../../assets/icon/hashtag_subscription.svg';
// import { PalleteButton } from '../../components/atoms/Button';
import { useQueryHelper } from '../../libs/hooks';
import closeIcon from '../../assets/icon/close.svg';
import { keyFeaturesData } from './keyFeaturesTableData';
// import { mainBlue, mainPurple } from '../../libs/pallete';
// import { AnalyticsSubscriptionPlanType } from '../../__generated__/globalTypes';
import { subscriptionsPlansCards } from './planCards';

const SubscriptionPlansPage = () => {
  const { t, history } = useQueryHelper();

  return (
    <Wrapper>
      <CloseIcon src={closeIcon} alt="close " onClick={() => history.goBack()} />
      <Heading>
        <Logo
          src={logoBlack}
          alt="Casting Asia logo black"
          onClick={() => {
            history.goBack();
          }}
        />
        <span>{t('Analytics Dashboard Choose a plan')}</span>
      </Heading>
      <SubscriptionCardsWrapper>
        {subscriptionsPlansCards.map(card => (
          // TODO:
          // const isSelectedPlan = card.planType === AnalyticsSubscriptionPlanType.FREE;
          // const isEnterprisePlan = card.planType === AnalyticsSubscriptionPlanType.ENTERPRISE;

          <CardWrapper key={card.id}>
            <div
              css={css`
                height: 5px;
                border-radius: 16px 16px 0 0;
                background-color: ${card.headerColor};
              `}
            />
            <Card>
              <CardContent>
                <PlanHeader>{t(card.planHeader)}</PlanHeader>
                <PlanDescription>{t(card.planDescription)}</PlanDescription>
                <PriceSection>
                  {/* TODO: currency & amount should be based on users country */}
                  {card.currency ? <Currency>{card.currency}</Currency> : null}
                  {/* using defaultValue here to prevent long key name for showing while loading */}
                  <Amount>{t(card.amount, '')}</Amount>
                  {card.thousand ? <ThousandMark>{card.thousand}</ThousandMark> : null}
                  {card.month ? <Month>/{t(card.month)}</Month> : null}
                </PriceSection>
                <CheckList>
                  {card.checkList.map(item => {
                    const mark = item.checked ? '\u2713' : '\u00D7';

                    return (
                      <ListItem key={item.id}>
                        <CheckMark checked={item.checked}>{mark}</CheckMark>
                        <Text checked={item.checked}>{t(item.title)}</Text>
                      </ListItem>
                    );
                  })}
                </CheckList>
                {/* extras */}
                {card.extraFeatures?.comparedAccounts ? (
                  <ComparedAccounts>
                    <Icon src={instagramIcon} alt="instagram icon" />
                    <IgAmount>{card.extraFeatures?.comparedAccounts}</IgAmount>
                    <IgComparedText>{t('compared accounts')}</IgComparedText>
                  </ComparedAccounts>
                ) : null}
                {card.extraFeatures?.hashtags ? (
                  <Hashtags>
                    <Icon src={hashtagIcon} alt="hashtag icon" />
                    <HashtagsAmount>{card.extraFeatures?.hashtags}</HashtagsAmount>
                    <HashtagText>{t('hashtags')}</HashtagText>
                  </Hashtags>
                ) : null}
              </CardContent>
              {/* TODO: we don't need for now */}
              {/* <PalleteButton
                  text={isSelectedPlan ? 'Your Plan' : card.selectButton}
                  palleteColor={isEnterprisePlan ? mainPurple : mainBlue}
                  onClick={() => {}}
                  height="40px"
                  width="100%"
                  borderRadius="3px"
                  isDisabled={isSelectedPlan}
                /> */}
            </Card>
          </CardWrapper>
        ))}
      </SubscriptionCardsWrapper>
      {/* Key features table */}
      <TableWrapper>
        <Table>
          <thead>
            <MainHeaderTableRow>
              {keyFeaturesData.mainHeading.map(header => (
                <th key={header.id} css={header.css}>
                  {t(header.title)}
                </th>
              ))}
            </MainHeaderTableRow>
          </thead>
          <tbody>
            {keyFeaturesData.bodyItems.map(row => (
              <tr key={row.id} css={row.css}>
                {row.rowItems.map(rowItem => (
                  <TableCell key={rowItem.id} css={rowItem.css} colSpan={rowItem.colSpan}>
                    {t(rowItem.content)}
                  </TableCell>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 32px 120px;
  color: #27313b;
  background-color: #fff;
`;
const CloseIcon = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const Heading = styled.div`
  display: flex;
  place-items: center;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;

  span {
    padding-left: 24px;
  }
`;
const Logo = styled.img`
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;
const SubscriptionCardsWrapper = styled.section`
  display: flex;
  padding-top: 32px;
  max-width: 1300px;
`;
const CardWrapper = styled.div`
  flex: 1;

  &:not(:first-of-type) {
    margin-left: 24px;
  }
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  min-width: 240px;

  /* with select plan button -> height: 424px */
  height: 360px;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px #dee5ec80;
  background-color: #fff;
  box-sizing: border-box;
`;
const CardContent = styled.div`
  padding: 8px;
`;
const PlanHeader = styled.h3`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
`;
const PlanDescription = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
`;
const PriceSection = styled.p`
  display: flex;
  align-items: flex-end;
  margin-top: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
`;
const Currency = styled.span`
  padding-right: 4px;
  font-size: 24px;
  height: 27px;
`;
const Amount = styled.span`
  padding-right: 3px;
  font-size: 32px;
  font-weight: bold;
  height: 35px;
`;
const ThousandMark = styled.span`
  padding-right: 4px;
  font-size: 14px;
  font-weight: normal;
  height: 20px;
`;
const Month = styled.span`
  font-size: 14px;
  font-weight: bold;
  height: 19px;
`;
const CheckList = styled.ul`
  margin-top: 26px;
`;
const ListItem = styled.li`
  display: flex;
  align-items: baseline;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
`;
const CheckMark = styled.span<{ checked: boolean }>`
  width: 22px;
  font-size: 20px;
  ${({ checked }) => (checked ? 'color: #ff2b52;' : 'color: #c5d0da; font-weight: 600;')}
`;
const Text = styled.span<{ checked: boolean }>`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${({ checked }) => (checked ? 'inherit' : '#c5d0da')};
`;
const ComparedAccounts = styled.div`
  display: flex;
  margin-top: 31px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #27313b;
`;
const Hashtags = styled(ComparedAccounts)`
  margin-top: 6px;
`;
const Icon = styled.img`
  width: 18px;
  height: 18px;
`;
const IgAmount = styled.span`
  margin-left: 6px;
  font-weight: 600;
`;
const HashtagsAmount = styled(IgAmount)`
  margin-left: 6px;
`;
const IgComparedText = styled.span`
  margin-left: 4px;
`;
const HashtagText = styled(IgComparedText)``;
const TableWrapper = styled.section`
  height: 620px;
  overflow-x: hidden;
  overflow-y: scroll;
  max-width: 1300px;
  margin-top: 40px;
  border-radius: 3px;
  border: solid 1px #dee5ec;
`;
const Table = styled.table`
  width: 100%;
  color: #27313b;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
`;
const TableCell = styled.td`
  font-size: 20px;
  font-weight: 600;
  color: #ff2b52;
  border-right: 1px solid #e6ecf0;
  text-align: center;
`;
const MainHeaderTableRow = styled.tr`
  height: 49px;
`;

export default SubscriptionPlansPage;
