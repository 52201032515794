import { AnalyticsSubscriptionPlanType } from '../../__generated__/globalTypes';

export const subscriptionsPlansCards = [
  {
    id: 1,
    planHeader: 'Free',
    planDescription: 'For individuals',
    currency: '¥',
    amount: 'FREE_SUBSCRIPTION_PRICE',
    thousand: '',
    month: 'month',
    checkList: [
      {
        id: 1,
        checked: true,
        title: 'Dashboard'
      },
      {
        id: 2,
        checked: false,
        title: 'Compare'
      },
      {
        id: 3,
        checked: false,
        title: 'HashTags'
      },
      {
        id: 4,
        checked: false,
        title: 'Interaction'
      }
    ],
    planType: AnalyticsSubscriptionPlanType.FREE,
    selectButton: 'Select Plan',
    headerColor: '#c5d0da'
  },
  {
    id: 2,
    planHeader: 'Trial',
    planDescription: '14 days Free!',
    currency: '¥',
    amount: 'TRIAL_SUBSCRIPTION_PRICE',
    thousand: '',
    month: 'month',
    checkList: [
      {
        id: 1,
        checked: true,
        title: 'Dashboard'
      },
      {
        id: 2,
        checked: true,
        title: 'Compare'
      },
      {
        id: 3,
        checked: true,
        title: 'HashTags'
      },
      {
        id: 4,
        checked: true,
        title: 'Interaction'
      }
    ],
    // TODO: we don't have trial plan now
    planType: 'TRIAL',
    selectButton: 'Select Plan',
    headerColor: '#ffb63d',
    extraFeatures: { comparedAccounts: '15', hashtags: '15' }
  },
  {
    id: 3,
    planHeader: 'Basic',
    planDescription: 'All Pages Available',
    currency: '¥',
    amount: 'BASIC_SUBSCRIPTION_PRICE',
    thousand: '万',
    month: 'month',
    checkList: [
      {
        id: 1,
        checked: true,
        title: 'Dashboard'
      },
      {
        id: 2,
        checked: true,
        title: 'Compare'
      },
      {
        id: 3,
        checked: true,
        title: 'HashTags'
      },
      {
        id: 4,
        checked: true,
        title: 'Interaction'
      }
    ],
    // TODO: should work for Basic+ and Basic ++ too?
    planType: AnalyticsSubscriptionPlanType.BASIC,
    selectButton: 'Select Plan',
    headerColor: '#ff5f5f',
    extraFeatures: { comparedAccounts: '5~', hashtags: '5~' }
  },
  {
    id: 4,
    planHeader: 'Enterprise',
    planDescription: 'Including Posts Management',
    currency: '',
    amount: 'ASK',
    thousand: '',
    month: '',
    checkList: [
      {
        id: 1,
        checked: true,
        title: 'Dashboard'
      },
      {
        id: 2,
        checked: true,
        title: 'Compare'
      },
      {
        id: 3,
        checked: true,
        title: 'HashTags'
      },
      {
        id: 4,
        checked: true,
        title: 'Interaction'
      }
    ],
    // TODO: should work for Enterprise+ and Basic ++ too?
    planType: AnalyticsSubscriptionPlanType.ENTERPRISE,
    selectButton: 'Contact',
    headerColor: '#966acc',
    extraFeatures: { comparedAccounts: '5~', hashtags: '5~' }
  }
];
