import React from 'react';
import { useQuery } from '@apollo/client';
import { getOffset, getPageInfo } from '../../../../libs/paging';
import { SortActionType } from '../../../../libs/filter';
import { InstagramFeedPostSortField } from '../../../../__generated__/globalTypes';
import { useQueryHelper } from '../../../../libs/hooks';
import { LIMIT } from '../../../../libs/paging/constant';
import { TaggedPostFilterType } from '../../../../pages/TaggedPosts/types';
import ListIndicator from '../../../molecules/ListIndicator';
import { AllInstagramTaggedPosts, AllInstagramTaggedPostsVariables } from './__generated__/AllInstagramTaggedPosts';
import * as ALL_INSTAGRAM_TAGGED_POSTS from './AllInstagramTaggedPosts.graphql';
import TaggedPostList from './TaggedPostList';

export interface TaggedPostListProps extends SortActionType<InstagramFeedPostSortField> {
  currentPage: number;
  id: number;
  filter: TaggedPostFilterType;
}

const TaggedPostListComponent = (props: TaggedPostListProps) => {
  const {
    currentPage,
    sort,
    setSort,
    id,
    filter: { keyword, period }
  } = props;
  const { t, history, enqueueSnackbar } = useQueryHelper();
  const { data, loading, error } = useQuery<AllInstagramTaggedPosts, AllInstagramTaggedPostsVariables>(
    ALL_INSTAGRAM_TAGGED_POSTS,
    {
      variables: {
        accountId: id,
        offset: getOffset(currentPage),
        limit: LIMIT,
        keyword,
        orderBy: sort,
        startDate: period.startDate,
        endDate: period.endDate
      },
      fetchPolicy: 'cache-and-network',
      skip: !id
    }
  );

  if (loading) {
    return <ListIndicator />;
  }

  if (error) {
    history.push(`/analytics/dashboard/${id}/interaction`);
    enqueueSnackbar(t(error.message), { variant: 'error' });
  }

  const pageInfo = getPageInfo(currentPage, data);
  const taggedPosts = data?.allInstagramTaggedPosts || [];

  return (
    <TaggedPostList sort={sort} setSort={setSort} pageInfo={pageInfo} taggedPosts={taggedPosts} type="taggedPost" />
  );
};

export default TaggedPostListComponent;
