import styled from '@emotion/styled';
import * as React from 'react';

export interface InputPasswordProps extends InputProps {
  placeholder: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: any) => void;
  value?: any;
  name?: string;
  type?: 'text' | 'password';
  isDisabled?: boolean;
}

const InputPassword = (props: InputPasswordProps) => {
  const { placeholder, error, isDisabled, type = 'password', ...field } = props;

  return <Input type={type} placeholder={placeholder} error={error} disabled={isDisabled} {...field} />;
};

interface InputProps {
  error?: boolean;
  disabled?: boolean;
}

const Input = styled.input<InputProps>`
  box-sizing: border-box;
  width: 100%;
  padding: 0 12px;
  background-color: ${props => (props.disabled ? '#f6f8fa' : '#fff')};
  border: 1px solid transparent;
  border-color: ${props => (props.error ? 'tomato' : '#e0e8ed')};
  border-radius: 2px;
  font-size: 13px;
  line-height: 38px;

  &:focus {
    border-color: #179cd7;
  }
`;

export default InputPassword;
