import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Locize from 'i18next-locize-backend';
import { languageDetector } from './languageDetector';

declare const LOCIZE_API_KEY: string;
declare const LOCIZE_PROJECT_ID: string;

const locizeOptions = {
  apiKey: LOCIZE_API_KEY,
  projectId: LOCIZE_PROJECT_ID,
  allowedAddOrUpdateHosts: ['localhost']
};

i18next
  .use(Locize)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    initImmediate: false,
    fallbackLng: 'en',
    // due to some unwanted keys appears on locize DB -> all new keys must be added by hand
    saveMissing: false,
    defaultNS: 'admin',
    ns: ['admin'],
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    backend: locizeOptions,
    react: { wait: true, useSuspense: false }
  });

export const LANGUAGE_OPTIONS = [
  { value: 'en', label: 'English' },
  { value: 'ja', label: '日本語' }
];

export default i18next;
