import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import Label from '../../../atoms/Label';
import SearchForm from '../../../molecules/SearchForm';
import { DayPickerRange, SELECTED_RANGE } from '../../../molecules/DayPicker';
import { BaseComponents, BaseCss } from '../../../molecules/BaseFilterComponents';
import { FormValues } from './types';

interface BasePostListFilterProps {
  wrapWithContainer?: boolean;
}

const BasePostListFilter = ({ wrapWithContainer = true }: BasePostListFilterProps) => {
  const { t } = useTranslation();

  const { handleSubmit, handleBlur, handleChange, setFieldValue } = useFormikContext<FormValues>();

  const [keywordField] = useField<FormValues['keyword']>('keyword');
  const [periodField, periodMeta] = useField<FormValues['period']>('period');

  const rangeList = [
    SELECTED_RANGE.TODAY,
    SELECTED_RANGE.LAST_SEVEN_DAYS,
    SELECTED_RANGE.TODAY_TO_MONTH_AGO,
    SELECTED_RANGE.THIS_MONTH,
    SELECTED_RANGE.LAST_MONTH,
    SELECTED_RANGE.CUSTOM
  ];

  const handleBlurAndSubmit = (e: any) => {
    handleBlur(e);
    handleSubmit(e);
  };

  const handleChangePeriod = (value: any) => {
    setFieldValue('period', value);
  };

  const handleChangeAndSubmitPeriod = async (e: any) => {
    handleChangePeriod(e);
    await Promise.resolve();
    handleSubmit(e);
  };

  const mainContent = (
    <BaseComponents.Wrapper css={[BaseCss.noMarginBottom]}>
      <Keyword
        title="Keyword"
        placeholder="keywords"
        value={keywordField.value}
        name="keyword"
        onBlur={handleBlurAndSubmit}
        onChange={handleChange}
        onEnterKeyPress={handleSubmit}
        css={[BaseCss.flex, BaseCss.marginRight]}
      />

      <DayPickerRangeWrapper>
        <Label title={t(`Selector.Period`)} />
        <DayPickerRange
          period={periodField.value}
          handleChangePeriod={handleChangeAndSubmitPeriod}
          rangeList={rangeList}
          error={!!periodMeta.error}
        />
      </DayPickerRangeWrapper>
    </BaseComponents.Wrapper>
  );

  return (
    <form onSubmit={handleSubmit}>
      {wrapWithContainer ? (
        <BaseComponents.Container css={[paddingBottom15]}>{mainContent}</BaseComponents.Container>
      ) : (
        mainContent
      )}
    </form>
  );
};

const paddingBottom15 = css`
  padding-bottom: 15px;
`;

const Keyword = styled(SearchForm)`
  flex: 1;
  margin-right: 12px;
`;

const DayPickerRangeWrapper = styled.div``;

export default BasePostListFilter;
