import styled from '@emotion/styled';
import { FieldArray } from 'formik';
import * as React from 'react';
import CheckBox from './CheckBox';

export interface CheckBoxForFormProps extends DisabledProps {
  name: string;
  value: string[];
  items: Array<{
    value: string;
    label: string;
  }>;
  onSubmit?: (e?: any) => void;
  fontSizeCheckbox?: string;
  canOnlyAddMore?: boolean;
  initialValues?: string[];
}

const CheckBoxForForm = (props: CheckBoxForFormProps) => {
  const {
    name,
    value: checkedValues,
    items,
    disabled,
    onSubmit,
    fontSizeCheckbox = '14px',
    canOnlyAddMore,
    initialValues
  } = props;

  return (
    <Wrapper>
      <FieldArray name={name}>
        {({ push, remove }) =>
          items.map(item => {
            const { value, label } = item;
            const checked = checkedValues.includes(value);

            const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked) {
                push(value);
              } else {
                const idx = checkedValues.indexOf(value);
                const isInitialValue = initialValues?.includes(value);
                if (canOnlyAddMore && isInitialValue) {
                  return;
                }
                remove(idx);
              }

              if (!!onSubmit) {
                await Promise.resolve();
                onSubmit(e);
              }

              return;
            };

            return (
              <StyledCheckBox
                key={label}
                name={name}
                onChange={handleChange}
                label={label}
                checked={checked}
                disabled={disabled}
                size={fontSizeCheckbox}
              />
            );
          })
        }
      </FieldArray>
    </Wrapper>
  );
};

interface DisabledProps {
  disabled?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 6px;
`;

const StyledCheckBox = styled(CheckBox)`
  line-height: 20px;
  margin-right: 16px;
`;

export default CheckBoxForForm;
