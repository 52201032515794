import React from 'react';
import { useQuery } from '@apollo/client';
import { useQueryHelper } from '../../../../libs/hooks';
import ListIndicator from '../../../molecules/ListIndicator';
import { AnalyticsPeriodWithId } from '../../AnalyticsShared/types';
import { InstagramHashtagPosts, InstagramHashtagPostsVariables } from './__generated__/InstagramHashtagPosts';
import * as INSTAGRAM_HASHTAG_POSTS from './InstagramHashtagPosts.graphql';
import Template from './template';

interface AnalyticsHashtagPostsProps extends AnalyticsPeriodWithId {
  allHashtag: Record<number, string>;
  selectedHashtagIds: number[];
}

const AnalyticsHashtagPosts = ({ id, period, allHashtag, selectedHashtagIds }: AnalyticsHashtagPostsProps) => {
  const { t, enqueueSnackbar, history } = useQueryHelper();

  const { data, loading, error } = useQuery<InstagramHashtagPosts, InstagramHashtagPostsVariables>(
    INSTAGRAM_HASHTAG_POSTS,
    {
      variables: {
        analyticsAccountId: id,
        startDate: period.startDate,
        endDate: period.endDate
      },
      skip: !id
    }
  );

  if (loading) {
    return <ListIndicator />;
  }

  if (!data?.instagramHashtagPosts || error) {
    if (error) {
      enqueueSnackbar(t('Can not get analytics for current account'), { variant: 'error' });
    }
    history.push('/analytics');

    return null;
  }

  const hashtagData = data.instagramHashtagPosts.hashtags
    .filter(ht => selectedHashtagIds.includes(ht.id))
    .map(h => ({
      ...h,
      hashtag: allHashtag[h.id]
    }));

  return <Template id={id} data={hashtagData} />;
};

export default AnalyticsHashtagPosts;
