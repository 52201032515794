import css from '@emotion/css';

const maxContent = css`
  div {
    width: max-content;
  }
`;
const paddingRight = css`
  padding-right: 16px !important;
`;
const overflowHidden = css`
  div {
    overflow: hidden;
    max-height: 32px;
  }
`;
const twoLineHeader = css`
  white-space: pre-line;
  line-height: 1.4em;
  text-align: left;

  span {
    align-self: center;
  }
`;
const btnWidth = css`
  min-width: 133px;
`;
const btnHeight = css`
  height: 32px;
`;
const marginTop = css`
  margin: 0 23px 0;
`;
const bgndColor = css`
  background-color: #f6f8fa;
`;
const clearPadding = css`
  padding: 0 !important;
`;
const paddingLeft = css`
  padding-left: 16px !important;
`;
const paddingSides = css`
  td:first-of-type {
    padding-left: 16px;
  }

  td:last-of-type {
    padding-right: 16px;
  }
`;
const rowHeight62 = css`
  height: 62px;
`;
const rowHeight52 = css`
  height: 52px;
`;
const rowHeight56 = css`
  height: 56px;
`;
const fixedRowHeight61 = css`
  height: 61px;
`;
const fixedRowHeight51 = css`
  height: 51px;
`;
const noWordBreak = css`
  word-break: keep-all;
`;
const preLine = css`
  span {
    white-space: pre-line;
    line-height: 16px;
  }
`;
const paddingLeft8 = css`
  padding-left: 8px;
`;
const paddingRight8 = css`
  padding-right: 8px;
`;
const marginRight8 = css`
  margin-right: 8px;
`;
const flexStart = css`
  div {
    justify-content: flex-start;
  }
`;
const spreadButtonsStyles = css`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;
const rowHeight40 = css`
  height: 40px;
`;
const lastChildPaddingRight = css`
  th:last-of-type {
    padding-right: 16px;
  }
`;
const width270 = css`
  width: 270px;
`;
const boderTop = css`
  border-top: 1px solid #dee5ec;
`;
const boderBottom = css`
  border-bottom: 1px solid #eef3f7;
`;
const displayFlexColumn = css`
  display: flex;
  flex-direction: column;
`;
const width245 = css`
  width: 245px;
`;
const getPaddingLeft = (left: string) => css`
  padding-left: ${left};
`;
export const setWidth = (width: string) => css`
  width: ${width};
`;
export const setMinWidth = (minWidth: string) => css`
  min-width: ${minWidth};
`;

export default {
  maxContent,
  paddingRight,
  paddingLeft,
  paddingSides,
  rowHeight62,
  fixedRowHeight61,
  clearPadding,
  bgndColor,
  marginTop,
  btnHeight,
  btnWidth,
  twoLineHeader,
  overflowHidden,
  noWordBreak,
  preLine,
  fixedRowHeight51,
  rowHeight52,
  paddingLeft8,
  paddingRight8,
  marginRight8,
  flexStart,
  spreadButtonsStyles,
  rowHeight40,
  lastChildPaddingRight,
  width270,
  boderTop,
  displayFlexColumn,
  boderBottom,
  rowHeight56,
  width245,
  getPaddingLeft,
  setWidth,
  setMinWidth
};
