import styled from '@emotion/styled';
import * as React from 'react';
import { APP_NAME } from '../../../libs/constant';
import TermsText from '../../atoms/TermsText';
import TermsTitle from '../../atoms/TermsTitle';

const PrivacyContent = () => (
  <Wrapper>
    <TermsTitle title={`Privacy Policy for Using the ${APP_NAME} Service`} />

    <TermsSubTitle>Last Updated: 18 January 2018</TermsSubTitle>

    <TermsText>
      {`${APP_NAME} Pte. Ltd. ("us", "we", or "our") operates "${APP_NAME}"`}&nbsp;
      <Anchor href="https://anytag.tech/" target="_blank">
        https://anytag.tech/
      </Anchor>
      &nbsp;
      {`(the "${APP_NAME} Service").This page informs you of our policies regarding the collection, use and
      disclosure of Personal Information when you use ${APP_NAME} Service.We will not use or share your information with
      anyone except as described in this Privacy Policy.`}
    </TermsText>

    <TermsText>
      {`We use your Personal Information for providing and improving ${APP_NAME} Service. By using ${APP_NAME} Service,
      you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in
      this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions,
      accessible at`}
      &nbsp;
      <Anchor href="https://anytag.tech/" target="_blank">
        {`${APP_NAME} Service.`}
      </Anchor>
    </TermsText>

    <TermsText heading="Information Collection And Use">
      {`While using ${APP_NAME} Service, we may ask you to provide us with certain personally identifiable information
      that can be used to contact or identify you. Personal identifiable information may include, but is not limited to,
      your email address, other information ("Personal Information").`}
    </TermsText>

    <TermsText heading="Log Data">
      {`We may also collect information that your browser sends whenever you visit ${APP_NAME} Service ("Log Data"). This
      Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser
      version, the pages of ${APP_NAME} Service that you visit, the time and date of your visit, the time spent on those
      pages and other statistics.`}
    </TermsText>

    <BreakTermsText heading="Cookies">
      Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to
      your browser from a web site and stored on your computer's hard drive.
    </BreakTermsText>
    <TermsText>
      {`We use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a
      cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of
      ${APP_NAME} Service.`}
    </TermsText>

    <BreakTermsText heading="Service Providers">
      {`We may employ third party companies and individuals to facilitate ${APP_NAME} Service, to provide ${APP_NAME}
      Service on our behalf, to perform Service-related services or to assist us in analyzing how our ${APP_NAME}
      Service is used.`}
    </BreakTermsText>
    <TermsText>
      These third parties have access to your Personal Information only to perform these tasks on our behalf and are
      obligated not to disclose or use it for any other purpose.
    </TermsText>

    <TermsText heading="Security">
      The security of your Personal Information is important to us, but remember that no method of transmission over the
      Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to
      protect your Personal Information, we cannot guarantee its absolute security.
    </TermsText>

    <BreakTermsText heading="International Transfer">
      Your information, including Personal Information, may be transferred to — and maintained on — computers located
      outside of your state, province, country or other governmental jurisdiction where the data protection laws may
      differ than those from your jurisdiction.
    </BreakTermsText>
    <BreakTermsText>
      If you are located outside Singapore and choose to provide information to us, please note that we transfer the
      information, including Personal Information, to Singapore and process it there.
    </BreakTermsText>
    <TermsText>
      Your consent to this Privacy Policy followed by your submission of such information represents your agreement to
      that transfer.
    </TermsText>

    <TermsText heading="Links To Other Sites">
      {`${APP_NAME} Service may contain links to other sites that are not operated by us. If you click on a third party
      link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of
      every site you visit.`}
    </TermsText>

    <TermsText heading="Changes To This Privacy Policy">
      We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy
      Policy on this page.You are advised to review this Privacy Policy periodically for any changes. Changes to this
      Privacy Policy are effective when they are posted on this page.
    </TermsText>

    <TermsText heading="Contact Us">
      If you have any questions about this Privacy Policy, please contact us at 77 Robinson Road, #34-00, Singapore
      068896 or via email at&nbsp;
      <Anchor href="https://anytag.tech/" target="_blank">
        info@anytag.com
      </Anchor>
      .
    </TermsText>
  </Wrapper>
);

const Wrapper = styled.section`
  width: 856px;
  padding: 48px 48px 8px;
  background-color: #fff;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
`;

const Anchor = styled.a`
  display: inline;
  color: #179cd7;

  &:hover {
    text-decoration: underline;
  }
`;

const BreakTermsText = styled(TermsText)`
  margin-bottom: 16px;
`;

const TermsSubTitle = styled.h4`
  font-size: 13px;
  font-weight: normal;
  color: #6e7c89;
  padding: 40px 0;
  text-align: center;
`;

export default PrivacyContent;
