import styled from '@emotion/styled';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface MenuLinkProps {
  href: LocationDescriptor;
  title: string;
}

const MenuLink = (props: MenuLinkProps) => {
  const { href, title } = props;

  return <LinkStyle to={href}>{title}</LinkStyle>;
};

const LinkStyle = styled(Link)`
  padding: 0 14px;
  border-right: 1px solid #8694a2;
  color: #6e7c89;
  font-size: 12px;
  line-height: 16px;
`;

export default MenuLink;
