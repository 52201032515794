import styled from '@emotion/styled';
import * as React from 'react';
import { MenuTitle } from '../../../libs/constant';
import Footer from '../Footer';
import Header from '../Header';

export interface LayoutProps {
  menuTitle?: MenuTitle;
}

const Layout: React.FC<LayoutProps> = props => {
  const { children, menuTitle } = props;

  return (
    <Container>
      <Wrapper>
        <Header menuTitle={menuTitle} />
        {children}
        <Footer />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  min-height: 100%;
  background-color: #f6f8fa;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 192px);
`;

export default Layout;
