import React from 'react';
import styled from '@emotion/styled';
import css from '@emotion/css';
import { useQuery } from '@apollo/client';
import AnalyticsContentWrapper, { ContentType, TitleWithHelp } from '../AnalyticsShared/AnalyticsContentWrapper';
import BasePosts from '../AnalyticsDashboard/LikeCommentPosts';
import * as Styled from '../AnalyticsDashboard/StyledComponents';
import { AnalyticsPeriodWithId } from '../AnalyticsShared/types';
import ListIndicator from '../../molecules/ListIndicator';
import { useQueryHelper } from '../../../libs/hooks';
import UserAnalysis from './UserAnalysis';
import * as IG_INTERACTION_POSTS from './InstagramInteractionPosts.graphql';
import {
  InstagramInteractionPosts,
  InstagramInteractionPostsVariables
} from './__generated__/InstagramInteractionPosts';

const InteractionPosts = ({ period, id }: AnalyticsPeriodWithId) => {
  const { t, history, enqueueSnackbar } = useQueryHelper();
  const { data, loading, error } = useQuery<InstagramInteractionPosts, InstagramInteractionPostsVariables>(
    IG_INTERACTION_POSTS,
    {
      // we need only first 5 items
      variables: {
        analyticsAccountId: id,
        startDate: period.startDate,
        endDate: period.endDate,
        offset: 0,
        limit: 5,
        orderBy: { field: null, order: null },
        keyword: ''
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  if (loading) {
    return <ListIndicator />;
  }

  if (!data?.instagramInteractionPosts || !data.allInstagramInteractionUsers || error) {
    if (error) {
      enqueueSnackbar(t('Can not get analytics for current account'), { variant: 'error' });
    }
    history.push('/analytics');

    return null;
  }

  const { taggedPosts, mentionedPosts } = data.instagramInteractionPosts;

  return (
    <Wrapper contentType={ContentType.Posts} tab="interaction">
      <UserAnalysis userAnalytics={data.allInstagramInteractionUsers.usernames} />
      <TaggedPosts
        data={taggedPosts}
        header={
          <Styled.PostsTitle>
            <StyledTitleWithHelp title="HeaderColumn.Tagged Posts" help="interactionTaggedPosts" />
          </Styled.PostsTitle>
        }
        moreLink={`/analytics/dashboard/${id}/interaction/tagged_posts`}
        css={css`
          border-top: 1px solid #dee5ec;
        `}
        type="taggedPost"
      />
      <MentionedPosts
        data={mentionedPosts}
        header={
          <Styled.PostsTitle>
            <StyledTitleWithHelp title="HeaderColumn.Mentioned Posts" help="interactionMentionedPosts" />
          </Styled.PostsTitle>
        }
        moreLink={`/analytics/dashboard/${id}/interaction/mentioned_posts`}
        type="mentionedPost"
      />
    </Wrapper>
  );
};

const Wrapper = styled(AnalyticsContentWrapper)`
  margin-top: 24px;
`;
const TaggedPosts = styled(BasePosts)``;
const MentionedPosts = styled(BasePosts)``;
const StyledTitleWithHelp = styled(TitleWithHelp)`
  margin-bottom: 0;
`;

export default InteractionPosts;
