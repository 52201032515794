import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface SubmitButtonProps extends SubmitProps {
  className?: string;
  value: string;
  onClick?: () => void;
  submittingMessage?: string;
  isSubmitting?: boolean;
}

const SubmitButton = (props: SubmitButtonProps) => {
  const { t } = useTranslation();
  const { className, value, isSubmitting, onClick, color, bgcolor, hoverbgcolor } = props;
  const submittingMessage = props.submittingMessage || 'Submitting';
  const submitValue = isSubmitting ? submittingMessage : value;
  const disabled = isSubmitting || props.disabled;

  return (
    <Submit
      className={className}
      type="submit"
      value={t(`Button.${submitValue}`).toUpperCase() as string}
      disabled={disabled}
      color={color}
      bgcolor={bgcolor}
      hoverbgcolor={hoverbgcolor}
      onClick={onClick}
    />
  );
};

interface SubmitProps {
  disabled?: boolean;
  color?: string;
  bgcolor?: string;
  hoverbgcolor?: string;
}

const Submit = styled.input<SubmitProps>`
  width: 100%;
  height: 32px;
  background-color: ${props => (props.bgcolor ? props.bgcolor : '#3892e5')};
  border-radius: 3px;
  color: ${props => (props.color ? props.color : '#fff')};
  line-height: 32px;
  font-size: 12px;
  font-weight: 600;
  transition: background-color 0.3s ease-in-out;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  box-sizing: border-box;

  &:hover {
    background-color: ${props => (props.hoverbgcolor ? props.hoverbgcolor : '#5fa7ea')};
  }

  &:active {
    background-color: #2c74b7;
  }

  &:disabled {
    background-color: #dee5ec;
  }
`;

export default SubmitButton;
