import React from 'react';
import { useTranslation } from 'react-i18next';
import css from '@emotion/css';
import { getIconByName } from '../../../../libs/icons';
import Carousel from '../../../molecules/Carousel';
import ImageSlider from '../../../molecules/ImageSlider';
import * as SliderStyledComponents from '../StyledComponents';
import { defaultEmptyImage } from '../../../../libs/image';
import { SocialAccountType } from '../../../../__generated__/globalTypes';
import * as StyledComponents from './StyledComponents';
import InfluencerInfo from './InfluencerInfo';
import InstagramEngagmentInfo from './instagram/EngagementInfo';
import { InstagramEngagementInfoProps, YoutubeEngagementInfoProps } from './types';
import YoutubeEngagementInfo from './youtube/EngagementInfo';

interface PostDetailProps {
  closeModal: () => void;
  accountType?: SocialAccountType;
  engagement: InstagramEngagementInfoProps | YoutubeEngagementInfoProps;
  avatarUrl: string | null;
  accountName: string | null;
  followers: number | null;
  content: string;
  commentList: { id: number; content: string; username: string | null }[];
  postImageUrl: string | null;
  postDate?: string;
  currSlideIdx?: number;
  setCurrentSlideIdx?: (idx: number) => void;
  postsPreviewData?: { id: number; thumbNail: string | null }[];
  hideFollowersCount?: boolean;
}

const PostDetail = (props: PostDetailProps) => {
  const {
    avatarUrl,
    accountName,
    followers,
    content,
    commentList,
    postImageUrl,
    engagement,
    closeModal,
    postDate,
    currSlideIdx = 0,
    setCurrentSlideIdx,
    postsPreviewData,
    hideFollowersCount
  } = props;

  const accountType = props.accountType || SocialAccountType.INSTAGRAM;
  const { t } = useTranslation();

  const hasComments = !!commentList.length;

  const getEngagementTemplate = () => {
    switch (accountType) {
      case SocialAccountType.YOUTUBE:
        return <YoutubeEngagementInfo {...(engagement as YoutubeEngagementInfoProps)} />;
      case SocialAccountType.INSTAGRAM:
      default:
        return <InstagramEngagmentInfo {...(engagement as InstagramEngagementInfoProps)} />;
    }
  };

  return (
    <StyledComponents.ContainerWrapper>
      <StyledComponents.Container>
        <StyledComponents.TopHeading>
          <InfluencerInfo
            avatar={avatarUrl}
            name={accountName}
            accountType={accountType}
            followersCount={followers}
            postDate={postDate}
            hideFollowersCount={hideFollowersCount}
          />

          <StyledComponents.CloseIcon src={getIconByName('close')} onClick={closeModal} />
        </StyledComponents.TopHeading>

        <StyledComponents.MainContent>
          <StyledComponents.LeftContent>
            {postImageUrl && (
              <Carousel
                sources={postsPreviewData ? (postsPreviewData.map(el => el.thumbNail) as string[]) : [postImageUrl]}
                afterChangeHandler={idx => {
                  // eslint-disable-next-line no-unused-expressions
                  setCurrentSlideIdx?.(idx);
                }}
                currSlideIdx={currSlideIdx}
              />
            )}
          </StyledComponents.LeftContent>

          <StyledComponents.RightContent>
            {/* Engagement Info */}
            {getEngagementTemplate()}
            {/* Post Caption */}
            <StyledComponents.Title>{t('Post’s Caption')}</StyledComponents.Title>
            <StyledComponents.PostCaptionWrapper>
              <StyledComponents.Caption>{content}</StyledComponents.Caption>
            </StyledComponents.PostCaptionWrapper>

            {/* Post Comments */}
            {hasComments && (
              <StyledComponents.CommentsSection>
                <StyledComponents.Title>{t('General.Comments')}</StyledComponents.Title>
                <StyledComponents.CaptionFrame>
                  {commentList.map(item => (
                    <StyledComponents.CommentsWrapper key={item.id}>
                      <StyledComponents.TextWrapper>
                        <StyledComponents.CommentUsername>{item.username}</StyledComponents.CommentUsername>
                        <StyledComponents.CommentText>{item.content}</StyledComponents.CommentText>
                      </StyledComponents.TextWrapper>
                    </StyledComponents.CommentsWrapper>
                  ))}
                </StyledComponents.CaptionFrame>
              </StyledComponents.CommentsSection>
            )}
          </StyledComponents.RightContent>
        </StyledComponents.MainContent>
      </StyledComponents.Container>
      {postsPreviewData && postsPreviewData.length > 1 && (
        <SliderStyledComponents.PostsPreviewWrapper>
          <SliderStyledComponents.TotalPostsPreview>
            {/* count is starting from 1 -> currSlideIdx + 1 */}
            {`${t('General.Post')} ${currSlideIdx + 1} / ${postsPreviewData.length}`}
          </SliderStyledComponents.TotalPostsPreview>
          <ImageSlider
            data={postsPreviewData}
            css={css`
              padding-top: 8px;
            `}
            slideWidth={112}
            hasBgImage={false}
            sliderArrowCss={css`
              width: 24px;
              height: 48px;
              top: 30px;
            `}
            currentSlideIdx={currSlideIdx}
            sliderLength={postsPreviewData.length}
          >
            {postsPreviewData.map((item, idx) => {
              const isActive = currSlideIdx === idx;

              return (
                <SliderStyledComponents.PostImageWrapper key={item.id}>
                  <SliderStyledComponents.PostImage
                    src={defaultEmptyImage(item.thumbNail)}
                    // eslint-disable-next-line no-unused-expressions
                    onClick={() => setCurrentSlideIdx?.(idx)}
                    css={css`
                      width: 96px;
                      height: 96px;
                      background-color: #000;
                      border: ${isActive ? '3px solid red' : ''};
                      box-sizing: border-box;
                    `}
                  />
                </SliderStyledComponents.PostImageWrapper>
              );
            })}
          </ImageSlider>
        </SliderStyledComponents.PostsPreviewWrapper>
      )}
    </StyledComponents.ContainerWrapper>
  );
};

export default PostDetail;
