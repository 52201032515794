import React from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { formatPercent, formatNumber } from '../../../../libs/format';
import HighChart, { Highcharts } from '../../../molecules/HighChart';
import { TitleWithHelp } from '../../AnalyticsShared/AnalyticsContentWrapper';
import { InstagramComparePosts_instagramComparePosts_compareAccounts_postsHabit } from '../../AnalyticsCompare/AnalyticsComparePosts/__generated__/InstagramComparePosts';
import { OpacityContentWrapper } from '../../AnalyticsShared/SharedComponents';
import { hourIntervals, weekDays } from '../../../../libs/date';
import { minColor, maxColor } from './PostingHabitsChartConfig';

const PostingHabits = ({
  postsHabit,
  showHeader = true,
  className
}: {
  postsHabit: InstagramComparePosts_instagramComparePosts_compareAccounts_postsHabit[];
  showHeader?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation();

  const chartData = !postsHabit?.every(data => data.engagementRate === 0)
    ? postsHabit.map(item => ({
        x: item.hour,
        y: item.weekday,
        value: parseFloat(formatPercent(item.engagementRate, false)),
        likes: formatNumber(item.averageLikes),
        comments: formatNumber(item.averageComments),
        time: `${weekDays[item.weekday]} ${hourIntervals[item.hour]}`
      }))
    : [];

  const options: Highcharts.Options = {
    chart: {
      type: 'heatmap',
      marginTop: 0,
      plotBorderWidth: 1,
      marginRight: 0,
      inverted: true,
      height: 300,
      borderColor: '#FFFFFF'
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: hourIntervals.map(item => item.replace(/\s/g, '')),
      min: 0,
      gridLineColor: 'transparent',
      lineColor: '#FFFFFF'
    },
    yAxis: {
      categories: weekDays.map(item => item.slice(0, 3)),
      title: {
        text: ''
      },
      min: 0,
      gridLineColor: 'transparent',
      lineColor: '#FFFFFF',
      lineWidth: 2
    },
    colorAxis: {
      min: 0,
      minColor,
      maxColor
    },
    legend: {
      enabled: false
    },
    series: [
      {
        type: 'heatmap',
        pointPadding: 2,
        borderColor: 'transparent',
        name: 'Posting Habits',
        data: chartData,
        dataLabels: {
          enabled: false
        }
      }
    ],
    tooltip: {
      padding: 0,
      useHTML: true,
      borderColor: '#27313b',
      headerFormat: '',
      borderWidth: 0,
      borderRadius: 3,
      pointFormat: `
        <div style="border-radius:3px;box-shadow: 0 3px 10px 0 rgba(39, 49, 59, 0.59);background-color:#27313b;padding:16px">
  
        <h1 style="font-size:13px;color: #ffffff;margin-bottom:8px">{point.time}  </h1>
   
        <div style="display:flex">
            <div style="margin-right:16px;border-left:1px solid #39444f;padding-left:4px">
            <p style="color:#c5d0da;font-size:11px;margin-bottom:2px">${t('General.Likes')}</p>
            <p style="color:#ffffff;font-size:13px">{point.likes}</p>
            </div>
            <div style="margin-right:16px;border-left:1px solid #39444f;padding-left:4px">
            <p style="color:#c5d0da;font-size:11px;margin-bottom:2px">${t('General.Comments')}</p>
              <p style="color:#ffffff;font-size:13px">{point.comments}</p></div>
            <div style="border-left:1px solid #39444f;padding-left:4px">
            <p style="color:#c5d0da;font-size:11px;margin-bottom:2px">${t('General.Engagement Rate')}</p>
              <p style="color:#ffffff;font-size:13px">{point.value}%</p>
            </div>
          </div>`,
      footerFormat: '</div>'
    }
  };

  return (
    <OpacityContentWrapper
      header={showHeader ? <MainTitle help="postingHabits" title="Influencer.PostingHabits" /> : undefined}
      dataNotAvailable={!postsHabit || postsHabit.every(data => data.engagementRate === 0)}
      className={className}
    >
      {/* Setting tooltip arrow color */}
      <Global
        styles={css`
          .highcharts-tooltip path {
            fill: #27313b;
          }
        `}
      />
      <HighChart options={options} />
    </OpacityContentWrapper>
  );
};

const MainTitle = styled(TitleWithHelp)`
  margin-bottom: 16px;
`;

export default PostingHabits;
