import { FormikProps } from 'formik';
import { Genders } from '../__generated__/globalTypes';
import { getFileTypeFromUrl } from './request/getFileObjectFromStorage';
import { Material } from './request/types';
import { Option } from './types';

export const MIN_AGE = 18;
export const MAX_AGE = 50;
export const MIN_FOLLOWERS = 0;
export const MAX_FOLLOWERS = 1000000;
export const MAX_FOLLOWERS_MARKETPLACE = 100000;
export const MAX_FOLLOWERS_ENGAGEMENT = 1000000;
export const FOLLOWER_STEP_MARKETPLACE = 500;
export const FOLLOWER_STEP_ENGAGEMENT = 5000;
export const MIN_ENGAGEMENT_RATE = 0;
export const MAX_ENGAGEMENT_RATE = 20;
export const MAX_POST_ENGAGEMENT = 1000000;
export const MIN_POST_ENGAGEMENT = 0;

// Fields
export const getField = <Values extends {}, Name extends keyof Values>(formProps: FormikProps<Values>, name: Name) => {
  const { values, errors, handleChange } = formProps;

  return {
    name,
    value: values[name],
    error: !!errors[name],
    errorMessage: errors[name],
    onChange: handleChange
  };
};

export const getOptions = <T extends { id: number | string; name: string }>(items?: Array<T | null> | null): Option[] =>
  items
    ? items
        .map(item =>
          item
            ? {
                value: String(item.id),
                label: item.name
              }
            : null
        )
        .filter((item): item is Option => item !== null)
    : [];

export const getInitialMaterials = <
  T extends {
    id: number;
    url: string;
  }
>(
  payload: Array<T | null> | null
) => {
  if (!payload) {
    return [] as Material[];
  }

  // Filter out nulls from materials
  const materials = payload.reduce<Material[]>((previous, current) => {
    if (!!current) {
      const { id, url } = current;

      return [...previous, { id, url }];
    }

    return previous;
  }, []);

  return materials;
};

export const getInitialUploadedFiles = (materialUrls: string[] | null) => {
  if (!materialUrls) {
    return [];
  }

  return materialUrls.map(url => {
    const fileType = getFileTypeFromUrl(url);
    const isVideo = ['avi', 'mp4', 'mov'].includes(fileType);

    return {
      url,
      preview: isVideo ? '' : url
    };
  });
};

export const convertMinAge = (minAge: number) => (minAge <= MIN_AGE ? 0 : minAge);
export const convertMaxAge = (maxAge: number) => (maxAge >= MAX_AGE ? 100 : maxAge);

// Add UNKNOWN gender if "OTHERS" is selected if it is not already there
export const convertGenders = (genders: Genders[]) => {
  if (genders.length === 1 && genders[0] === Genders.UNKNOWN) {
    return [];
  }

  return genders.includes(Genders.NOT_APPLICABLE) ? [...new Set([...genders, Genders.UNKNOWN])] : genders;
};
