import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface ErrorMessageProps {
  className?: string;
  message?: string;
  dynamicError?: string;
}

const ErrorMessage = (props: ErrorMessageProps) => {
  const { t } = useTranslation();
  const { className, message, dynamicError } = props;

  if (dynamicError) {
    return <Message className={className}>*&nbsp;{dynamicError}</Message>;
  }

  return !!message ? <Message className={className}>*&nbsp;{t(message)}</Message> : null;
};

const Message = styled.p`
  padding: 2px 0 0 2px;
  color: tomato;
  font-size: 11px;
`;

export default ErrorMessage;
