import { weekDays, hourIntervals } from '../../../../libs/date';
import { PostHabits } from './types';

export const generateTimeslots = (postsHabit: PostHabits[] | null) => {
  // we need to be aware of Apollo's freezing query results for better caching since v.2.6 -> https://github.com/apollographql/apollo-client/issues/5987
  //  we have to copy array before sorting it due to it is immutable after beign retrieved from query results
  const topEngagementRateHabits = postsHabit
    ? [...postsHabit].sort((a, b) => (a.engagementRate > b.engagementRate ? -1 : 1)).slice(0, 3)
    : [];

  return topEngagementRateHabits.map(
    habit => `${weekDays[habit.weekday].slice(0, 3)} ${hourIntervals[habit.hour].toUpperCase()}`
  );
};
