import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { formatIntNumber } from '../../../../libs/format';
import { defaultEmptyImage } from '../../../../libs/image';
import ImageSlider from '../../../molecules/ImageSlider';
import CarouselModal from '../../../molecules/CarouselPopup';
import { usePostDetailModal } from '../../AnalyticsShared/BasePostList/usePostDetailModal';
import * as StyledComponents from '../StyledComponents';
import { OpacityContentWrapper } from '../../AnalyticsShared/SharedComponents';
import AnayticsFeedPostDetail from '../../AnalyticsPost/AnalyticsFeedPostDetail';
import AnayticsTaggedPostDetail from '../../AnalyticsPost/TaggedPostDetail';
import AnayticsMentionedPostDetail from '../../AnalyticsPost/MentionedPostDetail';

interface LikeCommentPostsDataType {
  id: number;
  likes: number | null;
  comments: number | null;
  thumbNail: string | null;
}
interface LikeCommentPostsProps {
  data: LikeCommentPostsDataType[] | null;
  header: ReactElement;
  moreLink: string;
  className?: string;
  type?: 'taggedPost' | 'mentionedPost';
}
const LikeCommentPosts = (props: LikeCommentPostsProps) => {
  const { t } = useTranslation();
  const { data, header, moreLink, className, type } = props;

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev
  } = usePostDetailModal(data || []);

  return (
    <>
      <MainWrapper className={className}>
        <StyledOpacityWrapper header={header} dataNotAvailable={!data?.length}>
          {!!data?.length && (
            <>
              <ImageSlider data={data}>
                {data.map(item => (
                  <StyledComponents.PostImageWrapper key={item.id}>
                    <StyledComponents.PostImage
                      src={defaultEmptyImage(item.thumbNail)}
                      onClick={() => handleClickPost(item.id)}
                    />
                    <StyledComponents.EngagementInfo>
                      <StyledComponents.InfoWrapper>
                        <StyledComponents.Label>{t('Like')}</StyledComponents.Label>
                        <StyledComponents.Count>{formatIntNumber(item.likes)}</StyledComponents.Count>
                      </StyledComponents.InfoWrapper>
                      <StyledComponents.InfoWrapper>
                        <StyledComponents.Label>{t('General.Comments')}</StyledComponents.Label>
                        <StyledComponents.Count>{formatIntNumber(item.comments)}</StyledComponents.Count>
                      </StyledComponents.InfoWrapper>
                    </StyledComponents.EngagementInfo>
                  </StyledComponents.PostImageWrapper>
                ))}
              </ImageSlider>

              <StyledComponents.MoreText to={moreLink}>
                <span>{t('Button.More')}</span> <StyledComponents.Arrow>{'\u2192'}</StyledComponents.Arrow>
              </StyledComponents.MoreText>
            </>
          )}
        </StyledOpacityWrapper>
      </MainWrapper>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        {type === 'taggedPost' ? (
          <AnayticsTaggedPostDetail closeModal={handleClosePostDetail} />
        ) : type === 'mentionedPost' ? (
          <AnayticsMentionedPostDetail closeModal={handleClosePostDetail} />
        ) : (
          <AnayticsFeedPostDetail closeModal={handleClosePostDetail} />
        )}
      </CarouselModal>
    </>
  );
};

const MainWrapper = styled.div`
  padding-left: 16px;
  border-bottom: 1px solid #e6ecf0;
`;
const StyledOpacityWrapper = styled(OpacityContentWrapper)`
  min-height: 200px;
`;

export default LikeCommentPosts;
