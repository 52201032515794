import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import Slider from 'react-slick';
import emptyImage from '../../../assets/img/emptyImage.jpg';
import { LeftArrow, RightArrow } from './CustomArrows';

interface CarouselProps {
  className?: string;
  sources: string[];
  afterChangeHandler?: (currentSlide: number) => void;
  currSlideIdx?: number;
}

const SETTINGS = {
  speed: 500,
  infinite: true,
  dots: true,
  adaptiveHeight: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <LeftArrow to="prev" />,
  nextArrow: <RightArrow to="next" />
};

const Carousel = (props: CarouselProps) => {
  const { className, sources = [], afterChangeHandler, currSlideIdx } = props;
  let sliderMethods: Slider | null;

  useEffect(() => {
    if (sliderMethods && currSlideIdx !== undefined) {
      sliderMethods.slickGoTo(currSlideIdx);
    }
  }, [currSlideIdx]);

  return (
    <StyledSlider
      className={className}
      {...SETTINGS}
      afterChange={afterChangeHandler}
      ref={slider => (sliderMethods = slider)}
    >
      {sources.map(source => {
        const extensions = /.jpeg|.jpg|.png|.gif|.zip|.pdf|.ppt|.pptx|.xls|.xlsx|.doc|.docx|.txt/;
        const isImage = extensions.test(source.toLowerCase());

        return (
          <Wrapper key={source}>
            {isImage ? (
              <Image
                src={source}
                alt=""
                className="sliderImage"
                onError={(e: React.SyntheticEvent): void => {
                  const target = e.target as HTMLSourceElement;
                  target.onerror = null;
                  target.src = emptyImage;
                }}
              />
            ) : (
              <Video src={source} loop={true} muted={true} autoPlay={true} playsinline={true} controls={true} />
            )}
          </Wrapper>
        );
      })}
    </StyledSlider>
  );
};

const StyledSlider = styled(Slider)`
  width: 400px;
  height: 400px;

  & .slick-list {
    height: auto !important;
  }

  & div {
    outline: none !important;
  }

  & .slick-arrow {
    z-index: 100;
  }

  & .slick-arrow.slick-next {
    right: 0;
  }

  & .slick-arrow.slick-prev {
    left: 0;
  }

  & .slick-arrow::before {
    font-size: 24px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    opacity: 0.8;
    content: '' !important;
    background-color: #fff;
  }

  & .slick-dots {
    bottom: 20px;
    right: 0;
    left: 0;
    margin: auto;
    background-color: #fff;
    border-radius: 8px;
    width: fit-content;
    max-width: 200px;
    padding: 0 2px;

    li {
      margin: 0;
      width: 14px;
      height: 14px;
    }

    li button::before {
      font-size: 11px;
      line-height: 13px;
      margin: 0;
      width: 14px;
      color: #ff2b52;
    }
  }
`;

const Wrapper = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #000;
`;

const Image = styled.img`
  object-fit: contain;
  width: 400px;
  height: 400px;
`;

const Video = styled.video`
  max-width: 100%;
  max-height: 400px;
`;

export default Carousel;
