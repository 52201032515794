import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { useSort } from '../../libs/filter';
import { usePaging } from '../../libs/paging';
import { getThirtyDaysAgo, getToday } from '../../libs/date';
import { useQueryHelper } from '../../libs/hooks';
import { getInstagramFeedPostSortFieldType } from '../../libs/filter/enum';
import { InstagramFeedPostSortField } from '../../__generated__/globalTypes';
import { useFilter } from '../../components/organisms/AnalyticsShared/BasePostListFilter/useFilter';
import {
  InstagramAnalyticsHashtags,
  InstagramAnalyticsHashtagsVariables
} from '../../components/organisms/AnalyticsHashTag/__generated__/InstagramAnalyticsHashtags';
import ListIndicator from '../../components/molecules/ListIndicator';
import * as IG_ANALYTICS_HASHTAGS from '../../components/organisms/AnalyticsHashTag/InstagramAnalyticsHashtags.graphql';
import Template from './template';

export interface ComparableHashtagType {
  id: number;
  hashTag: string;
  isSelected: boolean;
}

const InstagramHashtagFeedPost = () => {
  const { history, enqueueSnackbar, t } = useQueryHelper();
  const { id } = useParams<{ id: string }>();
  const accountId = Number(id);

  // Paging
  const currentPage = usePaging();
  // Filter
  const { filter, setFilter } = useFilter({ startDate: getThirtyDaysAgo(), endDate: getToday() });
  // Sort
  const { sort, setSort } = useSort<InstagramFeedPostSortField>(getInstagramFeedPostSortFieldType);

  const { loading, error } = useQuery<InstagramAnalyticsHashtags, InstagramAnalyticsHashtagsVariables>(
    IG_ANALYTICS_HASHTAGS,
    {
      variables: { accountId },
      skip: !accountId,
      onCompleted: useCallback(({ instagramAnalyticsHashtags }: InstagramAnalyticsHashtags) => {
        if (instagramAnalyticsHashtags?.hashTags?.length) {
          const hashtags = instagramAnalyticsHashtags.hashTags
            .filter(ht => !ht.blocked)
            .map(hash => ({ ...hash, isSelected: false }));

          hashtags[0] = { ...hashtags[0], isSelected: true };

          setAllHashtags(hashtags);

          return;
        }

        handleRedirectToAnalytics();
      }, [])
    }
  );

  const [allHashtags, setAllHashtags] = useState<ComparableHashtagType[]>([]);

  const selectedHashtagId = allHashtags.find(hash => hash.isSelected)?.id || undefined;

  const handleRedirectToAnalytics = () => {
    history.push('/analytics');
    enqueueSnackbar(t('Can not find hashtag information'), { variant: 'error' });
  };

  if (loading) {
    return <StyledLoadingIndicator />;
  }

  if (error) {
    handleRedirectToAnalytics();

    return null;
  }

  const handleTabChange = (hashtagId: string) => {
    setAllHashtags(
      allHashtags.map(hash => {
        if (hash.id === Number(hashtagId)) {
          return { ...hash, isSelected: true };
        } else {
          return { ...hash, isSelected: false };
        }
      })
    );
  };

  return (
    <Template
      accountId={accountId}
      filter={filter}
      currentPage={currentPage}
      setFilter={setFilter}
      sort={sort}
      setSort={setSort}
      hashtags={allHashtags}
      selectedHashtagId={selectedHashtagId?.toString() || ''}
      onChangeHashtag={hashtagId => handleTabChange(hashtagId)}
    />
  );
};

const StyledLoadingIndicator = styled(ListIndicator)`
  margin: 0;
`;

export default InstagramHashtagFeedPost;
