import React from 'react';
import css from '@emotion/css';
import { PageInfo } from '../../../../libs/paging';
import { SortActionType } from '../../../../libs/filter';
import { formatIntNumber, formatDate } from '../../../../libs/format';
import { InstagramFeedPostSortField, ORDER } from '../../../../__generated__/globalTypes';
import TaggedPostDetail from '../TaggedPostDetail';
import MentionedPostDetail from '../MentionedPostDetail';
import * as List from '../../../atoms/List';
import CarouselModal from '../../../molecules/CarouselPopup';
import { SliderComponents, SliderCss, StyledComponents } from '../../../molecules/SliderTable';
import PostColumn from '../PostColumn';
import { usePostDetailModal } from '../../AnalyticsShared/BasePostList/usePostDetailModal';
import { InteractionPostListType } from '../types';

export type TaggedPostSortAction = SortActionType<InstagramFeedPostSortField>;

export interface TaggedPostsListProps extends TaggedPostSortAction {
  taggedPosts: any[];
  pageInfo: PageInfo;
  type: InteractionPostListType;
}

const TaggedPostsList = (props: TaggedPostsListProps) => {
  const {
    sort: { field, order },
    setSort,
    taggedPosts,
    pageInfo: { firstIndex, lastIndex, totalCount, currentPage, totalPages },
    type
  } = props;
  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev
  } = usePostDetailModal(taggedPosts);

  return (
    <>
      <StyledComponents.ButtonWrapper css={SliderCss.boderTop}>
        <SliderComponents.PageCount total={totalCount} countLabel="Posts" />
      </StyledComponents.ButtonWrapper>
      <SliderComponents.Wrapper css={[SliderCss.marginTop, SliderCss.clearPadding]}>
        <SliderComponents.Container>
          <SliderComponents.SliderSection>
            <SliderComponents.SliderTable>
              <thead>
                <SliderComponents.HeaderRow css={SliderCss.bgndColor}>
                  <SliderComponents.StyckyHeaderDivWrapper>
                    <List.HeaderColumn
                      title="Post"
                      css={[SliderCss.clearPadding, SliderCss.width245, SliderCss.flexStart]}
                      isTable={false}
                    />
                  </SliderComponents.StyckyHeaderDivWrapper>
                  {/* slider */}
                  <List.HeaderColumn
                    title="Like"
                    activeAsc={field === InstagramFeedPostSortField.LIKE && order === ORDER.ASC}
                    activeDesc={field === InstagramFeedPostSortField.LIKE && order === ORDER.DESC}
                    sortAsc={() => setSort({ field: InstagramFeedPostSortField.LIKE, order: ORDER.ASC })}
                    sortDesc={() => setSort({ field: InstagramFeedPostSortField.LIKE, order: ORDER.DESC })}
                    css={[SliderCss.flexStart, paddingLeft24]}
                  />
                  <List.HeaderColumn
                    title="Comment"
                    activeAsc={field === InstagramFeedPostSortField.COMMENT && order === ORDER.ASC}
                    activeDesc={field === InstagramFeedPostSortField.COMMENT && order === ORDER.DESC}
                    sortAsc={() => setSort({ field: InstagramFeedPostSortField.COMMENT, order: ORDER.ASC })}
                    sortDesc={() => setSort({ field: InstagramFeedPostSortField.COMMENT, order: ORDER.DESC })}
                    css={[SliderCss.flexStart, SliderCss.paddingLeft8]}
                  />
                  <List.HeaderColumn
                    title="Post date"
                    activeAsc={field === InstagramFeedPostSortField.POST_DATE && order === ORDER.ASC}
                    activeDesc={field === InstagramFeedPostSortField.POST_DATE && order === ORDER.DESC}
                    sortAsc={() => setSort({ field: InstagramFeedPostSortField.POST_DATE, order: ORDER.ASC })}
                    sortDesc={() => setSort({ field: InstagramFeedPostSortField.POST_DATE, order: ORDER.DESC })}
                    css={[SliderCss.flexStart, SliderCss.paddingLeft8]}
                  />
                </SliderComponents.HeaderRow>
              </thead>
              <tbody>
                {taggedPosts.map(item => {
                  const { id, postDate, comments, likes, thumbNail, content } = item;

                  return (
                    <StyledComponents.StyledRowNew key={id} css={[SliderCss.paddingSides, SliderCss.rowHeight62]}>
                      <SliderComponents.StickyBodyRowWrapper css={[SliderCss.clearPadding, SliderCss.width245]}>
                        <PostColumn
                          key={id}
                          id={id}
                          src={thumbNail}
                          title={content}
                          onClick={() => handleClickPost(id)}
                        />
                      </SliderComponents.StickyBodyRowWrapper>
                      {/* sliding section */}
                      <StyledComponents.NumberColumn
                        data={formatIntNumber(likes)}
                        css={[
                          SliderCss.flexStart,
                          css`
                            width: 56px;
                            padding-left: 40px !important;
                          `
                        ]}
                      />
                      <StyledComponents.NumberColumn
                        data={formatIntNumber(comments)}
                        css={[
                          SliderCss.flexStart,
                          css`
                            width: 100px;
                            padding-left: 20px !important;
                          `
                        ]}
                      />
                      <List.TextColumn data={formatDate(postDate)} css={postDateCss} />
                    </StyledComponents.StyledRowNew>
                  );
                })}
              </tbody>
            </SliderComponents.SliderTable>
          </SliderComponents.SliderSection>
        </SliderComponents.Container>
      </SliderComponents.Wrapper>
      <SliderComponents.Pager
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        first={firstIndex}
        last={lastIndex}
      />
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        {type === 'taggedPost' ? (
          <TaggedPostDetail closeModal={handleClosePostDetail} />
        ) : (
          <MentionedPostDetail closeModal={handleClosePostDetail} />
        )}
      </CarouselModal>
    </>
  );
};

const paddingLeft24 = css`
  padding-left: 24px !important;
`;
const postDateCss = css`
  width: 200px;
`;

export default TaggedPostsList;
