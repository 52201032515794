import React from 'react';
import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';
import css from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AnalyticsTab, { AnalyticsTabType } from '../../components/organisms/Analytics/AnalyticsTab';
import { Period } from '../../components/molecules/DayPicker/types';
import AnalyticsDashboard from '../../components/organisms/AnalyticsDashboard';
import HashTagTab from '../../components/organisms/AnalyticsHashTag';
import AnalyticsCompare from '../../components/organisms/AnalyticsCompare';
import AnalyticsInteraction from '../../components/organisms/AnalyticsInteraction';
import { switchImageActive } from '../../libs/SocialMedia';
import { AnalyticsSubscriptionPlanType, SocialAccountType } from '../../__generated__/globalTypes';
import { AnalyticsAccountProps } from '../../libs/hooks';
import AnalyticsDetailsHeader from './AnalyticsDetailsHeader';

interface TemplateProps {
  tab: AnalyticsTabType;
  period: Period;
  onChangePeriod: (period: Period) => void;
  id: number;
  analyticsAccountInfo: AnalyticsAccountProps;
}

const Template = (props: TemplateProps) => {
  const { tab, period, onChangePeriod, id, analyticsAccountInfo } = props;
  const { t } = useTranslation();
  const hasFreePlan =
    // TODO: later on add TRIAL plan here
    analyticsAccountInfo.plan === AnalyticsSubscriptionPlanType.FREE;

  if (!analyticsAccountInfo?.id) {
    return (
      <LoaderWrapper>
        <CircularProgress
          size={44}
          thickness={2.4}
          css={css`
            margin: 14px auto;
            color: #e0e8ed;
          `}
        />
      </LoaderWrapper>
    );
  }

  const src = switchImageActive(SocialAccountType.INSTAGRAM, true);
  const avatar = analyticsAccountInfo?.avatar || '';
  const accountName = analyticsAccountInfo?.accountName || '';
  const mainAccount = { src, avatar, accountName };
  const profileUrl = `https://www.instagram.com/${analyticsAccountInfo.socialAccountUsername}/`;

  const getPageContents = (currentTab: AnalyticsTabType) => {
    switch (currentTab) {
      case AnalyticsTabType.Compare:
        return (
          <AnalyticsCompare
            period={period}
            {...{ id, username: accountName, avatarUrl: avatar, profileUrl, blocked: false }}
          />
        );
      case AnalyticsTabType.HashTag:
        return <HashTagTab id={id} period={period} />;
      case AnalyticsTabType.Interaction:
        return <AnalyticsInteraction period={period} id={id} />;
      case AnalyticsTabType.Dashboard:
      default:
        return <AnalyticsDashboard id={id} period={period} avatar={avatar} />;
    }
  };

  return (
    <>
      <StickyWrapper>
        {hasFreePlan && (
          <TrialPlanWarning>
            {t('upgradeTrialPlanWarning')}
            <UpgradeLink to="/subscription">{t('Upgrade')}</UpgradeLink>
          </TrialPlanWarning>
        )}
        <AnalyticsDetailsHeader period={period} onChangePeriod={onChangePeriod} {...mainAccount} />
        <AnalyticsTab plan={analyticsAccountInfo.plan} />
      </StickyWrapper>
      <ContentWrapper>{getPageContents(tab)}</ContentWrapper>
    </>
  );
};

const ContentWrapper = styled.div`
  padding: 24px 24px 0;
`;
const LoaderWrapper = styled.div`
  background-color: #fff;
  border-top: 1px solid #dee5ec;
  text-align: center;
`;
const StickyWrapper = styled.div`
  position: sticky;
  top: 48px;
  z-index: 9;
`;
const TrialPlanWarning = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff2b52;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #fff;
`;
const UpgradeLink = styled(Link)`
  padding-left: 16px;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #fff;

  &:hover {
    text-decoration: underline;
  }
`;

export default Template;
