import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { onError } from '@apollo/link-error';
import { GRAPHQL_URL } from '../libs/request';

// eslint-disable-next-line no-underscore-dangle,no-undef
const __DEV__ = process.env.NODE_ENV !== 'production';

// Authentication
const httpLink = new HttpLink({
  uri: GRAPHQL_URL
});
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken');

  return {
    headers: {
      ...headers,
      authorization: token || ''
    }
  };
});

// Error handling
const showError = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // @ts-ignore
      const isQuery = operation.query.definitions.map(op => op)[0]?.operation === 'query';

      if (__DEV__) {
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      }

      if (message === 'PERMISSION_DENIED' && window.location.pathname !== '/notfound' && isQuery) {
        window.location.href = '/notfound';
      }
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const cache = new InMemoryCache({ addTypename: false });
const link = ApolloLink.from([authLink, showError, httpLink]);

const client = new ApolloClient({
  cache,
  link,
  connectToDevTools: __DEV__,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all'
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'none'
    }
  }
});

export default client;
