import { Highcharts } from '../../../molecules/HighChart';
import {
  SeriesHistoryType,
  formatLineChartDateCount,
  formatDateCount,
  getSerieDayData,
  SeriesType,
  TooltipDataType
} from '../../AnalyticsShared/Overview/helpers';

export const defaultSelectableCharts: ChartColorTypes[] = ['Follower', 'Post', 'Like', 'Reach', 'Comment', 'Save'];
export const additionalSelectableCharts: ChartColorTypes[] = [
  // 'Follow', -> we don't need for now
  'Impression',
  'Engagement',
  'Engagement rate',
  'Profile views',
  'Website Click'
];
export const allSelectableCharts: ChartColorTypes[] = [...defaultSelectableCharts, ...additionalSelectableCharts];

export type ChartColorTypes =
  | 'Follower'
  | 'Post'
  | 'Like'
  | 'Reach'
  | 'Comment'
  | 'Save'
  | 'Follow'
  | 'Impression'
  | 'Engagement'
  | 'Engagement rate'
  | 'Profile views'
  | 'Website Click';
export const colors: Record<ChartColorTypes, string> = {
  Follower: '#ff2b52',
  Post: '#ffd7c1',
  Reach: '#ff96ae',
  Like: '#e9b8d7',
  Comment: '#aec0e4',
  Save: '#a0c8df',
  Follow: '#9dd9f4',
  Impression: '#ffb6b7',
  Engagement: '#a0dfd5',
  'Engagement rate': '#a5a8e0',
  'Profile views': '#c484eb',
  'Website Click': '#ffaae1'
};

export const getDailyDataForTooltip = (categories: string[], series: SeriesType[]): TooltipDataType<ChartColorTypes> =>
  categories.reduce(
    (acc, day, index) => ({
      ...acc,
      [day]: getSerieDayData<ChartColorTypes>(index, series)
    }),
    {}
  );

interface SeriesAndCategoriesType {
  historyData: Record<string, SeriesHistoryType[]>;
  displayedCharts: ChartColorTypes[];
}
export const getSeriesAndCategories = ({
  historyData: {
    followersHistory,
    postsHistory,
    reachHistory,
    likesHistory,
    commentsHistory,
    savedHistory,
    impressionsHistory,
    engagementHistory,
    engagementRateHistory,
    profileViewsHistory,
    websiteClicksHistory
  },
  displayedCharts
}: SeriesAndCategoriesType) => {
  // We are creating data-sets based on Period which is represented by followersHistory, if no data for some day we will replace it with 0
  const { count: followersCount, date: followersCategories } = formatLineChartDateCount(followersHistory);
  const { count: postsCount } = formatDateCount(followersHistory, postsHistory);
  const { count: reachCount } = formatDateCount(followersHistory, reachHistory);
  const { count: likesCount } = formatDateCount(followersHistory, likesHistory);
  const { count: commentsCount } = formatDateCount(followersHistory, commentsHistory);
  const { count: savedCount } = formatDateCount(followersHistory, savedHistory);
  const { count: impressionsCount } = formatDateCount(followersHistory, impressionsHistory);
  const { count: engagementCount } = formatDateCount(followersHistory, engagementHistory);
  const { count: engagementRateCount } = formatDateCount(followersHistory, engagementRateHistory);
  const { count: profileViewsCount } = formatDateCount(followersHistory, profileViewsHistory);
  const { count: websiteClicksCount } = formatDateCount(followersHistory, websiteClicksHistory);

  const dateCategories = followersCategories;

  const series = [
    {
      name: 'Follower',
      type: 'line',
      data: followersCount,
      color: colors.Follower,
      marker: {
        symbol: 'circle',
        lineWidth: 2,
        lineColor: undefined, // inherit from chart
        fillColor: '#fff'
      },
      visible: displayedCharts.includes('Follower'),
      zIndex: 1
    },
    {
      name: 'Post',
      type: 'column',
      data: postsCount,
      color: colors.Post,
      yAxis: 1,
      visible: displayedCharts.includes('Post')
    },
    {
      name: 'Like',
      type: 'column',
      data: likesCount,
      color: colors.Like,
      yAxis: 2,
      visible: displayedCharts.includes('Like')
    },
    {
      name: 'Reach',
      type: 'column',
      data: reachCount,
      color: colors.Reach,
      yAxis: 3,
      visible: displayedCharts.includes('Reach')
    },
    {
      name: 'Comment',
      type: 'column',
      data: commentsCount,
      color: colors.Comment,
      yAxis: 4,
      visible: displayedCharts.includes('Comment')
    },
    {
      name: 'Save',
      type: 'column',
      data: savedCount,
      color: colors.Save,
      yAxis: 5,
      visible: displayedCharts.includes('Save')
    },
    {
      name: 'Impression',
      type: 'column',
      data: impressionsCount,
      color: colors.Impression,
      yAxis: 6,
      visible: displayedCharts.includes('Impression')
    },
    {
      name: 'Engagement',
      type: 'column',
      data: engagementCount,
      color: colors.Engagement,
      yAxis: 7,
      visible: displayedCharts.includes('Engagement')
    },
    {
      name: 'Engagement rate',
      type: 'column',
      data: engagementRateCount,
      color: colors['Engagement rate'],
      yAxis: 8,
      visible: displayedCharts.includes('Engagement rate')
    },
    {
      name: 'Profile views',
      type: 'column',
      data: profileViewsCount,
      color: colors['Profile views'],
      yAxis: 9,
      visible: displayedCharts.includes('Profile views')
    },
    {
      name: 'Website Click',
      type: 'column',
      data: websiteClicksCount,
      color: colors['Website Click'],
      yAxis: 10,
      visible: displayedCharts.includes('Website Click')
    }
  ];
  const tooltipData = getDailyDataForTooltip(dateCategories, series);

  return {
    series: series as Highcharts.SeriesOptionsType[],
    categories: dateCategories,
    tooltipData
  };
};
