import * as yup from 'yup';
import { PasswordConfirmSchema, PasswordSchema } from './password';
import {
  AdvertiserIdSchema,
  AdvertiserIdsSchema,
  CountrySchema,
  EmailSchema,
  NameSchema,
  UserTypeSchema
} from './share';

// User Schema
export default yup.object().shape({
  userType: UserTypeSchema,
  advertiserId: AdvertiserIdSchema,
  advertiserIds: AdvertiserIdsSchema,
  name: NameSchema,
  email: EmailSchema,
  countryId: CountrySchema,
  password: yup.string().when('shouldSendInvitation', {
    is: false,
    then: PasswordSchema
  }),
  passwordConfirm: yup.string().when('shouldSendInvitation', {
    is: false,
    then: PasswordConfirmSchema
  })
});
