import * as yup from 'yup';
import { PhoneNumberSchema } from './advertiser';
import { EmailSchema } from './share';

// SignUp Schema
export default yup.object().shape({
  email: EmailSchema,
  companyName: yup.string().required(),
  companyUrl: yup.string().required(),
  phoneNumber: PhoneNumberSchema.required(),
  countryId: yup.string().required(),
  name: yup.string().required()
});
