import { useLocation, useParams } from 'react-router';
import { useQueryHelper } from '../../libs/hooks';
import { QuerySocialAccountType } from '../../__generated__/globalTypes';

type ProviderType = 'twitter' | 'youtube' | 'facebook' | '';
export const getSocialAccountType = (provider: ProviderType): QuerySocialAccountType => {
  switch (provider) {
    case 'twitter':
      return QuerySocialAccountType.TWITTER;
    case 'youtube':
      return QuerySocialAccountType.YOUTUBE;
    case 'facebook':
      return QuerySocialAccountType.FACEBOOK;

    default:
      // normally this never happen, if no provider in url we can't get on this page
      return QuerySocialAccountType.FACEBOOK;
  }
};

export const useProviderResponse = () => {
  const { search } = useLocation();
  const { provider = '' } = useParams<{ provider: ProviderType }>();
  const { t, history, enqueueSnackbar } = useQueryHelper();

  const response = search.substr(1);

  if (!response) {
    enqueueSnackbar(t('Authentication attempt fail, please try again'), { variant: 'error' });
    history.push('/analytics');
  }

  return { provider: getSocialAccountType(provider as ProviderType), response };
};
