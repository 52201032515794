import { useState, useEffect } from 'react';
import { useRedirectUrl } from '../../../../libs/hooks/useRedirectUrl';
import { AnalyticsAuthSocialAccountType, AnalyticsRedirectType } from '../../../../__generated__/globalTypes';
import { manageLocalStorageItem } from '../../../../libs/localStorage';
import { useQueryHelper } from '../../../../libs/hooks';
import { AccountListAnalyticsForAdminV2_allAccountAnalyticsForAdminV2_accounts } from './__generated__/AccountListAnalyticsForAdminV2';

interface IgReconnectAccount {
  businessAccountId: string;
  accountId: string;
  accountName: string;
  fbPageId: string | null;
}
interface YtReconnectAccount {
  channelId: string;
  channelName: string;
}
interface ReconnectAccountState {
  igAccount: IgReconnectAccount;
  ytAccount: YtReconnectAccount;
  reconnectSuccessType: AnalyticsAuthSocialAccountType | '';
  openedReconnectAccountType: AnalyticsAuthSocialAccountType | '';
}
export const defaultState: ReconnectAccountState = {
  igAccount: {
    businessAccountId: '',
    accountId: '',
    accountName: '',
    fbPageId: ''
  },
  ytAccount: { channelId: '', channelName: '' },
  reconnectSuccessType: '',
  openedReconnectAccountType: ''
};
export interface ReconnectAccountOuterProps {
  setReconnectIgOpen: (igAccount: IgReconnectAccount) => void;
  setReconnectYtOpen: (ytAccount: YtReconnectAccount) => void;
  openedReconnectAccountType: AnalyticsAuthSocialAccountType | '';
  onReconnectClick: (socialMedia: AnalyticsAuthSocialAccountType | '') => Promise<void>;
  loading: boolean;
  connectedIgAccountName: string;
  reconnectSuccessType: AnalyticsAuthSocialAccountType | '';
  clearReconnectState: () => void;
  connectedFbPageName: string;
  chosenYtAccount: YtReconnectAccount;
}
interface ReconnectAccountsInnerProps {
  accounts: AccountListAnalyticsForAdminV2_allAccountAnalyticsForAdminV2_accounts[];
}
export const useReconnectAccountFromList = ({ accounts }: ReconnectAccountsInnerProps): ReconnectAccountOuterProps => {
  const [
    {
      igAccount: { businessAccountId, accountId, accountName, fbPageId },
      ytAccount: { channelId, channelName },
      reconnectSuccessType,
      openedReconnectAccountType
    },
    setReconnectState
  ] = useState<ReconnectAccountState>(defaultState);

  const { enqueueSnackbar, t, history } = useQueryHelper();
  const { getRedirectUrl, setLoading, loading } = useRedirectUrl(AnalyticsRedirectType.ANALYTICS_LIST_CONNECT);

  const onReconnectClick = async (socialMedia: AnalyticsAuthSocialAccountType | '') => {
    if (!socialMedia) {
      return;
    }

    setLoading(true);
    const { pathname, search } = history.location;
    try {
      const redirectUrl = await getRedirectUrl(socialMedia);
      if (redirectUrl) {
        // Redirect to SocialMedia
        location.href = redirectUrl;
        manageLocalStorageItem('set', 'redirectBackRoute', pathname + search);
        if (socialMedia === AnalyticsAuthSocialAccountType.FACEBOOK) {
          manageLocalStorageItem('set', 'businessAccountId', businessAccountId);
          manageLocalStorageItem('set', 'analyticsIgAccountId', accountId);
          manageLocalStorageItem('set', 'analyticsIgAccountName', accountName);
          manageLocalStorageItem('set', 'fbPageId', fbPageId || '');
        } else if (socialMedia === AnalyticsAuthSocialAccountType.YOUTUBE) {
          manageLocalStorageItem('set', 'channelId', channelId || '');
        }
      }
    } catch (error) {
      enqueueSnackbar(t('UnexpectedError'), { variant: 'error' });
    } finally {
      setReconnectState(defaultState);
      setLoading(false);
    }
  };

  const setReconnectIgOpen = (igAccount: IgReconnectAccount) => {
    setReconnectState(prevState => ({
      ...prevState,
      igAccount,
      openedReconnectAccountType: AnalyticsAuthSocialAccountType.FACEBOOK
    }));
  };
  const setReconnectYtOpen = (ytAccount: YtReconnectAccount) => {
    setReconnectState(prevState => ({
      ...prevState,
      ytAccount,
      openedReconnectAccountType: AnalyticsAuthSocialAccountType.YOUTUBE
    }));
  };

  useEffect(() => {
    const reconnectStatus = manageLocalStorageItem('get', 'reconnectStatus');

    if (reconnectStatus === 'success') {
      const businessIgAccountIdFromStore = manageLocalStorageItem('get', 'businessAccountId') || '';
      const channelIdFromStore = manageLocalStorageItem('get', 'channelId') || '';
      const reconnectType = businessIgAccountIdFromStore
        ? AnalyticsAuthSocialAccountType.FACEBOOK
        : AnalyticsAuthSocialAccountType.YOUTUBE;

      setReconnectState(prevState => ({
        ...prevState,
        igAccount: {
          ...prevState.igAccount,
          businessAccountId: businessIgAccountIdFromStore
        },
        ytAccount: {
          ...prevState.ytAccount,
          channelId: channelIdFromStore
        },
        reconnectSuccessType: reconnectType
      }));
      manageLocalStorageItem('remove', 'reconnectStatus');
    }
  }, []);

  const clearReconnectState = () => {
    setReconnectState(defaultState);
    manageLocalStorageItem('remove', 'businessAccountId');
    manageLocalStorageItem('remove', 'analyticsIgAccountId');
    manageLocalStorageItem('remove', 'analyticsIgAccountName');
    manageLocalStorageItem('remove', 'fbPageId');
  };

  const chosenConnectedAccount = accounts
    ? accounts.find(acc => acc.socialMediaAccounts.instagram?.businessAccountId === businessAccountId)
    : undefined;
  const chosenIgAccont = chosenConnectedAccount?.socialMediaAccounts.instagram;
  const connectedIgAccountName = chosenIgAccont?.instagramAccountName || 'IG account name';
  const connectedFbPageName = chosenIgAccont?.fbPageName || 'FB account name';
  const chosenConnectedYtAccount = accounts
    ? accounts.find(acc => acc.socialMediaAccounts.youtube?.channelId === channelId)
    : undefined;
  const chosenYTChannelName = chosenConnectedYtAccount?.socialMediaAccounts.youtube?.channelName;

  return {
    setReconnectIgOpen,
    setReconnectYtOpen,
    openedReconnectAccountType,
    onReconnectClick,
    loading,
    connectedIgAccountName,
    reconnectSuccessType,
    clearReconnectState,
    connectedFbPageName,
    chosenYtAccount: { channelId, channelName: chosenYTChannelName || channelName }
  };
};
