import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { css, SerializedStyles } from '@emotion/core';
import { PalleteButton } from '../../atoms/Button';
import { mainBlue } from '../../../libs/pallete';
import compareUpgradePlan from '../../../assets/img/compareUpgradePlan.jpg';
import hashtagUpgradePlan from '../../../assets/img/hashtagUpgradePlan.jpg';
import interactionUpgradePlan from '../../../assets/img/interactionUpgradePlan.jpg';
import influencerRed from '../../../assets/icon/influencerRed.svg';
import hashtag from '../../../assets/icon/hashtag.svg';
import interaction from '../../../assets/icon/interactionTrial.svg';

type PageType = 'compare' | 'hastag' | 'interaction';
interface ContentType {
  title: string;
  icon: string;
  upgradePlanImg: string;
  description: string[];
  benefits: string[];
  benefitsCss?: SerializedStyles;
}
const pageContent: Record<PageType, ContentType> = {
  compare: {
    title: 'Comparison function',
    icon: influencerRed,
    upgradePlanImg: compareUpgradePlan,
    description: [
      'Analyze and compare competitor’s social media accounts with your own',
      'If you upgrade, you can check the following data!'
    ],
    benefits: [
      'Percentage increase in followers',
      'Differences in follower attributes (Gender ratio, Age ratio)',
      'Comparison of positive comment rates from users',
      'Post comparison',
      'Relationship between posting times and engagement rates'
    ]
  },
  hastag: {
    title: 'Hash Tag function',
    icon: hashtag,
    upgradePlanImg: hashtagUpgradePlan,
    description: [
      'You can compare the popularity of registered hashtags',
      'If you upgrade, you can check the following data!'
    ],
    benefits: [
      'Correlation between number of posts with hashtags and increase in followers',
      'Popularity of competitor hashtags',
      'Monitor competitors’ campaign execution based on growth rate for competitor’s posts with hashtags'
    ],
    benefitsCss: css`
      margin-bottom: 16px;
    `
  },
  interaction: {
    title: 'Interaction function',
    icon: interaction,
    upgradePlanImg: interactionUpgradePlan,
    description: ['Enable follower analysis of your own accounts', 'If you upgrade, you can check the following data!'],
    benefits: [
      'Total number of follower posts that tagged or mentioned your company',
      'Tagged/mentions list in order of number of posts',
      'Tagged/mentioned post content'
    ],
    benefitsCss: css`
      margin-bottom: 16px;
    `
  }
};
export const youtubeContent = {
  title: 'YouTube Dashboard coming soon!',
  icon: influencerRed,
  description:
    'Understand follower increases, analyze follower demographics, and find trends of posts that performed well!',
  benefits: [
    'Daily metrics such as views and likes',
    'Number of subscribers before and after campaigns',
    'Post analytics, including trends for video with high engagement rates and views',
    'Follower metrics such as gender, age, location'
  ],
  descrCss: css`
    margin-top: 16px;
  `
};

interface UpgradeToPremiumModalProps {
  page: 'compare' | 'hastag' | 'interaction';
  hasUpgradeButton?: boolean;
}
export const UpgradeToPremiumModal = ({ page }: UpgradeToPremiumModalProps) => {
  const { t } = useTranslation();
  const { title, icon, description, benefits, benefitsCss } = pageContent[page];

  return (
    <UpgradeModalWrapper>
      <UpgradePlanImg src={compareUpgradePlan} />
      <RightPane>
        <ContentSection>
          <Header>
            <HeaderIcon src={icon} />
            <HeaderText>{t(title)}</HeaderText>
          </Header>
          <Description>
            {t(description[0])}
            <br />
            {t(description[1])}
          </Description>
          <PremiumBenefits>
            {benefits.map(text => (
              <BenefitItem key={text} css={benefitsCss}>
                <RedDot />
                {t(text)}
              </BenefitItem>
            ))}
          </PremiumBenefits>
        </ContentSection>
        <UpgradeButtonSection>
          <StyledUpdateBtn
            text="Upgrade"
            palleteColor={mainBlue}
            height="32px"
            width="max-content"
            borderRadius="3px"
            href="/subscription"
          />
        </UpgradeButtonSection>
      </RightPane>
    </UpgradeModalWrapper>
  );
};

export const YouTubeDashboardModal = () => {
  const { t } = useTranslation();
  const { benefits, description, descrCss } = youtubeContent;

  return (
    <>
      <Description css={descrCss}>{t(description)}</Description>
      <PremiumBenefits>
        {benefits.map(text => (
          <BenefitItem key={text}>
            <RedDot />
            {t(text)}
          </BenefitItem>
        ))}
      </PremiumBenefits>
    </>
  );
};

const UpgradeModalWrapper = styled.div`
  position: fixed;
  top: 250px;
  right: calc(50vw - 340px);
  display: flex;
  width: 680px;
  height: 440px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.24), 0 0 12px 0 rgba(0, 0, 0, 0.12);
`;
const UpgradePlanImg = styled.img`
  width: 300px;
  height: 100%;
  border-radius: 3px 0 0 3px;
`;
const RightPane = styled.div`
  display: flex;
  flex-direction: column;
`;
const Header = styled.p`
  display: flex;
  padding: 10px 0;
  align-items: center;
`;
const HeaderIcon = styled.img`
  width: 32px;
  height: 32px;
`;
const ContentSection = styled.div`
  padding: 32px;
  color: #27313b;
`;
const HeaderText = styled.span`
  font-size: 20px;
  padding-left: 8px;
`;
const Description = styled.p`
  font-size: 13px;
  line-height: 1.38;
`;
const PremiumBenefits = styled.ul`
  margin-top: 24px;
`;
const BenefitItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding-left: 12px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.38;
`;
const RedDot = styled.div`
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: #ff2b52;
  border-radius: 50%;
  top: 6px;
  left: 0;
`;
const UpgradeButtonSection = styled.div`
  margin-top: auto;
  border-top: 1px solid #dee5ec;
  padding: 16px;
`;
const StyledUpdateBtn = styled(PalleteButton)`
  min-width: 110px;
  margin-left: auto;
`;
