import styled from '@emotion/styled';
import React from 'react';
import css from '@emotion/css';
import { IconButton } from '../Button';
import { OpacityContentWrapper } from '../../organisms/AnalyticsShared/SharedComponents';

interface PopularHashtagsColumnProps {
  className?: string;
  hashtags: Array<string | null>;
  onClickAdd: () => void;
  isTable?: boolean;
}

const PopularHashtagsColumn = (props: PopularHashtagsColumnProps) => {
  const { className, hashtags, onClickAdd, isTable = true } = props;
  const formattedHastags = hashtags.length ? hashtags.slice(0, 4) : [];
  const noHashtagsData = !formattedHastags.length;

  const Wrapper = isTable ? TableElement : DivElement;

  return (
    <Wrapper className={className}>
      <FlexWrapper>
        <TableData>
          <HashtagsWrapper>
            <StyledBlurOpacityWrapper
              header=""
              dataNotAvailable={noHashtagsData}
              css={
                noHashtagsData
                  ? css`
                      zoom: 0.5;
                    `
                  : css`
                      flex-direction: row;
                    `
              }
            >
              <Container>
                {formattedHastags.map((item, index) => (
                  <Hashtag key={index} title={item || ''}>
                    {item}
                  </Hashtag>
                ))}
              </Container>
              {!noHashtagsData && <AddButton icon="add" color="#6e7c89" handleClick={onClickAdd} />}
            </StyledBlurOpacityWrapper>
          </HashtagsWrapper>
        </TableData>
      </FlexWrapper>
    </Wrapper>
  );
};

const DivElement = styled.div`
  padding: 16px;

  &:not(:last-of-type) {
    border-right: 1px solid #dee5ec;
  }
`;
const TableElement = styled(DivElement.withComponent('td'))``;
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
const TableData = styled.div`
  display: inline-block;
  font-size: 13px;
  line-height: 16px;
  width: 100%;
`;
const Hashtag = styled.p`
  height: 20px;
  line-height: 20px;
  font-size: 13px;
  color: #27313b;
  margin-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const HashtagsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Container = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 6px;
`;
const AddButton = styled(IconButton)`
  border: 1px solid #dee5ec;
  height: 32px;
  width: 32px;
  align-self: center;
`;
const StyledBlurOpacityWrapper = styled(OpacityContentWrapper)`
  border-radius: 0;
  box-shadow: none;
  width: 100%;
`;

export default PopularHashtagsColumn;
