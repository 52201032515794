import { useApolloClient } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { AdminStore } from '../../../Context';
import * as GET_AGENCY_LOGO from './GetAgencyLogo.graphql';
import { GetAgencyLogoVariables, GetAgencyLogo } from './__generated__/GetAgencyLogo';

export const useLogo = () => {
  const client = useApolloClient();
  const {
    state: { hash: hashFromStore }
  } = useContext(AdminStore);
  const { search } = useLocation();
  const [agencylogoUrl, setAgencyLogoUrl] = useState<string | null>(null);
  const [loadingLogo, setLoadingLogo] = useState<boolean>(true);
  const hashFromUrl = new URLSearchParams(search).get('hash');

  const fetchAgencyLogo = async () => {
    const { data } = await client.query<GetAgencyLogo, GetAgencyLogoVariables>({
      query: GET_AGENCY_LOGO,
      variables: { hash: hashFromStore || hashFromUrl || '' }
    });

    setAgencyLogoUrl(data?.agencyLogo?.logoUrl || '');
    setLoadingLogo(false);
  };
  // Query Agency Logo
  useEffect(() => {
    if (!!hashFromStore || !!hashFromUrl) {
      fetchAgencyLogo();
    }
    if (!hashFromUrl && !hashFromStore) {
      setLoadingLogo(false);
    }
  }, [hashFromStore]);

  return {
    agencylogoUrl,
    loadingLogo
  };
};
