import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { TitleWithHelp } from '../../AnalyticsShared/AnalyticsContentWrapper';
import commentIcon from '../../../../assets/icon/comment.svg';
import heartIcon from '../../../../assets/icon/heart.svg';
import bookmarkIcon from '../../../../assets/icon/bookmark.svg';
import pictureIcon from '../../../../assets/icon/picture.svg';
import { InstagramDashboardPostAnalytics_instagramDashboardPostAnalytics_averageEngagement } from '../AnalyticsPosts/__generated__/InstagramDashboardPostAnalytics';
import { formatNumber } from '../../../../libs/format';
import { getGrowthArrow, getGrowthPrefix } from '../../AnalyticsShared/utils';
import { OpacityContentWrapper } from '../../AnalyticsShared/SharedComponents';

const AverageEngagement = (
  props: InstagramDashboardPostAnalytics_instagramDashboardPostAnalytics_averageEngagement
) => {
  const { t } = useTranslation();
  const {
    averagePostsPerWeek,
    averagePostsGrowth,
    averageLikes,
    averageLikesGrowth,
    averageSaved,
    averageSavedGrowth,
    averageComments,
    averageCommentsGrowth
  } = props;

  return (
    <OpacityContentWrapper
      header={<MainTitle help="averageEngagement" title="Average Engagement" />}
      dataNotAvailable={!averagePostsPerWeek && !averageLikes && !averageSaved && !averageComments}
    >
      <MainWrapper>
        <EngagementDataContainer>
          <EngagementDataBlock>
            <Img src={pictureIcon} />
            <Label>{t('Avg posts/week')}</Label>
            <Value>{formatNumber(averagePostsPerWeek, 2)}</Value>
            {!!averagePostsGrowth && (
              <Identifier isPositive={+averagePostsGrowth > 0}>{`${getGrowthPrefix(averagePostsGrowth)}${formatNumber(
                averagePostsGrowth,
                2
              )}  ${getGrowthArrow(averagePostsGrowth)}`}</Identifier>
            )}
          </EngagementDataBlock>
          <EngagementDataBlock>
            <Img src={heartIcon} />
            <Label>{t('Avg Likes')}</Label>
            <Value>{formatNumber(averageLikes, 2)}</Value>
            {!!averageLikesGrowth && (
              <Identifier isPositive={Number(averageLikesGrowth) > 0}>{`${getGrowthPrefix(
                averageLikesGrowth
              )}${formatNumber(averageLikesGrowth, 2)}  ${getGrowthArrow(averageLikesGrowth)}`}</Identifier>
            )}
          </EngagementDataBlock>
        </EngagementDataContainer>
        <EngagementDataContainer>
          <EngagementDataBlock>
            <Img src={bookmarkIcon} />
            <Label>{t('Avg Saves')}</Label>
            <Value>{formatNumber(averageSaved, 2)}</Value>
            {!!averageSavedGrowth && (
              <Identifier isPositive={Number(averageSavedGrowth) > 0}>{`${getGrowthPrefix(
                averageSavedGrowth
              )}${formatNumber(averageSavedGrowth, 2)}  ${getGrowthArrow(averageSavedGrowth)}`}</Identifier>
            )}
          </EngagementDataBlock>
          <EngagementDataBlock>
            <Img src={commentIcon} />
            <Label>{t('Avg Comments')}</Label>
            <Value>{formatNumber(averageComments, 2)}</Value>
            {!!averageCommentsGrowth && (
              <Identifier isPositive={Number(averageCommentsGrowth) > 0}>{`${getGrowthPrefix(
                averageCommentsGrowth
              )}${formatNumber(averageCommentsGrowth, 2)}  ${getGrowthArrow(averageCommentsGrowth)}`}</Identifier>
            )}
          </EngagementDataBlock>
        </EngagementDataContainer>
      </MainWrapper>
    </OpacityContentWrapper>
  );
};

const MainTitle = styled(TitleWithHelp)`
  margin: 22px 16px 16px;
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const EngagementDataContainer = styled.div`
  display: flex;
  height: 50%;
`;
const EngagementDataBlock = styled.div`
  flex: 1;
  border-top: 1px solid #e6ecf0;
  border-right: 1px solid #e6ecf0;
  display: flex;
  flex-direction: column;
  padding-left: 16px;

  &:last-child {
    border-right-style: none;
  }
`;
const Label = styled.p`
  font-size: 11px;
  font-weight: 600;
  color: #27313b;
  margin-bottom: 4px;
`;
const Value = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: #27313b;
  margin-bottom: 4px;
`;
const Identifier = styled.p<{ isPositive: boolean }>`
  font-size: 13px;
  font-weight: 600;
  color: ${({ isPositive }) => (isPositive ? '#40b87c' : '#ff2b52')};
`;
const Img = styled.img`
  align-self: flex-end;
`;

export default AverageEngagement;
