import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generateYAxisBasedOnDisplayedCharts,
  getBarDataBasedOnPointer,
  getEngagementDataWithHistory,
  formatDate
} from '../../AnalyticsShared/Overview/helpers';
import { getGrowthArrow, getGrowthPrefix } from '../../AnalyticsShared/utils';
import userpicIcon from '../../../../assets/icon/userpic.svg';
import pictureIcon from '../../../../assets/icon/picture.svg';
import thunderIcon from '../../../../assets/icon/thunder.svg';
import heartIcon from '../../../../assets/icon/heart.svg';
import chatIcon from '../../../../assets/icon/chat_green.svg';
import bookmarkIcon from '../../../../assets/icon/bookmark.svg';
// import userpicPlusIcon from '../../../../assets/icon/userpicPlus.svg';
import impressionsIcon from '../../../../assets/icon/impressions.svg';
import engagementIcon from '../../../../assets/icon/engagement_analytics.svg';
import engagementRateIcon from '../../../../assets/icon/engagementRate.svg';
import profileViewsIcon from '../../../../assets/icon/profileViews.svg';
import websiteClicksIcon from '../../../../assets/icon/websiteClicks.svg';
import { InstagramDashboardOverview_instagramDashboardOverview } from './__generated__/InstagramDashboardOverview';
import { allSelectableCharts, ChartColorTypes, colors, getSeriesAndCategories } from './helpers';

export const getOptions = (
  displayedCharts: ChartColorTypes[],
  instagramDashboardOverview: InstagramDashboardOverview_instagramDashboardOverview,
  fetchDailyPosts: (date: string) => void,
  chosenSelectableCharts: ChartColorTypes[]
): {
  options: Highcharts.Options;
  engagement: Array<{ icon: string; title: string; total: number; growth: number; rate: number }>;
} => {
  const { t } = useTranslation();
  const [chartState, setChartState] = useState<Highcharts.Chart | undefined>(undefined);
  const {
    followers,
    posts,
    reach,
    likes,
    comments,
    saved,
    impressions,
    engagement,
    engagementRate,
    profileViews,
    websiteClicks
  } = instagramDashboardOverview;
  const { history: followersHistory, engagement: followersEngagement } = getEngagementDataWithHistory(followers);
  const { history: postsHistory, engagement: postsEngagement } = getEngagementDataWithHistory(posts);
  const { history: reachHistory, engagement: reachEngagement } = getEngagementDataWithHistory(reach);
  const { history: likesHistory, engagement: likesEngagement } = getEngagementDataWithHistory(likes);
  const { history: commentsHistory, engagement: commentsEngagement } = getEngagementDataWithHistory(comments);
  const { history: savedHistory, engagement: savedEngagement } = getEngagementDataWithHistory(saved);
  const { history: impressionsHistory, engagement: impressionsEngagement } = getEngagementDataWithHistory(impressions);
  const { history: engagementHistory, engagement: engagementEngagement } = getEngagementDataWithHistory(engagement);
  const { history: engagementRateHistory, engagement: engagementRateEngagement } = getEngagementDataWithHistory(
    engagementRate
  );
  const { history: profileViewsHistory, engagement: profileViewsEngagement } = getEngagementDataWithHistory(
    profileViews
  );
  const { history: websiteClicksHistory, engagement: websiteClicksEngagement } = getEngagementDataWithHistory(
    websiteClicks
  );

  // we are showing X-Axis dates based on displayed charts checkboxes
  const { series, categories, tooltipData } = getSeriesAndCategories({
    historyData: {
      followersHistory,
      postsHistory,
      reachHistory,
      likesHistory,
      commentsHistory,
      savedHistory,
      impressionsHistory,
      engagementHistory,
      engagementRateHistory,
      profileViewsHistory,
      websiteClicksHistory
    },
    displayedCharts
  });
  const yAxis = generateYAxisBasedOnDisplayedCharts<ChartColorTypes>(allSelectableCharts, false);

  return {
    engagement: [
      {
        icon: userpicIcon,
        title: 'Follower (Total)',
        ...followersEngagement
      },

      { icon: pictureIcon, title: 'Post', ...postsEngagement },
      { icon: thunderIcon, title: 'Reach', ...reachEngagement },
      { icon: heartIcon, title: 'Like', ...likesEngagement },
      { icon: chatIcon, title: 'Comment', ...commentsEngagement },
      { icon: bookmarkIcon, title: 'Save', ...savedEngagement },
      // ...(chosenSelectableCharts.includes('Follow')
      //   ?
      //     [{ icon: userpicPlusIcon, title: 'Follow', ...followersEngagement }]
      //   : []),
      ...(chosenSelectableCharts.includes('Impression')
        ? [{ icon: impressionsIcon, title: 'Impression', ...impressionsEngagement }]
        : []),
      ...(chosenSelectableCharts.includes('Engagement')
        ? [{ icon: engagementIcon, title: 'Engagement', ...engagementEngagement }]
        : []),
      ...(chosenSelectableCharts.includes('Engagement rate')
        ? [{ icon: engagementRateIcon, title: 'Engagement rate', ...engagementRateEngagement }]
        : []),
      ...(chosenSelectableCharts.includes('Profile views')
        ? [{ icon: profileViewsIcon, title: 'Profile views', ...profileViewsEngagement }]
        : []),
      ...(chosenSelectableCharts.includes('Website Click')
        ? [{ icon: websiteClicksIcon, title: 'Website Click', ...websiteClicksEngagement }]
        : [])
    ],
    options: {
      chart: {
        type: 'column',
        events: {
          // eslint-disable-next-line object-shorthand
          click: function(e) {
            const selectedPoint = getBarDataBasedOnPointer(this, e, [colors.Post]);
            // if Y-axis value is other than 0, means that there are some posts on this day
            if (selectedPoint?.length && selectedPoint[0].y) {
              fetchDailyPosts(`${selectedPoint[0].category}`);
            }
          },
          // eslint-disable-next-line object-shorthand
          load: function() {
            setChartState(this);
          }
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories,
        crosshair: true,
        type: 'category',
        labels: {
          formatter: function formatter() {
            return formatDate(`${this.value}`);
          }
        }
      },
      yAxis,
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          cursor: 'pointer',

          point: {
            events: {
              // eslint-disable-next-line object-shorthand
              click: function() {
                if (colors.Post === this.color) {
                  fetchDailyPosts(`${this.category}`);
                }
              },
              // eslint-disable-next-line object-shorthand
              mouseOver: function() {
                if (tooltipData[this.category]?.Post.value !== '-') {
                  /* eslint-disable */
                  // @ts-ignore
                  chartState?.chartBackground?.htmlCss({ cursor: 'pointer' });
                }
              },
              mouseOut: () => {
                // @ts-ignore
                chartState?.chartBackground?.htmlCss({ cursor: 'auto' });
              }
            }
          }
        }
      },
      tooltip: {
        useHTML: true,
        borderColor: '#27313b',
        borderRadius: 3,
        outside: true,
        shadow: false,
        formatter: function formatter(): string {
          const followersIncrement = tooltipData[this.key].Follower.increment;
          const incrementArrow = getGrowthArrow(followersIncrement);
          const prefix = getGrowthPrefix(followersIncrement);

          const incrementSection = followersIncrement
            ? `<span style="color: ${
                followersIncrement > 0 ? '#40b87c' : '#ff2b52'
              };">${prefix} ${followersIncrement} ${incrementArrow}</span>`
            : '';

          return `
          <div style="display:flex;flex-direction:column;border-radius:3px;border: 2px solid #27313b;margin:-2px;background-color:#27313b;font-size:12px;padding:13px 16px;">
            <h3 style="margin-bottom:8px; font-size: 13px; color: #fff;">${this.key}</h3>
            <div style="display:flex; flex-direction: column;">
              <div style="display: flex;">
                <div style="display: flex; flex: 1; width: max-content; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Follower')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Follower.value}
                      ${incrementSection}
                    </p>
                  </div>
                </div>
  
                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Post')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Post.value}
                    </p>
                  </div>
                </div>
  
                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Reach')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Reach.value}
                    </p>
                  </div>
                </div>
              </div>
  
              <div style="display: flex; margin-top: 12px;">
                <div style="display: flex; flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Like')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Like.value}
                    </p>
                  </div>
                </div>
  
                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Comment')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Comment.value}
                    </p>
                  </div>
                </div>
  
                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Save')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Save.value}
                    </p>
                  </div>
                </div>
              </div>

              <div style="display: flex; margin-top: 12px;">
                <div style="display: flex; flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Impression')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Impression.value}
                    </p>
                  </div>
                </div>

                <div style="display: flex; flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Engagement')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key].Engagement.value}
                    </p>
                  </div>
                </div>

                <div style="display: flex; flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Engagement rate')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key]['Engagement rate'].value}
                    </p>
                  </div>
                </div>
              </div>

              <div style="display: flex; margin-top: 12px; justify-content: space-between;">
                <div style="display: flex; flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Profile views')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key]['Profile views'].value}
                    </p>
                  </div>
                </div>

                <div style="flex: 1;"></div>
 
                <div style="display: flex; flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Website Click')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key]['Website Click'].value}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>`;
        },
        pointFormat: ''
      },
      series
    }
  };
};
