import css from '@emotion/css';
import { getOffset } from '../../../../libs/paging';
import { LIMIT } from '../../../../libs/paging/constant';
import { InstagramStoryPostSortField } from '../../../../__generated__/globalTypes';
import { InstagramStoryPostListProps } from './index';

export const allInstagramStoryPostsVariables = (props: InstagramStoryPostListProps) => {
  const {
    currentPage,
    filter: { keyword, period },
    sort: { field, order },
    accountId
  } = props;

  return {
    accountId,
    offset: getOffset(currentPage),
    limit: LIMIT,
    keyword,
    orderBy: { field, order },
    startDate: period.startDate,
    endDate: period.endDate
  };
};

export const getAllStoryPostsHeaders = () => [
  {
    title: 'Impression',
    help: 'ImpsHelp',
    orderField: InstagramStoryPostSortField.IMPRESSIONS,
    columnName: 'impressions'
  },
  {
    title: 'Reach',
    help: 'ReachPostHelp',
    orderField: InstagramStoryPostSortField.REACH,
    columnName: 'reach'
  },
  {
    title: 'Comment',
    help: 'CommentHelp',
    orderField: InstagramStoryPostSortField.COMMENT,
    columnName: 'comment'
  },
  {
    title: 'Exits',
    help: 'ExitsHelp',
    orderField: InstagramStoryPostSortField.EXITS,
    columnName: 'exits'
  },
  {
    title: 'Tap Forward',
    help: 'TapForward',
    orderField: InstagramStoryPostSortField.TAPS_FORWARD,
    columnName: 'tapForward'
  },
  {
    title: 'Tap Back',
    help: 'TapBack',
    orderField: InstagramStoryPostSortField.TAPS_BACK,
    columnName: 'tapBack'
  },
  {
    title: 'Post date',
    help: 'PostDateHelp',
    orderField: InstagramStoryPostSortField.POST_DATE,
    columnName: 'postDate',
    css: css`
      min-width: 95px;
    `
  }
];
