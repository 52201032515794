import styled from '@emotion/styled';
import * as React from 'react';
import Footer from '../../components/organisms/Footer';
import SignInForm from '../../components/organisms/SignIn/SignInForm';
import SignInSignUpHeader from '../../components/organisms/SignIn/SignInSignUpHeader';

const Template = () => (
  <>
    <SignInSignUpHeader />
    <Container>
      <Wrapper>
        <SignInForm />
      </Wrapper>
      <FooterWrapper>
        <Footer padding={'24px 0'} />
      </FooterWrapper>
    </Container>
  </>
);

const Container = styled.div`
  padding-top: 40px;
  background-color: #f6f8fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.section`
  display: flex;
  align-content: center;
  width: 856px;
  margin: auto;
  border-radius: 3px;
  box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: relative;
`;

const FooterWrapper = styled.div`
  display: flex;
  align-content: center;
  width: 856px;
  margin: auto;
  position: relative;
`;

export default Template;
