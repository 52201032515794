import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COUNTRY_KEY } from '../../languages/translateCountry';
import { Option } from '../types';

export const useTranslateCountryOptions = (OPTIONS: Option[]): Option[] => useTranslateOptions(OPTIONS, COUNTRY_KEY);

// Country Translation Function
export const useTranslateCountry = () => {
  const { t } = useTranslation();
  const tc = (value: string) => t(`${COUNTRY_KEY}.${value.includes('.') ? value.replace(/[.]/g, '') : value}`);

  return { tc };
};

const useTranslateOptions = (OPTIONS: Option[], key = 'Option'): Option[] => {
  const [allOption, setAllOption] = useState(OPTIONS);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setAllOption(
      OPTIONS.map(option => ({
        ...option,
        label: t(`${key}.${option.label.includes('.') ? option.label.replace(/[.]/g, '') : option.label}`)
      }))
    );
  }, [i18n.language, i18n.isInitialized]);

  return allOption;
};

export default useTranslateOptions;
