import styled from '@emotion/styled';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface LinkButtonProps extends AnchorProps {
  className?: string;
  href: LocationDescriptor;
  icon?: string;
  title: string;
}

const LinkButton = (props: LinkButtonProps) => {
  const { className, href, icon, title, color, bgcolor, hoverbgcolor } = props;

  return (
    <Anchor className={className} to={href} color={color} bgcolor={bgcolor} hoverbgcolor={hoverbgcolor}>
      {icon ? <Icon className="material-icons">{icon}</Icon> : null}
      <Title>{title.toUpperCase()}</Title>
    </Anchor>
  );
};

interface AnchorProps {
  color?: string;
  bgcolor?: string;
  hoverbgcolor?: string;
  activeColor?: string;
}

const Anchor = styled(Link)<AnchorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 32px;
  background-color: ${({ bgcolor }) => bgcolor || '#00ac56'};
  border-radius: 3px;
  color: ${({ color }) => color || '#fff'};
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
  box-sizing: border-box;

  &:hover {
    background-color: ${({ hoverbgcolor }) => hoverbgcolor || '#ff5575'};
  }

  &:active {
    background-color: ${({ activeColor }) => activeColor || '#cc2241'};
  }
`;

const Icon = styled.i`
  margin-right: 4px;
  font-size: 16px;
`;

const Title = styled.p`
  font-weight: 600;
`;

export default LinkButton;
