import { InstagramInteractionUserSortField } from '../../../__generated__/globalTypes';

export enum UserAnalysisSortFiled {
  POST = 'POST',
  LIKE = 'LIKE',
  COMMENT = 'COMMENT',
  TAGGED_POST = 'TAGGED_POST',
  MENTIONED_POST = 'MENTIONED_POST',
  TAGGED_LIKE = 'TAGGED_LIKE',
  MENTIONED_LIKE = 'MENTIONED_LIKE',
  TAGGED_COMMENT = 'TAGGED_COMMENT',
  MENTIONED_COMMENT = 'MENTIONED_COMMENT'
}

export const getUserAnalysisSortFiledType = (field?: string | null) => {
  if (!field) {
    return null;
  }

  switch (field) {
    case 'POST':
      return InstagramInteractionUserSortField.POST;
    case 'LIKE':
      return InstagramInteractionUserSortField.LIKE;
    case 'COMMENT':
      return InstagramInteractionUserSortField.COMMENT;
    case 'TAGGED_POST':
      return InstagramInteractionUserSortField.TAGGED_POST;
    case 'MENTIONED_POST':
      return InstagramInteractionUserSortField.MENTIONED_POST;
    case 'TAGGED_LIKE':
      return InstagramInteractionUserSortField.TAGGED_LIKE;
    case 'MENTIONED_LIKE':
      return InstagramInteractionUserSortField.MENTIONED_LIKE;
    case 'TAGGED_COMMENT':
      return InstagramInteractionUserSortField.TAGGED_COMMENT;
    case 'MENTIONED_COMMENT':
      return InstagramInteractionUserSortField.MENTIONED_COMMENT;

    default:
      return null;
  }
};
