import React from 'react';
import UserAnalysisFilter from '../../components/organisms/AnalyticsShared/BasePostListFilter';
import { SortType } from '../../libs/filter';
import UserAnalysisList from '../../components/organisms/AnalyticsPost/UserAnalysisList';
import { InstagramInteractionUserSortField } from '../../__generated__/globalTypes';
import { UserAnalysisFilterType } from './types';

interface TemplateProps {
  currentPage: number;
  filter: UserAnalysisFilterType;
  setFilter: (filter: UserAnalysisFilterType) => void;
  sort: SortType<InstagramInteractionUserSortField>;
  setSort: (sort: SortType<InstagramInteractionUserSortField>) => void;
  id: number;
}
const Template = ({ filter, setFilter, currentPage, sort, setSort, id }: TemplateProps) => (
  <>
    <UserAnalysisFilter filter={filter} setFilter={setFilter} />
    <UserAnalysisList currentPage={currentPage} sort={sort} setSort={setSort} filter={filter} id={id} />
  </>
);

export default Template;
