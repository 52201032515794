import React from 'react';
import { InstagramCompareFeedPostListProps } from './types';
import { ComparableAccountFeedPosts } from './ComparableAccountFeedPost';
import { MainAccountFeedPosts } from './MainAccountFeedPost';

const InstagramCompareFeedPostListComponent = (props: InstagramCompareFeedPostListProps) => {
  const { accountId, accountToCompareWithId, selectedElementId } = props;

  if (accountId === accountToCompareWithId && selectedElementId === undefined) {
    return <MainAccountFeedPosts {...props} />;
  } else {
    return <ComparableAccountFeedPosts {...props} />;
  }
};

export default InstagramCompareFeedPostListComponent;
