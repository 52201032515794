import styled from '@emotion/styled';
import * as React from 'react';

interface TermsTextProps {
  className?: string;
  heading?: string;
  children: React.ReactNode;
}

const TermsText = (props: TermsTextProps) => {
  const { heading, children, className } = props;

  return (
    <Wrapper className={className}>
      {heading && <Heading>{heading}</Heading>}
      <Paragraph>{children}</Paragraph>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 40px;
`;

const Heading = styled.h3`
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #27313b;
  margin-bottom: 8px;
`;

const Paragraph = styled.p`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #27313b;
`;

export default TermsText;
