import styled from '@emotion/styled';
import { Icon } from '@material-ui/core';
import { addMonths } from 'date-fns';
import React, { forwardRef, Ref } from 'react';

export const Navbar = (props: any) => (
  <NavbarWrapper>
    <CurrentMonth>
      <StyledIcon className="material-icons" fontSize="small" onClick={() => props.onPreviousClick()}>
        keyboard_arrow_left
      </StyledIcon>
      <p>{props.localeUtils.formatMonthTitle(props.month)}</p>
    </CurrentMonth>
    <NextMonth>
      <p>{props.localeUtils.formatMonthTitle(addMonths(props.month, 1))}</p>
      <StyledIcon className="material-icons" fontSize="small" onClick={() => props.onNextClick()}>
        keyboard_arrow_right
      </StyledIcon>
    </NextMonth>
  </NavbarWrapper>
);

const CurrentMonth = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  p {
    margin-left: 30px;
  }
`;
const NextMonth = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  p {
    margin-right: 30px;
  }
`;
const NavbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 0 10px;

  span {
    margin: 0 10px;
  }
`;

const StyledIcon = styled(Icon)<{ onClick: () => void }>`
  &:hover {
    background-color: #e1e2e3;
  }
`;

interface CustomInputProps {
  error: boolean;
  disabled: boolean;
}

export const CustomInput = forwardRef((props: CustomInputProps, ref: Ref<HTMLInputElement>) => {
  const { error, disabled, ...rest } = props;

  return (
    <StyledPickerInput error={error} disabled={disabled} ref={ref}>
      <Icon className="material-icons" fontSize="small">
        calendar_today_outlined
      </Icon>
      <input {...rest} ref={ref} disabled={disabled} />
    </StyledPickerInput>
  );
});
CustomInput.displayName = 'CustomInput';

const StyledPickerInput = styled.div<{ disabled: boolean; error: boolean }>`
  align-self: center;
  height: 32px;
  background-color: #fff;
  position: relative;
  border: 1px solid #e0e8ed;
  border-radius: 3px;
  box-sizing: border-box;
  padding-left: 40px;
  font-size: 12px;
  line-height: 32px;
  ${({ disabled }) => disabled && 'background-color: #f7f7f7; cursor: default;'}
  ${({ error }) => error && 'border-color: tomato;'}

  span,
  svg {
    position: absolute;
    left: 10px;
    top: 8px;
    color: #6e7c89;
  }
`;
