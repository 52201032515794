import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Period } from '../../components/molecules/DayPicker/types';
import { DayPickerRange } from '../../components/molecules/DayPicker';
import { getToday, getTwoYearsAgo } from '../../libs/date';
import { useGlobalLayoutProps } from '../../libs/hooks';
import { validateUrl } from '../../libs/url';
import { analyticsDashboardRangeList } from './utils';
import { AnalyticsAccountHeaderType } from './types';

interface AnalyticsDetailsHeaderProps extends AnalyticsAccountHeaderType {
  period: Period;
  onChangePeriod: (period: Period) => void;
}

const AnalyticsDetailsHeader = (props: AnalyticsDetailsHeaderProps) => {
  const {
    period: { startDate, endDate },
    onChangePeriod,
    avatar,
    src,
    accountName
  } = props;
  const { updateGlobalLayout } = useGlobalLayoutProps();

  useEffect(() => {
    updateGlobalLayout({ hasStickyHeader: true });

    return () => {
      updateGlobalLayout({ hasStickyHeader: false });
    };
  }, []);
  const avatarUrl = validateUrl(avatar || null);

  return (
    <Container>
      <Wrapper>
        {avatarUrl ? <Avatar src={avatarUrl} alt="avatar" /> : <BlankAvatar />}
        <Image src={src} />
        <Title>{accountName}</Title>
      </Wrapper>
      <Wrapper>
        <DayPickerRange
          period={{ startDate, endDate }}
          handleChangePeriod={onChangePeriod}
          rangeList={analyticsDashboardRangeList}
          // for All_Time
          initialPeriod={{ startDate: getTwoYearsAgo(), endDate: getToday() }}
          disabledRange={{ after: new Date() }}
        />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  height: 72px;
  display: flex;
  padding: 0 24px;
  background-color: #fff;
  justify-content: space-between;
  box-shadow: 1px 1px 2px 0 #dee5ec, 1px 0 0 1px rgba(222, 229, 236, 0.5);
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const BlankAvatar = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  margin-right: 16px;
  border-radius: 50%;
  border: 1px solid #dee5ec;
`;
const Image = styled.img`
  width: 18px;
  margin-right: 8px;
`;
const Title = styled.p`
  color: #27313b;
  font-size: 18px;
  font-weight: 600;
`;

const Avatar = styled(BlankAvatar.withComponent('img'))`
  object-fit: contain;
`;

export default AnalyticsDetailsHeader;
