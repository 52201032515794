import React from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { formatIntNumber } from '../../../../libs/format';
import { defaultEmptyImage } from '../../../../libs/image';
import ImageSlider from '../../../molecules/ImageSlider';
import CarouselModal from '../../../molecules/CarouselPopup';
import { usePostDetailModal } from '../../AnalyticsShared/BasePostList/usePostDetailModal';
import { InstagramDashboardPostAnalytics_instagramDashboardPostAnalytics_storyPosts } from '../AnalyticsPosts/__generated__/InstagramDashboardPostAnalytics';
import { OpacityContentWrapper } from '../../AnalyticsShared/SharedComponents';
import * as StyledComponents from '../StyledComponents';
import AnalyticsStoryPostDetail from '../../AnalyticsPost/AnalyticsStoryPostDetail';
import { TitleWithHelp } from '../../AnalyticsShared/AnalyticsContentWrapper';

interface StoryPostsProps {
  data: InstagramDashboardPostAnalytics_instagramDashboardPostAnalytics_storyPosts[] | null;
}

const StoryPosts = (props: StoryPostsProps) => {
  const { t } = useTranslation();

  const { data } = props;

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev
  } = usePostDetailModal(data || []);

  const { id } = useParams<{ id: string }>();

  return (
    <>
      <MainWrapper>
        <StyledOpacityWrapper
          header={
            <Heading>
              <StyledTitleWithHelp title="HeaderColumn.Story Posts" help="dashboardStoryPosts" />
              <Annotation>{t('Annotation.Story Posts')}</Annotation>
            </Heading>
          }
          dataNotAvailable={!data?.length}
        >
          {!!data?.length && (
            <>
              <ImageSlider data={data}>
                {data.map(item => (
                  <StyledComponents.PostImageWrapper key={item.id}>
                    <StyledComponents.PostImage
                      src={defaultEmptyImage(item.thumbNail)}
                      onClick={() => handleClickPost(item.id)}
                    />
                    <StyledComponents.EngagementInfo>
                      <StyledComponents.InfoWrapper>
                        <StyledComponents.Label>{t('Reach')}</StyledComponents.Label>
                        <StyledComponents.Count>{formatIntNumber(item.reach)}</StyledComponents.Count>
                      </StyledComponents.InfoWrapper>
                      <StyledComponents.InfoWrapper>
                        <StyledComponents.Label>{t('General.Comments')}</StyledComponents.Label>
                        <StyledComponents.Count>{formatIntNumber(item.comments)}</StyledComponents.Count>
                      </StyledComponents.InfoWrapper>
                    </StyledComponents.EngagementInfo>
                  </StyledComponents.PostImageWrapper>
                ))}
              </ImageSlider>

              <StyledComponents.MoreText to={`/analytics/dashboard/${id}/instagram_story_post`}>
                <span>{t('Button.More')}</span> <StyledComponents.Arrow>{'\u2192'}</StyledComponents.Arrow>
              </StyledComponents.MoreText>
            </>
          )}
        </StyledOpacityWrapper>
      </MainWrapper>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleClosePostDetail}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        <AnalyticsStoryPostDetail closeModal={handleClosePostDetail} accountId={Number(id)} />
      </CarouselModal>
    </>
  );
};

const MainWrapper = styled.div`
  padding-left: 16px;
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  margin: 27px 0 16px 0;
`;
const StyledTitleWithHelp = styled(TitleWithHelp)`
  font-size: 18px;
  margin-right: 8px;
  margin-bottom: 0;
`;
const Annotation = styled.span`
  font-size: 12px;
  color: #6e7c89;
`;

const StyledOpacityWrapper = styled(OpacityContentWrapper)`
  min-height: 200px;
`;

export default StoryPosts;
