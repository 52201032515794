import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { DisabledProps } from '../types';

interface MenuItemProps extends DisabledProps {
  label: string;
  icon?: ReactNode;
  active: boolean;
  handleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  prefixIcon?: ReactNode;
}

const MenuItem = (props: MenuItemProps) => {
  const { label, icon, active, disabled, handleClick, prefixIcon } = props;

  return (
    <ItemWrapper active={active} disabled={disabled} onClick={handleClick}>
      <Item>
        {prefixIcon}
        {icon}
        {label}
      </Item>
    </ItemWrapper>
  );
};

const ItemWrapper = styled.div<DisabledProps & { active: boolean }>`
  background-color: ${props => (props.active ? '#179cd726' : 'transparent')};

  &:hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    background-color: ${props => (props.disabled ? 'transparent' : '#179cd726')};
  }
`;

const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
`;

export default MenuItem;
