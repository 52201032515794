import styled from '@emotion/styled';
import React from 'react';
import css from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { formatIntNumber } from '../../../libs/format';
import { defaultEmptyImage } from '../../../libs/image';
import ImageSlider from '../../molecules/ImageSlider';
import * as StyledComponents from '../../organisms/AnalyticsDashboard/StyledComponents';
import { OpacityContentWrapper } from '../../organisms/AnalyticsShared/SharedComponents';

interface FeedPostsColumnProps {
  className?: string;
  data: { id: number; likes: number | null; comments: number | null; thumbNail: string | null }[];
  onClick: (id: number) => void;
  isTable?: boolean;
}

const FeedPostsColumn = (props: FeedPostsColumnProps) => {
  const { t } = useTranslation();

  const { data, className, onClick, isTable = true } = props;
  const Wrapper = isTable ? TableElement : DivElement;

  return (
    <Wrapper className={className}>
      <StyledOpacityContentWrapper
        dataNotAvailable={!data?.length}
        css={css`
          ${!data?.length ? 'zoom: 0.5' : ''}
        `}
      >
        <TableData>
          <ImageSlider data={data}>
            {data.map(item => (
              <StyledComponents.PostImageWrapper key={item.id}>
                <StyledComponents.PostImage src={defaultEmptyImage(item.thumbNail)} onClick={() => onClick(item.id)} />
                <StyledComponents.EngagementInfo>
                  <StyledComponents.InfoWrapper>
                    <StyledComponents.Label>{t('Like')}</StyledComponents.Label>
                    <StyledComponents.Count>{formatIntNumber(item.likes)}</StyledComponents.Count>
                  </StyledComponents.InfoWrapper>
                  <StyledComponents.InfoWrapper>
                    <StyledComponents.Label>{t('General.Comments')}</StyledComponents.Label>
                    <StyledComponents.Count>{formatIntNumber(item.comments)}</StyledComponents.Count>
                  </StyledComponents.InfoWrapper>
                </StyledComponents.EngagementInfo>
              </StyledComponents.PostImageWrapper>
            ))}
          </ImageSlider>
        </TableData>
      </StyledOpacityContentWrapper>
    </Wrapper>
  );
};

const DivElement = styled.div`
  padding: 16px;

  &:not(:last-of-type) {
    border-right: 1px solid #dee5ec;
  }
`;
const TableElement = styled(DivElement.withComponent('td'))``;
const TableData = styled.div`
  display: inline-block;
  font-size: 13px;
  line-height: 16px;
  width: 100%;
`;
const StyledOpacityContentWrapper = styled(OpacityContentWrapper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default FeedPostsColumn;
