import styled from '@emotion/styled';

const Container = styled.div`
  padding: 12px 17px 0;
  margin: 24px 24px 0;
  background-color: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
`;
const StyledContainer = styled(Container)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0;
`;

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

const SlidersWrapper = styled(Wrapper)`
  margin: 0 23px 0;
  background-color: #fff;
`;

const HeadingWrapper = styled(SlidersWrapper)`
  padding: 16px;
  margin: 24px 24px 0;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

const GridWrapper = styled.div<{ repeat: string }>`
  display: inline-grid;
  grid-template-columns: ${({ repeat }) => `repeat(${repeat}, 1fr)`};
  grid-gap: 8px;
  width: 100%;
  margin-bottom: 10px;
`;

export default { Container, Wrapper, SlidersWrapper, GridWrapper, StyledContainer, HeadingWrapper };
