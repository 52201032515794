import styled from '@emotion/styled';
import Tooltip from '@material-ui/core/Tooltip';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface TooltipLinkColumnProps {
  className?: string;
  href: LocationDescriptor;
  data: string;
  tooltip: React.ReactNode | null;
}

const TooltipLinkColumn = (props: TooltipLinkColumnProps) => {
  const { className, href, data, tooltip } = props;

  return (
    <td>
      <Wrapper>
        {tooltip === null ? (
          <TableData className={className} to={href}>
            {data}
          </TableData>
        ) : (
          <Tooltip title={tooltip} interactive={true}>
            <TableData className={className} to={href}>
              {data}
            </TableData>
          </Tooltip>
        )}
      </Wrapper>
    </td>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 8px;
`;

const TableData = styled(Link)`
  overflow: hidden;
  max-height: 32px;
  font-size: 13px;
  line-height: 16px;
`;

export default TooltipLinkColumn;
