import React from 'react';
import ListIndicator from '../../../molecules/ListIndicator';
import { isAdminStaffAgency } from '../../../../libs/auth';
import AnalyticsListAdmin from './AnalyticsListAdmin';
import AnalyticsListAdvertiser from './AnalyticsListAdvertiser';
import { useAnalyticsData } from './useAnalyticsData';
import { AdvertiserListProps } from './types';
import { useReconnectAccountFromList } from './useReconnectAccountFromList';

const AdvertiserListComponent = (props: AdvertiserListProps) => {
  const isAdminOrAgencyUser = isAdminStaffAgency();

  const { loading, analyticsAccountsAdmin, analyticsAccountsAdvertiser, pageInfo } = useAnalyticsData({
    ...props,
    isAdminOrAgencyUser
  });

  const reconnectProps = useReconnectAccountFromList({ accounts: analyticsAccountsAdmin });

  if (loading || reconnectProps.loading) {
    return <ListIndicator />;
  }

  return isAdminOrAgencyUser ? (
    <AnalyticsListAdmin pageInfo={pageInfo} analyticsAccounts={analyticsAccountsAdmin} {...reconnectProps} />
  ) : (
    <AnalyticsListAdvertiser analyticsAccounts={analyticsAccountsAdvertiser} {...reconnectProps} />
  );
};

export default AdvertiserListComponent;
