import { useTranslation } from 'react-i18next';
import mentionPost from '../../../../assets/icon/mentionPost.svg';
import pictureIcon from '../../../../assets/icon/picture.svg';
import mentionLike from '../../../../assets/icon/mentionLike.svg';
import heartIcon from '../../../../assets/icon/heart.svg';
import chatIcon from '../../../../assets/icon/chat_green.svg';
import mentionComment from '../../../../assets/icon/mentionComment.svg';
import { getGrowthArrow, getGrowthPrefix } from '../../AnalyticsShared/utils';
import {
  formatDateCount,
  formatLineChartDateCount,
  SeriesHistoryType,
  formatDate,
  getSerieDayData,
  SeriesType,
  TooltipDataType,
  getEngagementData,
  generateYAxisBasedOnDisplayedCharts
} from '../../AnalyticsShared/Overview/helpers';
import { InstagramInteractionOverview_instagramInteractionOverview } from './__generated__/InstagramInteractionOverview';

type InteractionChartColorTypes =
  | 'Follower'
  | 'Tagged Posts'
  | 'Tagged Likes'
  | 'Tagged Comments'
  | 'Mentioned Posts'
  | 'Mentioned Likes'
  | 'Mentioned Comments';
export const allInteractionMetrics: InteractionChartColorTypes[] = [
  'Follower',
  'Tagged Posts',
  'Tagged Likes',
  'Tagged Comments',
  'Mentioned Posts',
  'Mentioned Likes',
  'Mentioned Comments'
];
export const defaultSelectedCharts: InteractionChartColorTypes[] = ['Follower', 'Tagged Posts', 'Mentioned Posts'];

export const colors: Record<InteractionChartColorTypes, string> = {
  Follower: '#ff2b52',
  ['Tagged Posts']: '#ffd7c1',
  ['Tagged Likes']: '#ff96ae',
  ['Tagged Comments']: '#e9b8d7',
  ['Mentioned Posts']: '#aec0e4',
  ['Mentioned Likes']: '#a0c8df',
  ['Mentioned Comments']: '#9dd9f4'
};

export const getDailyDataForTooltip = (
  categories: string[],
  series: SeriesType[]
): TooltipDataType<InteractionChartColorTypes> =>
  categories.reduce(
    (acc, day, index) => ({
      ...acc,
      [day]: getSerieDayData<InteractionChartColorTypes>(index, series)
    }),
    {}
  );

interface SeriesAndCategoriesType {
  historyData: Record<string, SeriesHistoryType[]>;
  displayedCharts: InteractionChartColorTypes[];
}
export const getSeriesAndCategories = ({
  historyData: {
    followersHistory,
    taggedPostsHistory,
    taggedLikesHistory,
    taggedCommentsHistory,
    mentionedPostsHistory,
    mentionedLikesHistory,
    mentionedCommentsHistory
  },
  displayedCharts
}: SeriesAndCategoriesType) => {
  // we are using followersCategories as main one, in case of some data may be missing in other fields
  const { count: followersCount, date: dateCategories } = formatLineChartDateCount(followersHistory);
  const { count: taggedPostsCount } = formatDateCount(followersHistory, taggedPostsHistory);
  const { count: taggedLikesCount } = formatDateCount(followersHistory, taggedLikesHistory);
  const { count: taggedCommentsCount } = formatDateCount(followersHistory, taggedCommentsHistory);
  const { count: mentionedPostsCount } = formatDateCount(followersHistory, mentionedPostsHistory);
  const { count: mentionedLikesCount } = formatDateCount(followersHistory, mentionedLikesHistory);
  const { count: mentionedCommentsCount } = formatDateCount(followersHistory, mentionedCommentsHistory);

  const series = [
    {
      name: 'Follower',
      type: 'line',
      data: followersCount,
      color: colors.Follower,
      marker: {
        symbol: 'circle',
        lineWidth: 2,
        lineColor: undefined, // inherit from chart
        fillColor: '#fff'
      },
      zIndex: 1,
      visible: displayedCharts.includes('Follower')
    },
    {
      name: 'Tagged Posts',
      type: 'column',
      data: taggedPostsCount,
      color: colors['Tagged Posts'],
      yAxis: 1,
      visible: displayedCharts.includes('Tagged Posts')
    },
    {
      name: 'Tagged Likes',
      type: 'column',
      data: taggedLikesCount,
      color: colors['Tagged Likes'],
      yAxis: 2,
      visible: displayedCharts.includes('Tagged Likes')
    },
    {
      name: 'Tagged Comments',
      type: 'column',
      data: taggedCommentsCount,
      color: colors['Tagged Comments'],
      yAxis: 3,
      visible: displayedCharts.includes('Tagged Comments')
    },
    {
      name: 'Mentioned Posts',
      type: 'column',
      data: mentionedPostsCount,
      color: colors['Mentioned Posts'],
      yAxis: 4,
      visible: displayedCharts.includes('Mentioned Posts')
    },
    {
      name: 'Mentioned Likes',
      type: 'column',
      data: mentionedLikesCount,
      color: colors['Mentioned Likes'],
      yAxis: 5,
      visible: displayedCharts.includes('Mentioned Likes')
    },
    {
      name: 'Mentioned Comments',
      type: 'column',
      data: mentionedCommentsCount,
      color: colors['Mentioned Comments'],
      yAxis: 6,
      visible: displayedCharts.includes('Mentioned Comments')
    }
  ];
  const tooltipData = getDailyDataForTooltip(dateCategories, series);

  return {
    series: series as Highcharts.SeriesOptionsType[],
    // we are combining all datas categories, but maybe it is not correct
    categories: dateCategories,
    tooltipData: tooltipData as typeof tooltipData
  };
};

export const getOptions = (
  displayedCharts: typeof defaultSelectedCharts,
  instagramInteractionOverview: InstagramInteractionOverview_instagramInteractionOverview
): {
  options: Highcharts.Options;
  engagement: Array<{ icon: string; title: string; total: number; growth: number; rate: number }>;
} => {
  const { t } = useTranslation();
  const {
    // these are combining values
    graph: {
      followers: followersHistory,
      taggedPosts: taggedPostsHistory,
      taggedLikes: taggedLikesHistory,
      taggedComments: taggedCommentsHistory,
      mentionedPosts: mentionedPostsHistory,
      mentionedLikes: mentionedLikesHistory,
      mentionedComments: mentionedCommentsHistory
    },
    summaries: { taggedPosts, taggedLikes, taggedComments, mentionPosts, mentionLikes, mentionComments }
  } = instagramInteractionOverview;
  const taggedPostsEngagement = getEngagementData(taggedPosts);
  const taggedLikesEngagement = getEngagementData(taggedLikes);
  const taggedCommentsEngagement = getEngagementData(taggedComments);
  const mentionsPostsEngagement = getEngagementData(mentionPosts);
  const mentionLikesEngagement = getEngagementData(mentionLikes);
  const mentionCommentsEngagement = getEngagementData(mentionComments);

  // we are showing X-Axis dates based on displayed charts checkboxes
  const { series, categories, tooltipData } = getSeriesAndCategories({
    historyData: {
      followersHistory,
      taggedPostsHistory,
      taggedLikesHistory,
      taggedCommentsHistory,
      mentionedPostsHistory,
      mentionedLikesHistory,
      mentionedCommentsHistory
    },
    displayedCharts
  });

  const yAxis = generateYAxisBasedOnDisplayedCharts<InteractionChartColorTypes>(allInteractionMetrics, false);

  return {
    engagement: [
      {
        icon: pictureIcon,
        title: 'Tagged Posts',
        ...taggedPostsEngagement
      },
      { icon: heartIcon, title: 'Tagged Likes', ...taggedLikesEngagement },
      { icon: chatIcon, title: 'Tagged Comments', ...taggedCommentsEngagement },
      { icon: mentionPost, title: 'Mentions Posts', ...mentionsPostsEngagement },
      { icon: mentionLike, title: 'Mention Likes', ...mentionLikesEngagement },
      { icon: mentionComment, title: 'Mention Comments', ...mentionCommentsEngagement }
    ],
    options: {
      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories,
        crosshair: true,
        type: 'category',
        labels: {
          formatter: function formatter() {
            return formatDate(`${this.value}`);
          }
        }
      },
      yAxis,
      legend: {
        enabled: false
      },
      tooltip: {
        useHTML: true,
        borderColor: '#27313b',
        borderRadius: 3,
        outside: true,
        shadow: false,
        formatter: function formatter(): string {
          const followersIncrement = tooltipData[this.key].Follower.increment;
          const incrementArrow = getGrowthArrow(followersIncrement);
          const prefix = getGrowthPrefix(followersIncrement);

          const incrementSection = followersIncrement
            ? `<span style="color: ${
                followersIncrement > 0 ? '#40b87c' : '#ff2b52'
              };">${prefix} ${followersIncrement} ${incrementArrow}</span>`
            : '';

          return `
        <div style="display:flex;flex-direction:column;border-radius:3px;border: 2px solid #27313b;margin:-2px;background-color:#27313b;font-size:12px;padding:13px 16px;">
          <h3 style="margin-bottom:8px; font-size: 13px; color: #fff;">${this.key}</h3>
          
            <div style="display: flex;">
              <div style="display: flex; flex: 1; width: max-content; border-left: 1px solid #6e7c89; padding-left: 2px;">
                <div>
                  <span style="color: #c5d0da; font-size: 11px;">${t('Follower')}</span>
                  <br />
                  <p style="font-size: 13px; color: #fff;">
                    ${tooltipData[this.key].Follower.value}
                    ${incrementSection}
                  </p>
                </div>
              </div>
            </div>
          // first row
          <div style="display:flex; flex-direction: column;">
            <div style="display: flex;">
              <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                <div>
                  <span style="color: #c5d0da; font-size: 11px;">${t('Tagged Posts')}</span>
                  <br />
                  <p style="font-size: 13px; color: #fff;">
                    ${tooltipData[this.key]['Tagged Posts'].value}
                  </p>
                </div>
              </div>

              <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                <div>
                  <span style="color: #c5d0da; font-size: 11px;">${t('Tagged Likes')}</span>
                  <br />
                  <p style="font-size: 13px; color: #fff;">
                    ${tooltipData[this.key]['Tagged Likes'].value}
                  </p>
                </div>
              </div>

              <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                <div>
                  <span style="color: #c5d0da; font-size: 11px;">${t('Tagged Comments')}</span>
                  <br />
                  <p style="font-size: 13px; color: #fff;">
                    ${tooltipData[this.key]['Tagged Comments'].value}
                  </p>
                </div>
              </div>
            </div>
            // second row
            <div style="display:flex; flex-direction: column;">
              <div style="display: flex;">
                <div style="display: flex;  flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Mentioned Posts')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key]['Mentioned Posts'].value}
                    </p>
                  </div>
                </div>
  
                <div style="display: flex; flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Mentioned Likes')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key]['Mentioned Likes'].value}
                    </p>
                  </div>
                </div>
  
                <div style="display: flex; flex: 1; min-width: 60px; border-left: 1px solid #6e7c89; padding-left: 2px;">
                  <div>
                    <span style="color: #c5d0da; font-size: 11px;">${t('Mentioned Comments')}</span>
                    <br />
                    <p style="font-size: 13px; color: #fff;">
                      ${tooltipData[this.key]['Mentioned Comments'].value}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>`;
        },
        pointFormat: ''
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series
    }
  };
};
