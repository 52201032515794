import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { CheckBox } from '../CheckBox';

interface CheckboxColumnProps {
  className?: string;
  value: boolean;
  label?: string | ReactNode;
  onChange?: () => void;
  disabled?: boolean;
  fontSizeCheckbox?: string;
  name: string;
}

const CheckboxColumn = (props: CheckboxColumnProps) => {
  const { className, value, onChange, disabled = false, label, fontSizeCheckbox = '14px' } = props;

  return (
    <Wrapper className={className}>
      <CheckBox
        name={name}
        onChange={onChange}
        label={label}
        checked={value}
        disabled={disabled}
        size={fontSizeCheckbox}
      />
    </Wrapper>
  );
};

const Wrapper = styled.td`
  width: 22px;
`;

export default CheckboxColumn;
