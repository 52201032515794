import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useParams } from 'react-router';
import { useInstagramAnalyticsHeader, useQueryHelper } from '../../../libs/hooks';
import PageLoading from '../../molecules/PageLoading';
import * as IG_ANALYTICS_HEADER from './InstagramAnalyticsAccountInfoHeader.graphql';
import {
  InstagramAnalyticsAccountInfoHeader,
  InstagramAnalyticsAccountInfoHeaderVariables
} from './__generated__/InstagramAnalyticsAccountInfoHeader';

const WithAnalyticsHeader: React.FC = ({ children }) => {
  const client = useApolloClient();
  const { id } = useParams<{ id: string }>();
  const { t, history, enqueueSnackbar } = useQueryHelper();
  const { analyticsAccountInfo, updateAnalyticsAccountInfo, resetAnalyticsAccountInfo } = useInstagramAnalyticsHeader();

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const accountId = Number(id);

  useEffect(() => {
    if (accountId && !(analyticsAccountInfo.accountName && analyticsAccountInfo.id === accountId)) {
      fetchHeaderInfo();
    }

    return () => {
      // we are caching analyticsAccountInfo to reduce amount of requests between analytics tabs
      // once user leave analytics domain e.g. any path with `/analytics` in it
      // we need to make sure to refetch analyticsAccountInfo once he will be back, so it is worth to reset this info
      const pathname = history.location.pathname;
      const hasRedirectOutOfAnalytics = !pathname.includes('/analytics');
      if (hasRedirectOutOfAnalytics) {
        resetAnalyticsAccountInfo();
      }
    };
  }, [accountId]);

  const fetchHeaderInfo = async () => {
    setLoading(true);
    try {
      const { data, errors } = await client.query<
        InstagramAnalyticsAccountInfoHeader,
        InstagramAnalyticsAccountInfoHeaderVariables
      >({
        query: IG_ANALYTICS_HEADER,
        variables: { accountId }
      });
      if (data?.instagramAccountInfo) {
        updateAnalyticsAccountInfo({ ...data.instagramAccountInfo, ...data.analyticsSubscriptionPlan });
      }

      if (errors?.length) {
        setError(errors[0].message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <PageLoading />;
  }

  if (error) {
    enqueueSnackbar(t('Failed to get the account data.'), { variant: 'error' });
    history.push('/analytics');

    return null;
  }

  return (
    <>
      {React.cloneElement(children as React.ReactElement<any>, {
        ...analyticsAccountInfo
      })}
    </>
  );
};

export default WithAnalyticsHeader;
