import {
  InfluencerListSortField,
  PostListSortField,
  SocialAccountInfluencerListSortField,
  InstagramFeedPostSortField,
  InstagramStoryPostSortField
} from '../../__generated__/globalTypes';

export const getInfluencerListSortFieldType = (field?: string | null) => {
  if (!field) {
    return null;
  }

  switch (field) {
    case 'DATE':
      return InfluencerListSortField.DATE;
    case 'ENGAGEMENT':
      return InfluencerListSortField.ENGAGEMENT;
    case 'ENGAGEMENT_RATE':
      return InfluencerListSortField.ENGAGEMENT_RATE;
    case 'FOLLOWERS':
      return InfluencerListSortField.FOLLOWERS;
    case 'ENGAGEMENT_PROPOSED_COUNT':
      return InfluencerListSortField.ENGAGEMENT_PROPOSED_COUNT;
    case 'ENGAGEMENT_POSTED_COUNT':
      return InfluencerListSortField.ENGAGEMENT_POSTED_COUNT;
    case 'MARKETPLACE_JOINED_COUNT':
      return InfluencerListSortField.MARKETPLACE_JOINED_COUNT;
    case 'MARKETPLACE_POSTED_COUNT':
      return InfluencerListSortField.MARKETPLACE_POSTED_COUNT;
    default:
      return null;
  }
};

export const getPostListSortFieldType = (field?: string | null) => {
  if (!field) {
    return null;
  }

  switch (field) {
    case 'AGE':
      return PostListSortField.AGE;
    case 'ENGAGEMENT':
      return PostListSortField.ENGAGEMENT;
    case 'COMMENT':
      return PostListSortField.COMMENT;
    case 'FOLLOWERS':
      return PostListSortField.FOLLOWERS;
    case 'COUNTRY':
      return PostListSortField.COUNTRY;
    case 'GENDER':
      return PostListSortField.GENDER;
    case 'LIKE':
      return PostListSortField.LIKE;
    case 'POST_DATE':
      return PostListSortField.POST_DATE;
    case 'SHARE':
      return PostListSortField.SHARE;
    case 'VIEW':
      return PostListSortField.VIEW;
    default:
      return null;
  }
};

export const getInstagramFeedPostSortFieldType = (field?: string | null) => {
  if (!field) {
    return null;
  }

  switch (field) {
    case 'COMMENT':
      return InstagramFeedPostSortField.COMMENT;
    case 'ENGAGEMENT':
      return InstagramFeedPostSortField.ENGAGEMENT;
    case 'IMPRESSIONS':
      return InstagramFeedPostSortField.IMPRESSIONS;
    case 'LIKE':
      return InstagramFeedPostSortField.LIKE;
    case 'NEGATIVE_RATE':
      return InstagramFeedPostSortField.NEGATIVE_RATE;
    case 'POSITIVE_RATE':
      return InstagramFeedPostSortField.POSITIVE_RATE;
    case 'POST_DATE':
      return InstagramFeedPostSortField.POST_DATE;
    case 'REACH':
      return InstagramFeedPostSortField.REACH;
    case 'SAVED':
      return InstagramFeedPostSortField.SAVED;

    default:
      return null;
  }
};

export const getInstagramStoryPostSortFieldType = (field?: string | null) => {
  if (!field) {
    return null;
  }

  switch (field) {
    case 'COMMENT':
      return InstagramStoryPostSortField.COMMENT;
    case 'EXITS':
      return InstagramStoryPostSortField.EXITS;
    case 'IMPRESSIONS':
      return InstagramStoryPostSortField.IMPRESSIONS;
    case 'NEGATIVE_RATE':
      return InstagramStoryPostSortField.NEGATIVE_RATE;
    case 'POSITIVE_RATE':
      return InstagramStoryPostSortField.POSITIVE_RATE;
    case 'POST_DATE':
      return InstagramStoryPostSortField.POST_DATE;
    case 'REACH':
      return InstagramStoryPostSortField.REACH;
    case 'TAPS_BACK':
      return InstagramStoryPostSortField.TAPS_BACK;
    case 'TAPS_FORWARD':
      return InstagramStoryPostSortField.TAPS_FORWARD;

    default:
      return null;
  }
};

export const getSocialAccountInfluencerListSortFieldType = (field?: string | null) => {
  if (!field) {
    return null;
  }

  switch (field) {
    case 'AGE':
      return SocialAccountInfluencerListSortField.AGE;
    case 'CATEGORY':
      return SocialAccountInfluencerListSortField.CATEGORY;
    case 'COMMENT':
      return SocialAccountInfluencerListSortField.COMMENT;
    case 'COUNTRY':
      return SocialAccountInfluencerListSortField.COUNTRY;
    case 'DISLIKE':
      return SocialAccountInfluencerListSortField.DISLIKE;
    case 'ENGAGEMENT_POSTED_COUNT':
      return SocialAccountInfluencerListSortField.ENGAGEMENT_POSTED_COUNT;
    case 'ENGAGEMENT_PROPOSED_COUNT':
      return SocialAccountInfluencerListSortField.ENGAGEMENT_PROPOSED_COUNT;
    case 'GENDER':
      return SocialAccountInfluencerListSortField.GENDER;
    case 'INFLUENCER_ENGAGEMENT':
      return SocialAccountInfluencerListSortField.INFLUENCER_ENGAGEMENT;
    case 'INFLUENCER_ENGAGEMENT_RATE':
      return SocialAccountInfluencerListSortField.INFLUENCER_ENGAGEMENT_RATE;
    case 'INFLUENCER_FOLLOWERS':
      return SocialAccountInfluencerListSortField.INFLUENCER_FOLLOWERS;
    case 'LIKE':
      return SocialAccountInfluencerListSortField.LIKE;
    case 'MARKETPLACE_JOINED_COUNT':
      return SocialAccountInfluencerListSortField.MARKETPLACE_JOINED_COUNT;
    case 'MARKETPLACE_POSTED_COUNT':
      return SocialAccountInfluencerListSortField.MARKETPLACE_POSTED_COUNT;
    case 'REPLY':
      return SocialAccountInfluencerListSortField.REPLY;
    case 'RETWEET':
      return SocialAccountInfluencerListSortField.RETWEET;
    case 'SHARE':
      return SocialAccountInfluencerListSortField.SHARE;
    case 'SOCIAL_ACCOUNT_ENGAGEMENT':
      return SocialAccountInfluencerListSortField.SOCIAL_ACCOUNT_ENGAGEMENT;
    case 'SOCIAL_ACCOUNT_ENGAGEMENT_RATE':
      return SocialAccountInfluencerListSortField.SOCIAL_ACCOUNT_ENGAGEMENT_RATE;
    case 'SOCIAL_ACCOUNT_FOLLOWERS':
      return SocialAccountInfluencerListSortField.SOCIAL_ACCOUNT_FOLLOWERS;
    case 'STATUS':
      return SocialAccountInfluencerListSortField.STATUS;
    case 'VIEW':
      return SocialAccountInfluencerListSortField.VIEW;
    default:
      return null;
  }
};
