import styled from '@emotion/styled';
import React from 'react';

interface FieldType {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  value?: any;
  name?: string;
  step: number;
}

export interface TextProps extends FieldType, InputProps {
  className?: string;
  placeholder?: string;
  type?: string;
  minValue: number;
  maxValue: number;
  handleChange: (e: React.ChangeEvent<any>) => void;
}

const trimmedValue = (value: string) => +value.replace(/[^0-9.]/g, '');

const TextWithButtons = (props: TextProps) => {
  const { className, value, minValue, maxValue, step, handleChange } = props;

  const handleAddRemoveValue = (val: number, limit: number) => {
    const newValue = trimmedValue(value);

    if (newValue === limit) {
      return;
    }

    handleChange({ target: { value: (newValue + val).toString() } } as React.ChangeEvent<any>);
  };

  const setNewInputValue = (updValue: number) => {
    handleChange({ target: { value: updValue.toString() } } as React.ChangeEvent<any>);
  };

  const handleInputChange = (e: React.ChangeEvent<any>) => {
    const inputValue = Number(trimmedValue(e.target.value));

    if (isNaN(inputValue)) {
      return;
    }

    if (inputValue < minValue) {
      setNewInputValue(minValue);
    } else if (inputValue > maxValue) {
      setNewInputValue(maxValue);
    }

    setNewInputValue(inputValue);
  };

  return (
    <Wrapper className={className}>
      <StyledInput value={value} onChange={handleInputChange} />
      <ButtonWrapper>
        <StyledButtonPlus onClick={() => handleAddRemoveValue(step, maxValue)}>+</StyledButtonPlus>
        <StyledButtonMinus onClick={() => handleAddRemoveValue(-step, minValue)}>-</StyledButtonMinus>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;
const StyledInput = styled.input`
  width: 68px;
  padding-left: 6px;
  border: solid 1px #dee5ec;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const BaseButton = styled.button`
  width: 24px;
  height: 13px;
  line-height: 0;
  background-color: #fff;
  border: solid 1px #dee5ec;
  border-left: none;
  cursor: pointer;

  &:hover {
    box-shadow: inset 0 1px 2px 0 rgba(39, 49, 59, 0.2);
  }
`;
const StyledButtonPlus = styled(BaseButton)`
  border-top-right-radius: 3px;
  border-bottom: none;
`;
const StyledButtonMinus = styled(BaseButton)`
  border-bottom-right-radius: 3px;
`;

interface InputProps {
  error?: boolean;
  disabled?: boolean;
}

export default TextWithButtons;
