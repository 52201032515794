import { MutationFunction } from '@apollo/client';
import { GraphQLError } from 'graphql';
import {
  CreateSocialAccountAnalyticsAdminInputV2,
  EditSocialAccountAnalyticsAdminInputV2
} from '../../../../__generated__/globalTypes';
import { FormValues } from './types';
import {
  EditSocialAccountAnalyticsAdminV2,
  EditSocialAccountAnalyticsAdminV2Variables
} from './__generated__/EditSocialAccountAnalyticsAdminV2';
import {
  CreateSocialAccountAnalyticsAdminV2,
  CreateSocialAccountAnalyticsAdminV2Variables
} from './__generated__/CreateSocialAccountAnalyticsAdminV2';

export type CreateAnalyticsAccountMutation = MutationFunction<
  CreateSocialAccountAnalyticsAdminV2,
  CreateSocialAccountAnalyticsAdminV2Variables
>;
export type EditAnalyticsAccountMutation = MutationFunction<
  EditSocialAccountAnalyticsAdminV2,
  EditSocialAccountAnalyticsAdminV2Variables
>;
interface CreateUpdateAnalyticsAccountProps {
  id?: number;
  createAccountAnalytics: CreateAnalyticsAccountMutation;
  updateAccountAnalytics: EditAnalyticsAccountMutation;
  youtubeChannelId?: string;
  fbPageId: string;
}
export const createUpdateAnalyticsAccountAdminAgency = async (
  props: CreateUpdateAnalyticsAccountProps,
  payload: FormValues
) => {
  const { id: accountIdFromUrl, updateAccountAnalytics, createAccountAnalytics, youtubeChannelId, fbPageId } = props;
  const { accountManagerIds, advertiserId, instagramUserId, accountName } = payload;

  const basePayload = {
    accountManagersPICs: accountManagerIds.length ? accountManagerIds.map(acc => Number(acc)) : null,
    youtubeChannelId: youtubeChannelId || null,
    accountName
  };
  const updateAnalyticsAccountInput: EditSocialAccountAnalyticsAdminInputV2 = {
    ...basePayload,
    brandAccountId: accountIdFromUrl as number,
    igFacebookPageId: fbPageId || null
  };
  const createAnalyticsAccountInput: CreateSocialAccountAnalyticsAdminInputV2 = {
    ...basePayload,
    advertiserId: Number(advertiserId),
    instagramUserId: instagramUserId || null
  };

  if (!!accountIdFromUrl) {
    const { ok, error } = await updateAccountAnalytics({ variables: { input: updateAnalyticsAccountInput } })
      .then(result => {
        if (result?.data?.editSocialAccountAnalyticsAdminV2) {
          return { ok: result.data.editSocialAccountAnalyticsAdminV2.ok, error: null };
        }

        return { ok: false, error: null };
      })
      .catch((e: GraphQLError) => ({ ok: false, error: e.message }));

    return { ok, error };
  } else {
    const { ok, error } = await createAccountAnalytics({ variables: { input: createAnalyticsAccountInput } })
      .then(result => {
        if (result?.data?.createSocialAccountAnalyticsAdminV2) {
          return { ok: result.data.createSocialAccountAnalyticsAdminV2.ok, error: null };
        }

        return { ok: false, error: null };
      })
      .catch((e: GraphQLError) => ({ ok: false, error: e.message }));

    return { ok, error };
  }
};
