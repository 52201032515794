import React from 'react';
import { useUpdateDocumentTitle, useInstagramAnalyticsHeader } from '../../libs/hooks';
import { usePaging } from '../../libs/paging';
import { useSort } from '../../libs/filter';
import { useFilter } from '../../components/organisms/AnalyticsShared/BasePostListFilter/useFilter';
import { InstagramFeedPostSortField } from '../../__generated__/globalTypes';
import { getInstagramFeedPostSortFieldType } from '../../libs/filter/enum';
import { getThirtyDaysAgo, getToday } from '../../libs/date';
import Template from './template';

const TaggedPostsPage = () => {
  const {
    analyticsAccountInfo: { accountName, id }
  } = useInstagramAnalyticsHeader();
  useUpdateDocumentTitle({
    title: !!accountName ? 'documentTitle.MentionedPostsListWithAccountName' : 'documentTitle.Mentioned Posts List',
    interpolation: {
      title: accountName
    },
    appHeader: !!accountName ? 'appHeader.MentionedPostsListWithAccountName' : 'appHeader.Mentioned Posts List',
    href: `/analytics/dashboard/${id}/interaction`
  });

  const currentPage = usePaging();
  const { filter, setFilter } = useFilter({ startDate: getThirtyDaysAgo(), endDate: getToday() });
  const { sort, setSort } = useSort<InstagramFeedPostSortField>(getInstagramFeedPostSortFieldType);

  return (
    <Template sort={sort} setSort={setSort} currentPage={currentPage} filter={filter} setFilter={setFilter} id={id} />
  );
};

export default TaggedPostsPage;
