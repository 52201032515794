import styled from '@emotion/styled';

const InstagramBox = styled.div`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  background-image: linear-gradient(120deg, #d82e7c -3%, #ff8404 99%);
`;

const FacebookBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  object-fit: contain;
  border-radius: 3px;
  background-color: #4460a0;
`;

const YoutubeBox = styled.div`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  background-color: #ce1312;
`;

const TwitterBox = styled.div`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  background-color: #00aaec;
`;

const DisabledBox = styled.div`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  background-color: #dee5ec;
`;

export { InstagramBox, FacebookBox, YoutubeBox, TwitterBox, DisabledBox };
