import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import { SignUpFormSchema } from '../../../../libs/validation';
import { useQueryHelper } from '../../../../libs/hooks';
import SignUpForm from './SignUpForm';
import { FormValues } from './types';
import CREATE_ADVERTISER from './CreateSelfRegisteredAdvertiserUser.graphql';
import {
  CreateSelfRegisteredAdvertiserUser,
  CreateSelfRegisteredAdvertiserUserVariables
} from './__generated__/CreateSelfRegisteredAdvertiserUser';

const SignUpFormComponent = () => {
  const [createAdvertiserUser] = useMutation<
    CreateSelfRegisteredAdvertiserUser,
    CreateSelfRegisteredAdvertiserUserVariables
  >(CREATE_ADVERTISER);
  const { t, history, enqueueSnackbar } = useQueryHelper();

  const handleSubmit = async (payload: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    localStorage.setItem('confirmationEmail', payload.email);

    try {
      await createAdvertiserUser({
        variables: {
          input: {
            advertiserCompanyName: payload.companyName,
            advertiserCompanyUrl: payload.companyUrl,
            advertiserPhoneNumber: payload.phoneNumber,
            countryId: payload.countryId,
            userName: payload.name,
            userEmail: payload.email
          }
        }
      });

      enqueueSnackbar(t('Your account was created successfully'), {
        variant: 'success'
      });
      history.push('/signup/success');
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        companyName: '',
        companyUrl: '',
        phoneNumber: '',
        countryId: '',
        name: '',
        email: ''
      }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={SignUpFormSchema}
    >
      <SignUpForm />
    </Formik>
  );
};

export default SignUpFormComponent;
