/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AnalyticsAuthSocialAccountType {
  FACEBOOK = "FACEBOOK",
  YOUTUBE = "YOUTUBE",
}

export enum AnalyticsRedirectType {
  ANALYTICS_CONNECT = "ANALYTICS_CONNECT",
  ANALYTICS_LIST_CONNECT = "ANALYTICS_LIST_CONNECT",
  ANALYTICS_SIGNIN = "ANALYTICS_SIGNIN",
}

export enum AnalyticsSubscriptionPlanType {
  BASIC = "BASIC",
  BASIC_PLUS = "BASIC_PLUS",
  BASIC_PLUS_PLUS = "BASIC_PLUS_PLUS",
  ENTERPRISE = "ENTERPRISE",
  ENTERPRISE_PLUS = "ENTERPRISE_PLUS",
  ENTERPRISE_PLUS_PLUS = "ENTERPRISE_PLUS_PLUS",
  FREE = "FREE",
}

/**
 * An enumeration.
 */
export enum CampaignReportOrderField {
  CLICK = "CLICK",
  COMMENT = "COMMENT",
  CONVERSION = "CONVERSION",
  COST = "COST",
  COST_PER_ACTION = "COST_PER_ACTION",
  COST_PER_CLICK = "COST_PER_CLICK",
  COST_PER_COMMENT = "COST_PER_COMMENT",
  COST_PER_ENGAGEMENT = "COST_PER_ENGAGEMENT",
  COST_PER_FOLLOWER = "COST_PER_FOLLOWER",
  COST_PER_IMPRESSIONS = "COST_PER_IMPRESSIONS",
  COST_PER_LIKE = "COST_PER_LIKE",
  COST_PER_REACH = "COST_PER_REACH",
  COST_PER_SAVED = "COST_PER_SAVED",
  COST_PER_SHARE = "COST_PER_SHARE",
  COST_PER_VIEW = "COST_PER_VIEW",
  DATE = "DATE",
  ENGAGEMENT = "ENGAGEMENT",
  ENGAGEMENT_RATE = "ENGAGEMENT_RATE",
  FOLLOWERS = "FOLLOWERS",
  IMPRESSIONS = "IMPRESSIONS",
  LIKE = "LIKE",
  NET = "NET",
  PROFIT = "PROFIT",
  PROFIT_RATE = "PROFIT_RATE",
  REACH = "REACH",
  SAVED = "SAVED",
  SHARE = "SHARE",
  VIEW = "VIEW",
}

/**
 * An enumeration.
 */
export enum CampaignSocialMediaType {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  INSTAGRAM_STORY = "INSTAGRAM_STORY",
  TWITTER = "TWITTER",
  YOUTUBE = "YOUTUBE",
}

/**
 * An enumeration.
 */
export enum CampaignStatusForReport {
  FINISHED = "FINISHED",
  ONGOING = "ONGOING",
  OVER_BUDGET = "OVER_BUDGET",
}

/**
 * An enumeration.
 */
export enum CampaignType {
  ENGAGEMENT = "ENGAGEMENT",
  MARKETPLACE = "MARKETPLACE",
}

/**
 * An enumeration.
 */
export enum EngagementCampaignPostStatus {
  APPROVED = "APPROVED",
  EFFECTIVE = "EFFECTIVE",
  INEFFECTIVE = "INEFFECTIVE",
  READY_TO_POST = "READY_TO_POST",
  REJECT = "REJECT",
  REVIEWING = "REVIEWING",
  WARNING = "WARNING",
  WARNING_SOLVED = "WARNING_SOLVED",
}

/**
 * An enumeration.
 */
export enum EngagementCampaignPostStatusForSearch {
  AD_FRAUD = "AD_FRAUD",
  API_ERROR = "API_ERROR",
  APPROVED = "APPROVED",
  DELETED_POST = "DELETED_POST",
  EFFECTIVE = "EFFECTIVE",
  EXPIRED_TOKEN = "EXPIRED_TOKEN",
  INEFFECTIVE = "INEFFECTIVE",
  INVALID_CONTENT = "INVALID_CONTENT",
  READY_TO_POST = "READY_TO_POST",
  REJECT = "REJECT",
  REVIEWING = "REVIEWING",
  SCRAPING_ERROR = "SCRAPING_ERROR",
  WARNING = "WARNING",
  WARNING_SOLVED = "WARNING_SOLVED",
}

/**
 * An enumeration.
 */
export enum EngagementCampaignStatus {
  DEMO = "DEMO",
  DRAFT = "DRAFT",
  FINISHED = "FINISHED",
  LOST = "LOST",
  ONGOING = "ONGOING",
  REVIEWING = "REVIEWING",
  UPCOMING = "UPCOMING",
}

export enum Genders {
  FEMALE = "FEMALE",
  MALE = "MALE",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  UNKNOWN = "UNKNOWN",
}

export enum InfluencerListSortField {
  DATE = "DATE",
  ENGAGEMENT = "ENGAGEMENT",
  ENGAGEMENT_POSTED_COUNT = "ENGAGEMENT_POSTED_COUNT",
  ENGAGEMENT_PROPOSED_COUNT = "ENGAGEMENT_PROPOSED_COUNT",
  ENGAGEMENT_RATE = "ENGAGEMENT_RATE",
  FOLLOWERS = "FOLLOWERS",
  MARKETPLACE_JOINED_COUNT = "MARKETPLACE_JOINED_COUNT",
  MARKETPLACE_POSTED_COUNT = "MARKETPLACE_POSTED_COUNT",
}

/**
 * An enumeration.
 */
export enum InstagramFeedPostSortField {
  COMMENT = "COMMENT",
  ENGAGEMENT = "ENGAGEMENT",
  ENGAGEMENT_RATE = "ENGAGEMENT_RATE",
  IMPRESSIONS = "IMPRESSIONS",
  LIKE = "LIKE",
  NEGATIVE_RATE = "NEGATIVE_RATE",
  POSITIVE_RATE = "POSITIVE_RATE",
  POST_DATE = "POST_DATE",
  REACH = "REACH",
  SAVED = "SAVED",
  VIEW = "VIEW",
}

/**
 * An enumeration.
 */
export enum InstagramInteractionUserSortField {
  COMMENT = "COMMENT",
  LIKE = "LIKE",
  MENTIONED_COMMENT = "MENTIONED_COMMENT",
  MENTIONED_LIKE = "MENTIONED_LIKE",
  MENTIONED_POST = "MENTIONED_POST",
  POST = "POST",
  TAGGED_COMMENT = "TAGGED_COMMENT",
  TAGGED_LIKE = "TAGGED_LIKE",
  TAGGED_POST = "TAGGED_POST",
}

/**
 * An enumeration.
 */
export enum InstagramStoryPostSortField {
  COMMENT = "COMMENT",
  EXITS = "EXITS",
  IMPRESSIONS = "IMPRESSIONS",
  NEGATIVE_RATE = "NEGATIVE_RATE",
  POSITIVE_RATE = "POSITIVE_RATE",
  POST_DATE = "POST_DATE",
  REACH = "REACH",
  TAPS_BACK = "TAPS_BACK",
  TAPS_FORWARD = "TAPS_FORWARD",
}

/**
 * An enumeration.
 */
export enum MarketplaceCampaignPostStatus {
  APPROVED = "APPROVED",
  EFFECTIVE = "EFFECTIVE",
  INEFFECTIVE = "INEFFECTIVE",
  TRACKING = "TRACKING",
  WARNING = "WARNING",
  WARNING_SOLVED = "WARNING_SOLVED",
}

/**
 * An enumeration.
 */
export enum MarketplaceCampaignPostStatusForAdvertiser {
  APPROVED = "APPROVED",
  EFFECTIVE = "EFFECTIVE",
  INEFFECTIVE = "INEFFECTIVE",
  JOINED = "JOINED",
  TRACKING = "TRACKING",
  WARNING = "WARNING",
}

/**
 * An enumeration.
 */
export enum MarketplaceCampaignPostStatusForSearch {
  AD_FRAUD = "AD_FRAUD",
  API_ERROR = "API_ERROR",
  APPROVED = "APPROVED",
  DELETED_POST = "DELETED_POST",
  EFFECTIVE = "EFFECTIVE",
  INEFFECTIVE = "INEFFECTIVE",
  INVALID_CONTENT = "INVALID_CONTENT",
  INVALID_TOKEN = "INVALID_TOKEN",
  JOINED = "JOINED",
  SCRAPING_ERROR = "SCRAPING_ERROR",
  TRACKING = "TRACKING",
  WARNING = "WARNING",
}

/**
 * An enumeration.
 */
export enum MarketplaceCampaignStatus {
  FINISHED = "FINISHED",
  ONGOING = "ONGOING",
  REVIEWING = "REVIEWING",
  SUSPENDED = "SUSPENDED",
  UPCOMING = "UPCOMING",
}

/**
 * An enumeration.
 */
export enum ORDER {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * An enumeration.
 */
export enum PaymentRequestStatus {
  FAILED = "FAILED",
  PAID = "PAID",
  REJECTED = "REJECTED",
  REVIEWING = "REVIEWING",
}

/**
 * An enumeration.
 */
export enum PostListSortField {
  AGE = "AGE",
  COMMENT = "COMMENT",
  COUNTRY = "COUNTRY",
  ENGAGEMENT = "ENGAGEMENT",
  FOLLOWERS = "FOLLOWERS",
  GENDER = "GENDER",
  LIKE = "LIKE",
  POST_DATE = "POST_DATE",
  SAVED = "SAVED",
  SHARE = "SHARE",
  VIEW = "VIEW",
}

export enum QuerySocialAccountType {
  FACEBOOK = "FACEBOOK",
  FACEBOOK_PAGE = "FACEBOOK_PAGE",
  INSTAGRAM = "INSTAGRAM",
  INSTAGRAM_BUSINESS = "INSTAGRAM_BUSINESS",
  TWITTER = "TWITTER",
  YOUTUBE = "YOUTUBE",
}

/**
 * An enumeration.
 */
export enum SelectedInfluencers {
  SELECTED = "SELECTED",
  UNSELECTED = "UNSELECTED",
}

/**
 * An enumeration.
 */
export enum SocialAccountInfluencerListSortField {
  AGE = "AGE",
  CATEGORY = "CATEGORY",
  COMMENT = "COMMENT",
  COUNTRY = "COUNTRY",
  DISLIKE = "DISLIKE",
  ENGAGEMENT_POSTED_COUNT = "ENGAGEMENT_POSTED_COUNT",
  ENGAGEMENT_PROPOSED_COUNT = "ENGAGEMENT_PROPOSED_COUNT",
  GENDER = "GENDER",
  ID = "ID",
  INFLUENCER_ENGAGEMENT = "INFLUENCER_ENGAGEMENT",
  INFLUENCER_ENGAGEMENT_RATE = "INFLUENCER_ENGAGEMENT_RATE",
  INFLUENCER_FOLLOWERS = "INFLUENCER_FOLLOWERS",
  LIKE = "LIKE",
  MARKETPLACE_JOINED_COUNT = "MARKETPLACE_JOINED_COUNT",
  MARKETPLACE_POSTED_COUNT = "MARKETPLACE_POSTED_COUNT",
  REPLY = "REPLY",
  RETWEET = "RETWEET",
  SAVED = "SAVED",
  SHARE = "SHARE",
  SOCIAL_ACCOUNT_ENGAGEMENT = "SOCIAL_ACCOUNT_ENGAGEMENT",
  SOCIAL_ACCOUNT_ENGAGEMENT_RATE = "SOCIAL_ACCOUNT_ENGAGEMENT_RATE",
  SOCIAL_ACCOUNT_FOLLOWERS = "SOCIAL_ACCOUNT_FOLLOWERS",
  STATUS = "STATUS",
  VIEW = "VIEW",
}

export enum SocialAccountStatus {
  SCRAPING = "SCRAPING",
  SIGNED_UP = "SIGNED_UP",
}

/**
 * An enumeration.
 */
export enum SocialAccountType {
  FACEBOOK = "FACEBOOK",
  FACEBOOK_PAGE = "FACEBOOK_PAGE",
  INSTAGRAM = "INSTAGRAM",
  TWITTER = "TWITTER",
  YOUTUBE = "YOUTUBE",
}

/**
 * An enumeration.
 */
export enum TrackingMethod {
  PIXEL = "PIXEL",
  POSTBACK_URL = "POSTBACK_URL",
}

/**
 * An enumeration.
 */
export enum UserRoles {
  ADMINISTRATOR = "ADMINISTRATOR",
  ADVERTISER = "ADVERTISER",
  AGENCY = "AGENCY",
  INFLUENCER = "INFLUENCER",
  STAFF = "STAFF",
}

/**
 * An enumeration.
 */
export enum WarningReason {
  AD_FRAUD = "AD_FRAUD",
  API_ERROR = "API_ERROR",
  DELETED_POST = "DELETED_POST",
  EXPIRED_TOKEN = "EXPIRED_TOKEN",
  INVALID_CONTENT = "INVALID_CONTENT",
  INVALID_TOKEN = "INVALID_TOKEN",
  SCRAPING_ERROR = "SCRAPING_ERROR",
}

export interface AddAllSelectedInfluencersInput {
  marketplaceId: number;
}

export interface AddInstagramCompareAccountAnalyticsInput {
  compareAccountIgUsername: string;
  mainAnalyticsAccountId: number;
}

export interface AddInstagramHashtagAnalyticsInput {
  hashtag: string;
  analyticsAccountId: number;
}

export interface AddMarketplacePostUrlInput {
  marketplaceId: number;
  postUrl: string;
  influencerId: number;
}

export interface AddSelectedInfluencersInput {
  marketplaceId: number;
  influencerIds: number[];
}

export interface AllowEngagementInfluencerProposalListInput {
  campaignId: number;
  influencerIds: number[];
}

export interface AnalyticsSelectSubscriptionPlanInput {
  advertiserId: number;
  plan: AnalyticsSubscriptionPlanType;
}

export interface ApproveMarketplaceInput {
  id: number;
}

export interface CampaignFilter {
  id: number;
  type: CampaignType;
}

export interface CampaignReportOrderBy {
  field?: CampaignReportOrderField | null;
  order?: ORDER | null;
}

export interface CreateAdminInput {
  name: string;
  email: string;
  countryId: string;
  hasInvitationEmail: boolean;
  password?: string | null;
}

export interface CreateAdvertiserInput {
  name: string;
  countryId: string;
  companyUrl?: string | null;
  phoneNumber?: string | null;
}

export interface CreateAdvertiserUserInput {
  name: string;
  email: string;
  countryId: string;
  hasInvitationEmail: boolean;
  password?: string | null;
  advertiserId: number;
}

export interface CreateAgencyInput {
  name: string;
  email: string;
  countryId: string;
  hasInvitationEmail: boolean;
  password?: string | null;
  advertiserIds: number[];
  logo?: string | null;
}

export interface CreateEngagementInput {
  title: string;
  countryIds: string[];
  advertiserId: number;
  startDate: any;
  endDate: any;
  socialMedias: CampaignSocialMediaType[];
  budget: number;
  serviceInformation: string;
  objective: string;
  serviceUrl?: string | null;
  categoryIds: number[];
  numberOfInfluencers?: number | null;
  genders: Genders[];
  minAge: number;
  maxAge: number;
  minFollowers: number;
  maxFollowers: number;
  thumbNail: string;
  materials?: string[] | null;
  hashtags?: string[] | null;
  currency?: string | null;
  marginRate?: number | null;
  adAgencyMarginRate?: number | null;
  influencerAgencyMarginRate?: number | null;
  salesPicIds?: number[] | null;
  influencerManagementPicIds?: number[] | null;
  defaultPostRequirement?: string | null;
  materialUrl?: string | null;
  trackingMethod?: TrackingMethod | null;
  landingPageUrl?: string | null;
}

export interface CreateEngagementPostInputV2 {
  campaignId: number;
  influencerId: number;
  socialMedia: CampaignSocialMediaType;
  planedPostDate?: any | null;
  content?: string | null;
  postUrl?: string | null;
  materialsUrl?: string[] | null;
  socialAccountId: number;
  socialAccountMedia: SocialAccountType;
}

export interface CreateInfluencerInputV2 {
  facebookAccount?: InfluencerSocialNetworkAccountInput | null;
  facebookPages?: InfluencerSocialNetworkAccountInput[] | null;
  instagramAccounts?: InfluencerSocialNetworkAccountInput[] | null;
  twitterAccounts?: InfluencerSocialNetworkAccountInput[] | null;
  youtubeAccounts?: InfluencerSocialNetworkAccountInput[] | null;
  name: string;
  gender: Genders;
  email?: string | null;
  phoneNumber?: string | null;
  dateOfBirth?: any | null;
  introduce?: string | null;
  campaignDisplay: boolean;
  notificationSetting: boolean;
  tags: string[];
  defaultEngagementSelectionReason?: string | null;
  internalMemo?: string | null;
  categoryIds: number[];
  countryId: string;
  regionId?: number | null;
}

export interface CreateInstagramAccountAnalyticsAdvInput {
  instagramUserId: string;
}

export interface CreateInstagramAccountAnalyticsInput {
  instagramUserId: string;
  advertiserId: number;
  accountManagersIds?: number[] | null;
}

export interface CreateMarketplaceInput {
  title: string;
  countryId: string;
  regionIds: number[];
  advertiserId: number;
  startDate: any;
  endDate: any;
  socialMedia: CampaignSocialMediaType;
  costPerPost?: number | null;
  costPerLike?: number | null;
  costPerView?: number | null;
  costPerComment?: number | null;
  costPerShare?: number | null;
  costPerFollower?: number | null;
  costPerClick?: number | null;
  costPerAction?: number | null;
  budget: number;
  serviceInformation: string;
  requirement: string;
  hashtags: string[];
  clickUrl?: string | null;
  categoryIds: number[];
  genders: Genders[];
  minAge: number;
  maxAge: number;
  minFollowers: number;
  maxFollowers: number;
  maximumRevenuePerInfluencer?: number | null;
  thumbNail: string;
  materials?: string[] | null;
  marginRate: number;
  agencyMarginRate?: number | null;
  trackingMethod?: TrackingMethod | null;
  landingPageUrl?: string | null;
  salesPicIds?: number[] | null;
  influencerManagementPicIds?: number[] | null;
  allowNewInfluencer: boolean;
  materialUrl?: string | null;
}

export interface CreateSelfRegisteredAdvertiserUserInput {
  advertiserCompanyName: string;
  advertiserCompanyUrl: string;
  advertiserPhoneNumber: string;
  countryId: string;
  userName: string;
  userEmail: string;
}

export interface CreateSocialAccountAnalyticsAdminInputV2 {
  instagramUserId?: string | null;
  youtubeChannelId?: string | null;
  accountName: string;
  advertiserId: number;
  accountManagersPICs?: number[] | null;
}

export interface CreateSocialAccountAnalyticsAdvertiserInputV2 {
  instagramUserId?: string | null;
  youtubeChannelId?: string | null;
  accountName: string;
}

export interface CreateStaffInput {
  name: string;
  email: string;
  countryId: string;
  hasInvitationEmail: boolean;
  password?: string | null;
  advertiserIds: number[];
}

export interface DeleteEngagementInfluencerProposalInput {
  campaignId: number;
  influencerIds: number[];
}

export interface DeleteInstagramCompareAccountAnalyticsInput {
  compareAccountId: number;
  mainAnalyticsAccountId: number;
}

export interface DeleteInstagramHashtagAnalyticsInput {
  analyticsAccountId: number;
  hashtagId: number;
}

export interface DenyEngagementInfluencerProposalListInput {
  campaignId: number;
  influencerIds: number[];
  deniedReason: string;
}

export interface EditSocialAccountAnalyticsAdminInputV2 {
  brandAccountId: number;
  igFacebookPageId?: string | null;
  youtubeChannelId?: string | null;
  accountName: string;
  accountManagersPICs?: number[] | null;
}

export interface EditSocialAccountAnalyticsAdvertiserInputV2 {
  brandAccountId: number;
  igFacebookPageId?: string | null;
  youtubeChannelId?: string | null;
  accountName: string;
}

export interface FollowersAgeGroupInput {
  start: number;
  end: number;
}

export interface GetSelectableYoutubeAnalyticsChannelsInput {
  response: string;
  callbackUrl: string;
}

export interface InfluencerOrderBy {
  field?: InfluencerListSortField | null;
  order?: ORDER | null;
}

export interface InfluencerSocialNetworkAccountInput {
  username: string;
  exclusive: boolean;
}

export interface InstagramFeedPostOrderBy {
  field?: InstagramFeedPostSortField | null;
  order?: ORDER | null;
}

export interface InstagramInteractionUserOrderBy {
  field?: InstagramInteractionUserSortField | null;
  order?: ORDER | null;
}

export interface InstagramStoryPostOrderBy {
  field?: InstagramStoryPostSortField | null;
  order?: ORDER | null;
}

export interface ObtainJSONWebTokenInput {
  email: string;
  password: string;
}

export interface PostOrderBy {
  field?: PostListSortField | null;
  order?: ORDER | null;
}

export interface RefreshPasswordInput {
  email?: string | null;
}

export interface RejectEngagementDraftPostInput {
  id: number;
  reason: string;
  detailedReason?: string | null;
}

export interface RemoveAllSelectedInfluencersInput {
  marketplaceId: number;
}

export interface RemoveSelectedInfluencersInput {
  marketplaceId: number;
  influencerIds: number[];
}

export interface ReportEngagementPostStatusInput {
  id: number;
  reason: string;
  detailedReason?: string | null;
}

export interface ReportMarketplacePostStatusInput {
  id: number;
  reason: string;
  detailedReason?: string | null;
}

export interface ResendEmailForAdvertiserInput {
  email: string;
}

export interface ResetPasswordInput {
  token?: string | null;
  password?: string | null;
}

export interface SocialAccountInfluencerAudience {
  gender: Genders;
  age?: FollowersAgeGroupInput | null;
  minPercentage: number;
}

export interface SocialAccountInfluencerOrderBy {
  field?: SocialAccountInfluencerListSortField | null;
  order?: ORDER | null;
}

export interface SocialAuthConnectableInstagramAccountsAnalyticsInput {
  response: string;
  callbackUrl: string;
}

/**
 * `businessAccountId`: Instagram business account id.
 * `response`: response from Facebook API. Used to get the access token
 * `callbackUrl`: callback URL from Facebook API. Used to get the access token
 * `redirectType`: type of redirect. We have many pages to redirect to when Facebook is done with processing our requests.
 *  We can go back to "account list", "add accounts" page, and etc. Used to get the access token
 * `accountId`: analytics account id.
 * `username`: reconnected Instagram account username.
 * `fbPageId`: id of the Facebook page the reconnected account is connected to.
 */
export interface SocialAuthReconnectInstagramAccountAnalyticsInput {
  businessAccountId: string;
  response: string;
  callbackUrl: string;
  redirectType: AnalyticsRedirectType;
  accountId: number;
  username: string;
  fbPageId?: string | null;
}

/**
 * `channelId`: id of the YouTube channel the reconnected account is connected to.
 * `callbackUrl`: callback URL from Google API. Used to get the access token
 * `response`: response from Google API. Used to get the access token
 */
export interface SocialAuthReconnectYouTubeAccountAnalyticsInput {
  channelId: string;
  response: string;
  callbackUrl: string;
}

export interface UpdateAdminInput {
  id: number;
  name?: string | null;
  email?: string | null;
  countryId?: string | null;
  password?: string | null;
}

export interface UpdateAdvertiserInput {
  id: number;
  name?: string | null;
  countryId?: string | null;
  phoneNumber?: string | null;
  companyUrl?: string | null;
}

export interface UpdateAdvertiserUserInput {
  id: number;
  name?: string | null;
  email?: string | null;
  countryId?: string | null;
  password?: string | null;
  advertiserId?: number | null;
}

export interface UpdateAdvertiserUserSettingsInput {
  advertiserName: string;
  advertiserPhoneNumber: string;
  advertiserCountryId: string;
  advertiserCompanyUrl: string;
  name: string;
  email: string;
  countryId: string;
}

export interface UpdateAgencyInput {
  id: number;
  name?: string | null;
  email?: string | null;
  countryId?: string | null;
  password?: string | null;
  advertiserIds?: number[] | null;
  logo?: string | null;
}

export interface UpdateEngagementInfluencerProposalInput {
  campaignId: number;
  influencerId: number;
  budget: number;
  influencerCost: number;
  requirements: string;
  reason: string;
  profit: number;
  adAgencyMarginValue?: number | null;
  influencerAgencyMarginValue?: number | null;
}

export interface UpdateEngagementInfluencerProposalListInput {
  campaignId: number;
  influencerIds: number[];
}

export interface UpdateEngagementInput {
  title: string;
  countryIds: string[];
  advertiserId: number;
  startDate: any;
  endDate: any;
  socialMedias: CampaignSocialMediaType[];
  budget: number;
  serviceInformation: string;
  objective: string;
  serviceUrl?: string | null;
  categoryIds: number[];
  numberOfInfluencers?: number | null;
  genders: Genders[];
  minAge: number;
  maxAge: number;
  minFollowers: number;
  maxFollowers: number;
  thumbNail: string;
  materials?: string[] | null;
  hashtags?: string[] | null;
  currency?: string | null;
  marginRate?: number | null;
  adAgencyMarginRate?: number | null;
  influencerAgencyMarginRate?: number | null;
  salesPicIds?: number[] | null;
  influencerManagementPicIds?: number[] | null;
  defaultPostRequirement?: string | null;
  materialUrl?: string | null;
  trackingMethod?: TrackingMethod | null;
  landingPageUrl?: string | null;
  id: number;
}

export interface UpdateEngagementPostInputV2 {
  campaignId: number;
  influencerId: number;
  socialMedia: CampaignSocialMediaType;
  planedPostDate?: any | null;
  content?: string | null;
  postUrl?: string | null;
  materialsUrl?: string[] | null;
  id: number;
  saved?: number | null;
  socialAccountId: number;
  socialAccountMedia: SocialAccountType;
}

export interface UpdateEngagementPostStatusInput {
  id: number;
  status: EngagementCampaignPostStatus;
}

export interface UpdateEngagementStatusInput {
  id: number;
  status: EngagementCampaignStatus;
}

export interface UpdateInfluencerInputV2 {
  facebookAccount?: InfluencerSocialNetworkAccountInput | null;
  facebookPages?: InfluencerSocialNetworkAccountInput[] | null;
  instagramAccounts?: InfluencerSocialNetworkAccountInput[] | null;
  twitterAccounts?: InfluencerSocialNetworkAccountInput[] | null;
  youtubeAccounts?: InfluencerSocialNetworkAccountInput[] | null;
  name: string;
  gender: Genders;
  email?: string | null;
  phoneNumber?: string | null;
  dateOfBirth?: any | null;
  introduce?: string | null;
  campaignDisplay: boolean;
  notificationSetting: boolean;
  tags: string[];
  defaultEngagementSelectionReason?: string | null;
  internalMemo?: string | null;
  id: number;
  categoryIds: number[];
  countryId: string;
  regionId?: number | null;
}

export interface UpdateInstagramAccountAnalyticsInput {
  id: number;
  accountManagersIds?: number[] | null;
}

export interface UpdateMarketplaceInput {
  title: string;
  countryId: string;
  regionIds: number[];
  advertiserId: number;
  startDate: any;
  endDate: any;
  socialMedia: CampaignSocialMediaType;
  costPerPost?: number | null;
  costPerLike?: number | null;
  costPerView?: number | null;
  costPerComment?: number | null;
  costPerShare?: number | null;
  costPerFollower?: number | null;
  costPerClick?: number | null;
  costPerAction?: number | null;
  budget: number;
  serviceInformation: string;
  requirement: string;
  hashtags: string[];
  clickUrl?: string | null;
  categoryIds: number[];
  genders: Genders[];
  minAge: number;
  maxAge: number;
  minFollowers: number;
  maxFollowers: number;
  maximumRevenuePerInfluencer?: number | null;
  thumbNail: string;
  materials?: string[] | null;
  marginRate: number;
  agencyMarginRate?: number | null;
  trackingMethod?: TrackingMethod | null;
  landingPageUrl?: string | null;
  salesPicIds?: number[] | null;
  influencerManagementPicIds?: number[] | null;
  allowNewInfluencer: boolean;
  materialUrl?: string | null;
  id: number;
}

export interface UpdateMarketplacePostStatusInput {
  id: number;
  status: MarketplaceCampaignPostStatus;
}

export interface UpdatePasswordByAdvertiserUserInput {
  originalPassword: string;
  newPassword: string;
}

export interface UpdatePaymentStatusInput {
  id: number;
  status: PaymentRequestStatus;
}

export interface UpdateStaffInput {
  id: number;
  name?: string | null;
  email?: string | null;
  countryId?: string | null;
  password?: string | null;
  advertiserIds?: number[] | null;
}

export interface VerifyInput {
  token: string;
  clientMutationId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
