import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { formatPercent, renderIfMoreThan10Percent } from '../../../../libs/format';

interface GenderBreakdownProps {
  male: number;
  female: number;
}

const GenderBreakdown = (props: GenderBreakdownProps) => {
  const { male, female } = props;

  const { t } = useTranslation();

  return (
    <Container>
      <BreakdownWrapper>
        <Female male={male} female={female} title={formatPercent(female, true, 1)}>
          {renderIfMoreThan10Percent({ value: female, digits: 1 })}
        </Female>
        <Male male={male} female={female} title={formatPercent(male, true, 1)}>
          {renderIfMoreThan10Percent({ value: male, digits: 1 })}
        </Male>
      </BreakdownWrapper>
      <LabelContainer>
        <Label>{t('Female')}</Label>
        <Label>{t('Male')}</Label>
      </LabelContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;
const BreakdownWrapper = styled.div`
  display: flex;
  color: #fff;
  height: 32px;
`;
const Gender = styled.div<GenderBreakdownProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
  cursor: pointer;

  &:hover {
    box-shadow: 0 3px 4px 0 rgba(39, 49, 59, 0.2);
    margin-bottom: 2px;
    margin-top: -2px;
  }
`;
const Male = styled(Gender)<GenderBreakdownProps>`
  background-color: #707fc0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: ${props => `${(props.male / (props.female + props.male)) * 100}%`};
`;
const Female = styled(Gender)<GenderBreakdownProps>`
  background-color: #ff8097;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: ${props => `${(props.female / (props.female + props.male)) * 100}%`};
`;
const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`;
const Label = styled.p`
  font-size: 11px;
  font-weight: 600;
  color: #27313b;
`;

export default GenderBreakdown;
