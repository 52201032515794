import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AppRoute from './components/organisms/AppRoute';
import VerifyToken from './components/organisms/VerifyToken';
import { withAdminStaffAdvertiserAgency } from './libs/auth/hoc';
import { MenuTitle } from './libs/constant';
import * as pages from './pages';
import ScrollToTop from './ScrollToTop';
import { WithAnalyticsHeader } from './components/atoms/QueryWrappers';

declare const IS_DISABLED_ON_PROD: boolean;

const Routes = () => (
  <Router>
    <VerifyToken>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact={true} component={pages.SignIn} />
          <Route path="/signup" exact component={pages.SignUp} />
          <Route path="/signup/success" exact component={pages.SignUpSuccess} />
          <Route path="/privacy" exact={true} component={pages.PrivacyPolicy} />
          <Route path="/terms" component={pages.TermsOfService} />
          <Route path="/subscription" component={pages.Subscription} />

          <Route path="/password_forgot" component={pages.PasswordForgot} />
          <Route path="/password_register/:token" component={pages.PasswordRegister} />
          <Route path="/password_reset/:token" component={pages.PasswordRegister} />

          <Route path="/redirect/analytics-signin/:provider" component={pages.RedirectAnalyticsSignin} />
          <Route path="/redirect/analytics-connect/:provider" component={pages.RedirectAnalyticsConnect} />
          <Route path="/redirect/analytics-list-connect/:provider" component={pages.RedirectAnalyticsListConnect} />

          {/* Analytics */}
          <AppRoute
            path="/analytics"
            exact
            menuTitle={MenuTitle.Analytics}
            component={withAdminStaffAdvertiserAgency(pages.Analytics)}
          />

          {!IS_DISABLED_ON_PROD && (
            <AppRoute
              path="/analytics_v2/dashboard/:id/:social/:tab?"
              key="/analytics_v2/dashboard/:id/:social/:tab?"
              exact
              menuTitle={MenuTitle.Analytics}
              component={withAdminStaffAdvertiserAgency((props: any) => (
                <WithAnalyticsHeader>
                  <pages.AnalyticsDetails {...props} />
                </WithAnalyticsHeader>
              ))}
            />
          )}
          <AppRoute
            path="/analytics/add"
            exact
            menuTitle={MenuTitle.Analytics}
            component={withAdminStaffAdvertiserAgency(pages.AnalyticsAdd)}
          />
          <AppRoute
            path="/analytics/:id"
            exact
            menuTitle={MenuTitle.Analytics}
            component={withAdminStaffAdvertiserAgency(pages.AnalyticsEdit)}
          />
          {/* Analytics Dashboard */}
          <AppRoute
            path="/analytics/dashboard/:id"
            exact
            menuTitle={MenuTitle.Analytics}
            component={withAdminStaffAdvertiserAgency((props: any) => (
              <WithAnalyticsHeader>
                <pages.AnalyticsDetails {...props} />
              </WithAnalyticsHeader>
            ))}
          />
          <AppRoute
            path="/analytics/dashboard/:id/instagram_compare_feed_post"
            exact
            menuTitle={MenuTitle.Analytics}
            component={withAdminStaffAdvertiserAgency(() => (
              <WithAnalyticsHeader>
                <pages.InstagramCompareFeedPost />
              </WithAnalyticsHeader>
            ))}
          />
          <AppRoute
            path="/analytics/dashboard/:id/instagram_hashtag_feed_post"
            exact
            menuTitle={MenuTitle.Analytics}
            component={withAdminStaffAdvertiserAgency(() => (
              <WithAnalyticsHeader>
                <pages.InstagramHashtagFeedPost />
              </WithAnalyticsHeader>
            ))}
          />
          <AppRoute
            path="/analytics/dashboard/:id/compare"
            menuTitle={MenuTitle.Analytics}
            component={withAdminStaffAdvertiserAgency((props: any) => (
              <WithAnalyticsHeader>
                <pages.AnalyticsDetails {...props} />
              </WithAnalyticsHeader>
            ))}
          />
          <AppRoute
            path="/analytics/dashboard/:id/hashtag"
            menuTitle={MenuTitle.Analytics}
            component={withAdminStaffAdvertiserAgency((props: any) => (
              <WithAnalyticsHeader>
                <pages.AnalyticsDetails {...props} />
              </WithAnalyticsHeader>
            ))}
          />
          <AppRoute
            path="/analytics/dashboard/:id/interaction"
            exact
            menuTitle={MenuTitle.Analytics}
            component={withAdminStaffAdvertiserAgency((props: any) => (
              <WithAnalyticsHeader>
                <pages.AnalyticsDetails {...props} />
              </WithAnalyticsHeader>
            ))}
          />
          <AppRoute
            path="/analytics/dashboard/:id/instagram_feed_post"
            exact
            menuTitle={MenuTitle.Analytics}
            component={withAdminStaffAdvertiserAgency(() => (
              <WithAnalyticsHeader>
                <pages.InstagramFeedPost />
              </WithAnalyticsHeader>
            ))}
          />
          <AppRoute
            path="/analytics/dashboard/:id/instagram_story_post"
            exact
            menuTitle={MenuTitle.Analytics}
            component={withAdminStaffAdvertiserAgency(() => (
              <WithAnalyticsHeader>
                <pages.InstagramStoryPost />
              </WithAnalyticsHeader>
            ))}
          />
          <AppRoute
            path="/analytics/dashboard/:id/interaction/user_analysis"
            exact
            menuTitle={MenuTitle.Analytics}
            component={withAdminStaffAdvertiserAgency(() => (
              <WithAnalyticsHeader>
                <pages.UserAnalysis />
              </WithAnalyticsHeader>
            ))}
          />
          <AppRoute
            path="/analytics/dashboard/:id/interaction/tagged_posts"
            exact
            menuTitle={MenuTitle.Analytics}
            component={withAdminStaffAdvertiserAgency(() => (
              <WithAnalyticsHeader>
                <pages.TaggedPosts />
              </WithAnalyticsHeader>
            ))}
          />
          <AppRoute
            path="/analytics/dashboard/:id/interaction/mentioned_posts"
            exact
            menuTitle={MenuTitle.Analytics}
            component={withAdminStaffAdvertiserAgency(() => (
              <WithAnalyticsHeader>
                <pages.MentionedPosts />
              </WithAnalyticsHeader>
            ))}
          />
          <Route path="/notfound" component={pages.NotFound} />
          <Route component={pages.NotFound} />
        </Switch>
      </ScrollToTop>
    </VerifyToken>
  </Router>
);

export default Routes;
