import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSort } from '../../libs/filter';
import { usePaging } from '../../libs/paging';
import { getThirtyDaysAgo, getToday } from '../../libs/date';
import { useInstagramAnalyticsHeader } from '../../libs/hooks';
import ListIndicator from '../../components/molecules/ListIndicator';
import { getInstagramFeedPostSortFieldType } from '../../libs/filter/enum';
import { InstagramFeedPostSortField } from '../../__generated__/globalTypes';
import { useFilter } from '../../components/organisms/AnalyticsShared/BasePostListFilter/useFilter';
import {
  InstagramAnalyticsCompareAccounts,
  InstagramAnalyticsCompareAccountsVariables
} from '../../components/organisms/AnalyticsCompare/__generated__/InstagramAnalyticsCompareAccounts';
import * as COMPARABLE_ACCOUNTS_LIST from '../../components/organisms/AnalyticsCompare/InstagramAnalyticsCompareAccounts.graphql';
import { ComparableAccountType } from '../../components/organisms/AnalyticsCompare/types';
import Template from './template';

const InstagramCompareFeedPost = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  // Paging
  const currentPage = usePaging();
  // Filter
  const { filter, setFilter } = useFilter({ startDate: getThirtyDaysAgo(), endDate: getToday() });
  // Sort
  const { sort, setSort } = useSort<InstagramFeedPostSortField>(getInstagramFeedPostSortFieldType);

  const {
    analyticsAccountInfo: { accountName: username, avatar: avatarUrl, id }
  } = useInstagramAnalyticsHeader();

  const defaultAccount = { username, avatarUrl, id, isSelected: true, blocked: false };

  const { loading, error } = useQuery<InstagramAnalyticsCompareAccounts, InstagramAnalyticsCompareAccountsVariables>(
    COMPARABLE_ACCOUNTS_LIST,
    {
      variables: { accountToCompareWithId: id },
      fetchPolicy: 'cache-and-network',
      onCompleted: useCallback(({ instagramCompareGetAccounts }: InstagramAnalyticsCompareAccounts) => {
        if (instagramCompareGetAccounts?.accounts) {
          const accounts =
            instagramCompareGetAccounts?.accounts
              .filter(acc => !acc.blocked)
              .map(acc => ({ ...acc, isSelected: false })) || [];

          setAllComparableAccounts([defaultAccount, ...accounts]);

          return;
        }

        handleRedirectToAnalytics();
      }, [])
    }
  );

  const [allComparableAccounts, setAllComparableAccounts] = useState<ComparableAccountType[]>([defaultAccount]);

  const { id: selectedAccountId, elementId: selectedElementId } = allComparableAccounts.find(acc => acc.isSelected) || {
    id: undefined,
    elementId: undefined
  };

  const handleRedirectToAnalytics = () => {
    history.push('/analytics');
    enqueueSnackbar(t('Can not find comparable accounts information'), { variant: 'error' });
  };

  if (loading) {
    return <StyledLoadingIndicator />;
  }

  if (error || selectedAccountId === undefined) {
    history.push('/analytics');
    enqueueSnackbar(t('Can not find comparable accounts information'), { variant: 'error' });

    return null;
  }

  const handleTabChange = (accountId: string, elementId?: number) => {
    setAllComparableAccounts(
      allComparableAccounts.map(acc => {
        if (acc.id === Number(accountId) && elementId === acc.elementId) {
          return { ...acc, isSelected: true };
        } else {
          return { ...acc, isSelected: false };
        }
      })
    );
  };

  return (
    <Template
      accountName={username}
      accountId={id}
      currentPage={currentPage}
      filter={filter}
      setFilter={setFilter}
      sort={sort}
      setSort={setSort}
      comparableAccounts={allComparableAccounts}
      onChangeTab={handleTabChange}
      selectedAccountId={selectedAccountId}
      selectedElementId={selectedElementId}
    />
  );
};

const StyledLoadingIndicator = styled(ListIndicator)`
  margin: 0;
`;

export default InstagramCompareFeedPost;
