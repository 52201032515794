import styled from '@emotion/styled';
import * as React from 'react';
import { formatDate } from '../../../libs/format';

interface DateColumnProps {
  date: string | null;
  className?: string;
}

const DateColumn = (props: DateColumnProps) => {
  const { date, className } = props;

  return (
    <Wrapper className={className}>
      <Data>
        {date ? (
          <>
            {formatDate(date, 'MMM dd')}
            <br />
            <Year>{formatDate(date, 'yyyy')}</Year>
          </>
        ) : (
          '-'
        )}
      </Data>
    </Wrapper>
  );
};

const Wrapper = styled.td`
  height: 56px;
  padding: 0 8px;
`;

const Data = styled.p`
  font-size: 13px;
  text-align: left;
  white-space: nowrap;
`;

const Year = styled.span`
  color: #8694a2;
  font-size: 11px;
  text-align: center;
`;

export default DateColumn;
