import React from 'react';
import { useUpdateDocumentTitle } from '../../libs/hooks';
import Template from './template';

const Subscription = () => {
  useUpdateDocumentTitle({
    title: 'documentTitle.Analytics Account Plan',
    appHeader: '',
    href: ''
  });

  return <Template />;
};

export default Subscription;
