import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from '@emotion/styled';
import css from '@emotion/css';
import { useMutation } from '@apollo/client';
import Footer from '../../components/organisms/Footer';
import SignInSignUpHeader from '../../components/organisms/SignIn/SignInSignUpHeader';
import { checkSignedIn } from '../../libs/auth';
import { useQueryHelper, useUpdateDocumentTitle } from '../../libs/hooks';
import mailOpened from '../../assets/icon/mail-opened.svg';
import ListIndicator from '../../components/molecules/ListIndicator';
import { Container, Wrapper } from './template';
import * as RESEND_EMAIL from './ResendEmailForAdvertiser.graphql';
import { ResendEmailForAdvertiserVariables } from './__generated__/ResendEmailForAdvertiser';

const SignUpSuccess = () => {
  useUpdateDocumentTitle({ title: 'documentTitle.SignUp', appHeader: '', href: '' });

  const { t, enqueueSnackbar } = useQueryHelper();
  const [loading, setLoading] = useState(false);
  const [resendEmail] = useMutation<ResendEmailForAdvertiserVariables>(RESEND_EMAIL);

  if (checkSignedIn()) {
    return <Redirect to="/analytics" />;
  }

  const email = localStorage.getItem('confirmationEmail');

  const handleResendEmail = async () => {
    setLoading(true);
    try {
      await resendEmail({ variables: { input: { email } } });
      enqueueSnackbar('Confirmation email has been resended', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to resend email, please try again', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SignInSignUpHeader />
      <Container>
        <Wrapper
          css={css`
            flex-direction: column;
            align-items: center;
          `}
        >
          {loading ? (
            <StyledListIndicator />
          ) : (
            <>
              <MailIcon src={mailOpened} alt="opened mail" />
              <MainTitle>{t('We have sent a confirmation email')}</MainTitle>
              {!!email && <Email>{email}</Email>}
              <ConfirmationDescription>
                {t('SignUp.Confirmation_1')}
                <br />
                {t('SignUp.Confirmation_2')}
              </ConfirmationDescription>
              <ResendEmail onClick={handleResendEmail}>{t('Resend confirmation email')}</ResendEmail>
            </>
          )}
        </Wrapper>
        <Footer />
      </Container>
    </>
  );
};

const MailIcon = styled.img`
  margin-top: 80px;
`;
const MainTitle = styled.h1`
  margin-top: 27px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #27313b;
`;
const Email = styled.span`
  margin-top: 24px;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
`;
const ConfirmationDescription = styled.p`
  width: max-content;
  margin-top: 24px;
  font-size: 13px;
  text-align: center;
`;
const ResendEmail = styled.button`
  margin: 40px 0 80px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: #3892e5;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const StyledListIndicator = styled(ListIndicator)`
  width: 100%;
  height: 370px;
`;

export default SignUpSuccess;
