import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '../Button';

interface PostingHabitsColumnProps {
  className?: string;
  timeslots: string[];
  onClickAdd: () => void;
  isTable?: boolean;
}

const PostingHabitsColumn = (props: PostingHabitsColumnProps) => {
  const { className, timeslots, onClickAdd, isTable = true } = props;

  const { t } = useTranslation();
  const Wrapper = isTable ? TableElement : DivElement;

  return (
    <Wrapper className={className}>
      <FlexWrapper>
        <TableData>
          <TimeSlotsWrapper>
            <TimeSlot>
              <Label>{t('First time zone')}</Label>
              <DateTime>{timeslots[0]}</DateTime>
            </TimeSlot>
            <TimeSlot>
              <Label>{t('Second time zone')}</Label>
              <DateTime>{timeslots[1]}</DateTime>
            </TimeSlot>
            <TimeSlot>
              <Label>{t('Third time zone')}</Label>
              <DateTime>{timeslots[2]}</DateTime>
            </TimeSlot>
            <AddButton icon="add" color="#6e7c89" handleClick={onClickAdd} />
          </TimeSlotsWrapper>
        </TableData>
      </FlexWrapper>
    </Wrapper>
  );
};

const DivElement = styled.div`
  padding: 16px;

  &:not(:last-of-type) {
    border-right: 1px solid #dee5ec;
  }
`;
const TableElement = styled(DivElement.withComponent('td'))``;
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TableData = styled.div`
  display: inline-block;
  font-size: 13px;
  line-height: 16px;
  width: 100%;
`;
const Label = styled.p`
  font-size: 11px;
  color: #27313b;
  margin-bottom: 12px;
`;
const DateTime = styled.p`
  font-size: 13px;
  font-weight: 600;
  color: #27313b;
`;
const TimeSlot = styled.div``;
const TimeSlotsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const AddButton = styled(IconButton)`
  border: 1px solid #dee5ec;
  height: 32px;
  width: 32px;
  line-height: 0;
`;

export default PostingHabitsColumn;
