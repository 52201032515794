import * as yup from 'yup';
import {
  AdvertiserSchema,
  BudgetSchema,
  CategoriesSchema,
  CountrySchema,
  GendersSchema,
  MaxAgeSchema,
  MaxFollowersSchema,
  MinAgeSchema,
  MinFollowersSchema,
  PeriodSchema,
  PositiveNumberSchema,
  ServiceInformationSchema,
  TitleSchema
} from './share';

const RequirementSchema = yup.string().required('requiredRequirementMessage');
const ClickUrlSchema = yup.string().url('invalidUrlMessage');
const SocialMediaSchema = yup.string().required('requiredSocialMediaMessage');
const ThumbnailAdvertiserSchema = yup
  .array()
  .of(yup.string().required('requiredThumbnailMessage'))
  .required('requiredThumbnailMessage');

const HashtagsSchema = yup
  .array()
  .of(yup.string())
  .compact()
  .test('isUnique', 'HashTagsMustUniqueMessage', values => values.length === new Set(values).size)
  .min(3, (props: any) => `leastHashtagsMessage_${props.min}`);

// https://github.com/jquense/yup/issues/176#issuecomment-369925782
const CostPerPostSchema = PositiveNumberSchema.when(
  ['costPerLike', 'costPerView', 'costPerShare', 'costPerComment', 'costPerFollower', 'costPerClick', 'costPerAction'],
  {
    is: (
      costPerLike: number,
      costPerView: number,
      costPerShare: number,
      costPerComment: number,
      costPerFollower: number,
      costPerClick: number,
      costPerAction: number
    ) =>
      !costPerLike &&
      !costPerView &&
      !costPerShare &&
      !costPerComment &&
      !costPerFollower &&
      !costPerClick &&
      !costPerAction,
    then: yup.number().required('requiredAtLeastOneCostMessage')
  }
).when('budget', (budget: number, schema: any) =>
  !!budget ? schema.max(budget, 'costMustBeLessThanOrEqualToBudgetMessage') : schema
);
const CostPerLikeSchema = PositiveNumberSchema.when(
  ['costPerPost', 'costPerView', 'costPerShare', 'costPerComment', 'costPerFollower', 'costPerClick', 'costPerAction'],
  {
    is: (
      costPerPost: number,
      costPerView: number,
      costPerShare: number,
      costPerComment: number,
      costPerFollower: number,
      costPerClick: number,
      costPerAction: number
    ) =>
      !costPerPost &&
      !costPerView &&
      !costPerShare &&
      !costPerComment &&
      !costPerFollower &&
      !costPerClick &&
      !costPerAction,
    then: yup.number().required('requiredAtLeastOneCostMessage')
  }
).when('budget', (budget: number, schema: any) =>
  !!budget ? schema.max(budget, 'costMustBeLessThanOrEqualToBudgetMessage') : schema
);
const CostPerViewSchema = PositiveNumberSchema.when(
  ['costPerPost', 'costPerLike', 'costPerShare', 'costPerComment', 'costPerFollower', 'costPerClick', 'costPerAction'],
  {
    is: (
      costPerPost: number,
      costPerLike: number,
      costPerShare: number,
      costPerComment: number,
      costPerFollower: number,
      costPerClick: number,
      costPerAction: number
    ) =>
      !costPerPost &&
      !costPerLike &&
      !costPerShare &&
      !costPerComment &&
      !costPerFollower &&
      !costPerClick &&
      !costPerAction,
    then: yup.number().required('requiredAtLeastOneCostMessage')
  }
).when('budget', (budget: number, schema: any) =>
  !!budget ? schema.max(budget, 'costMustBeLessThanOrEqualToBudgetMessage') : schema
);
const CostPerShareSchema = PositiveNumberSchema.when(
  ['costPerPost', 'costPerLike', 'costPerView', 'costPerComment', 'costPerFollower', 'costPerClick', 'costPerAction'],
  {
    is: (
      costPerPost: number,
      costPerLike: number,
      costPerView: number,
      costPerComment: number,
      costPerFollower: number,
      costPerClick: number,
      costPerAction: number
    ) =>
      !costPerPost &&
      !costPerLike &&
      !costPerView &&
      !costPerComment &&
      !costPerFollower &&
      !costPerClick &&
      !costPerAction,
    then: yup.number().required('requiredAtLeastOneCostMessage')
  }
).when('budget', (budget: number, schema: any) =>
  !!budget ? schema.max(budget, 'costMustBeLessThanOrEqualToBudgetMessage') : schema
);
const CostPerCommentSchema = PositiveNumberSchema.when(
  ['costPerPost', 'costPerLike', 'costPerView', 'costPerShare', 'costPerFollower', 'costPerClick', 'costPerAction'],
  {
    is: (
      costPerPost: number,
      costPerLike: number,
      costPerView: number,
      costPerShare: number,
      costPerFollower: number,
      costPerClick: number,
      costPerAction: number
    ) =>
      !costPerPost &&
      !costPerLike &&
      !costPerView &&
      !costPerShare &&
      !costPerFollower &&
      !costPerClick &&
      !costPerAction,
    then: yup.number().required('requiredAtLeastOneCostMessage')
  }
).when('budget', (budget: number, schema: any) =>
  !!budget ? schema.max(budget, 'costMustBeLessThanOrEqualToBudgetMessage') : schema
);
const CostPerFollowerSchema = PositiveNumberSchema.when(
  ['costPerPost', 'costPerLike', 'costPerView', 'costPerShare', 'costPerComment', 'costPerClick', 'costPerAction'],
  {
    is: (
      costPerPost: number,
      costPerLike: number,
      costPerView: number,
      costPerShare: number,
      costPerComment: number,
      costPerClick: number,
      costPerAction: number
    ) =>
      !costPerPost &&
      !costPerLike &&
      !costPerView &&
      !costPerShare &&
      !costPerComment &&
      !costPerClick &&
      !costPerAction,
    then: yup.number().required('requiredAtLeastOneCostMessage')
  }
).when('budget', (budget: number, schema: any) =>
  !!budget ? schema.max(budget, 'costMustBeLessThanOrEqualToBudgetMessage') : schema
);

const CostPerClickSchema = PositiveNumberSchema.when(
  ['costPerPost', 'costPerLike', 'costPerView', 'costPerShare', 'costPerComment', 'costPerFollower', 'costPerAction'],
  {
    is: (
      costPerPost: number,
      costPerLike: number,
      costPerView: number,
      costPerShare: number,
      costPerComment: number,
      costPerFollower: number,
      costPerAction: number
    ) =>
      !costPerPost &&
      !costPerLike &&
      !costPerView &&
      !costPerShare &&
      !costPerComment &&
      !costPerFollower &&
      !costPerAction,
    then: yup.number().required('requiredAtLeastOneCostMessage')
  }
).when('budget', (budget: number, schema: any) =>
  !!budget ? schema.max(budget, 'costMustBeLessThanOrEqualToBudgetMessage') : schema
);

const CostPerActionSchema = PositiveNumberSchema.when(
  ['costPerPost', 'costPerLike', 'costPerView', 'costPerShare', 'costPerComment', 'costPerFollower', 'costPerClick'],
  {
    is: (
      costPerPost: number,
      costPerLike: number,
      costPerView: number,
      costPerShare: number,
      costPerComment: number,
      costPerFollower: number,
      costPerClick: number
    ) =>
      !costPerPost &&
      !costPerLike &&
      !costPerView &&
      !costPerShare &&
      !costPerComment &&
      !costPerFollower &&
      !costPerClick,
    then: yup.number().required('requiredAtLeastOneCostMessage')
  }
).when('budget', (budget: number, schema: any) =>
  !!budget ? schema.max(budget, 'costMustBeLessThanOrEqualToBudgetMessage') : schema
);

const MaximumRevenuePerInfluencerSchema = yup
  .number()
  .typeError('invalidNumberMessage')
  .min(0, 'numberMustBePositiveMessage')
  .when('budget', (budget: number, schema: any) =>
    !!budget ? schema.max(budget, 'costMustBeLessThanOrEqualToBudgetMessage') : schema
  );

const SalesPicsSchema = yup.array().required('requiredSalesPicMessage');
const InfluencerManagementPicsSchema = yup.array().required('requiredInfluencerManagementPicMessage');

export default (isAdminStaff: boolean) =>
  yup.object().shape(
    {
      advertiserId: AdvertiserSchema,
      title: TitleSchema,
      serviceInformation: ServiceInformationSchema,
      requirement: RequirementSchema,
      hashtags: HashtagsSchema,
      clickUrl: ClickUrlSchema,
      countryId: CountrySchema,
      socialMedia: SocialMediaSchema,
      categoryIds: CategoriesSchema,
      genders: GendersSchema,
      minAge: MinAgeSchema,
      maxAge: MaxAgeSchema,
      minFollowers: MinFollowersSchema,
      maxFollowers: MaxFollowersSchema,
      period: PeriodSchema,
      costPerPost: CostPerPostSchema,
      costPerLike: CostPerLikeSchema,
      costPerView: CostPerViewSchema,
      costPerShare: CostPerShareSchema,
      costPerComment: CostPerCommentSchema,
      costPerFollower: CostPerFollowerSchema,
      costPerClick: CostPerClickSchema,
      costPerAction: CostPerActionSchema,
      maximumRevenuePerInfluencer: MaximumRevenuePerInfluencerSchema,
      budget: BudgetSchema,
      thumbNail: ThumbnailAdvertiserSchema,
      ...(isAdminStaff && { salesPics: SalesPicsSchema, influencerManagementPics: InfluencerManagementPicsSchema })
      // materials:, // TODO: Validate materials
    },

    [
      ['costPerPost', 'costPerLike'],
      ['costPerPost', 'costPerView'],
      ['costPerPost', 'costPerShare'],
      ['costPerPost', 'costPerComment'],
      ['costPerPost', 'costPerFollower'],
      ['costPerPost', 'costPerAction'],
      ['costPerPost', 'costPerClick'],
      ['costPerLike', 'costPerView'],
      ['costPerLike', 'costPerShare'],
      ['costPerLike', 'costPerComment'],
      ['costPerLike', 'costPerFollower'],
      ['costPerLike', 'costPerAction'],
      ['costPerLike', 'costPerClick'],
      ['costPerView', 'costPerShare'],
      ['costPerView', 'costPerComment'],
      ['costPerView', 'costPerFollower'],
      ['costPerView', 'costPerAction'],
      ['costPerView', 'costPerClick'],
      ['costPerShare', 'costPerComment'],
      ['costPerShare', 'costPerFollower'],
      ['costPerShare', 'costPerAction'],
      ['costPerShare', 'costPerClick'],
      ['costPerComment', 'costPerFollower'],
      ['costPerComment', 'costPerAction'],
      ['costPerComment', 'costPerClick'],
      ['costPerClick', 'costPerAction'],
      ['costPerFollower', 'costPerAction'],
      ['costPerFollower', 'costPerClick']
    ]
  );
