import React from 'react';
import styled from '@emotion/styled';
import css from '@emotion/css';
import { defaultInfluencerAvatar } from '../../../../libs/image';
import { SliderComponents, SliderCss, StyledComponents } from '../../../molecules/SliderTable';
import { HeaderColumn } from '../../../atoms/List';
import CustomCss from '../AnalyticsComparePosts/CustomCss';
import { TableGrowthSection } from './GrowthSection';
import { EngagementAccountDataType } from './types';

const ComparableAccountsTable = ({ engagement }: { engagement: EngagementAccountDataType[] }) => (
  <SliderComponents.Wrapper css={[SliderCss.clearPadding, CustomCss.clearMargin, CustomCss.clearBorder]}>
    <SliderComponents.Container>
      <SliderComponents.SliderSection>
        <SliderComponents.SliderTable>
          <thead>
            <SliderComponents.HeaderRow css={[SliderCss.bgndColor]}>
              <SliderComponents.StickyHeaderRowWrapper>
                <StyledHeader title="Account" css={[SliderCss.clearPadding]} isTable={false} />
              </SliderComponents.StickyHeaderRowWrapper>
              <StyledHeader
                title="Followers (Total)"
                css={[SliderCss.clearPadding, SliderCss.paddingLeft]}
                help="compareFollowersTotal"
              />
              <StyledHeader title="Posts (Total)" help="comparePostsTotal" />
              <StyledHeader
                title="Avg Likes"
                css={[SliderCss.clearPadding, SliderCss.paddingLeft]}
                help="compareAvgLikes"
              />
              <StyledHeader
                title="Maximum Likes"
                css={[SliderCss.clearPadding, SliderCss.paddingLeft]}
                help="compareMaximumLikes"
              />
              <StyledHeader
                title="Avg Comments"
                css={[SliderCss.clearPadding, SliderCss.paddingLeft]}
                help="compareAvgComments"
              />
            </SliderComponents.HeaderRow>
          </thead>

          <tbody>
            {engagement.map((item, index) => {
              const { avatar, name, followers, posts, avgLikes, maxLikes, avgComments, color } = item;

              return (
                <StyledComponents.StyledRowNew css={[SliderCss.paddingSides, SliderCss.rowHeight62]} key={index}>
                  <SliderComponents.StickyBodyRowWrapper>
                    <SliderComponents.ThumbnailCell
                      src={defaultInfluencerAvatar(avatar)}
                      title={name}
                      role="div"
                      imageWidth="42px"
                      css={[
                        SliderCss.clearPadding,
                        css`
                          pointer-events: none;
                        `
                      ]}
                    >
                      <ColorBox color={color as string} />
                    </SliderComponents.ThumbnailCell>
                  </SliderComponents.StickyBodyRowWrapper>
                  <TableGrowthSection {...followers} />
                  <TableGrowthSection {...posts} />
                  <TableGrowthSection {...avgLikes} hasDecimals />
                  <TableGrowthSection {...maxLikes} />
                  <TableGrowthSection {...avgComments} hasDecimals />
                </StyledComponents.StyledRowNew>
              );
            })}
          </tbody>
        </SliderComponents.SliderTable>
      </SliderComponents.SliderSection>
    </SliderComponents.Container>
  </SliderComponents.Wrapper>
);

const StyledHeader = styled(HeaderColumn)`
  & > div {
    justify-content: flex-start;
  }
`;
const ColorBox = styled.div<{ color: string }>`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background-color: ${({ color }) => color};
`;

export default ComparableAccountsTable;
