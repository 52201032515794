import {
  endOfWeek,
  isLastDayOfMonth,
  isSameDay,
  isToday,
  isYesterday,
  startOfMonth,
  startOfWeek,
  subDays,
  subWeeks
} from 'date-fns';
import { RefObject } from 'react';
import { Period, PickerDate } from './types';
import { SELECTED_RANGE } from './index';

export const datesSepatator = '\u00A0\u00A0\u2013\u00A0\u00A0';
export const placeholderMulty = `Start Date${datesSepatator}End Date`;
export const placeholderSingle = 'Jan 01, 2020';
export const DAYPICKER_INPUT_FORMAT = 'MMM dd, yyyy';

export const getDefaultDate = (date: string) => (date ? new Date(date) : null);

export const getSelectedRange = (from: PickerDate, to: PickerDate, initialPeriod?: Period) => {
  const fromFormat = from === null ? 0 : from;
  const toFormat = to === null ? 0 : to;
  const today = new Date();
  const oneWeekAgo = subWeeks(today, 1);
  const lastDayOfPrevMonth = subDays(startOfMonth(today), 1);
  const initialFrom = initialPeriod ? new Date(initialPeriod.startDate) : today;
  const initialTo = initialPeriod ? new Date(initialPeriod.endDate) : today;

  if (isToday(fromFormat) && (isToday(toFormat) || !to)) {
    return SELECTED_RANGE.TODAY;
  }
  if (isYesterday(fromFormat) && (isYesterday(toFormat) || !to)) {
    return SELECTED_RANGE.YESTERDAY;
  }
  if (isSameDay(fromFormat, startOfMonth(today)) && isLastDayOfMonth(toFormat)) {
    return SELECTED_RANGE.THIS_MONTH;
  }
  if (isToday(toFormat) && isSameDay(fromFormat, subDays(toFormat, 13))) {
    return SELECTED_RANGE.LAST_TWO_WEEKS;
  }
  if (isToday(toFormat) && isSameDay(fromFormat, startOfWeek(toFormat))) {
    return SELECTED_RANGE.THIS_WEEK;
  }
  if (isToday(toFormat) && isSameDay(fromFormat, subDays(toFormat, 6))) {
    return SELECTED_RANGE.LAST_SEVEN_DAYS;
  }
  if (isSameDay(toFormat, endOfWeek(oneWeekAgo)) && isSameDay(fromFormat, startOfWeek(oneWeekAgo))) {
    return SELECTED_RANGE.LAST_WEEK;
  }
  if (isToday(toFormat) && isSameDay(fromFormat, subDays(toFormat, 29))) {
    return SELECTED_RANGE.TODAY_TO_MONTH_AGO;
  }
  if (isSameDay(toFormat, lastDayOfPrevMonth) && isSameDay(fromFormat, startOfMonth(lastDayOfPrevMonth))) {
    return SELECTED_RANGE.LAST_MONTH;
  }
  if (isSameDay(toFormat, initialTo) && isSameDay(fromFormat, initialFrom)) {
    return SELECTED_RANGE.ALL_TIME;
  }

  return SELECTED_RANGE.CUSTOM;
};

export const CloseOverlayAfterFocus = (inputRef: RefObject<any>) => {
  const activeElement = document.activeElement;
  const overlayContainer = document.getElementsByClassName('DayPickerInput-Overlay');

  if (overlayContainer && !overlayContainer[0].contains(activeElement)) {
    inputRef.current.hideAfterDayClick();
  }
};
