import styled from '@emotion/styled';
import * as React from 'react';
import { Tooltip } from '../Tooltip';

export interface LabelProps {
  className?: string;
  title: string;
  isRequired?: boolean;
  help?: React.ReactNode;
  iconMargin?: string;
  helpInterpol?: { [key: string]: number };
}

const Label = (props: LabelProps) => {
  const { className, title, isRequired, help, iconMargin, helpInterpol } = props;

  return (
    <Wrapper className={className}>
      {title}
      {isRequired && <RequiredIcon>&nbsp;*</RequiredIcon>}
      {help && <Tooltip help={help} placement="top" iconMargin={iconMargin} helpInterpol={helpInterpol} />}
    </Wrapper>
  );
};

const Wrapper = styled.label`
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 0 0 4px;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  color: #27313b;
  height: 20px;
`;

const RequiredIcon = styled.span`
  color: tomato;
`;

export default Label;
