import React, { createContext, Dispatch, useReducer } from 'react';
import { AnalyticsSubscriptionPlanType, UserRoles } from '../src/__generated__/globalTypes';
import reducer from './Reducer';

export interface StateType {
  userId: number | null;
  role: UserRoles | null;
  title: string;
  href: string;
  hash: string | null;
  email: string | null;
  globalLayout: {
    hasStickyHeader: boolean;
  };
  analyticsAccountInfo: {
    id: number;
    accountName: string;
    avatar: string;
    socialAccountUsername: string;
    subscriptionPlanId?: number;
    plan?: AnalyticsSubscriptionPlanType;
    availableFeatures?: {
      dashboardEnabled: boolean;
      hashtagsEnabled: boolean;
      compareEnabled: boolean;
      interactionEnabled: boolean;
    };
    capabilities?: {
      maxCompareAccounts: number;
      maxHashtags: number;
    };
  };
}

export interface ContextType {
  state: StateType;
  dispatch: Dispatch<any>;
}

export const defaultAnalyticsAccountInfo = {
  id: 0,
  accountName: '',
  avatar: '',
  socialAccountUsername: '',
  subscriptionPlanId: undefined,
  plan: undefined,
  availableFeatures: undefined,
  capabilities: undefined
};

export const initialState: StateType = {
  userId: null,
  role: null,
  title: '',
  href: '',
  hash: null,
  email: '',
  globalLayout: {
    hasStickyHeader: false
  },
  analyticsAccountInfo: defaultAnalyticsAccountInfo
};

export const AdminStore = createContext({} as ContextType);

export const AdminStoreProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer<React.Reducer<StateType, any>>(reducer, initialState);
  const value = { state, dispatch };

  return <AdminStore.Provider value={value}>{children}</AdminStore.Provider>;
};
