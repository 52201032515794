import React from 'react';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useProviderResponse } from '../helpers';
import { manageLocalStorageItem } from '../../../libs/localStorage';
import { getCallbackUrl } from '../../../libs/auth';
import { AnalyticsRedirectType, QuerySocialAccountType } from '../../../__generated__/globalTypes';
import PageLoading from '../../../components/molecules/PageLoading';
import * as RECONNECT_IG_ANALYTICS_ACCOUNT from '../SocialAuthReconnectInstagramAccountAnalytics.graphql';
import * as RECONNECT_YT_ANALYTICS_ACCOUNT from '../SocialAuthReconnectYouTubeAccountAnalytics.graphql';
import {
  SocialAuthReconnectInstagramAccountAnalytics,
  SocialAuthReconnectInstagramAccountAnalyticsVariables
} from '../__generated__/SocialAuthReconnectInstagramAccountAnalytics';
import {
  SocialAuthReconnectYouTubeAccountAnalytics,
  SocialAuthReconnectYouTubeAccountAnalyticsVariables
} from '../__generated__/SocialAuthReconnectYouTubeAccountAnalytics';
import { useQueryHelper } from '../../../libs/hooks';

const AnalyticsListConnectRedirect = () => {
  const { t, history, enqueueSnackbar } = useQueryHelper();
  const { response, provider } = useProviderResponse();
  const businessAccountId = manageLocalStorageItem('get', 'businessAccountId');
  const analyticsAccountId = manageLocalStorageItem('get', 'analyticsIgAccountId');
  const analyticsAccountName = manageLocalStorageItem('get', 'analyticsIgAccountName');
  const fbPageId = manageLocalStorageItem('get', 'fbPageId');
  const channelId = manageLocalStorageItem('get', 'channelId') || '';
  const pathname = manageLocalStorageItem('get', 'redirectBackRoute') || '/analytics';

  const [reconnectIgAnalyticsAccount] = useMutation<
    SocialAuthReconnectInstagramAccountAnalytics,
    SocialAuthReconnectInstagramAccountAnalyticsVariables
  >(RECONNECT_IG_ANALYTICS_ACCOUNT);
  const [reconnectYtAnalyticsAccount] = useMutation<
    SocialAuthReconnectYouTubeAccountAnalytics,
    SocialAuthReconnectYouTubeAccountAnalyticsVariables
  >(RECONNECT_YT_ANALYTICS_ACCOUNT);

  const callReconnectAnalyticsAccount = async () => {
    try {
      if (provider === QuerySocialAccountType.FACEBOOK) {
        await reconnectIgAnalyticsAccount({
          variables: {
            input: {
              businessAccountId: String(businessAccountId),
              response,
              callbackUrl: getCallbackUrl(
                QuerySocialAccountType.FACEBOOK,
                AnalyticsRedirectType.ANALYTICS_LIST_CONNECT
              ),
              redirectType: AnalyticsRedirectType.ANALYTICS_LIST_CONNECT,
              accountId: Number(analyticsAccountId),
              username: String(analyticsAccountName),
              fbPageId: fbPageId || null
            }
          }
        });
      } else if (provider === QuerySocialAccountType.YOUTUBE) {
        await reconnectYtAnalyticsAccount({
          variables: {
            input: {
              channelId,
              response,
              callbackUrl: getCallbackUrl(QuerySocialAccountType.YOUTUBE, AnalyticsRedirectType.ANALYTICS_LIST_CONNECT)
            }
          }
        });
      }
      manageLocalStorageItem('set', 'reconnectStatus', 'success');
    } catch (error) {
      enqueueSnackbar(t(error.message || 'Fail to reconnect account, please try again later'), { variant: 'error' });
    } finally {
      history.push(pathname);
      manageLocalStorageItem('remove', 'analyticsIgAccountId');
    }
  };

  useEffect(() => {
    callReconnectAnalyticsAccount();
  }, []);

  return <PageLoading />;
};

export default AnalyticsListConnectRedirect;
