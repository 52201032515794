import React from 'react';
import { useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { History } from 'history';
import {
  InstagramDashboardFollowerAnalytics,
  InstagramDashboardFollowerAnalyticsVariables
} from '../AudienceChart/__generated__/InstagramDashboardFollowerAnalytics';
import ListIndicator from '../../../molecules/ListIndicator';
import { AnalyticsPeriodWithId } from '../../AnalyticsShared/types';
import AudienceChart from './template';
import * as IG_FOLLOWERS_ANALYTICS from './InstagramDashboardFollowerAnalytics.graphql';

interface AnalyticsPostsType extends AnalyticsPeriodWithId {
  history: History;
  avatar: string;
}
const AudienceFollowersSection = ({ period, id, history, avatar }: AnalyticsPostsType) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, error } = useQuery<
    InstagramDashboardFollowerAnalytics,
    InstagramDashboardFollowerAnalyticsVariables
  >(IG_FOLLOWERS_ANALYTICS, {
    variables: { accountId: id, startDate: period.startDate, endDate: period.endDate },
    fetchPolicy: 'cache-and-network'
  });

  if (loading) {
    return <ListIndicator />;
  }

  if (!data?.instagramDashboardFollowerAnalytics || error) {
    if (error) {
      enqueueSnackbar(t('Can not get Instagram followers data for current account'), { variant: 'error' });
    }
    history.push('/analytics');

    return null;
  }

  return <AudienceChart {...data?.instagramDashboardFollowerAnalytics} avatar={avatar} />;
};

export default AudienceFollowersSection;
