import { WithTranslation } from 'react-i18next';
import * as yup from 'yup';

export { default as SignInSchema } from './signIn';
export { default as PasswordSchema } from './password';
export { default as RefreshPasswordSchema } from './refreshPassword';
export { default as UserSchema } from './addUser';
export { default as EngagementPostSchema } from './engagementPost';
export { default as MarketplaceSchema } from './marketplace';
export { default as EngagementSchema } from './engagement';
export { default as jobDescriptionSchema } from './jobDescription';
export { default as AdvertiserSchema } from './advertiser';
export { default as UserPasswordSchema } from './editUser';
export { default as AddAnalyticsAdminAccountSchema } from './addAnalyticsAdmin';
export { default as AddAnalyticsAdminAccountSchemaV2 } from './addAnalyticsAdminV2';
export { default as AddAnalyticsAdvertiserAccountSchema } from './addAnalyticsAdvertiser';
export { default as SubscriptionFormSchema } from './subscriptionForm';
export { default as AdvertiserPasswordSchema } from './advertiserPasswordForm';
export { default as SettingsFormSchema } from './settingsForm';
export { default as SignUpFormSchema } from './signUp';

/*
Message
*/

export const UNEXPECTED_ERROR = 'UnexpectedError';
export const EMAIL_DUPLICATED = 'emailDuplicatedMessage';
export const formatDynamicErrorMessage = (t: WithTranslation['t'], msg?: string) => {
  if (!msg) {
    return msg;
  }
  const [base, n] = msg.split('_');

  return t(base, { n });
};

export default yup;
