import React from 'react';
import { useQuery } from '@apollo/client';
import { getOffset, getPageInfo } from '../../../../libs/paging';
import { SortActionType } from '../../../../libs/filter';
import { UserAnalysisFilterType } from '../../../../pages/UserAnalysis/types';
import { LIMIT } from '../../../../libs/paging/constant';
import { InstagramInteractionUserSortField } from '../../../../__generated__/globalTypes';
import ListIndicator from '../../../molecules/ListIndicator';
import { useQueryHelper } from '../../../../libs/hooks';
import UserAnalysisList from './UserAnalysisList';
import * as GET_USERS_LIST_ANALYSIS from './InstagramInteractionsUsers.graphql';
import {
  AllInstagramInteractionUsers,
  AllInstagramInteractionUsersVariables
} from './__generated__/AllInstagramInteractionUsers';

export interface UserAnalysisListProps extends SortActionType<InstagramInteractionUserSortField> {
  currentPage: number;
  filter: UserAnalysisFilterType;
  id: number;
}

const UserAnalysisListComponent = (props: UserAnalysisListProps) => {
  const { t, history, enqueueSnackbar } = useQueryHelper();
  const {
    currentPage,
    sort,
    setSort,
    id,
    filter: { keyword, period }
  } = props;
  const { data, loading, error } = useQuery<AllInstagramInteractionUsers, AllInstagramInteractionUsersVariables>(
    GET_USERS_LIST_ANALYSIS,
    {
      variables: {
        accountId: id,
        offset: getOffset(currentPage),
        limit: LIMIT,
        keyword,
        orderBy: sort,
        ...period
      },
      skip: !id
    }
  );

  if (loading) {
    return <ListIndicator />;
  }

  if (error) {
    history.push(`/analytics/dashboard/${id}/interaction`);
    enqueueSnackbar(t(error.message), { variant: 'error' });
  }

  const pageInfo = getPageInfo(currentPage, {
    getTotalNumber: { totalNumber: data?.allInstagramInteractionUsers.totalNumber || 0 }
  });

  return <UserAnalysisList sort={sort} setSort={setSort} pageInfo={pageInfo} userData={data} />;
};

export default UserAnalysisListComponent;
