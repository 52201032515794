import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import languageIcon from '../../../assets/icon/earth.svg';
import { AdminStore } from '../../../Context';
import LanguageSwitcher from '../../molecules/LanguageSwitcher';
import LanguageMenu, { LanguageMenuChildProps } from '../LanguageMenu';
import { MenuTitle, getHeaderIcon } from '../../../libs/constant';
import { useGlobalLayoutProps } from '../../../libs/hooks';
import HeaderButton from './HeaderButton';

interface HeaderProps {
  menuTitle?: MenuTitle;
}

const Header = (props: HeaderProps) => {
  const {
    state: { href, title, email }
  } = useContext(AdminStore);
  const {
    globalLayout: { hasStickyHeader }
  } = useGlobalLayoutProps();
  const history = useHistory();

  const backToParentUrl = href || '/';
  const handleGoBack = () => {
    const isGoBackExcludedPage = history.location.pathname.includes('report');
    if (history.length > 2 && history.action !== 'POP' && !isGoBackExcludedPage) {
      return history.goBack();
    }
    history.push(backToParentUrl);
  };

  return (
    <Wrapper isSticky={hasStickyHeader}>
      {href ? (
        <Anchor onClick={handleGoBack}>
          <Icon className="material-icons">arrow_back</Icon>
        </Anchor>
      ) : (
        <img src={getHeaderIcon(props.menuTitle)} />
      )}

      <Title>{title}</Title>

      {email && <UserEmail>{email}</UserEmail>}

      <LanguageMenu>
        {({ title: language, handleButtonClick, visible, handleSwitcherClick }: LanguageMenuChildProps) => (
          <>
            <HeaderButton icon={languageIcon} title={language} onClick={handleButtonClick} />
            <Switcher visible={visible} onClick={handleSwitcherClick} />
          </>
        )}
      </LanguageMenu>
    </Wrapper>
  );
};

const UserEmail = styled.p`
  color: #27313b;
  font-size: 13px;
`;

const Wrapper = styled.header<{ isSticky?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 48px;
  padding: 0 24px 0 24px;
  background-color: #fff;
  box-shadow: 0 3px 2px -2px #dee5ec, 0 1px rgba(222, 229, 236, 0.5);
  ${({ isSticky }) =>
    isSticky &&
    `position: sticky;
     top: 0;
     z-index: 10;
    `}
`;

const Anchor = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #efefef;
  }
`;

const Icon = styled.i`
  color: #ff4b61;
  font-size: 22px;
`;

const Title = styled.h1`
  box-sizing: border-box;
  overflow: hidden;
  max-width: 540px;
  margin-right: auto;
  padding-left: 8px;
  font-size: 18px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Switcher = styled(LanguageSwitcher)`
  top: 24px;
  right: -8px;
`;

export default Header;
