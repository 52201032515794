import React from 'react';
import AnalyticsForm from '../../components/organisms/Analytics/AnalyticsForm';
import { useUpdateDocumentTitle } from '../../libs/hooks/index';

interface AddAnalyticsAccountProps {
  connectedIgAccount: {
    fbPageName: string;
    fbPageId: string;
    instagramAccountName: string;
    needReconnect: boolean;
    businessAccountId: string;
    id: number;
  };
  connectedYtChannel: {
    channelId: string;
    channelName: string;
    hasYtReconnect: boolean;
  };
  formValues: {
    accountManagerIds: string[];
    advertiserId: string;
    accountName: string;
  };
}
const Template = ({ connectedIgAccount, connectedYtChannel, formValues }: AddAnalyticsAccountProps) => {
  useUpdateDocumentTitle({
    title: 'documentTitle.AddAccount',
    appHeader: 'appHeader.Add Account',
    href: '/analytics'
  });

  return (
    <AnalyticsForm
      connectedIgAccount={connectedIgAccount}
      connectedYtChannel={connectedYtChannel}
      formValues={formValues}
    />
  );
};

export default Template;
