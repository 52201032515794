import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { LocationDescriptor } from 'history';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';

interface ThumbnailColumnProps extends ThumbnailProps {
  className?: string;
  href?: LocationDescriptor;
  title: string;
  handleClick?: () => void;
  children?: ReactNode;
  role?: string;
}

const ThumbnailColumn = (props: ThumbnailColumnProps) => {
  const { className, href, src, title, handleClick, children, imageWidth, imageHeight, role } = props;
  const Wrapper = role === 'div' ? 'div' : 'td';
  const hasInteraction = !!href || !!handleClick;

  return (
    <Wrapper className={className}>
      {!!href ? (
        <div css={wrapperStyle}>
          <Thumbnail
            src={src}
            onClick={handleClick}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            hasInteraction={hasInteraction}
          />
          <div css={displayFlex}>
            <Title onClick={handleClick}>
              <StyledLink to={href} noName={!title}>
                {title || '-'}
              </StyledLink>
            </Title>
            {children}
          </div>
        </div>
      ) : (
        <div css={wrapperStyle}>
          <Thumbnail
            src={src}
            onClick={handleClick}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            hasInteraction={hasInteraction}
          />
          <div css={displayFlex}>
            <Title onClick={handleClick}>{title}</Title>
            {children}
          </div>
        </div>
      )}
    </Wrapper>
  );
};

interface ThumbnailProps {
  src: string | null;
  imageWidth?: string;
  imageHeight?: string;
  onClick?: () => void;
  hasInteraction?: boolean;
}

const Thumbnail = styled.div<ThumbnailProps>`
  flex-shrink: 0;
  width: ${({ imageWidth }) => imageWidth || '98px'};
  height: ${({ imageHeight }) => imageHeight || '42px'};
  margin-right: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: ${({ src }) => (!!src ? `url(${src})` : 'none')};
  border: 1px solid #f5f5f5;
  border-radius: 2px;
  ${({ hasInteraction }) =>
    !!hasInteraction &&
    `
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  `}
`;

const Title = styled.p<{ onClick?: () => void }>`
  overflow: hidden;
  flex: 1;
  min-width: 120px;
  max-height: 46px;
  font-size: 13px;
  line-height: 16px;
  word-break: break-word;
  ${({ onClick }) =>
    !!onClick &&
    `
  &:hover {
    color: #179cd7;
    text-decoration: underline;
    cursor: pointer;
  }
  `}
`;

const wrapperStyle = css`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StyledLink = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ noName: boolean }>`
  ${({ noName }) => noName && 'text-decoration: none; text-decoration-color: transparent !important;'}

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    ${({ noName }) => noName && 'text-decoration: none; text-decoration-color: transparent !important;'}
  }
`;

const displayFlex = css`
  display: flex;
  align-items: center;
  width: 100%;
`;

export default ThumbnailColumn;
