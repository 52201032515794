import * as yup from 'yup';
import { EmailSchema } from './share';

export const LoginPasswordSchema = yup.string().required('requiredPasswordMessage');

// SignIn Schema
export default yup.object().shape({
  email: EmailSchema,
  password: LoginPasswordSchema
});
