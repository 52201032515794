import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const Title = styled.h6`
  font-size: 12px;
  font-weight: 600;
`;

export const Caption = styled.p`
  padding: 8px;
  font-size: 13px;
  line-height: 18px;
  word-break: break-all;
  color: #27313b;
  white-space: pre-wrap;
`;

export const PostCaptionWrapper = styled.div`
  height: 148px;
  margin-bottom: 16px;
  overflow-y: auto;
  border: 1px solid #dee5ec;
`;

export const TopHeading = styled.div`
  height: 40px;
  width: auto;
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  justify-content: space-between;
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    color: #c5d0da;
    opacity: 1;
  }
`;

export const MainContent = styled.div`
  display: flex;
`;

export const LeftContent = styled.div`
  width: 400px;
  margin-right: 24px;
`;

export const RightContent = styled.div`
  flex: 1;
`;

export const CommentsSection = styled.div``;

export const CommentsWrapper = styled.div`
  display: block;
  width: auto;
`;

export const CaptionFrame = styled.div`
  height: 111px;
  border: 1px solid #dee5ec;
  overflow-y: auto;
`;

export const TextWrapper = styled.div`
  display: flex;
  padding: 8px;
`;
export const CommentText = styled.p`
  margin-left: 10px;
  font-size: 12px;
  color: #27313b;
  line-height: 17px;
  width: fit-content;
  word-break: break-all;
`;
export const CommentUsername = styled.p`
  width: 60px;
  min-width: 60px;
  font-size: 13px;
  font-weight: 600;
  color: #27313b;
  word-break: break-all;
`;
export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
