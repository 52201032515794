import {
  AnalyticsAdminFilterTypeV2,
  AnalyticsAdvertiserFilterTypeV2
} from '../../components/organisms/Analytics/types';
import { getParamsFromSearch } from '../../libs/paging/utils';
import { AnalyticsSubscriptionPlanType } from './../../__generated__/globalTypes';

interface AdminFilterParams {
  kw: string;
  co: string;
  ad: string;
  sp: AnalyticsSubscriptionPlanType | '';
}
interface AdvertiserFilterParams {
  kw: string;
  sp: AnalyticsSubscriptionPlanType | '';
}

export const getAdminFilterParams = <T extends AnalyticsAdminFilterTypeV2>(params: T): AdminFilterParams => ({
  kw: params.keyword || '',
  co: params.countryId || '',
  ad: params.advertiserId || '',
  sp: params.subscriptionPlan || ''
});

export const getInitialAdminFilter = (search: string) => {
  const params = getParamsFromSearch<AdminFilterParams>(search);

  const keyword = params.kw;
  const countryId = params.co;
  const advertiserId = params.ad;
  const subscriptionPlan = params.sp;

  const initialFilter: AnalyticsAdminFilterTypeV2 = {
    keyword: keyword || '',
    countryId: countryId || '',
    advertiserId: advertiserId || '',
    subscriptionPlan: subscriptionPlan || ''
  };

  return initialFilter;
};

export const getAdvertiserFilterParams = (params: AnalyticsAdvertiserFilterTypeV2): AdvertiserFilterParams => ({
  kw: params.keyword || '',
  sp: params.subscriptionPlan || ''
});

export const getInitialAdvertiserFilter = (search: string) => {
  const params = getParamsFromSearch<AdvertiserFilterParams>(search);

  const keyword = params.kw;
  const subscriptionPlan = params.sp;

  const initialFilter: AnalyticsAdvertiserFilterTypeV2 = {
    keyword: keyword || '',
    subscriptionPlan: subscriptionPlan || ''
  };

  return initialFilter;
};
