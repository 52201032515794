import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { useLocation } from 'react-router';
import Styled from '../StyledComponents';
import { useQueryHelper } from '../../../../libs/hooks';
import Template from './template';
import {
  InstagramComparePostDetails,
  InstagramComparePostDetailsVariables
} from './__generated__/InstagramComparePostDetails';
import * as INSTAGRAM_COMPARE_POST_DETAILS from './InstagramComparePostDetails.graphql';

interface AnayticsFeedPostDetailProps {
  closeModal: () => void;
}

const CompareFeedPostDetail = (props: AnayticsFeedPostDetailProps) => {
  const { closeModal } = props;
  const { t, history, enqueueSnackbar } = useQueryHelper();
  const { search, pathname } = useLocation();

  const postId = Number(new URLSearchParams(search).get('popup'));

  const { data, loading, error } = useQuery<InstagramComparePostDetails, InstagramComparePostDetailsVariables>(
    INSTAGRAM_COMPARE_POST_DETAILS,
    {
      variables: { postId },
      fetchPolicy: 'cache-and-network',
      onError: useCallback(() => {
        history.push(pathname);
        enqueueSnackbar(t('Post was not found'), { variant: 'error' });
      }, [])
    }
  );

  if (loading || error) {
    return <CircularProgress size={64} thickness={2.4} color="inherit" />;
  }

  return (
    <Styled.PostDetailWrapper>
      <Template closeModal={closeModal} data={data} />
    </Styled.PostDetailWrapper>
  );
};

export default CompareFeedPostDetail;
