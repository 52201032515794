import styled from '@emotion/styled';
import * as List from '../../atoms/List';
import BasePageCount from '../PageCount';
import BasePager from '../Pager';
import * as Table from '../Table';

const Wrapper = Table.Wrapper;

const Container = styled.section`
  overflow-x: auto;
  display: flex;
`;

const Sticky = styled.section`
  position: relative;
  position: sticky;
  left: 0;
  z-index: 3;
`;

const StickyWrapper = styled.table`
  flex-shrink: 0;
  width: 320px;
  height: 100%;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.06) 3px 0 2px;
  background-color: #fff;
`;

const StickyHeader = styled.tr`
  display: flex;
  align-items: center;
  height: 47.8px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 4;
`;

const StickyTitle = styled.p`
  margin-bottom: 5px;
  white-space: nowrap;
  width: 200px;

  &:hover {
    color: #179cd7;
    text-decoration: underline;
    cursor: pointer;
  }

  a {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const TitleWrapper = styled.tr<{ isRowHovered: boolean; isRowChecked?: boolean }>`
  padding: 0 8px;
  background-color: ${({ isRowChecked, isRowHovered }) =>
    isRowHovered ? '#f6f8fa' : !!isRowChecked ? '#fffde7' : '#fff'};
  border-top: 1px solid #efefef;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p > a {
    color: ${({ isRowHovered }) => isRowHovered && '#179cd7'};
    text-decoration: ${({ isRowHovered }) => isRowHovered && 'underline'};
  }
`;

const HeaderRow = styled.tr``;

const SliderSection = styled.section`
  flex: 1;
  width: 1px;
  position: relative;
`;

const SliderTable = styled.table`
  table-layout: fixed;
  width: auto;
  position: relative;
  min-width: 100%;
`;

const Row = styled.tr<{ isRowHovered: boolean; isRowChecked?: boolean }>`
  td {
    background-color: ${({ isRowChecked, isRowHovered }) =>
      isRowHovered ? '#f6f8fa' : !!isRowChecked ? '#fffde7' : '#fff'};
  }

  a {
    color: ${props => props.isRowHovered && '#179cd7'};
    text-decoration: ${props => props.isRowHovered && 'underline'};
  }
`;

const ThumbnailCell = styled(List.ThumbnailColumn)<{ titleWidth?: string }>`
  padding: 0 8px;
  padding-left: 16px;

  p {
    overflow: hidden;
    width: ${({ titleWidth }) => titleWidth || '120px'};
    max-height: 33px;
    text-overflow: ellipsis;
    min-width: unset;
  }
`;

const PageCount = styled(BasePageCount)`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  padding-right: 8px;
`;

const Pager = styled(BasePager)`
  margin: 0 23px 0;
`;

const BlankColumn = styled.td<{ width?: string; height?: string }>`
  width: ${({ width }) => width || '22px'};
  height: ${({ height }) => height || '56px'};
`;

const StickyHeaderRowWrapper = styled.th`
  position: sticky;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  box-shadow: 3px 0 0 0 #efefef;
  padding-left: 16px;
  background-color: #f6f8fa;
`;
const StyckyHeaderDivWrapper = styled.td`
  position: sticky;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  box-shadow: 3px 0 0 0 #efefef;
  padding-left: 16px;
  background-color: #f6f8fa;
`;

const StickyBodyRowWrapper = styled.td`
  position: sticky;
  left: 0;
  z-index: 3;
  box-shadow: 3px 0 0 0 #efefef;
`;

export default {
  Pager,
  PageCount,
  ThumbnailCell,
  Row,
  SliderTable,
  SliderSection,
  HeaderRow,
  TitleWrapper,
  StickyTitle,
  StickyHeader,
  StickyWrapper,
  Sticky,
  Container,
  Wrapper,
  BlankColumn,
  StickyHeaderRowWrapper,
  StickyBodyRowWrapper,
  StyckyHeaderDivWrapper
};
