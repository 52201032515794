import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { CircularProgress, Popover } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AnalyticsContentWrapper, { ContentType } from '../../AnalyticsShared/AnalyticsContentWrapper';
import * as StyledCards from '../../AnalyticsShared/AccountCardsStyled';
import { CheckBox } from '../../../atoms/CheckBox';
import { PalleteButton } from '../../../atoms/Button';
import { mainBlue } from '../../../../libs/pallete';
import { AnalyticsPeriodWithId } from '../../AnalyticsShared/types';
import {
  InstagramDashboardOverview,
  InstagramDashboardOverviewVariables
} from './__generated__/InstagramDashboardOverview';
import * as IG_OVERVIEW from './InstagramDashboardOverview.graphql';
import Overview from './AnalyticsOverview';
import { additionalSelectableCharts, ChartColorTypes, defaultSelectableCharts } from './helpers';

const AnalyticsOverview = ({ period, id }: AnalyticsPeriodWithId) => {
  const { data, loading } = useQuery<InstagramDashboardOverview, InstagramDashboardOverviewVariables>(IG_OVERVIEW, {
    variables: { accountId: id, startDate: period.startDate, endDate: period.endDate }
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [savedEngagementTiles, setSavedEngagementTiles] = useState<ChartColorTypes[]>(defaultSelectableCharts);
  const [allSelectedCheckboxes, setSelectedCheckboxes] = useState<ChartColorTypes[]>(savedEngagementTiles); // aka temp checkboxes state
  const { t } = useTranslation();

  const handleEngagementSave = () => {
    setAnchorEl(null);
    setSavedEngagementTiles(allSelectedCheckboxes);
  };

  return (
    <>
      <AnalyticsContentWrapper
        contentType={ContentType.Overview}
        tab="dashboard"
        addButton={<StyledAddButton onClick={e => setAnchorEl(e.currentTarget)}>+</StyledAddButton>}
      >
        {loading ? (
          <LoaderWrapper>
            <CircularProgress
              size={64}
              thickness={2.4}
              css={css`
                margin: 240px auto;
                color: #e0e8ed;
              `}
            />
          </LoaderWrapper>
        ) : (
          <Overview
            instagramDashboardOverview={data?.instagramDashboardOverview || null}
            id={id}
            allEngagements={savedEngagementTiles}
          />
        )}
      </AnalyticsContentWrapper>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        // reset to prev saved state
        onExited={() => setSelectedCheckboxes(savedEngagementTiles)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        css={css`
          z-index: 100;
          margin-top: 4px !important;

          & .MuiPaper-elevation8 {
            box-shadow: 0 1px 2px 0 #27313b19;
            width: 248px;
            background-color: #fff;
            border-radius: 3px;
            border: solid 1px #dee5ec;
          }
        `}
      >
        <div>
          <Checkboxes>
            {additionalSelectableCharts.map(chart => {
              const isChecked = allSelectedCheckboxes.includes(chart);

              return (
                <CheckBox
                  key={chart}
                  checked={isChecked}
                  name={chart}
                  label={t(chart)}
                  onChange={() => {
                    if (isChecked) {
                      setSelectedCheckboxes(allSelectedCheckboxes.filter(checkboxVal => checkboxVal !== chart));
                    } else {
                      setSelectedCheckboxes([...allSelectedCheckboxes, chart]);
                    }
                  }}
                  css={css`
                    &:not(:last-of-type) {
                      padding-bottom: 16px;
                    }
                  `}
                />
              );
            })}
          </Checkboxes>
          <SubmitSection>
            <StyledSaveButton
              text="Save"
              onClick={handleEngagementSave}
              palleteColor={mainBlue}
              borderRadius="3px"
              width="auto"
              height="24px"
              fontSize="12px"
            />
          </SubmitSection>
        </div>
      </Popover>
    </>
  );
};

const LoaderWrapper = styled.div`
  background-color: #fff;
  text-align: center;
  height: 554px;
`;
const StyledAddButton = styled(StyledCards.AddAccout)`
  width: 32px;
  height: 32px;
  flex: unset;
  margin-left: auto;
  margin-right: 16px;
  font-size: 24px;
  border: solid 1px #c5d0da;
  border-radius: 3px;
  align-items: baseline;
  background-color: #f6f8fa;
`;
const Checkboxes = styled.div`
  padding: 8px;
`;
const SubmitSection = styled.div`
  padding: 8px;
  border-top: 1px solid #dee5ec;
`;
const StyledSaveButton = styled(PalleteButton)`
  margin-left: auto;
  min-width: 66px;
`;

export default AnalyticsOverview;
