import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useField, useFormikContext } from 'formik';
import css from '@emotion/css';
import { SubmitButton } from '../../../atoms/Button';
import ErrorMessage from '../../../molecules/ErrorMessage';
import PasswordField from '../../../molecules/PasswordField';
import TextForm from '../../../molecules/TextForm';
import { Annotation, Confirmation, Form } from '../../../atoms/EmotionPrimitives/Forms';
import { FormValues } from './types';

const SignInForm = () => {
  const { handleSubmit, isSubmitting, status } = useFormikContext<FormValues>();
  const { t } = useTranslation();

  const [emailField, emailMeta] = useField('email');
  const [passwordField, passwordMeta] = useField('password');

  return (
    <Form
      css={css`
        margin: auto;
      `}
      onSubmit={handleSubmit}
    >
      <Title>{t('Sign in')}</Title>

      <Annotation>
        {t('Password.Please Sign In')}
        <br />
        {t('Password.Email And Password')}
      </Annotation>

      <Email title="Email" placeholder="your@email.com" error={!!emailMeta.error} {...emailField} />
      <Error message={emailMeta.error} />

      <Password
        title={t('Password.Password')}
        placeholder={t('Password.Your Password')}
        error={!!passwordMeta.error}
        {...passwordField}
      />
      <Error message={passwordMeta.error} setMargin={true} />

      {!emailMeta.error && !passwordMeta.error && status && <Error message={status} setMargin={true} />}

      <ForgotPassword to="/password_forgot">{t('Password.Forgot Password')}</ForgotPassword>

      <Button value="Sign In" isSubmitting={isSubmitting} bgcolor="#ff4b61" hoverbgcolor="#eb475d" />

      <Confirmation>
        {t('Password.Confirmation')}&nbsp;
        <Anchor to="/privacy">{t('Password.Privacy Policy')}</Anchor> and&nbsp;
        <Anchor to="/terms">{t('Password.Terms of Service')}</Anchor>.
      </Confirmation>
    </Form>
  );
};

const Title = styled.h1`
  font-size: 24px;
`;

const Email = styled(TextForm)`
  width: 100%;

  & label {
    padding: 0 0 4px;
  }

  & input {
    line-height: 32px;
    padding: 0 7px;
  }
`;

const Password = styled(PasswordField)`
  width: 100%;
  padding-top: 24px;

  & label {
    padding: 0 0 4px;
  }

  & input {
    line-height: 32px;
    padding: 0 7px;
  }
`;

const Anchor = styled(Link)`
  display: inline;
  color: #3892e5;
  font-size: 12px;
  line-height: 1.4em;

  &:hover {
    text-decoration: underline;
  }
`;

const ForgotPassword = styled(Anchor)`
  padding: 24px 0;
  align-self: flex-end;
`;

const Error = styled(ErrorMessage)<{ setMargin?: boolean }>`
  width: 100%;
  margin-bottom: ${props => (props.setMargin ? '-17px' : 0)};
`;

const Button = styled(SubmitButton)`
  line-height: 32px;
  background-color: #ff2b52;
  border-radius: 3px;

  &:hover {
    background-color: #ff5575;
  }

  &:active {
    background-color: #cc2241;
  }
`;

export default SignInForm;
