import css from '@emotion/css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTranslateCountry } from '../../../../libs/hooks';
import { PageInfo } from '../../../../libs/paging';
import { SliderComponents, SliderCss } from '../../../molecules/SliderTable';
import { EmptyTable } from '../../../molecules/Table';
import { defaultInfluencerAvatar } from '../../../../libs/image';
import editIcon from '../../../../assets/icon/edit_pen_grey.svg';
import warningIcon from '../../../../assets/icon/warning.svg';
import checkedIcon from '../../../../assets/icon/checkIcon.svg';
import { TextColumn, HeaderColumn, LinkButtonColumn } from '../../../atoms/List';
import Dialog from '../../../molecules/Dialog';
import { ConnectedIgAndFbAccounts, ReconnectButton } from '../Components';
import { AnalyticsAuthSocialAccountType, SocialAccountType } from '../../../../__generated__/globalTypes';
import { SUBSCRIPTIONS_TYPES } from '../../../../libs/constant';
import { StyledConnectedSocialMedia } from '../AnalyticsForm/AnalyticsAccountForm';
import { youtubeContent, YouTubeDashboardModal } from '../../AnalyticsShared/UpgradeToPremiumModal';
import { AccountListAnalyticsForAdminV2_allAccountAnalyticsForAdminV2_accounts } from './__generated__/AccountListAnalyticsForAdminV2';
import * as StyledComponents from './StyledComponents';
import { ReconnectAccountOuterProps } from './useReconnectAccountFromList';
import { getReconnectSMType, getSMProfileUrls } from './helpers';

export interface AnalyticsListProps extends ReconnectAccountOuterProps {
  analyticsAccounts: AccountListAnalyticsForAdminV2_allAccountAnalyticsForAdminV2_accounts[];
  pageInfo: PageInfo;
}

const AnalyticsListAdmin = (props: AnalyticsListProps) => {
  const { t } = useTranslation();
  const { tc } = useTranslateCountry();
  const {
    analyticsAccounts,
    pageInfo: { firstIndex, lastIndex, currentPage, totalPages, totalCount },
    setReconnectIgOpen,
    setReconnectYtOpen,
    openedReconnectAccountType,
    onReconnectClick,
    reconnectSuccessType,
    connectedIgAccountName,
    clearReconnectState,
    connectedFbPageName,
    chosenYtAccount
  } = props;

  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const [isYtModalOpen, setYtModalState] = useState(false);

  if (analyticsAccounts.length === 0) {
    return <EmptyTable colSpan={6} link={{ href: '/analytics/add', title: t('Analytics.AddAnAnalytics') }} />;
  }

  const renderConnectedSocialMediaAccount = (reconnectType: AnalyticsAuthSocialAccountType | '') => (
    <>
      {reconnectType === AnalyticsAuthSocialAccountType.FACEBOOK ? (
        <ConnectedIgAndFbAccounts igAccountName={connectedIgAccountName} fbAccountName={connectedFbPageName} />
      ) : null}
      {reconnectType === AnalyticsAuthSocialAccountType.YOUTUBE ? (
        <StyledConnectedSocialMedia
          socialMedia={SocialAccountType.YOUTUBE}
          accountName={chosenYtAccount.channelName}
          isTable={false}
          css={css`
            padding-left: 0;
          `}
        />
      ) : null}
    </>
  );

  const reconnectSMType = getReconnectSMType(openedReconnectAccountType);

  return (
    <>
      <SliderComponents.Wrapper css={[SliderCss.marginTop, SliderCss.clearPadding]}>
        <SliderComponents.Container>
          <SliderComponents.SliderSection>
            <SliderComponents.SliderTable>
              <thead>
                <SliderComponents.HeaderRow css={SliderCss.bgndColor}>
                  <HeaderColumn title="Account" css={accountColWidth} />
                  <HeaderColumn title="Social Media Analytics" css={[width300, SliderCss.paddingLeft8]} />
                  <HeaderColumn title="Advertiser" css={[width150, SliderCss.paddingLeft8]} />
                  <HeaderColumn title="Plan" css={[picsWidth, SliderCss.paddingLeft8]} />
                  <HeaderColumn title="PIC" css={[picsWidth, SliderCss.paddingLeft8]} />
                  <HeaderColumn title="Country" css={[width100, SliderCss.paddingLeft8]} />
                  <HeaderColumn title="Edit" css={[SliderCss.paddingLeft8]} />
                </SliderComponents.HeaderRow>
              </thead>
              <tbody>
                {analyticsAccounts.map((analytic, i) => {
                  const {
                    id,
                    avatar,
                    accountName,
                    socialMediaAccounts,
                    accountManagers,
                    advertiser,
                    subscriptionPlan
                  } = analytic;
                  const { instagram, youtube } = socialMediaAccounts;
                  const pics = accountManagers.map(pic => pic.name).join(', ');
                  const igAccountId = instagram?.id;
                  const { igProfileUrl, ytChannelUrl } = getSMProfileUrls(
                    instagram?.instagramAccountName,
                    youtube?.channelId
                  );

                  return (
                    <StyledComponents.StyledRow
                      key={id}
                      isRowHovered={i === hoverIndex}
                      onMouseOver={() => {
                        setHoverIndex(i);
                      }}
                      css={[SliderCss.paddingSides, SliderCss.rowHeight56]}
                    >
                      <StyledComponents.Account>
                        <StyledComponents.Avatar src={defaultInfluencerAvatar(avatar)} />
                        {/* TODO: this redirect only for v1 IG accountId */}
                        {igAccountId ? (
                          <StyledComponents.TextLink
                            to={`/analytics/dashboard/${igAccountId}`}
                            onClick={() => (!igAccountId ? setYtModalState(true) : null)}
                            css={[SliderCss.getPaddingLeft('16px')]}
                          >
                            {accountName}
                          </StyledComponents.TextLink>
                        ) : (
                          <StyledComponents.BrandAccountName
                            onClick={() => setYtModalState(true)}
                            css={[SliderCss.getPaddingLeft('16px')]}
                          >
                            {accountName}
                          </StyledComponents.BrandAccountName>
                        )}
                      </StyledComponents.Account>
                      <StyledComponents.Media>
                        <StyledComponents.MediaContent>
                          <StyledComponents.StyledSocialMedia
                            socialMedia={SocialAccountType.INSTAGRAM}
                            isDisconnected={!instagram}
                            isTable={false}
                            needReconnect={instagram?.needReconnect}
                            profileUrl={igProfileUrl}
                            onReconnect={() =>
                              setReconnectIgOpen({
                                businessAccountId: instagram?.businessAccountId || '',
                                accountId: String(id),
                                accountName,
                                fbPageId: instagram?.fbPageId || ''
                              })
                            }
                          />
                          <StyledComponents.StyledSocialMedia
                            socialMedia={SocialAccountType.YOUTUBE}
                            isDisconnected={!youtube}
                            isTable={false}
                            needReconnect={youtube?.needReconnect}
                            profileUrl={ytChannelUrl}
                            onReconnect={() =>
                              setReconnectYtOpen({
                                channelId: youtube?.channelId || '',
                                channelName: youtube?.channelName || ''
                              })
                            }
                          />
                        </StyledComponents.MediaContent>
                      </StyledComponents.Media>

                      <TextColumn
                        data={
                          <StyledComponents.TextLink to={`/advertiser/${advertiser.id}`}>
                            {advertiser.name}
                          </StyledComponents.TextLink>
                        }
                      />
                      <TextColumn data={SUBSCRIPTIONS_TYPES.find(s => s.value === subscriptionPlan)?.label || ''} />
                      <TextColumn data={pics} css={picMaxWidth} />
                      <TextColumn data={tc(advertiser.country.name)} />
                      <LinkButtonColumn
                        icon={<StyledComponents.EditIcon src={editIcon} />}
                        title=""
                        href={`/analytics/${id}`}
                      />
                    </StyledComponents.StyledRow>
                  );
                })}
              </tbody>
            </SliderComponents.SliderTable>
          </SliderComponents.SliderSection>
        </SliderComponents.Container>
      </SliderComponents.Wrapper>

      <SliderComponents.Pager
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        first={firstIndex}
        last={lastIndex}
      />
      {/* reconnect modals */}
      <Dialog
        open={!!openedReconnectAccountType}
        title={t('Dialog.Please reconnect account')}
        titleIcon={<StyledComponents.ReconnectIcon src={warningIcon} css={paddingRight8} />}
        onClose={clearReconnectState}
      >
        <StyledComponents.ReconnectText>{t('PleaseReconnectYourAccount')}</StyledComponents.ReconnectText>
        <StyledComponents.ReconnectAccount>
          {renderConnectedSocialMediaAccount(openedReconnectAccountType)}
          <ReconnectButton onClick={() => onReconnectClick(openedReconnectAccountType)} socialMedia={reconnectSMType}>
            {t('Button.Reconnect')}
          </ReconnectButton>
        </StyledComponents.ReconnectAccount>
      </Dialog>
      <Dialog
        open={!!reconnectSuccessType}
        title={t('Dialog.Successfully connected')}
        onClose={clearReconnectState}
        handleExecute={clearReconnectState}
        execute={t('Button.Save')}
        hasCancelButton={false}
      >
        <StyledComponents.ReconnectAccount>
          {renderConnectedSocialMediaAccount(reconnectSuccessType)}
          <img src={checkedIcon} />
        </StyledComponents.ReconnectAccount>
      </Dialog>
      {/* YouTube Dashboard Modal */}
      <Dialog
        open={isYtModalOpen}
        title={t(youtubeContent.title)}
        titleIcon={<StyledComponents.ReconnectIcon src={youtubeContent.icon} css={paddingRight8} />}
        onClose={() => setYtModalState(false)}
      >
        <YouTubeDashboardModal />
      </Dialog>
    </>
  );
};

// TODO: refactor we can use more generic from SliderCss
const accountColWidth = css`
  width: 162px;
`;
const picsWidth = css`
  width: 180px;
`;
const width100 = css`
  width: 100px;
`;
const width150 = css`
  width: 150px;
`;
const width300 = css`
  width: 300px;
`;
const paddingRight8 = css`
  padding-right: 8px;
`;
export const picMaxWidth = css`
  div {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export default AnalyticsListAdmin;
