import { getParamsFromSearch } from '../../libs/paging/utils';
import { getOrderType } from '../constant';
import { Exclusive } from '../types';
import { SortType } from './index';

interface SortParams<T extends string> {
  f: T | ''; // field
  o: string; // order
}

export const getSortParams = <T extends string>(params: SortType<T>): SortParams<T> => ({
  f: params.field || '',
  o: params.order || ''
});

export const getInitialSort = <T extends string>(search: string, getInitialField: (field?: T | '') => T | null) => {
  const params = getParamsFromSearch<SortParams<T>>(search);

  const field = params.f;
  const order = params.o;

  const initialSort: SortType<T> = {
    field: getInitialField(field),
    order: getOrderType(order)
  };

  return initialSort;
};

export const getExclusiveType = (exclusive: string | null) => {
  if (exclusive === null) {
    return null;
  }

  switch (exclusive.toLowerCase()) {
    case 'exclusive':
      return Exclusive.EXCLUSIVE;
    case 'not_exclusive':
      return Exclusive.NOT_EXCLUSIVE;

    default:
      return null;
  }
};
