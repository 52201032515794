import React from 'react';
import { useUpdateDocumentTitle } from '../../libs/hooks';
import { usePaging } from '../../libs/paging/usePaging';
import Template from './template';

const Analytics = () => {
  useUpdateDocumentTitle({ title: 'documentTitle.Analytics', appHeader: 'appHeader.Analytics', href: '' });
  const currentPage = usePaging();

  return <Template currentPage={currentPage} />;
};

export default Analytics;
