import { SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { Option } from '../../../../libs/types';
import BaseSelector, { MenuType } from '../BaseSelector';
import { MenuControlIcon } from '../MultiSelector/MenuControlIcon';
import MenuItems from './MenuItems';
import { getInitialSelectedItem } from './utils';

type ValueType = Option | null;
export interface SingleSelectorProps {
  className?: string;
  value?: string;
  name: string;
  options: Option[];
  error?: boolean;
  disabled?: boolean;
  placeholder?: string;
  hideInput?: boolean;
  hideDeselectOption?: boolean;
  onChange?: (value: string) => void;
  onClose?: () => void;
  dropdownCss?: SerializedStyles;
}

const SingleSelector = (props: SingleSelectorProps) => {
  const {
    className,
    name,
    value = '',
    options,
    error,
    disabled,
    placeholder,
    hideDeselectOption,
    hideInput,
    onChange,
    onClose,
    dropdownCss
  } = props;

  // Items
  const selectedValue = getInitialSelectedItem(value, options) || null;
  const setSelectedValue = (item: Option | null) => {
    if (onChange) {
      onChange(item ? item.value : '');
    }
  };
  const removeValue = (_: Option) => {
    setSelectedValue(null);
  };

  const onClickInputBox = (menu: MenuType) => {
    if (disabled) {
      return;
    }
    menu.setIsOpen(true);
  };

  return (
    // TODO: we can do better generic here and  `type ValueType = Option | null`
    <BaseSelector<ValueType>
      className={className}
      name={name}
      items={{ selectedValue, setSelectedValue, removeValue }}
      error={error}
      options={options}
      disabled={disabled}
      disabledInputBox={false}
      placeholder={!!selectedValue ? '' : placeholder}
      hideInput={hideInput}
      onClose={onClose}
      onClickInputBox={onClickInputBox}
      MenuControlIcon={disabled ? undefined : MenuControlIcon}
      setSelectedValue={setSelectedValue}
      renderSelectedValue={({ selectedValue: option }) =>
        !!option && (
          <SelectedValues>
            {option.prefixIcon}
            {option.icon}
            <SelectedValueText>{option.label}</SelectedValueText>
          </SelectedValues>
        )
      }
      renderMenu={({ items, search, menu, cursor, setCursor }) => {
        const handleRemove = (_: React.MouseEvent<HTMLDivElement>) => {
          if (disabled) {
            return;
          }
          items.setSelectedValue(null);
          setCursor(null);
          menu.setIsOpen(false);
        };

        const handleClickItem = (item: Option) => (_: React.MouseEvent<HTMLDivElement>) => {
          if (disabled) {
            return;
          }
          items.setSelectedValue(item);
          setCursor(item);
          menu.setIsOpen(false);
        };

        return (
          <MenuWrapper>
            <MenuItems
              activeOption={cursor}
              disabled={disabled}
              options={search.filteredValue}
              hideDeselectOption={hideDeselectOption}
              handleRemove={handleRemove}
              handleClick={handleClickItem}
            />
          </MenuWrapper>
        );
      }}
      dropdownCss={dropdownCss}
    />
  );
};

const SelectedValues = styled.div`
  height: 16px;
  display: flex;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  position: absolute;
  padding: 0 2em 0 0.5em;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
`;
const SelectedValueText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MenuWrapper = styled.div`
  position: relative;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export default SingleSelector;
