import styled from '@emotion/styled';
import React from 'react';

interface HeaderButtonProps {
  icon?: string;
  title: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const HeaderButton = (props: HeaderButtonProps) => {
  const { icon, title, onClick } = props;

  return (
    <Button onClick={onClick}>
      <Icon src={icon} />
      <Title>{title}</Title>
    </Button>
  );
};

const Button = styled.button`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 16px;
  cursor: pointer;
`;

const Title = styled.p`
  font-size: 13px;
  line-height: 24px;
  color: #6e7c89;

  ${Button}:hover & {
    color: #3892e5;
  }
`;

const Icon = styled.img`
  margin-right: 8px;
  font-size: 16px;
`;

export default HeaderButton;
