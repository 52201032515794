import React from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import AnalyticsContentWrapper, { ContentType } from '../../AnalyticsShared/AnalyticsContentWrapper';
import ListIndicator from '../../../molecules/ListIndicator';
import { AnalyticsPeriodWithId } from '../../AnalyticsShared/types';
import CompareOverView from './CompareOverview';
import * as IG_COMPARE_OVERVIEW from './InstagramCompareOverview.graphql';
import { InstagramCompareOverview, InstagramCompareOverviewVariables } from './__generated__/InstagramCompareOverview';
import { AvatarAndNameType } from './types';

interface CompareOverViewSectionType extends AnalyticsPeriodWithId {
  comparableAccountIds: number[];
  accountsAvatarAndName: AvatarAndNameType[];
  visibleAccountsIds: number[];
  isMainAccountVisible: boolean;
}
const CompareOverViewSection = ({
  id,
  comparableAccountIds,
  period,
  accountsAvatarAndName,
  visibleAccountsIds,
  isMainAccountVisible
}: CompareOverViewSectionType) => {
  const { data, loading } = useQuery<InstagramCompareOverview, InstagramCompareOverviewVariables>(IG_COMPARE_OVERVIEW, {
    variables: {
      mainAccountId: id,
      comparableAccountIds,
      ...period
    },
    fetchPolicy: 'cache-and-network'
  });

  if (loading) {
    return <StyledLoadingIndicator />;
  }

  const compareOverviewData = data?.instagramCompareOverview;

  return (
    <AnalyticsContentWrapper contentType={ContentType.Overview} tab="compare">
      <CompareOverView
        compareOverviewData={compareOverviewData}
        accountsAvatarAndName={accountsAvatarAndName}
        visibleAccountsIds={visibleAccountsIds}
        id={id}
        isMainAccountVisible={isMainAccountVisible}
      />
    </AnalyticsContentWrapper>
  );
};

const StyledLoadingIndicator = styled(ListIndicator)`
  margin: 0;
`;

export default CompareOverViewSection;
