import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useField, useFormikContext } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTranslateCountryOptions } from '../../../../libs/hooks';
import * as Buttons from '../../../atoms/Button';
import SearchForm from '../../../molecules/SearchForm';
import SingleSelectField from '../../../molecules/SingleSelectedField';
import { BaseComponents, BaseCss } from '../../../molecules/BaseFilterComponents';
import { SUBSCRIPTIONS_TYPES } from '../../../../libs/constant';
import { FormValues, Option } from './types';

export interface AnalyticsFilterProps {
  allCountries: Option[];
  allAdvertisers: Option[];
}

const AnalyticsFilter = (props: AnalyticsFilterProps) => {
  const { allCountries, allAdvertisers } = props;
  const { t } = useTranslation();
  const { handleSubmit, handleBlur, handleChange, setFieldValue } = useFormikContext<FormValues>();

  const [countryField, countryMeta] = useField('countryId');
  const [keywordField] = useField('keyword');
  const [advertiserField] = useField('advertiserId');
  const [subscriptionPlanField] = useField('subscriptionPlan');

  const handleBlurAndSubmit = (e: any) => {
    handleBlur(e);
    handleSubmit(e);
  };

  return (
    <form onSubmit={handleSubmit}>
      <BaseComponents.Container css={[paddingBottom15]}>
        <BaseComponents.Wrapper css={[BaseCss.noMarginBottom]}>
          <Keyword
            title="Search"
            placeholder="Search by account name"
            help="Account Name, Social Media Analytics can be only search in this field"
            value={keywordField.value}
            name="keyword"
            onBlur={handleBlurAndSubmit}
            onChange={handleChange}
            onEnterKeyPress={handleSubmit}
            css={[BaseCss.marginRight, min140]}
          />
          <SingleSelectField
            css={[BaseCss.marginRight, BaseCss.flex]}
            name={advertiserField.name}
            value={advertiserField.value}
            title="Advertiser"
            options={allAdvertisers}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
          />
          <SingleSelectField
            css={[BaseCss.marginRight, BaseCss.flex]}
            name={subscriptionPlanField.name}
            value={subscriptionPlanField.value}
            title="Plan"
            options={SUBSCRIPTIONS_TYPES}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
          />
          <SingleSelectField
            css={[BaseCss.marginRight, BaseCss.flex]}
            name={countryField.name}
            value={countryField.value}
            title="Country"
            error={!!countryMeta.error}
            options={useTranslateCountryOptions(allCountries)}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
          />

          <Button
            css={[min140, alignSelfFlexEnd]}
            href="/analytics/add"
            icon="add"
            title={t('Button.add account')}
            bgcolor="#ff2b52"
          />
        </BaseComponents.Wrapper>
      </BaseComponents.Container>
    </form>
  );
};

const paddingBottom15 = css`
  padding-bottom: 15px;
`;
const alignSelfFlexEnd = css`
  align-self: flex-end;
`;
const min140 = css`
  width: 140px;
`;

const Keyword = styled(SearchForm)`
  flex: 1;
  margin-right: 12px;
`;

const Button = styled(Buttons.LinkButton)`
  width: 180px;
`;

export default AnalyticsFilter;
