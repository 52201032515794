import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../../../libs/hooks/useDialog';
import HighChart, { Highcharts } from '../../../molecules/HighChart';
import Dialog from '../../../molecules/Dialog';
import { TitleWithHelp } from '../../AnalyticsShared/AnalyticsContentWrapper';
import { OpacityContentWrapper } from '../../AnalyticsShared/SharedComponents';
import { formatPercent } from '../../../../libs/format';
import { defaultInfluencerAvatar } from '../../../../libs/image';

interface AreaBreakdownChartProps {
  followerRegionRate: Array<{ name: string; rate: number }>;
  width: number;
  avatar: string;
}
const AreaBreakdownChart = ({ followerRegionRate, width, avatar }: AreaBreakdownChartProps) => {
  const dialog = useDialog();

  const { t } = useTranslation();

  const { areaData, countries } = followerRegionRate?.reduce<{ areaData: number[]; countries: string[] }>(
    (acc, curr) => ({ areaData: [...acc.areaData, curr.rate], countries: [...acc.countries, curr.name] }),
    { areaData: [], countries: [] }
  );

  const chartOptions = (showAll?: boolean): Highcharts.Options => ({
    chart: {
      type: 'bar',
      width: showAll ? 440 : width,
      height: showAll ? null : 265
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: showAll ? countries : countries.slice(0, 7),
      title: {
        text: null
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: ''
      },
      labels: {
        overflow: 'justify',
        formatter: function formatter() {
          return formatPercent(this.value, true, 0);
        }
      }
    },
    tooltip: {
      formatter: function formatter(): string {
        return `
          <div style="border-radius:3px;display:flex;align-items:center;padding:13px 16px">
          <span style="display:inline-block;margin-right:8px;background-color:${
            this.point.color
          };height:12px;width:12px"></span>
            <span style="margin-right:16px">${this.x}</span>
            <span>${formatPercent(this.y)}</span>
          </div>`;
      },
      padding: 0,
      borderColor: 'green',
      borderWidth: 0,
      borderRadius: 3,
      backgroundColor: '#27313b',
      style: {
        color: '#ffffff'
      },
      useHTML: true
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: false
        },
        showInLegend: false
      }
    },

    credits: {
      enabled: false
    },
    series: [
      {
        data: showAll ? areaData : areaData.slice(0, 7),
        color: '#9ea0e0',
        type: 'bar'
      }
    ],
    legend: {
      symbolHeight: 15,
      symbolWidth: 15,
      symbolRadius: 2,
      itemStyle: {
        fontSize: '13px',
        color: '#27313b',
        fontWeight: 'normal'
      }
    }
  });

  return (
    <>
      <OpacityContentWrapper
        header={<TitleWithHelp help="areaBreakdown" title="Area Breakdown" />}
        dataNotAvailable={areaData.every(item => item === 0)}
      >
        <HighChart options={chartOptions()} />
        <Footer>
          <TotalContainer>
            <LabelBlock />
            <TotalText>{t('Total')}</TotalText>
          </TotalContainer>
          <MoreText onClick={dialog.open}>
            {t('MORE')} <Arrow>{'\u2192'}</Arrow>
          </MoreText>
        </Footer>
      </OpacityContentWrapper>
      <Dialog
        open={dialog.isOpening}
        onClose={dialog.close}
        title={t('Follower Area')}
        titleIcon={<ModalAvatarIcon src={defaultInfluencerAvatar(avatar)} alt="avatar" />}
      >
        <HighChart options={chartOptions(true)} />
      </Dialog>
    </>
  );
};

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TotalContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;
const LabelBlock = styled.span`
  position: relative;
  background-color: #9ea0e0;
  width: 15px;
  height: 15px;
  margin-right: 8px;
  border-radius: 2px;
`;
const TotalText = styled.p`
  font-size: 13px;
  color: #27313b;
`;
const MoreText = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: #6e7c89;
  cursor: pointer;
`;
const Arrow = styled.span`
  color: #ff2b52;
`;
const ModalAvatarIcon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
`;

export default AreaBreakdownChart;
