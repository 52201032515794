import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const usePseudoAttribute = (root: HTMLElement | null) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    if (!!root) {
      root.setAttribute('lang', i18n.language);
    }
  }, [i18n.language, i18n.isInitialized]);
};
