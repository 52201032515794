import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ORDER } from '../../__generated__/globalTypes';
import { initialPagingParam } from '../paging';
import { setUrlParams } from '../url';
import { getInitialSort, getSortParams } from './utils';

export interface SortType<T extends string> {
  field: T | null;
  order: ORDER | null;
}

export interface SortActionType<T extends string> {
  sort: SortType<T>;
  setSort: (sort: SortType<T>) => void;
}

export const useSort = <T extends string>(
  getInitialField: (field: T | '' | undefined) => T | null
): SortActionType<T> => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const initialSort = getInitialSort(search, getInitialField);
  const [sort, baseSetSort] = useState<SortType<T>>(initialSort);

  const setSort = (props: SortType<T>) => {
    const shouldReset = props.field === sort.field && props.order === sort.order;
    const newSort = shouldReset ? { field: null, order: null } : props;
    baseSetSort(newSort);

    // Set parameters to Url
    const newUrlParams = {
      ...initialPagingParam,
      ...getSortParams<T>(newSort)
    };
    setUrlParams(history, pathname, search, newUrlParams);
  };

  return { sort, setSort };
};
