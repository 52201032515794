import React from 'react';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import AnalyticsContentWrapper, { ContentType, TitleWithHelp } from '../../AnalyticsShared/AnalyticsContentWrapper';
import ListIndicator from '../../../molecules/ListIndicator';
import { AvatarAndNameType } from '../OverView/types';
import { OpacityContentWrapper } from '../../AnalyticsShared/SharedComponents';
import { AnalyticsPeriodWithId } from '../../AnalyticsShared/types';
import GenderBreakdown from './GenderBreakdown';
import AgeGenderDemographicChart, { AgeGenderDemographicChartProps } from './AgeGenderDemographicChart';
import * as IG_COMPARE_AUDIENCE from './InstagramCompareFollowers.graphql';
import {
  InstagramCompareFollowers,
  InstagramCompareFollowersVariables
} from './__generated__/InstagramCompareFollowers';
import { getAgeGroups } from './helpers';

interface AudienceProps extends AnalyticsPeriodWithId {
  comparableAccountIds: number[];
  accountsAvatarAndName: AvatarAndNameType[];
  visibleAccountsIds: number[];
  isMainAccountVisible: boolean;
}
const Audience = ({
  id,
  comparableAccountIds,
  period,
  accountsAvatarAndName,
  visibleAccountsIds,
  isMainAccountVisible
}: AudienceProps) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery<InstagramCompareFollowers, InstagramCompareFollowersVariables>(
    IG_COMPARE_AUDIENCE,
    {
      variables: {
        accountId: id,
        comparableAccountIds,
        ...period
      }
    }
  );

  if (loading) {
    return <StyledLoadingIndicator />;
  }

  if (!data?.instagramCompareFollowers || !accountsAvatarAndName) {
    return null;
  }

  const { mainAccount, compareAccounts } = data.instagramCompareFollowers;
  const combinedAccountResponseData = [
    ...(isMainAccountVisible ? [mainAccount] : []),
    ...compareAccounts.filter(acc => visibleAccountsIds.includes(acc.id))
  ];
  // for 0 account selected just render table header (rare case)
  if (!combinedAccountResponseData.length) {
    return (
      <AnalyticsContentWrapper contentType={ContentType.Audience} tab="compare">
        <Header>
          <p css={thumbnailColWidth}>{t('HeaderColumn.Account')}</p>
          <TitleWithHelp title="Est. Gender Breakdown" help="estGenderBreakdown" css={breakdownColCss} />
          <TitleWithHelp title="Est. Age/Gender Demographic" help="estAgeGenderDemographic" css={demographicColCss} />
        </Header>
      </AnalyticsContentWrapper>
    );
  }

  const audienceData = accountsAvatarAndName.map((acc, i) => ({
    ...acc,
    femaleRate: combinedAccountResponseData[i].followerGenderRate.femaleRate,
    maleRate: combinedAccountResponseData[i].followerGenderRate.maleRate,
    ageGenderRate: combinedAccountResponseData[i].followerAgeRate
  }));
  const getDemographicChartData = (idx: number): AgeGenderDemographicChartProps => ({
    name: audienceData[idx].name,
    ageGenderRate: {
      maleRate: audienceData[idx].ageGenderRate.maleRate,
      femaleRate: audienceData[idx].ageGenderRate.femaleRate
    },
    ageGroup: getAgeGroups(audienceData[idx].ageGenderRate.ageGroup)
  });

  return (
    <AnalyticsContentWrapper contentType={ContentType.Audience} tab="compare">
      <FlexContainer>
        {audienceData.map((item, index) => {
          const noGenderData = !(item.femaleRate || item.maleRate);
          const demographicData = getDemographicChartData(index);
          const noDemographicData = !(
            demographicData.ageGenderRate.femaleRate?.length && demographicData.ageGenderRate.femaleRate?.length
          );
          const isOddNumber = audienceData.length % 2 === 1;

          return (
            <FlexItem key={index} isOddNumber={isOddNumber}>
              <Top>
                <NameAndAvatar>
                  <Avatar src={item.avatar} />
                  <NameWrapper title={item.name}>{item.name}</NameWrapper>
                </NameAndAvatar>
                <StyledOpacityContentWrapper
                  key={index}
                  dataNotAvailable={noGenderData}
                  css={
                    noGenderData
                      ? css`
                          zoom: 0.5;
                        `
                      : ''
                  }
                >
                  <GenderBreakdown male={item.maleRate || 0} female={item.femaleRate || 0} />
                </StyledOpacityContentWrapper>
              </Top>
              {/* bottom */}
              <OpacityContentWrapper dataNotAvailable={noDemographicData}>
                <AgeGenderDemographicChart {...demographicData} />
              </OpacityContentWrapper>
            </FlexItem>
          );
        })}
      </FlexContainer>
    </AnalyticsContentWrapper>
  );
};

const thumbnailColWidth = css`
  width: 160px;
`;
const breakdownColCss = css`
  width: 276px;
  justify-content: center;
  margin-bottom: 0;
`;
const demographicColCss = css`
  flex: 1;
  justify-content: center;
  margin-bottom: 0;
`;
const Top = styled.div`
  display: flex;
  width: 100%;
  place-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;
const Header = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  background-color: #f6f8fa;

  p {
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    color: #27313b;
  }
`;
const FlexContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
`;
const FlexItem = styled.div<{ isOddNumber: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 0 50%;
  padding: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #dee5ec;

  &:nth-of-type(odd) {
    border-right: 1px solid #dee5ec;
  }

  /* last 2 or 1 element depends on odd or even length */
  &:nth-last-of-type(-n + ${({ isOddNumber }) => (isOddNumber ? '1' : '2')}) {
    border-bottom: none;
  }
`;
const NameAndAvatar = styled.div`
  display: flex;
  place-items: center;
`;
const Avatar = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 8px;
  border-radius: 20px;
`;
const StyledLoadingIndicator = styled(ListIndicator)`
  margin: 0;
`;
const NameWrapper = styled.p`
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StyledOpacityContentWrapper = styled(OpacityContentWrapper)`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default Audience;
