// Diff / Omit taken from https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

import { ReactNode } from 'react';

export enum UserType {
  ADMINISTRATOR = 'ADMINISTRATOR',
  ADVERTISER = 'ADVERTISER',
  STAFF = 'STAFF',
  AGENCY = 'AGENCY'
}

/**
 * Form Types
 */
// Form Option Type
export interface Option {
  label: string;
  value: string;
  icon?: ReactNode;
  title?: string;
  prefixIcon?: ReactNode;
}

export interface CountryOption extends Option {
  currency: string;
}

export interface PeriodType {
  startDate: string;
  endDate: string;
}

/**
 * Influencer
 */
export enum Exclusive {
  EXCLUSIVE = 'EXCLUSIVE',
  NOT_EXCLUSIVE = 'NOT_EXCLUSIVE'
}

export enum CampaignDisplay {
  YES = 'TRUE',
  NO = 'FALSE'
}

// -> in-depth explanation https://fettblog.eu/typescript-union-to-intersection/
export type UnionToIntersection<T> = (T extends any ? (x: T) => any : never) extends (x: infer R) => any ? R : never;
