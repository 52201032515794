import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { getLocationFromSearch } from '../../../../libs/paging';

// TODO: idea is to pass any posts data, but fields might be differently typed
export const usePostDetailModal = (postsData: any[] | null, paramName: string | undefined = 'popup') => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const [openPostDetail, setOpenPostDetail] = useState<number | null>(null);
  const [siblingPostId, setSiblingPostId] = useState<{ prevId: number | null; nextId: number | null }>({
    prevId: null,
    nextId: null
  });

  const setQueryParams = (postId: number | null) => {
    const urlParams = getLocationFromSearch(pathname, search, { [paramName]: postId || '' });

    history.replace(`${urlParams.pathname}${urlParams.search}`);
  };

  const handleClosePostDetail = () => {
    setOpenPostDetail(null);
    setQueryParams(null);
  };

  const handleClickPost = (id: number) => {
    setOpenPostDetail(id);
    setQueryParams(id);
    getSiblingPostId(id);
  };

  const getSiblingPostId = (postId: number | null) => {
    if (postsData) {
      const index = postsData.findIndex(item => item.id === postId);

      const prevId = index === 0 || postsData.length <= 1 ? null : postsData[index - 1].id;

      const nextId = postsData.length > index + 1 ? postsData[index + 1].id : null;

      setSiblingPostId({ prevId, nextId });
    }
  };

  const handleClickNext = () => {
    getSiblingPostId(siblingPostId.nextId);
    setQueryParams(siblingPostId.nextId);
    setOpenPostDetail(siblingPostId.nextId);
  };

  const handleClickPrev = () => {
    getSiblingPostId(siblingPostId.prevId);
    setQueryParams(siblingPostId.prevId);
    setOpenPostDetail(siblingPostId.prevId);
  };

  return {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev,
    setOpenPostDetail
  };
};
