import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { css } from '@emotion/core';

export const NavbarWrapper = styled.div`
  margin: 24px 24px 0;
  background-color: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
`;
export const NavBar = styled.div<{ hasBorder?: boolean }>`
  display: flex;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  background-color: #fff;
  border: ${({ hasBorder }) => (!!hasBorder ? '1px solid #dee5ec' : 'none')};
  border-left: none;
  border-right: none;
`;

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
`;
export const NavTab = styled(NavLink)`
  height: 45px;
  padding: 0 15px;
  line-height: 45px;
  cursor: pointer;
  border-bottom: 4px solid transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:not(:first-of-type) {
    margin-left: 17px;
  }

  &.selected {
    border-bottom-color: #ff2b52;
    color: #ff2b52;
  }
`;
export const Delimeter = styled.span`
  width: 1px;
  height: 24px;
  margin-left: 17px;
  background-color: #dee5ec;
`;

export const btnWidth = css`
  width: 140px;
`;
export const padding018 = css`
  padding: 0 18px;
`;
