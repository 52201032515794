import React from 'react';
import { SortType } from '../../libs/filter';
import { useUpdateDocumentTitle } from '../../libs/hooks';
import { InstagramFeedPostSortField } from '../../__generated__/globalTypes';
import InstagramFeedPostFilter from '../../components/organisms/AnalyticsShared/BasePostListFilter';
import InstagramFeedPostList from '../../components/organisms/AnalyticsPost/InstagramFeedPostList';
import { InstagramFeedPostFilterType } from './types';

interface TemplateProps {
  accountId: number;
  accountName: string;
  currentPage: number;
  filter: InstagramFeedPostFilterType;
  setFilter: (filter: InstagramFeedPostFilterType) => void;
  sort: SortType<InstagramFeedPostSortField>;
  setSort: (sort: SortType<InstagramFeedPostSortField>) => void;
}

const Template = (props: TemplateProps) => {
  const { currentPage, accountName, accountId, filter, setFilter, sort, setSort } = props;
  const goBackUrl = `/analytics/dashboard/${accountId}`;

  useUpdateDocumentTitle({
    title: !!accountName ? 'documentTitle.PostListWithAccountName' : 'documentTitle.Post List',
    interpolation: {
      title: accountName
    },
    appHeader: !!accountName ? 'appHeader.PostListWithAccountName' : 'appHeader.Post List',
    href: goBackUrl
  });

  return (
    <>
      <InstagramFeedPostFilter filter={filter} setFilter={setFilter} />
      <InstagramFeedPostList
        accountId={accountId}
        currentPage={currentPage}
        filter={filter}
        sort={sort}
        setSort={setSort}
        goBackUrl={goBackUrl}
      />
    </>
  );
};

export default Template;
