import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { AdminStore } from '../../../Context';
import { getToken, isValidUserRole, removeToken } from '../../../libs/auth/index';
import InitialLoading from '../../molecules/InitialLoading';
import { VerifyToken } from './__generated__/VerifyToken';
import * as VERIFY_TOKEN from './VerifyToken.graphql';

export interface VerifyTokenProps {
  children: ReactElement<any> | null;
}

const VerifyTokenComponent = (props: VerifyTokenProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const { dispatch } = useContext(AdminStore);
  const [doMutation] = useMutation<VerifyToken>(VERIFY_TOKEN);

  const dispatchAuthUser = (userId: number | null, role: string | null, hash: string | null, email: string | null) => {
    dispatch({
      type: 'AUTH_USER',
      payload: { userId, role, hash, email }
    });
  };

  // verify token and get userId and role
  const updateAuthData = async () => {
    const token = getToken();
    const variables = {
      input: {
        token
      }
    };

    if (!token) {
      dispatchAuthUser(null, null, null, null);
      setIsLoading(false);

      return;
    }

    const { data } = await doMutation({ variables });
    // update user data on success response
    if (!!data && !!data.verifyToken) {
      const {
        verifyToken: { userId, role, hash, email }
      } = data;

      if (!!userId && isValidUserRole(role)) {
        dispatchAuthUser(userId, role, hash, email);
      } else {
        removeToken();
        dispatchAuthUser(null, null, null, null);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    updateAuthData();
  }, []);

  if (isLoading) {
    return <InitialLoading />;
  }

  return props.children;
};

export default VerifyTokenComponent;
