import { LocationDescriptor } from 'history';
import {
  CampaignReportOrderField,
  CampaignSocialMediaType,
  EngagementCampaignPostStatus,
  EngagementCampaignStatus,
  Genders,
  MarketplaceCampaignPostStatusForSearch,
  ORDER,
  QuerySocialAccountType,
  SelectedInfluencers,
  SocialAccountStatus,
  UserRoles,
  SocialAccountType,
  CampaignType,
  AnalyticsSubscriptionPlanType
} from '../__generated__/globalTypes';
import ALL_COUNTIES_NAME from '../assets/AllCountriesList.json';
import advertiserIcon from '../assets/icon/advertiser.svg';
import reportIcon from '../assets/icon/report.svg';
import userIcon from '../assets/icon/user.svg';
import paymentIcon from '../assets/icon/payment.svg';
import influencerIcon from '../assets/icon/influencer.svg';
import marketplaceIcon from '../assets/icon/marketplace.svg';
import engagementIcon from '../assets/icon/engagement.svg';
import advertiserMenuIcon from '../assets/icon/advertiserRed.svg';
import reportMenuIcon from '../assets/icon/reportRed.svg';
import userMenuIcon from '../assets/icon/userRed.svg';
import paymentMenuIcon from '../assets/icon/paymentRed.svg';
import influencerMenuIcon from '../assets/icon/influencerRed.svg';
import marketplaceMenuIcon from '../assets/icon/marketplaceRed.svg';
import engagementMenuIcon from '../assets/icon/engagementRed.svg';
import analyticsIconMenu from '../assets/icon/analytics_white_top.svg';
import analyticsIconHeader from '../assets/icon/analytics_red_top.svg';
import settingsMenuIcon from '../assets/icon/settings.svg';
import settingsHeaderIcon from '../assets/icon/settingsHeader.svg';
import { switchText } from './SocialMedia';
import {
  getEngagementPostStatusMessage,
  getEngagementStatusMessage,
  getMarketplacePostStatusMessage,
  getWarningReasonStatusMessage
} from './status';
import { CampaignDisplay, CountryOption, Exclusive, Option } from './types';

export const APP_NAME = 'AnyTag';

export enum MenuTitle {
  Report = 'Report',
  Marketplace = 'Marketplace',
  Engagement = 'Engagement',
  Influencer = 'Influencer',
  Advertiser = 'Advertiser',
  User = 'User',
  Payment = 'Payment',
  Analytics = 'Analytics',
  Settings = 'Settings'
}

/**
 * Get menu icon by menuTitle
 */
export const getMenuIcon = (menuTitle: MenuTitle): string => {
  switch (menuTitle) {
    case MenuTitle.Report:
      return reportIcon;
    case MenuTitle.Marketplace:
      return marketplaceIcon;
    case MenuTitle.Engagement:
      return engagementIcon;
    case MenuTitle.Influencer:
      return influencerIcon;
    case MenuTitle.Advertiser:
      return advertiserIcon;
    case MenuTitle.User:
      return userIcon;
    case MenuTitle.Payment:
      return paymentIcon;
    case MenuTitle.Analytics:
      return analyticsIconMenu;
    case MenuTitle.Settings:
      return settingsMenuIcon;
    default:
      return '';
  }
};

/**
 * Get header icon by menuTitle
 */
export const getHeaderIcon = (menuTitle?: MenuTitle): string => {
  switch (menuTitle) {
    case MenuTitle.Report:
      return reportMenuIcon;
    case MenuTitle.Marketplace:
      return marketplaceMenuIcon;
    case MenuTitle.Engagement:
      return engagementMenuIcon;
    case MenuTitle.Influencer:
      return influencerMenuIcon;
    case MenuTitle.Advertiser:
      return advertiserMenuIcon;
    case MenuTitle.User:
      return userMenuIcon;
    case MenuTitle.Payment:
      return paymentMenuIcon;
    case MenuTitle.Analytics:
      return analyticsIconHeader;
    case MenuTitle.Settings:
      return settingsHeaderIcon;
    default:
      return '';
  }
};

export interface MenuItemType {
  href: LocationDescriptor;
  title: MenuTitle;
}

const BASE_MENU_ITEMS: MenuItemType[] = [
  { href: '/report', title: MenuTitle.Report },
  { href: '/marketplace', title: MenuTitle.Marketplace },
  { href: '/engagement', title: MenuTitle.Engagement }
];

export const ADMIN_MENU_ITEMS: MenuItemType[] = [
  ...BASE_MENU_ITEMS,
  { href: '/influencer/list/instagram', title: MenuTitle.Influencer },
  { href: '/advertiser', title: MenuTitle.Advertiser },
  { href: '/user', title: MenuTitle.User },
  { href: '/analytics', title: MenuTitle.Analytics },
  { href: '/payment', title: MenuTitle.Payment }
];

export const ADVERTISER_MENU_ITEMS = [
  ...BASE_MENU_ITEMS,
  { href: '/analytics', title: MenuTitle.Analytics },
  { href: '/settings', title: MenuTitle.Settings }
];

export const AGENCY_MENU_ITEMS: MenuItemType[] = [
  ...BASE_MENU_ITEMS,
  { href: '/advertiser', title: MenuTitle.Advertiser },
  { href: '/user', title: MenuTitle.User },
  { href: '/analytics', title: MenuTitle.Analytics }
];

/**
 * User
 */

export const getUserRoleString = (role: UserRoles | null) => {
  switch (role) {
    case UserRoles.ADMINISTRATOR:
      return 'Administrator';
    case UserRoles.STAFF:
      return 'Staff';
    case UserRoles.ADVERTISER:
      return 'Advertiser';
    case UserRoles.AGENCY:
      return 'Agency';
    default:
      return '';
  }
};

/**
 * Influencer
 */

export const getExclusiveString = (exclusive: Exclusive | null) => {
  switch (exclusive) {
    case Exclusive.NOT_EXCLUSIVE:
      return 'Not Exclusive';
    case Exclusive.EXCLUSIVE:
      return 'Exclusive';
    default:
      return '';
  }
};

export const EXCLUSIVE_OPTION = [Exclusive.EXCLUSIVE].map(status => ({
  label: getExclusiveString(status),
  value: status
}));

export const EXCLUSIVE_ON_OFF_OPTION = [
  {
    label: 'ON',
    value: Exclusive.EXCLUSIVE
  },
  { label: 'OFF', value: Exclusive.NOT_EXCLUSIVE }
];

export const DISPLAY_CAMPAIGN_OPTION = [
  { label: 'Show', value: CampaignDisplay.YES },
  { label: 'Hide', value: CampaignDisplay.NO }
];

/**
 * MarketplaceFilterType
 */

export const getFieldType = (field: string | null): CampaignReportOrderField | null => {
  switch (field) {
    case 'COMMENT':
      return CampaignReportOrderField.COMMENT;
    case 'COST':
      return CampaignReportOrderField.COST;
    case 'COST_PER_COMMENT':
      return CampaignReportOrderField.COST_PER_COMMENT;
    case 'COST_PER_LIKE':
      return CampaignReportOrderField.COST_PER_LIKE;
    case 'COST_PER_VIEW':
      return CampaignReportOrderField.COST_PER_VIEW;
    case 'COST_PER_FOLLOWER':
      return CampaignReportOrderField.COST_PER_FOLLOWER;
    case 'COST_PER_CLICK':
      return CampaignReportOrderField.COST_PER_CLICK;
    case 'COST_PER_ACTION':
      return CampaignReportOrderField.COST_PER_ACTION;
    case 'ENGAGEMENT':
      return CampaignReportOrderField.ENGAGEMENT;
    case 'ENGAGEMENT_RATE':
      return CampaignReportOrderField.ENGAGEMENT_RATE;
    case 'LIKE':
      return CampaignReportOrderField.LIKE;
    case 'NET':
      return CampaignReportOrderField.NET;
    case 'PROFIT':
      return CampaignReportOrderField.PROFIT;
    case 'PROFIT_RATE':
      return CampaignReportOrderField.PROFIT_RATE;
    case 'REACH':
      return CampaignReportOrderField.REACH;
    case 'SHARE':
      return CampaignReportOrderField.SHARE;
    case 'VIEW':
      return CampaignReportOrderField.VIEW;
    case 'CLICK':
      return CampaignReportOrderField.CLICK;
    case 'CONVERSION':
      return CampaignReportOrderField.CONVERSION;
    default:
      return null;
  }
};
export const getOrderType = (order?: string | null): ORDER | null => {
  switch (order) {
    case 'ASC':
      return ORDER.ASC;
    case 'DESC':
      return ORDER.DESC;
    default:
      return null;
  }
};

/**
 * MarketplaceStatusBox
 */

export const MARKETPLACE_POST_STATUS = [
  MarketplaceCampaignPostStatusForSearch.JOINED,
  MarketplaceCampaignPostStatusForSearch.APPROVED,
  MarketplaceCampaignPostStatusForSearch.WARNING,
  MarketplaceCampaignPostStatusForSearch.EFFECTIVE,
  MarketplaceCampaignPostStatusForSearch.INEFFECTIVE,
  MarketplaceCampaignPostStatusForSearch.TRACKING
].map(status => ({
  label: getMarketplacePostStatusMessage(status),
  value: status
}));

export const WARNING_REASON_STATUS = [
  MarketplaceCampaignPostStatusForSearch.AD_FRAUD,
  MarketplaceCampaignPostStatusForSearch.DELETED_POST,
  MarketplaceCampaignPostStatusForSearch.INVALID_TOKEN,
  MarketplaceCampaignPostStatusForSearch.INVALID_CONTENT,
  MarketplaceCampaignPostStatusForSearch.SCRAPING_ERROR
].map(status => ({
  label: getWarningReasonStatusMessage(status),
  value: status
}));

/**
 * Engagement statuses
 */
export const getEngagementStatusOptions = (isAdvertiserUser: boolean) =>
  (Object.keys(EngagementCampaignStatus) as EngagementCampaignStatus[]).reduce<{ value: string; label: string }[]>(
    (acc, currStatus) => {
      if (isAdvertiserUser && currStatus === EngagementCampaignStatus.LOST) {
        return acc;
      }

      return [
        ...acc,
        {
          label: getEngagementStatusMessage(currStatus, isAdvertiserUser ? UserRoles.ADVERTISER : null),
          value: currStatus
        }
      ];
    },
    []
  );

export const ENGAGEMENT_POST_STATUS = [
  EngagementCampaignPostStatus.REVIEWING,
  EngagementCampaignPostStatus.READY_TO_POST,
  EngagementCampaignPostStatus.EFFECTIVE,
  EngagementCampaignPostStatus.WARNING,
  EngagementCampaignPostStatus.INEFFECTIVE,
  EngagementCampaignPostStatus.APPROVED,
  EngagementCampaignPostStatus.REJECT
].map(status => ({
  label: getEngagementPostStatusMessage(status),
  value: status
}));

export const REJECT_ENGAGEMENT_POST = [
  { value: 'Inappropriate contents', label: 'Inappropriate contents' },
  { value: 'Doesn’t match the conditions', label: 'Doesn’t match the conditions' }
];
export const REPORT_INAPPROPRIATE_STATUS = [...REJECT_ENGAGEMENT_POST, { value: 'Other', label: 'Other' }];

export const INFLUENCER_STATUS = [
  { value: SocialAccountStatus.SIGNED_UP, label: 'Signed Up' },
  { value: SocialAccountStatus.SCRAPING, label: 'Not Signed Up' }
];

export const PAYMENT_STATUS = [
  { value: 'REVIEWING', label: 'Reviewing' },
  { value: 'PAID', label: 'Paid' },
  { value: 'FAILED', label: 'Failed' }
];

/**
 * Category
 */

export const CATEGORIES: Option[] = [
  { value: '1', label: 'News' },
  { value: '2', label: 'Beauty' },
  { value: '3', label: 'Comedy' },
  { value: '4', label: 'Sports' },
  { value: '5', label: 'FanPage' },
  { value: '6', label: 'Celebrity' },
  { value: '7', label: 'Food & Drink' },
  { value: '8', label: 'Kid & Family' },
  { value: '9', label: 'Technology' },
  { value: '10', label: 'Real Estate' },
  { value: '11', label: 'Pets & Animals' },
  { value: '12', label: 'Games & Gadgets' },
  { value: '13', label: 'Autos & Vehicles' },
  { value: '14', label: 'Fitness & Health' },
  { value: '15', label: 'Leisure & Travel' },
  { value: '16', label: 'Finance & Banking' },
  { value: '17', label: 'Fashion & Lifestyle' },
  { value: '18', label: 'Arts & Entertainment' },
  { value: '19', label: 'Business & Industrial' }
];

/**
 * Social Media
 */

export const ALL_SOCIAL_MEDIAS = [
  CampaignSocialMediaType.FACEBOOK,
  CampaignSocialMediaType.INSTAGRAM,
  CampaignSocialMediaType.INSTAGRAM_STORY,
  CampaignSocialMediaType.TWITTER,
  CampaignSocialMediaType.YOUTUBE
].map(campaignType => ({
  label: switchText(campaignType),
  value: campaignType
}));

export const SOCIAL_MEDIAS = ALL_SOCIAL_MEDIAS.filter(socialMedias =>
  [
    CampaignSocialMediaType.FACEBOOK,
    CampaignSocialMediaType.INSTAGRAM,
    CampaignSocialMediaType.YOUTUBE,
    CampaignSocialMediaType.INSTAGRAM_STORY,
    CampaignSocialMediaType.TWITTER
  ].includes(socialMedias.value)
);

export const ALL_SOCIAL_MEDIAS_INFLUENCER = [
  QuerySocialAccountType.FACEBOOK,
  QuerySocialAccountType.FACEBOOK_PAGE,
  QuerySocialAccountType.INSTAGRAM,
  QuerySocialAccountType.INSTAGRAM_BUSINESS,
  QuerySocialAccountType.TWITTER,
  QuerySocialAccountType.YOUTUBE
].map(campaignType => ({
  label: switchText(campaignType),
  value: campaignType
}));

export const SOCIAL_MEDIAS_INFLUENCER = ALL_SOCIAL_MEDIAS_INFLUENCER.filter(socialMedias =>
  [
    QuerySocialAccountType.FACEBOOK,
    QuerySocialAccountType.INSTAGRAM,
    QuerySocialAccountType.YOUTUBE,
    QuerySocialAccountType.INSTAGRAM_BUSINESS,
    QuerySocialAccountType.TWITTER
  ].includes(socialMedias.value as QuerySocialAccountType)
);

export const PROFILE_SOCIAL_MEDIAS = ALL_SOCIAL_MEDIAS_INFLUENCER.filter(socialMedia =>
  [
    QuerySocialAccountType.FACEBOOK,
    QuerySocialAccountType.INSTAGRAM,
    QuerySocialAccountType.YOUTUBE,
    QuerySocialAccountType.TWITTER
  ].includes(socialMedia.value as QuerySocialAccountType)
);
/**
 * Country
 */
// TODO: later we will use API for getting countries list for each specific place, for now use all of them everywhere
export const COUNTRY_IDS: CountryOption[] = [
  { value: 'JP', label: 'Japan', currency: 'JPY' },
  { value: 'HK', label: 'Hong Kong', currency: 'HKD' },
  { value: 'ID', label: 'Indonesia', currency: 'IDR' },
  { value: 'KH', label: 'Cambodia', currency: 'KHR' },
  { value: 'MM', label: 'Myanmar', currency: 'MMK' },
  { value: 'MY', label: 'Malaysia', currency: 'MYR' },
  { value: 'PH', label: 'Philippines', currency: 'PHP' },
  { value: 'SG', label: 'Singapore', currency: 'SGD' },
  { value: 'TH', label: 'Thailand', currency: 'THB' },
  { value: 'TW', label: 'Taiwan', currency: 'TWD' },
  { value: 'VN', label: 'Vietnam', currency: 'VND' },
  { value: 'IN', label: 'India', currency: 'INR' },
  { value: 'AE', label: 'United Arab Emirates', currency: 'USD' },
  { value: 'SA', label: 'Saudi Arabia', currency: 'USD' },
  { value: 'KW', label: 'Kuwait', currency: 'USD' }
];
export const US_COUNTRY_OPTION: CountryOption = { value: 'US', label: 'United States', currency: 'USD' };
export const AVAILABLE_COUNTRIES_LIST = [...COUNTRY_IDS, US_COUNTRY_OPTION];
export const MARKETPLACE_ALLOWED_COUNTRIES = COUNTRY_IDS.slice(0, 12);
interface AllCoutriesType {
  name: string;
  id: string;
}
export const ALL_COUNTRY_IDS: CountryOption[] = ALL_COUNTIES_NAME.sort((a: AllCoutriesType, b: AllCoutriesType) =>
  a.name.localeCompare(b.name)
).map((country: AllCoutriesType) => {
  const anyTagDefaultCountries = AVAILABLE_COUNTRIES_LIST.map(ids => ids.label);
  // if it is default CA coutry, then return it
  if (anyTagDefaultCountries.includes(country.name)) {
    return AVAILABLE_COUNTRIES_LIST.find(countryId => countryId.label === country.name);
  }

  // otherwise create new country with USD currency
  return {
    value: country.id,
    label: country.name,
    currency: 'USD'
  };
});

/**
 * Gender
 */
export const GENDERS = [
  { value: Genders.FEMALE, label: 'Female' },
  { value: Genders.MALE, label: 'Male' },
  { value: Genders.NOT_APPLICABLE, label: 'Other' }
];
export const AUDIENCE_GENDERS = [
  { value: Genders.FEMALE, label: 'Female' },
  { value: Genders.MALE, label: 'Male' }
];

/**
 * Selected Influencer
 */
export const SELECTED_INFLUENCERS_LIST = [
  { value: SelectedInfluencers.SELECTED, label: 'Selected' },
  { value: SelectedInfluencers.UNSELECTED, label: 'Unselected' }
];

export const getGenderString = (gender: Genders | null) => {
  switch (gender) {
    case Genders.MALE:
      return 'Male';
    case Genders.FEMALE:
      return 'Female';
    case Genders.NOT_APPLICABLE:
    case Genders.UNKNOWN:
      return 'Others';
    default:
      return '';
  }
};

/**
 * Date Format
 */
export const DEFAULT_FNS_DATE_FORMAT = 'yyyy-MM-dd';

const CURRENCIES = AVAILABLE_COUNTRIES_LIST.map(country => country.currency);
export const ALL_CURRENCIES = CURRENCIES.map(curr => ({ label: curr, value: curr }));
export const ALL_AVAILABLE_COUNTRIES = AVAILABLE_COUNTRIES_LIST.map(country => country.value);

export const POST_COMMENTS_LIMIT = 10;

export const ALL_SOCIAL_ACCOUNTS = [
  {
    label: 'YouTube',
    value: SocialAccountType.YOUTUBE
  },
  {
    label: 'Instagram',
    value: SocialAccountType.INSTAGRAM
  },
  {
    label: 'Twitter',
    value: SocialAccountType.TWITTER
  },
  {
    label: 'Facebook',
    value: SocialAccountType.FACEBOOK
  },
  {
    label: 'Facebook Page',
    value: SocialAccountType.FACEBOOK_PAGE
  }
];
// exclude Facebook Page type
export const PPTX_SOCIAL_MEDIAS = ALL_SOCIAL_ACCOUNTS.slice(0, 4);

export const CAMPAIGN_TYPES = [
  { value: CampaignType.ENGAGEMENT, label: 'Engagement' },
  { value: CampaignType.MARKETPLACE, label: 'Marketplace' },
  { value: '', label: 'None' }
];

export const SUBSCRIPTIONS_TYPES = [
  { value: AnalyticsSubscriptionPlanType.FREE, label: 'Free' },
  { value: AnalyticsSubscriptionPlanType.BASIC, label: 'Basic' },
  { value: AnalyticsSubscriptionPlanType.BASIC_PLUS, label: 'Basic +' },
  { value: AnalyticsSubscriptionPlanType.BASIC_PLUS_PLUS, label: 'Basic ++' },
  { value: AnalyticsSubscriptionPlanType.ENTERPRISE, label: 'Enterprise' },
  { value: AnalyticsSubscriptionPlanType.ENTERPRISE_PLUS, label: 'Enterprise +' },
  { value: AnalyticsSubscriptionPlanType.ENTERPRISE_PLUS_PLUS, label: 'Enterprise ++' }
];

// Audience
export const AUDIENCE_AGE_GROUPS = [
  // 'All' as selected equal unselected field for API request should be null
  { value: '', label: 'All' },
  { value: '13, 17', label: '13-17' },
  { value: '18, 24', label: '18-24' },
  { value: '25, 34', label: '25-34' },
  { value: '35, 44', label: '35-44' },
  { value: '45, 54', label: '45-54' },
  { value: '55, 64', label: '55-64' },
  { value: '65, 100', label: '65-' }
];
export const AUDIENCE_RATIO = [
  { value: '0', label: '0%' },
  { value: '0.1', label: '10%' },
  { value: '0.2', label: '20%' },
  { value: '0.3', label: '30%' },
  { value: '0.4', label: '40%' },
  { value: '0.5', label: '50%' },
  { value: '0.6', label: '60%' },
  { value: '0.7', label: '70%' },
  { value: '0.8', label: '80%' },
  { value: '0.9', label: '90%' }
];
