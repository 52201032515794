import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import {
  AddAnalyticsAdminAccountSchemaV2,
  AddAnalyticsAdvertiserAccountSchema,
  UNEXPECTED_ERROR
} from '../../../../libs/validation';
import { getOptions } from '../../../../libs/form';
import { isAdvertiserUser, isAgencyUser } from '../../../../libs/auth';
import PageLoading from '../../../molecules/PageLoading';
import { useQueryHelper } from '../../../../libs/hooks';
import AnalyticsAccountForm from './AnalyticsAccountForm';
import { AnalyticsAccountBaseFormProps, FormValues, LocalstorageFormValues } from './types';
import ALL_ADVERTISERS_AND_PICS from './ListOfAllAdvertisersAndPICs.graphql';
import { AllAdvertisersAndPICsV2 } from './__generated__/AllAdvertisersAndPICsV2';
import * as UPDATE_ACCOUNT_ANALYTICS from './EditSocialAccountAnalyticsAdminV2.graphql';
import * as CREATE_ACCOUNT_ANALYTICS from './CreateSocialAccountAnalyticsAdminV2.graphql';
import {
  EditSocialAccountAnalyticsAdminV2,
  EditSocialAccountAnalyticsAdminV2Variables
} from './__generated__/EditSocialAccountAnalyticsAdminV2';
import { createUpdateAnalyticsAccountAdminAgency } from './requestForAdminAgency';
import {
  CreateSocialAccountAnalyticsAdminV2,
  CreateSocialAccountAnalyticsAdminV2Variables
} from './__generated__/CreateSocialAccountAnalyticsAdminV2';
import { createUpdateAnalyticsAccountAdvertiser } from './requestForAdvertiser';
import {
  EditSocialAccountAnalyticsAdvertiserV2,
  EditSocialAccountAnalyticsAdvertiserV2Variables
} from './__generated__/EditSocialAccountAnalyticsAdvertiserV2';
import {
  CreateSocialAccountAnalyticsAdvertiser,
  CreateSocialAccountAnalyticsAdvertiserVariables
} from './__generated__/CreateSocialAccountAnalyticsAdvertiser';
import * as UPDATE_ACCOUNT_ANALYTICS_ADV from './EditSocialAccountAnalyticsAdvertiser.graphql';
import * as CREATE_ACCOUNT_ANALYTICS_ADV from './CreateSocialAccountAnalyticsAdvertiser.graphql';

const AnalyticsAccountFormComponent = (
  props: AnalyticsAccountBaseFormProps & { formValues: LocalstorageFormValues }
) => {
  const {
    formValues: { advertiserId, accountManagerIds, accountName },
    connectedIgAccount: {
      fbPageName,
      fbPageId,
      instagramAccountName,
      needReconnect,
      businessAccountId,
      // TODO: we need real instagramUserId from API which is not yet there
      id: instagramUserId
    },
    connectedYtChannel: { channelId, channelName, hasYtReconnect },
    isEdit,
    onSignInClick
  } = props;

  const { t, history, enqueueSnackbar } = useQueryHelper();
  const { id: editAccountId } = useParams<{ id: string }>();
  const isAdvertiser = isAdvertiserUser();

  const { data, loading } = useQuery<AllAdvertisersAndPICsV2>(ALL_ADVERTISERS_AND_PICS, {
    fetchPolicy: 'cache-and-network',
    skip: isAdvertiser
  });
  const [updateAccountAnalytics] = useMutation<
    EditSocialAccountAnalyticsAdminV2,
    EditSocialAccountAnalyticsAdminV2Variables
  >(UPDATE_ACCOUNT_ANALYTICS, {
    refetchQueries: ['AccountListAnalyticsForAdmin']
  });
  const [createAccountAnalytics] = useMutation<
    CreateSocialAccountAnalyticsAdminV2,
    CreateSocialAccountAnalyticsAdminV2Variables
  >(CREATE_ACCOUNT_ANALYTICS, { refetchQueries: ['AccountListAnalyticsForAdminV2'] });
  const [updateAccountAnalyticsAdv] = useMutation<
    EditSocialAccountAnalyticsAdvertiserV2,
    EditSocialAccountAnalyticsAdvertiserV2Variables
  >(UPDATE_ACCOUNT_ANALYTICS_ADV, {
    refetchQueries: ['AccountListAnalyticsForAdvertiserV2']
  });
  const [createAccountAnalyticsAdv] = useMutation<
    CreateSocialAccountAnalyticsAdvertiser,
    CreateSocialAccountAnalyticsAdvertiserVariables
  >(CREATE_ACCOUNT_ANALYTICS_ADV, { refetchQueries: ['AccountListAnalyticsForAdvertiserV2'] });

  if (loading) {
    return <PageLoading />;
  }

  const handleSubmit = async (payload: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const { ok, error } = isAdvertiser
      ? await createUpdateAnalyticsAccountAdvertiser(
          {
            id: editAccountId,
            youtubeChannelId: channelId,
            createAccountAnalyticsAdv,
            updateAccountAnalyticsAdv,
            fbPageId
          },
          payload
        )
      : await createUpdateAnalyticsAccountAdminAgency(
          {
            // TODO: fix after BE will achieve consistensy in those types
            id: Number(editAccountId),
            youtubeChannelId: channelId,
            createAccountAnalytics,
            updateAccountAnalytics,
            fbPageId
          },
          payload
        );

    if (ok && !error) {
      const successMessage = !!editAccountId ? 'succeededInUpdating' : 'succeededInCreating';
      enqueueSnackbar(t(successMessage, { name: payload.accountName }), { variant: 'success' });
      history.push('/analytics');
    } else {
      if (editAccountId) {
        enqueueSnackbar(t('failedToUpdate', { name: payload.accountName }), { variant: 'error' });
      } else {
        enqueueSnackbar(t('failedToCreateAnalyticsAccount'), { variant: 'error' });
      }

      const errorMessage = error || UNEXPECTED_ERROR;
      console.error(errorMessage);
      enqueueSnackbar(t(errorMessage), { variant: 'error' });
    }

    setSubmitting(false);
  };

  const allAdvertisers = getOptions(data?.allAdvertisersForSearch);
  const allPicUsers = getOptions(data?.allPicUsers);
  const validationSchema = isAdvertiser
    ? AddAnalyticsAdvertiserAccountSchema
    : AddAnalyticsAdminAccountSchemaV2(isAgencyUser());

  return (
    <Formik
      initialValues={{
        instagramUserId: `${instagramUserId || ''}`,
        advertiserId,
        accountManagerIds,
        accountName
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      // we need this to reinitialize form values after async call completed
      enableReinitialize
    >
      <AnalyticsAccountForm
        allAdvertisers={allAdvertisers}
        allPicUsers={allPicUsers}
        isEdit={!!isEdit}
        connectedIgAccount={{
          fbPageName,
          instagramAccountName,
          needReconnect,
          fbPageId,
          businessAccountId,
          id: instagramUserId
        }}
        connectedYtChannel={{
          channelId,
          channelName,
          hasYtReconnect
        }}
        onSignInClick={onSignInClick}
        editAccountId={editAccountId}
      />
    </Formik>
  );
};

export default AnalyticsAccountFormComponent;
