import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminStore } from '../../Context';

interface PropType {
  title: string;
  interpolation?: object;
  appHeader: string;
  href: string;
  skipUpdate?: { title: boolean; appHeader: boolean };
}

const useUpdateDocumentTitle = ({ title, interpolation, appHeader, href, skipUpdate }: PropType) => {
  const { t, i18n } = useTranslation();
  const { dispatch } = useContext(AdminStore);

  // Update Document Title
  useEffect(() => {
    if (!skipUpdate?.title) {
      document.title = t(title, { ...interpolation });
    }
  }, [i18n.language, i18n.isInitialized]);

  // Update Page Title
  useEffect(() => {
    if (!skipUpdate?.appHeader) {
      dispatch({
        type: 'APP_USER',
        payload: { title: t(appHeader, { ...interpolation }), href }
      });
    }
  }, [appHeader, i18n.language, i18n.isInitialized]);
};

export default useUpdateDocumentTitle;
