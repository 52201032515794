import styled from '@emotion/styled';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { formatIntNumber } from '../../../libs/format';

interface PageLinkProps {
  href: LocationDescriptor;
  page: number;
  disabled?: boolean;
}

const PageLink = (props: PageLinkProps) => {
  const { href, page, disabled } = props;
  const pageNumber = formatIntNumber(page);

  return disabled ? (
    <Page>{pageNumber}</Page>
  ) : (
    <li>
      <Anchor to={href}>{pageNumber}</Anchor>
    </li>
  );
};

const Page = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  border-radius: 3px;
  margin-right: 12px;
  padding: 0 12px;
  background-color: #27313b;
  color: #fff;
`;

const Anchor = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  border-radius: 3px;
  border: solid 1px #dee5ec;
  background-color: #fff;
  margin-right: 12px;
  padding: 0 12px;
  color: #8694a2;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    border-color: #c5d0da;
    color: #3b4146;
  }

  &:active {
    background-color: #f6f8fa;
  }
`;

export default PageLink;
