import styled from '@emotion/styled';
import React from 'react';
import { CheckBox } from '../../../atoms/CheckBox';
import { DisabledProps } from '../types';

interface MenuItemWithCheckBoxProps extends DisabledProps {
  label: string;
  handleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  isSelected: boolean;
}

const MenuItemWithCheckBox = (props: MenuItemWithCheckBoxProps) => {
  const { label, disabled, handleClick, isSelected } = props;

  return <StyledCheckBox onChange={handleClick} label={label} checked={isSelected} disabled={disabled} />;
};

const StyledCheckBox = styled(CheckBox)<DisabledProps>`
  padding: 10px;

  &:hover {
    background-color: ${props => (props.disabled ? 'transparent' : '#179cd726')};
  }
`;

export default MenuItemWithCheckBox;
