import styled from '@emotion/styled';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LINK } from '../../../libs/theme';

interface LinkButtonColumnProps extends StyledLinkProps {
  className?: string;
  href: LocationDescriptor;
  icon: string | React.ReactNode;
  title: string;
}

const LinkButtonColumn = (props: LinkButtonColumnProps) => {
  const { t } = useTranslation();
  const { className, href, icon, title, hoverbgcolor, disabled } = props;

  return (
    <TableData className={className}>
      <StyledLink disabled={disabled} to={href} hoverbgcolor={hoverbgcolor}>
        <Icon className="material-icons">{icon}</Icon>
        {title && <Title>{t(`Button.${title}`)}</Title>}
      </StyledLink>
    </TableData>
  );
};

const TableData = styled.td`
  width: 36px;
  padding: 0 6px;
`;

interface StyledLinkProps {
  disabled?: boolean;
  hoverbgcolor?: string;
}

const StyledLink = styled(Link)<StyledLinkProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  color: ${LINK.BASE};
  transition: color 0.3s ease-in-out;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    color: ${props => (props.hoverbgcolor ? props.hoverbgcolor : LINK.HOVER)};
  }

  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const Icon = styled.i`
  font-size: 20px;
`;

const Title = styled.p`
  margin-top: 2px;
  font-size: 8px;
`;

export default LinkButtonColumn;
