import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useQueryHelper } from '../../libs/hooks';
import { manageLocalStorageItem } from '../../libs/localStorage';
import { SelectConnectedAccountIdType } from './helpers';
import { ConnectableIgAccount, ConnectableYtChannel } from './types';

interface AnalyticsAddLocationState {
  advertiserId?: string;
  accountManagerIds?: string[];
  // TODO: rename to connectableIgAccounts
  connectableAccounts?: ConnectableIgAccount[];
  connectableYtChannels?: ConnectableYtChannel[];
  accountName?: string;
}
export const useConnectedAccountFromRouterState = () => {
  const { state: routerState, pathname } = useLocation<AnalyticsAddLocationState | undefined>();
  const { history } = useQueryHelper();

  useEffect(() => {
    // clear location state on page refresh e.g. 'POP' action
    if (history.action === 'POP') {
      history.replace(pathname, {});

      return;
    }

    if (routerState?.accountManagerIds || routerState?.advertiserId || routerState?.accountName) {
      setState(prevState => ({
        ...prevState,
        accountManagerIds: routerState?.accountManagerIds || [],
        advertiserId: routerState?.advertiserId || '',
        accountName: routerState?.accountName || ''
      }));

      manageLocalStorageItem('remove', 'advertiserId');
      manageLocalStorageItem('remove', 'accountManagerIds');
      manageLocalStorageItem('remove', 'accountName');
    }

    if (routerState?.connectableAccounts?.length) {
      setState(prevState => ({
        ...prevState,
        isModalOpen: true,
        connectableIgAccounts: routerState.connectableAccounts || []
      }));
    }
    if (routerState?.connectableYtChannels?.length) {
      setState(prevState => ({
        ...prevState,
        isModalOpen: true,
        connectableYtChannels: routerState.connectableYtChannels || []
      }));
    }
  }, []);

  interface AddAccountStateType {
    connectableIgAccounts?: ConnectableIgAccount[];
    connectableYtChannels?: ConnectableYtChannel[];
    connectedIgAccountId: number;
    connectedYtChannelId: string;
    isModalOpen: boolean;
    advertiserId: string;
    accountManagerIds: string[];
    accountName: string;
  }
  const [state, setState] = useState<AddAccountStateType>({
    connectableIgAccounts: undefined,
    connectableYtChannels: undefined,
    connectedIgAccountId: 0,
    connectedYtChannelId: '',
    isModalOpen: false,
    advertiserId: '',
    accountManagerIds: [],
    accountName: ''
  });

  const handleSelectAccountId = ({ isUsed, id, connectedAccountIdKey }: SelectConnectedAccountIdType) => {
    if (!isUsed) {
      setState(prevState => ({
        ...prevState,
        [connectedAccountIdKey]: id
      }));
    }
  };
  const closeIgModal = () => {
    setState(prevState => ({
      ...prevState,
      isModalOpen: false
    }));
  };

  return {
    state,
    closeIgModal,
    handleSelectAccountId
  };
};
