import * as yup from 'yup';
import { SocialAccountType } from '../../__generated__/globalTypes';
import { PositiveNumberSchema } from './share';

const InfluencerSchema = yup.string().required('requiredInfluencerMessage');
const igSocialMediaSchema = yup.string().when('socialAccountMedia', {
  is: (socialAccountMedia: SocialAccountType) => socialAccountMedia === SocialAccountType.INSTAGRAM,
  then: yup.string().required('requiredIgSocialMediaMessage'),
  otherwise: yup.string().notRequired()
});

const PlanedPostDateSchema = yup.string().required('requiredPlanedPostDateMessage');
const ContentSchema = yup.string().required('requiredContentMessage');
const PostUrlSchema = yup.string();
const SavedPostSchema = PositiveNumberSchema;
const SocialAccointIdSchema = yup.string().required('requiredSocialAccountId');

export default yup.object().shape({
  influencerId: InfluencerSchema,
  igSocialMedia: igSocialMediaSchema,
  planedPostDate: PlanedPostDateSchema,
  content: ContentSchema,
  postUrl: PostUrlSchema,
  saved: SavedPostSchema,
  socialAccountId: SocialAccointIdSchema
});
