import css from '@emotion/css';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { SingleSelector } from '../../../molecules/Selector';
import hideGray from '../../../../assets/icon/hide.svg';
import { hashtagColors, HIDDEN_KEY } from './hashtagHelpers';
export interface HashtagsDropdownType {
  label: string;
  value: number;
}
interface HashtagDropdownsSectionProps {
  hashtagsDropdownOptions: HashtagsDropdownType[];
  selectedHashtags: string[];
  setSelectedHashtags: React.Dispatch<React.SetStateAction<string[]>>;
}
export const HashtagDropdownsSection = (props: HashtagDropdownsSectionProps) => {
  const { hashtagsDropdownOptions, selectedHashtags, setSelectedHashtags } = props;
  const remainigHashtags = hashtagsDropdownOptions.slice(5, 15);

  const [dropdownHashtags, setDropdownHashtags] = useState<HashtagsDropdownType[]>(remainigHashtags);

  useEffect(() => {
    // recalculate remainig dropdowns options on hide/show accounts
    setDropdownHashtags(remainigHashtags);
  }, [hashtagsDropdownOptions.length]);

  const handleChange = (currHtId: string, newHtId: string) => {
    // No need to do all sorting if we select the same thing.
    if (currHtId === newHtId) {
      return;
    }

    setSelectedHashtags(prevState => prevState.map(htId => (htId === currHtId ? newHtId : htId)));

    setDropdownHashtags(prevState => {
      // remove the new newly selected accoutn from dropdown if there is any
      const accounts = prevState.filter(acc => acc.value !== Number(newHtId));

      // add back the previously selected account to the dropdown if there is any
      const previous = hashtagsDropdownOptions.find(htId => htId.value === Number(currHtId));
      if (previous) {
        accounts.push(previous);
      }

      return accounts;
    });
  };

  return (
    <ChartSelectorsWrapper>
      {/* 5 is a max */}
      {selectedHashtags.map((htId, idx) => (
        <SingleSelector
          key={`hashtag_${htId}`}
          name={`hashtag_${idx}`}
          value={htId}
          options={buildOptions(
            hashtagsDropdownOptions.find(acc => acc.value === Number(htId)),
            dropdownHashtags,
            idx
          )}
          onChange={newId => handleChange(htId, newId)}
          hideInput
          hideDeselectOption
          dropdownCss={css`
            max-width: calc((100vw - 250px) / 5);
          `}
          css={css`
            height: 40px;
            max-width: calc((100vw - 250px) / 5);

            & > div {
              height: 100%;

              & > div {
                height: 100%;
                align-items: center;
              }
            }
          `}
        />
      ))}
    </ChartSelectorsWrapper>
  );
};

const buildOptions = (account: HashtagsDropdownType | undefined, allAccounts: HashtagsDropdownType[], idx: number) => [
  ...(account ? prepareDropdownOptions([account], idx) : []),
  ...prepareDropdownOptions(allAccounts, idx),
  hiddenOption(idx)
];

const hiddenOption = (idx: number) => ({
  label: 'Hidden',
  title: '',
  icon: (
    <HiddenCircle
      css={css`
        background-color: black;
      `}
      src={hideGray}
    />
  ),
  value: `${HIDDEN_KEY}${idx}`
});

const prepareDropdownOptions = (hashtags: HashtagsDropdownType[], idx: number) =>
  hashtags.map(ht => ({
    label: ht.label,
    value: ht.value.toString(),
    prefixIcon: (
      <div
        css={css`
          width: 14px;
          height: 14px;
          background-color: ${hashtagColors[idx]};
          margin-right: 16px;
        `}
      />
    )
  }));

const ChartSelectorsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 21px;
`;

const HiddenCircle = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 4px;
`;
