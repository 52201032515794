import styled from '@emotion/styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from 'react';

const styles = createStyles({
  progress: {
    color: '#e0e8ed'
  }
});

interface IndicatorColumnProps {
  colSpan?: number;
}

const IndicatorColumn = (props: IndicatorColumnProps & WithStyles<typeof styles>) => {
  const { colSpan, classes } = props;

  return (
    <TableData colSpan={colSpan}>
      <CircularProgress className={classes.progress} size={64} thickness={2.4} />
    </TableData>
  );
};

const TableData = styled.td`
  height: 360px;
  text-align: center;
`;

export default withStyles(styles)(IndicatorColumn);
