import styled from '@emotion/styled';
import css from '@emotion/css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useField, useFormikContext } from 'formik';
import { PalleteButton } from '../../../atoms/Button';
import ErrorMessage from '../../../molecules/ErrorMessage';
import TextForm from '../../../molecules/TextForm';
import SingleSelectField from '../../../molecules/SingleSelectedField';
import { useTranslateCountryOptions } from '../../../../libs/hooks';
import { COUNTRY_IDS } from '../../../../libs/constant';
import { mainRed } from '../../../../libs/pallete';
import {
  Annotation,
  Title,
  Form,
  Confirmation,
  RightWrapper,
  FlexRowWithMargin,
  LeftWrapper
} from '../../../atoms/EmotionPrimitives/Forms';
import { FormValues } from './types';

const SignUpForm = () => {
  const { handleSubmit, isSubmitting, setFieldValue, values } = useFormikContext<FormValues>();
  const { t } = useTranslation();

  const [companyNameField, companyNameMeta] = useField('companyName');
  const [companyUrlField, companyUrlMeta] = useField('companyUrl');
  const [phoneNumberField, phoneNumberMeta] = useField('phoneNumber');
  const [countryIdField, countryIdMeta] = useField('countryId');
  const [nameField, nameMeta] = useField('name');
  const [emailField, emailMeta] = useField('email');

  const hasAllFieldFilled = Object.values(values).every(field => !!field);

  return (
    <Form
      css={css`
        margin: auto;
      `}
      onSubmit={handleSubmit}
    >
      <Title>{t('Sign up')}</Title>
      <Annotation>{t('Password.Please Sign Up')}</Annotation>

      <FlexRowWithMargin css={flexColumn}>
        <TextFormFullWidth
          title="Company Name"
          isRequired
          placeholder={t('Company Name')}
          error={!!companyNameMeta.error}
          {...companyNameField}
        />
        <ErrorMessage message={companyNameMeta.error} />
      </FlexRowWithMargin>

      <FlexRowWithMargin css={flexColumn}>
        <TextFormFullWidth
          title="Company URL"
          isRequired
          placeholder="https://casting-asia.com"
          error={!!companyUrlMeta.error}
          {...companyUrlField}
        />
        <ErrorMessage message={companyUrlMeta.error} />
      </FlexRowWithMargin>

      <FlexRowWithMargin>
        <LeftWrapper>
          <TextForm
            title="Phone Number"
            isRequired
            placeholder="066020485707"
            error={!!phoneNumberMeta.error}
            {...phoneNumberField}
          />
          <ErrorMessage message={phoneNumberMeta.error} />
        </LeftWrapper>

        <RightWrapper>
          <SingleSelectField
            name={countryIdField.name}
            value={countryIdField.value}
            title="Country"
            options={useTranslateCountryOptions(COUNTRY_IDS)}
            error={!!countryIdMeta.error}
            setFieldValue={setFieldValue}
            isRequired
          />
          <ErrorMessage message={countryIdMeta.error} />
        </RightWrapper>
      </FlexRowWithMargin>

      <FlexRowWithMargin css={flexColumn}>
        <TextFormFullWidth title="Name" isRequired placeholder={t('Name')} error={!!nameMeta.error} {...nameField} />
        <ErrorMessage message={nameMeta.error} />
      </FlexRowWithMargin>

      <FlexRowWithMargin css={flexColumn}>
        <TextFormFullWidth
          title="Email"
          isRequired
          placeholder="your@email.com"
          error={!!emailMeta.error}
          {...emailField}
        />
        <ErrorMessage message={emailMeta.error} />
      </FlexRowWithMargin>

      <StyledSubmit
        text="Sign Up"
        palleteColor={mainRed}
        onClick={() => {}}
        height="32px"
        width="100%"
        borderRadius="3px"
        isDisabled={!hasAllFieldFilled || isSubmitting}
        loading={isSubmitting}
        type="submit"
      />

      <Confirmation>
        {t('Password.Confirmation_signUp')}&nbsp;
        <Anchor to="/privacy">{t('Password.Privacy Policy')}</Anchor> and&nbsp;
        <Anchor to="/terms">{t('Password.Terms of Service')}</Anchor>.
      </Confirmation>
    </Form>
  );
};

const flexColumn = css`
  flex-direction: column;
`;

const Anchor = styled(Link)`
  display: inline;
  color: #3892e5;
  font-size: 12px;
  line-height: 1.4em;

  &:hover {
    text-decoration: underline;
  }
`;
const TextFormFullWidth = styled(TextForm)`
  width: 100%;
`;
const StyledSubmit = styled(PalleteButton)`
  margin-top: 24px;
`;

export default SignUpForm;
