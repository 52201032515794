import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { useLocation } from 'react-router';
import { useQueryHelper } from '../../../../libs/hooks';
import Styled from '../StyledComponents';
import Template from './template';
import {
  InstagramHashtagPostDetails,
  InstagramHashtagPostDetailsVariables
} from './__generated__/InstagramHashtagPostDetails';
import * as INSTAGRAM_HASHTAG_POST_DETAILS from './InstagramHashtagPostDetails.graphql';

interface AnayticsFeedPostDetailProps {
  closeModal: () => void;
}

const HashtagFeedPostDetail = (props: AnayticsFeedPostDetailProps) => {
  const { closeModal } = props;

  const { search, pathname } = useLocation();

  const { t, enqueueSnackbar, history } = useQueryHelper();

  const postId = Number(new URLSearchParams(search).get('popup'));

  const { data, loading, error } = useQuery<InstagramHashtagPostDetails, InstagramHashtagPostDetailsVariables>(
    INSTAGRAM_HASHTAG_POST_DETAILS,
    {
      variables: { postId },
      fetchPolicy: 'cache-and-network',
      onError: useCallback(() => {
        history.push(pathname);
        enqueueSnackbar(t('Post was not found'), { variant: 'error' });
      }, [])
    }
  );

  if (loading || error) {
    return <CircularProgress size={64} thickness={2.4} color="inherit" />;
  }

  return (
    <Styled.PostDetailWrapper>
      <Template closeModal={closeModal} data={data} />
    </Styled.PostDetailWrapper>
  );
};

export default HashtagFeedPostDetail;
