import css from '@emotion/css';

export const COLORS = {
  black: {
    light2: '#d5d5d5',
    light: '#757575',
    base: '#3b4146'
  },
  green: {
    base: '#00ac56',
    dark: '#00984c'
  },
  blue: {
    base: '#179cd7',
    dark: '#178BC3'
  },
  red: {
    base: '#ff2b51',
    dark: '#eb294b'
  }
};

const FOCUSED = '#179cd7';

export const FORM = {
  BORDER: '#e0e8ed',
  DISABLED: '#f2f2f2',
  ERROR: 'tomato',
  FOCUSED
};

export const LINK = {
  BASE: '#8694a2',
  HOVER: FOCUSED
};

export const darkGreen = css`
  background-color: rgb(64, 184, 124);
  color: #fff;
`;

export const darkGrey = css`
  background-color: rgb(110, 124, 137);
  color: #fff;
`;

export const alertLight = css`
  background-color: rgb(255, 95, 95, 0.4);
  color: #fff;
`;

export const darkYellow = css`
  background-color: rgb(255, 182, 61);
  color: #fff;
`;

export const darkPurple = css`
  background-color: rgb(150, 106, 204);
  color: #fff;
`;

export const lightGreen = css`
  background-color: rgb(179, 227, 203);
  color: #fff;
`;

export const darkBlue = css`
  background-color: rgb(56, 146, 229);
  color: #fff;
`;

export const mainRed = css`
  background-color: rgb(255, 43, 82);
  color: #fff;
`;

export const lightOrange = css`
  background-color: #ff6e30;
  color: #fff;
`;
