import React from 'react';
import { Option } from '../../../../libs/types';
import { DisabledProps } from '../types';
import MenuItemWithCheckBox from './MenuItem';

interface MenuItemsWithCheckboxProps extends DisabledProps {
  options: Option[];
  checkIsSelected: (option: Option) => boolean;
  handleClick: (option: Option) => (e: React.MouseEvent<HTMLDivElement>) => void;
}

const MenuItemsWithCheckbox = (props: MenuItemsWithCheckboxProps) => {
  const { options, checkIsSelected, disabled, handleClick } = props;

  return (
    <div>
      {options.map(option => {
        const isSelected = checkIsSelected(option);

        return (
          <MenuItemWithCheckBox
            key={option.value}
            isSelected={isSelected}
            label={option.label}
            handleClick={handleClick(option)}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

export default MenuItemsWithCheckbox;
