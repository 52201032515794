import { useContext } from 'react';
import { AdminStore } from '../../Context';

export const useAuthData = () => {
  const {
    state: { userId, role },
    dispatch
  } = useContext(AdminStore);

  return {
    auth: {
      userId,
      role
    },
    dispatch
  };
};

export default useAuthData;
