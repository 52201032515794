import styled from '@emotion/styled';
import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/styles/makeStyles';
import Modal from '@material-ui/core/Modal';
import React, { ReactElement } from 'react';

export interface ModalProps {
  open: boolean;
  title: string;
  cancel?: string;
  execute?: string;
  disabled?: boolean;
  handleExecute?: () => void;
  onClose: () => void;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  siblingPostId?: { prevId: number | null; nextId: number | null };
  children: ReactElement<any> | null;
  bgColor?: string;
  isProposalPage?: boolean;
  arrowsTop?: string;
}
interface StylesProps {
  arrowsTop: string;
  bgColor?: string;
}
const useStyles = makeStyles({
  root: ({ bgColor }: StylesProps) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: bgColor || 'unset'
  }),
  rightArrow: ({ arrowsTop }: StylesProps) => ({
    position: 'absolute',
    right: '-30px',
    top: arrowsTop,
    color: '#fff'
  }),
  rightArrowProposal: {
    position: 'absolute',
    right: '-60px',
    top: '55%',
    color: '#c5d0da'
  },
  leftArrow: ({ arrowsTop }: StylesProps) => ({
    position: 'absolute',
    left: '-30px',
    top: arrowsTop,
    color: '#fff'
  }),
  leftArrowProposal: {
    position: 'absolute',
    left: '-60px',
    top: '55%',
    color: '#c5d0da'
  }
});

const CarouselModal = (props: ModalProps) => {
  const {
    open,
    onClose,
    children,
    onClickNext,
    onClickPrev,
    siblingPostId,
    bgColor,
    isProposalPage,
    arrowsTop = '45%'
  } = props;
  const { root, leftArrow, rightArrow, rightArrowProposal, leftArrowProposal } = useStyles({ bgColor, arrowsTop });

  return (
    <Modal open={open} onClose={onClose} className={root}>
      <StyledWrapper>
        {!!siblingPostId?.prevId && (
          <IconWrapper onClick={onClickPrev}>
            <Icon className={isProposalPage ? leftArrowProposal : leftArrow} fontSize="large">
              keyboard_arrow_left
            </Icon>
          </IconWrapper>
        )}
        {children}
        {!!siblingPostId?.nextId && (
          <IconWrapper onClick={onClickNext}>
            <Icon className={isProposalPage ? rightArrowProposal : rightArrow} fontSize="large">
              keyboard_arrow_right
            </Icon>
          </IconWrapper>
        )}
      </StyledWrapper>
    </Modal>
  );
};

const IconWrapper = styled.button`
  cursor: pointer;
`;

const StyledWrapper = styled.div`
  position: relative;
  outline: none;
`;

export default CarouselModal;
