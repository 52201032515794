import React from 'react';
import MentionedPostsFilter from '../../components/organisms/AnalyticsShared/BasePostListFilter';
import { SortType } from '../../libs/filter';
import MentionedPostsList from '../../components/organisms/AnalyticsPost/MentionedPostList';
import { InstagramFeedPostSortField } from '../../__generated__/globalTypes';
import { MentionedPostFilterType } from './types';

interface TemplateProps {
  currentPage: number;
  filter: MentionedPostFilterType;
  setFilter: (filter: MentionedPostFilterType) => void;
  sort: SortType<InstagramFeedPostSortField>;
  setSort: (sort: SortType<InstagramFeedPostSortField>) => void;
  id: number;
}
const Template = ({ filter, setFilter, currentPage, sort, setSort, id }: TemplateProps) => (
  <>
    <MentionedPostsFilter filter={filter} setFilter={setFilter} />
    <MentionedPostsList currentPage={currentPage} sort={sort} setSort={setSort} filter={filter} id={id} />
  </>
);

export default Template;
