import React from 'react';
import { useLocation, useParams, useHistory } from 'react-router';
import { useUpdateDocumentTitle, AnalyticsAccountProps } from '../../libs/hooks';
import { getThirtyDaysAgo, getToday } from '../../libs/date';
import NotFound from '../NotFound';
import { Period } from '../../components/molecules/DayPicker/types';
import { setUrlParams } from '../../libs/url';
import Template from './template';
import { getAnalyticsTabType } from './utils';

const AnalyticsDetails = (props: AnalyticsAccountProps) => {
  const accountName = props?.accountName || '';
  useUpdateDocumentTitle({
    title: !!accountName ? 'documentTitle.AnalyticsDashboardWithAccountName' : 'documentTitle.AnalyticsDashboard',
    interpolation: {
      title: accountName
    },
    appHeader: !!accountName ? 'appHeader.AnalyticsDashboardWithAccountName' : 'appHeader.AnalyticsDashboard',
    href: '/analytics'
  });

  // AccountId
  const { id } = useParams<{ id: string }>();
  if (!id) {
    return <NotFound />;
  }

  // TabType
  const { pathname } = useLocation();
  const urlPath = pathname.split('/')[4] || null;
  const tab = getAnalyticsTabType(urlPath);

  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(search);

  const startDate = params.get('startDate') || getThirtyDaysAgo();
  const endDate = params.get('endDate') || getToday();

  const handleChangePeriod = (urlPeriod: Period) => {
    setUrlParams(history, pathname, '', urlPeriod);
  };

  return (
    <Template
      tab={tab}
      period={{ startDate, endDate }}
      onChangePeriod={handleChangePeriod}
      id={Number(id)}
      analyticsAccountInfo={props}
    />
  );
};

export default AnalyticsDetails;
