import React, { useEffect, createRef, useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageMenu, { ChildProps } from './LanguageMenu';
export type LanguageMenuChildProps = ChildProps;

interface LanguageMenuProps {
  children: (props: ChildProps) => ReactNode;
}
const LanguageMenuComponent = ({ children }: LanguageMenuProps) => {
  const { t } = useTranslation();
  const ref: React.RefObject<HTMLDivElement> = createRef();

  const [isVisibile, setVisible] = useState<boolean>(false);

  const toggleVisible = (_: React.MouseEvent<HTMLButtonElement>) => {
    setVisible(!isVisibile);
  };

  const setInvisible = (_: React.MouseEvent<HTMLButtonElement>) => {
    setVisible(false);
  };

  const handleClickOutside = (event: Event) => {
    const isOutside = !ref.current?.contains(event.target as Node);
    if (isOutside && isVisibile) {
      return setVisible(false);
    } else {
      return;
    }
  };

  useEffect(() => {
    const handleClickOutsideRef = handleClickOutside;
    document.addEventListener('click', handleClickOutsideRef, true);

    return () => {
      document.removeEventListener('click', handleClickOutsideRef, true);
    };
  }, [isVisibile, setVisible]);

  return (
    <LanguageMenu
      visible={isVisibile}
      title={t('languageTitle')}
      setInvisible={setInvisible}
      toggleVisible={toggleVisible}
      onRef={ref}
    >
      {children}
    </LanguageMenu>
  );
};

export default LanguageMenuComponent;
