import React from 'react';
import css from '@emotion/css';
import AnalyticsContentWrapper, { ContentType } from '../../AnalyticsShared/AnalyticsContentWrapper';
import SentimentAnalysis from '../SentimentAnalysis';
import AverageEngagement from '../AverageEngagement';
import PostingHabits from '../PostingHabits';
import FeedPosts from '../LikeCommentPosts';
import StoryPosts from '../StoryPosts';
import * as Styled from '../StyledComponents';
import { InstagramDashboardPostAnalytics_instagramDashboardPostAnalytics } from './__generated__/InstagramDashboardPostAnalytics';

interface AnalyticsPostsType extends InstagramDashboardPostAnalytics_instagramDashboardPostAnalytics {
  id: number;
}
const AnalyticsPosts = (props: AnalyticsPostsType) => {
  const { averageEngagement, commentAnalytics, postsHabit, feedPosts, storyPosts, id } = props;

  return (
    <AnalyticsContentWrapper contentType={ContentType.Posts} tab="dashboard">
      <Styled.ChartContainerBorderBottom>
        {averageEngagement && (
          <Styled.ChartWrapper css={engagementWrapperStyle}>
            <AverageEngagement {...averageEngagement} />
          </Styled.ChartWrapper>
        )}

        <Styled.ChartWrapper>
          <SentimentAnalysis commentAnalytics={commentAnalytics} />
        </Styled.ChartWrapper>
        {!!postsHabit && (
          <Styled.ChartWrapper>
            <PostingHabits postsHabit={postsHabit} />
          </Styled.ChartWrapper>
        )}
      </Styled.ChartContainerBorderBottom>
      <FeedPosts
        data={feedPosts}
        header={<Styled.StyledTitleWithHelp title="HeaderColumn.Feed Posts" help="dashboardFeedPosts" />}
        moreLink={`/analytics/dashboard/${id}/instagram_feed_post`}
      />
      <StoryPosts data={storyPosts} />
    </AnalyticsContentWrapper>
  );
};

const engagementWrapperStyle = css`
  padding: 0;
  display: flex;
  flex-direction: column;
`;

export default AnalyticsPosts;
