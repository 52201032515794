import styled from '@emotion/styled';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import * as React from 'react';

interface TooltipColumnProps {
  className?: string;
  data: string;
  tooltip: React.ReactNode | null;
  placement?: TooltipProps['placement'];
}

const TooltipColumn = (props: TooltipColumnProps) => {
  const { className, data, tooltip, placement } = props;

  return (
    <td>
      <Wrapper>
        {tooltip === null ? (
          <TableData className={className}>{data}</TableData>
        ) : (
          <Tooltip title={tooltip} interactive={true} placement={placement || 'right'}>
            <TableData className={className}>{data}</TableData>
          </Tooltip>
        )}
      </Wrapper>
    </td>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 8px;
`;

const TableData = styled.p`
  overflow: hidden;
  max-height: 32px;
  font-size: 13px;
  line-height: 16px;
`;

export default TooltipColumn;
