import downloadIcon from '../../assets/icon/download.svg';
import sortIcon from '../../assets/icon/sort.svg';
import closeIcon from '../../assets/icon/close.svg';
import arrowLeftIcon from '../../assets/icon/arrowLeft.svg';
import warningIcon from '../../assets/icon/warning.svg';
import arrowBackIcon from '../../assets/icon/arrowBack.svg';

type IconType = 'download' | 'sort' | 'close' | 'arrowLeft' | 'warning' | 'arrowBack';

export const getIconByName = (name: IconType) => {
  switch (name) {
    case 'download':
      return downloadIcon;
    case 'sort':
      return sortIcon;
    case 'close':
      return closeIcon;
    case 'arrowLeft':
      return arrowLeftIcon;
    case 'warning':
      return warningIcon;
    case 'arrowBack':
      return arrowBackIcon;

    default:
      return undefined;
  }
};
