import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

const useQueryHelper = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  return {
    t,
    enqueueSnackbar,
    history
  };
};

export default useQueryHelper;
