import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { initialPagingParam } from '../../libs/paging';
import { setUrlParams } from '../../libs/url';
import { AnalyticsAdvertiserFilterTypeV2 } from '../../components/organisms/Analytics/types';
import { getInitialAdvertiserFilter, getAdvertiserFilterParams } from './utils';

export const useAdvertiserFilter = (): [
  AnalyticsAdvertiserFilterTypeV2,
  (filter: AnalyticsAdvertiserFilterTypeV2) => void
] => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const initialFilter = getInitialAdvertiserFilter(search);
  const [filter, baseSetFilter] = useState(initialFilter);

  const setFilter = (newFilter: AnalyticsAdvertiserFilterTypeV2) => {
    baseSetFilter(newFilter);

    // Set parameters to Url
    const newUrlParams = {
      ...initialPagingParam, // Reset paging
      ...getAdvertiserFilterParams(newFilter)
    };
    setUrlParams(history, pathname, search, newUrlParams);
  };

  return [filter, setFilter];
};
