import React from 'react';
import { css } from '@emotion/core';
import { SortType } from '../../libs/filter';
import { useUpdateDocumentTitle } from '../../libs/hooks';
import { InstagramFeedPostSortField } from '../../__generated__/globalTypes';
import { BaseComponents } from '../../components/molecules/BaseFilterComponents';
import BaseHeaderTab from '../../components/organisms/AnalyticsShared/BaseHeaderTab';
import InstagramHashtagFeedPostFilter from '../../components/organisms/AnalyticsShared/BasePostListFilter';
import InstagramHashtagFeedPostList from '../../components/organisms/AnalyticsPost/InstagramHashtagFeedPostList';
import { InstagramHashtagFeedPostFilterType } from './types';
import { ComparableHashtagType } from './index';

interface TemplateProps {
  accountId: number;
  filter: InstagramHashtagFeedPostFilterType;
  setFilter: (filter: InstagramHashtagFeedPostFilterType) => void;
  sort: SortType<InstagramFeedPostSortField>;
  setSort: (sort: SortType<InstagramFeedPostSortField>) => void;
  onChangeHashtag: (hashtagId: string) => void;
  hashtags: ComparableHashtagType[];
  selectedHashtagId: string;
  currentPage: number;
}

const Template = (props: TemplateProps) => {
  const {
    accountId,
    filter,
    sort,
    setSort,
    setFilter,
    onChangeHashtag,
    hashtags,
    selectedHashtagId,
    currentPage
  } = props;

  const goBackUrl = `/analytics/dashboard/${accountId}/hashtag`;

  useUpdateDocumentTitle({
    title: 'documentTitle.Post List Hashtag',
    appHeader: 'appHeader.Post List Hashtag',
    href: goBackUrl
  });

  return (
    <>
      <BaseHeaderTab
        items={hashtags.map(h => ({ label: h.hashTag, value: h.id.toString() }))}
        selectedAccountId={selectedHashtagId}
        onChange={onChangeHashtag}
      />
      <BaseComponents.Container css={containerCss}>
        <InstagramHashtagFeedPostFilter filter={filter} setFilter={setFilter} wrapWithContainer={false} />
      </BaseComponents.Container>
      <InstagramHashtagFeedPostList
        currentPage={currentPage}
        filter={filter}
        hashtagId={+selectedHashtagId}
        sort={sort}
        setSort={setSort}
        goBackUrl={goBackUrl}
      />
    </>
  );
};

const containerCss = css`
  margin-top: 1px;
  padding-bottom: 15px;
  border-top-left-radius: 0;
`;

export default Template;
