import { LanguageDetectorModule } from 'i18next';
import { getLanguageFromLocalStorage, setLanguageToLocalStorage } from './localStorage';
import getLanguageFromNavigator from './navigator';
import { convertLanguageCode } from './utils';

export const languageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  init: () => undefined,
  detect: () => {
    const storageLanguage = getLanguageFromLocalStorage();
    if (storageLanguage) {
      return storageLanguage;
    }

    const browserLanguage = getLanguageFromNavigator();
    if (browserLanguage) {
      return convertLanguageCode(browserLanguage);
    }

    return '';
  },
  cacheUserLanguage: (lng: string) => {
    setLanguageToLocalStorage(lng);

    return;
  }
};
