import { useState } from 'react';
import { Option } from '../../../../libs/types';
import { filterItems } from '../utils';

export const useSearch = (options: Option[]) => {
  const [searchText, setSearchText] = useState('');
  const filteredValue = filterItems(options, searchText);

  return { searchText, setSearchText, filteredValue };
};
