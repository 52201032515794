import * as yup from 'yup';
import { PasswordConfirmSchema, PasswordSchema } from './password';
import {
  AdvertiserIdSchema,
  AdvertiserIdsSchema,
  CountrySchema,
  EmailSchema,
  NameSchema,
  UserTypeSchema
} from './share';

// User Schema
// https://github.com/jquense/yup/issues/176#issuecomment-369925782
export default yup.object().shape(
  {
    userType: UserTypeSchema,
    advertiserId: AdvertiserIdSchema,
    advertiserIds: AdvertiserIdsSchema,
    name: NameSchema,
    email: EmailSchema,
    countryId: CountrySchema,
    password: yup.string().when('passwordConfirm', {
      is: (passwordConfirm: string) => Boolean(passwordConfirm),
      then: PasswordSchema
    }),
    passwordConfirm: yup.string().when('password', {
      is: (password: string) => Boolean(password),
      then: PasswordConfirmSchema
    })
  },
  [['passwordConfirm', 'password']]
);
