import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { SUBSCRIPTIONS_TYPES } from '../../../../libs/constant';
import { AnalyticsSubscriptionPlanType } from '../../../../__generated__/globalTypes';
import { NavBar, TabWrapper, NavTab } from '../../../molecules/NavTab';

export enum AnalyticsTabType {
  Dashboard,
  Compare,
  HashTag,
  Interaction
}

const AnalyticsTab = ({ plan }: { plan?: AnalyticsSubscriptionPlanType }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const planTitle = SUBSCRIPTIONS_TYPES.find(type => type.value === plan)?.label;

  return (
    <NavBar hasBorder>
      <TabWrapper>
        <NavTab activeClassName="selected" exact to={`/analytics/dashboard/${id}`}>
          {t('General.Dashboard')}
        </NavTab>
        <NavTab activeClassName="selected" to={`/analytics/dashboard/${id}/compare`}>
          {t('General.Compare')}
        </NavTab>
        <NavTab activeClassName="selected" to={`/analytics/dashboard/${id}/hashtag`}>
          {t('General.Hashtag')}
        </NavTab>
        <NavTab activeClassName="selected" to={`/analytics/dashboard/${id}/interaction`}>
          {t('General.Interaction')}
        </NavTab>
      </TabWrapper>
      {!!planTitle && <PlanTile>{t(`${planTitle} Plan`)}</PlanTile>}
    </NavBar>
  );
};

const PlanTile = styled.span`
  display: flex;
  justify-items: center;
  align-items: center;
  height: 24px;
  padding: 0 8px;
  background-color: #27313b;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #fff;
`;

export default AnalyticsTab;
