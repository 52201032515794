import styled from '@emotion/styled';
import css from '@emotion/css';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';

interface ListIndicatorProps {
  hasTopMargin?: boolean;
  className?: string;
}

const ListIndicator = (props: ListIndicatorProps) => {
  const { className, hasTopMargin = false } = props;

  return (
    <Wrapper hasTopMargin={hasTopMargin} className={className}>
      <CircularProgress
        size={64}
        thickness={2.4}
        css={css`
          color: #e0e8ed;
        `}
      />
    </Wrapper>
  );
};

const Wrapper = styled.section<{ hasTopMargin: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 23px 0;
  background-color: #fff;
  border: 1px solid #efefef;
  border-top: none;
  border-radius: 2px;
  margin-top: ${({ hasTopMargin }) => (hasTopMargin ? '24px' : 'inherit')};
`;

export default ListIndicator;
