import styled from '@emotion/styled';
import css from '@emotion/css';
import { Link } from 'react-router-dom';
import { SliderComponents } from '../../../molecules/SliderTable';
import { SocialMediaBox } from '../../../atoms/List';

export const StyledRow = styled(SliderComponents.Row)`
  td {
    border-top: 1px solid #efefef;
  }
`;
export const Account = styled.td`
  display: flex;
  height: 56px;
  align-items: center;
  padding-left: 16px;
`;
export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;
const brandAccountNameCss = css`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #3892e5;
`;
export const TextLink = styled(Link)`
  ${brandAccountNameCss}
`;
export const BrandAccountName = styled.span`
  ${brandAccountNameCss}
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const Media = styled.td`
  height: 56px;
  align-items: center;
  padding-left: 8px;
`;
export const MediaContent = styled.div`
  display: inline-flex;

  div:first-of-type {
    margin-left: 0;
  }

  div:last-of-type {
    margin-right: 0;
  }
`;
export const NeedReconnect = styled.td``;
export const ReconnectIcon = styled.img`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
export const StyledSocialMedia = styled(SocialMediaBox)`
  margin: 0 4px;
  padding: 0;

  img {
    margin: 0;
  }
`;
export const EditIcon = styled.img`
  border: 1px solid #dee5ec;
  padding: 3px;
  border-radius: 3px;

  &:hover {
    border-color: #c5d0da;
    opacity: 0.8;
  }
`;
export const ReconnectText = styled.div`
  padding-top: 16px;
`;
export const ReconnectAccount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 5px;
  border: solid 1px #dee5ec;
  background-color: #fff;
  margin-top: 24px;
  min-width: 400px;
`;
