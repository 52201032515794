import { StateType } from './Context';
import { initialState } from './Context';

type ActionType = any;
const reducer = (state: StateType, { type, payload }: ActionType) => {
  switch (type) {
    case 'APP_USER':
    case 'AUTH_USER':
    case 'UPDATE_IG_ANALYTICS_HEADER':
      return {
        ...state,
        ...payload
      };
    case 'LOGOUT':
      return { ...initialState, hash: state.hash };
    case 'UPDATE_GLOBAL_LAYOUT':
      return {
        ...state,
        globalLayout: {
          ...state.globalLayout,
          ...payload.globalLayout
        }
      };
    default:
      return state;
  }
};

export default reducer;
