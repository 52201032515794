import isAfter from 'date-fns/isAfter';
import * as jwtDecode from 'jwt-decode';
import {
  UserRoles,
  QuerySocialAccountType,
  AnalyticsRedirectType,
  AnalyticsAuthSocialAccountType
} from '../../__generated__/globalTypes';
import { useAuthData } from '../hooks/index';
import { switchSocialMediaName } from '../SocialMedia';
import { Token } from './types';

declare const FB_REDIRECT_URL: string;

export const setToken = (token: string, refreshToken?: string | null) => {
  if (window.localStorage) {
    localStorage.setItem('accessToken', token);

    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
    }
  }
};
export const getToken = () => localStorage.getItem('accessToken');
export const removeToken = () => {
  localStorage.removeItem('accessToken');
};
export const checkSignedIn = () => {
  const token = getToken();
  if (!token) {
    return false;
  }

  try {
    const decoded = jwtDecode<Token>(token);

    return isAfter(decoded.exp * 1000, new Date());
  } catch (e) {
    removeToken();

    return false;
  }
};
export const getUserClaims = () => {
  const token = getToken();
  if (!token) {
    return null;
  }

  try {
    const decoded = jwtDecode<Token>(token);

    return { id: decoded.user_id };
  } catch (e) {
    console.error(e);

    return null;
  }
};

export const isValidUserRole = (role: UserRoles | null) =>
  role && [UserRoles.ADMINISTRATOR, UserRoles.STAFF, UserRoles.ADVERTISER, UserRoles.AGENCY].includes(role);
export const isAdminUser = () => {
  const { auth } = useAuthData();

  return auth.role === UserRoles.ADMINISTRATOR;
};
export const isStaffUser = () => {
  const { auth } = useAuthData();

  return auth.role === UserRoles.STAFF;
};
export const isAdvertiserUser = () => {
  const { auth } = useAuthData();

  return auth.role === UserRoles.ADVERTISER;
};
export const isAgencyUser = () => {
  const { auth } = useAuthData();

  return auth.role === UserRoles.AGENCY;
};

export const isAdminStaff = () => isAdminUser() || isStaffUser();

export const isAdminStaffAgency = () => isAdminUser() || isStaffUser() || isAgencyUser();

const getRedirectTypePath = (redirectType: AnalyticsRedirectType): string => {
  switch (redirectType) {
    case AnalyticsRedirectType.ANALYTICS_SIGNIN:
      return 'analytics-signin';
    case AnalyticsRedirectType.ANALYTICS_CONNECT:
      return 'analytics-connect';
    case AnalyticsRedirectType.ANALYTICS_LIST_CONNECT:
      return 'analytics-list-connect';

    default:
      return '';
  }
};

export const getProvider = (socialMedia: QuerySocialAccountType): QuerySocialAccountType =>
  socialMedia === QuerySocialAccountType.INSTAGRAM ? QuerySocialAccountType.FACEBOOK : socialMedia;

export const getRedirectPath = (
  socialMedia: QuerySocialAccountType | AnalyticsAuthSocialAccountType,
  redirectType: AnalyticsRedirectType
): string => {
  const redirectPath = `redirect/${getRedirectTypePath(redirectType)}`;

  const socialMediaName = switchSocialMediaName(socialMedia);

  return `${redirectPath}/${socialMediaName}`;
};

const getRedirectOrigin = (
  socialMedia: QuerySocialAccountType | AnalyticsAuthSocialAccountType,
  customOrigin?: string
): string => {
  let redirectOrigin: string = location.origin;

  if (customOrigin) {
    redirectOrigin = customOrigin;
  }

  const isFacebookAuth = [
    QuerySocialAccountType.FACEBOOK,
    QuerySocialAccountType.FACEBOOK_PAGE,
    QuerySocialAccountType.INSTAGRAM,
    QuerySocialAccountType.INSTAGRAM_BUSINESS,
    AnalyticsAuthSocialAccountType.FACEBOOK
  ].includes(socialMedia);
  if (isFacebookAuth && !!FB_REDIRECT_URL) {
    // Use another origin for redirect because Facebook app refuses our `anytag.tech` domain.
    redirectOrigin = FB_REDIRECT_URL;
  }

  return redirectOrigin;
};

export const getCallbackUrl = (
  socialMedia: QuerySocialAccountType | AnalyticsAuthSocialAccountType,
  redirectType: AnalyticsRedirectType,
  customOrigin?: string
): string => `${getRedirectOrigin(socialMedia, customOrigin)}/${getRedirectPath(socialMedia, redirectType)}`;
