import React from 'react';
import { useSort } from '../../libs/filter';
import { usePaging } from '../../libs/paging';
import { getThirtyDaysAgo, getToday } from '../../libs/date';
import { getInstagramFeedPostSortFieldType } from '../../libs/filter/enum';
import { InstagramFeedPostSortField } from '../../__generated__/globalTypes';
import { useInstagramAnalyticsHeader } from '../../libs/hooks';
import { useFilter } from '../../components/organisms/AnalyticsShared/BasePostListFilter/useFilter';
import Template from './template';

const InstagramFeedPost = () => {
  const { analyticsAccountInfo } = useInstagramAnalyticsHeader();

  // Paging
  const currentPage = usePaging();
  // Filter
  const { filter, setFilter } = useFilter({ startDate: getThirtyDaysAgo(), endDate: getToday() });
  // Sort
  const { sort, setSort } = useSort<InstagramFeedPostSortField>(getInstagramFeedPostSortFieldType);

  return (
    <Template
      accountName={analyticsAccountInfo.accountName}
      accountId={analyticsAccountInfo.id}
      currentPage={currentPage}
      filter={filter}
      setFilter={setFilter}
      sort={sort}
      setSort={setSort}
    />
  );
};

export default InstagramFeedPost;
