import { useApolloClient } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { UNEXPECTED_ERROR } from '../validation';
import { getCallbackUrl } from '../auth';
import { AnalyticsAuthSocialAccountType, AnalyticsRedirectType } from '../../__generated__/globalTypes';
import * as ANALYTICS_SOCIAL_AUTH_REDIRECT_URL from './AnalyticsSocialAuthRedirectUrl.graphql';
import {
  AnalyticsSocialAuthRedirectUrl,
  AnalyticsSocialAuthRedirectUrlVariables
} from './__generated__/AnalyticsSocialAuthRedirectUrl';

export const useRedirectUrl = (redirectType: AnalyticsRedirectType) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const client = useApolloClient();

  const getRedirectUrl = async (socialMedia: AnalyticsAuthSocialAccountType): Promise<string | null> => {
    const variables = {
      provider: socialMedia,
      redirectType,
      callbackUrl: getCallbackUrl(socialMedia, redirectType)
    };
    const { data, errors } = await client.query<
      AnalyticsSocialAuthRedirectUrl,
      AnalyticsSocialAuthRedirectUrlVariables
    >({
      query: ANALYTICS_SOCIAL_AUTH_REDIRECT_URL,
      variables
    });

    if (errors) {
      errors.forEach(err => {
        console.error(err);
        enqueueSnackbar(t(err.message), { variant: 'error' });
      });

      return null;
    }
    if (!data || !data.analyticsSocialAuthRedirectUrl) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });

      return null;
    }

    return data.analyticsSocialAuthRedirectUrl.redirectUri;
  };

  return {
    getRedirectUrl,
    loading: isLoading,
    setLoading
  };
};
