import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import audienceIcon from '../../../../assets/icon/averageAge.svg';
import postsIcon from '../../../../assets/icon/chat.svg';
import overviewIcon from '../../../../assets/icon/clipboard.svg';
import { Tooltip } from '../../../atoms/Tooltip';
import * as Styled from './StyledComponents';

export enum ContentType {
  Overview,
  Audience,
  Posts
}

interface AnalyticsContentWrapperProps {
  contentType: ContentType;
  className?: string;
  tab: AnalyticsTabType;
  addButton?: ReactElement;
}
type AnalyticsTabType = 'compare' | 'dashboard' | 'hashtag' | 'interaction';
const switchContentTitleWithIcon = (contentType: ContentType, tab: AnalyticsTabType) => {
  const { t } = useTranslation();

  switch (contentType) {
    case ContentType.Overview:
      return { title: t('OverView'), icon: overviewIcon, help: `${tab}Overview` };
    case ContentType.Audience:
      return { title: t('Audience (Followers)'), icon: audienceIcon, help: `${tab}Audience` };
    default:
      return { title: t('Posts'), icon: postsIcon, help: `${tab}Posts` };
  }
};

const AnalyticsContentWrapper: React.FC<AnalyticsContentWrapperProps> = props => {
  const { children, contentType, className, tab, addButton } = props;

  const { title, icon, help } = switchContentTitleWithIcon(contentType, tab);

  return (
    <Container className={className}>
      <Header>
        <Img src={icon} />
        <Title>{title}</Title>
        {help && <Tooltip help={help} placement="top" iconMargin="6px" />}
        {addButton}
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
  border-radius: 3px;
  margin-bottom: 24px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
`;
const Header = styled.div`
  height: 56px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6ecf0;
`;
const Img = styled.img`
  margin-right: 8px;
  width: 24px;
`;
const Title = styled.p`
  font-size: 18px;
  line-height: 1.22;
  color: #27313b;
`;
const Content = styled.div``;

export default AnalyticsContentWrapper;

interface TitleWithHelpType {
  help: string;
  title: string;
  className?: string;
}
export const TitleWithHelp = ({ help, title, className }: TitleWithHelpType) => {
  const { t } = useTranslation();

  return (
    <Styled.Title className={className}>
      {t(title)}
      <Tooltip help={help} placement="top" iconMargin="6px" />
    </Styled.Title>
  );
};
