import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import offlineIcon from '../../../assets/icon/offline.svg';

export interface ProfileDetailsContentWrapperProps {
  className?: string;
  children: ReactNode;
  title?: string;
  icon?: string;
  header?: ReactNode;
  dataNotAvailable?: boolean;
  isAvailableRate?: boolean;
  wrapperText?: string;
}
interface EnrichedChildren {
  type: {
    displayName: string;
  };
}

const BlurOpacityWrapper = (props: ProfileDetailsContentWrapperProps) => {
  const {
    className,
    children,
    header,
    title,
    icon,
    dataNotAvailable = false,
    isAvailableRate = false,
    wrapperText = 'General.Unable to retrieve data'
  } = props;
  const { t } = useTranslation();

  return (
    <Container className={className}>
      {header
        ? header
        : title && (
            <TitleWrapper>
              <Img src={icon} /> <Title>{title}</Title>
            </TitleWrapper>
          )}
      {dataNotAvailable ? (
        <>
          <FocusedBlock>
            <img src={offlineIcon} />
            <OfflineText>{t(wrapperText)}</OfflineText>
          </FocusedBlock>

          {React.Children.map(children, child => {
            let blurRadius = '5px';
            const elementChild = child as EnrichedChildren;

            if (elementChild?.type?.displayName === 'LegendBar' && isAvailableRate) {
              blurRadius = '0';
            }

            return <OfflineBlock blurRadius={blurRadius}>{child}</OfflineBlock>;
          })}
        </>
      ) : (
        children
      )}
    </Container>
  );
};

const OfflineText = styled.p`
  font-size: 13px;
  color: #27313b;
  margin-top: 8px;
`;
const FocusedBlock = styled.div`
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: fit-content;
`;
const Container = styled.section`
  position: relative;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgba(222, 229, 236, 0.5);
  padding: 16px;
  box-sizing: border-box;
  background-color: #fff;
`;
const OfflineBlock = styled.div<{ blurRadius: string }>`
  filter: ${({ blurRadius }) => `blur(${blurRadius})`};
`;
const Title = styled.h1`
  font-size: 13px;
  font-weight: 600;
  color: #27313b;
`;
const Img = styled.img`
  width: 24px;
  margin-right: 8px;
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export default BlurOpacityWrapper;
