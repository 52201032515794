import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { Option } from '../../../../libs/types';
import BaseSelector from '../BaseSelector';
import Apply from './Apply';
import { MenuControlIcon } from './MenuControlIcon';
import MenuItems from './MenuItems';
import SelectAll from './SelectAll';
import { getInitialSelectedItems } from './utils';

type ValueType = Option[];
export interface MultiSelectorProps {
  className?: string;
  value?: string[];
  name: string;
  options: Option[];
  error?: boolean;
  disabled?: boolean;
  placeholder?: string;
  hasSelectAll?: boolean;
  onChange?: (items: string[]) => void;
  onClose?: () => void;
  canOnlyAddMore?: boolean;
  initialValues?: string[];
}

const MultiSelector = (props: MultiSelectorProps) => {
  const {
    name,
    value = [],
    options,
    error,
    disabled,
    hasSelectAll = true,
    placeholder,
    onChange,
    onClose,
    canOnlyAddMore,
    initialValues
  } = props;

  // Items
  const selectedValue = getInitialSelectedItems(value, options);
  const setSelectedValue = (items: Option[]) => {
    if (onChange) {
      onChange(items.map(item => item.value));
    }
  };
  const removeValue = (item: Option) => {
    const isInitalValue = initialValues?.includes(item?.value);
    if (canOnlyAddMore && isInitalValue) {
      return;
    }

    setSelectedValue(selectedValue.filter(i => i !== item));
  };

  return (
    <BaseSelector<ValueType>
      name={name}
      items={{ selectedValue, setSelectedValue, removeValue }}
      error={error}
      options={options}
      disabled={disabled}
      disabledInputBox={false}
      placeholder={selectedValue.length === 0 ? placeholder : ''}
      onClose={onClose}
      MenuControlIcon={MenuControlIcon}
      renderSelectedValue={({ selectedValue: values }) =>
        values.length > 2 ? (
          <SelectedCount>{`${values.length} Selected`}</SelectedCount>
        ) : (
          values.length > 0 && <SelectedValues>{values.map(item => item.label).join(', ')} </SelectedValues>
        )
      }
      renderMenu={({ items, search, menu }) => {
        const isSelectedAll = search.filteredValue.every(v => items.selectedValue.includes(v));
        const isSelectedNoting = items.selectedValue.length === 0;

        const handleSelectAll = (_: React.MouseEvent<HTMLDivElement>) => {
          if (disabled) {
            return;
          }
          // can unselect all values exept initialValues
          const allUnselectedValues = canOnlyAddMore
            ? search.filteredValue.filter(val => initialValues?.includes(val.value))
            : [];
          items.setSelectedValue(isSelectedAll ? allUnselectedValues : search.filteredValue);
        };

        const handleClickItem = (item: Option) => (_: React.MouseEvent<HTMLDivElement>) => {
          if (disabled) {
            return;
          }
          const isSelected = items.selectedValue.includes(item);

          // Remove Item
          if (isSelected) {
            items.removeValue(item);

            return;
          }
          // Add Item
          items.setSelectedValue([...items.selectedValue, item]);
        };

        return (
          <>
            {hasSelectAll && (
              <SelectAll
                disabled={disabled}
                isSelectedAll={isSelectedAll}
                isSelectedNoting={isSelectedNoting}
                handleClick={handleSelectAll}
              />
            )}
            <MenuWrapper>
              <MenuItems
                disabled={disabled}
                options={search.filteredValue}
                handleClick={handleClickItem}
                checkIsSelected={(option: Option) => items.selectedValue.map(item => item.value).includes(option.value)}
              />
            </MenuWrapper>
            <Apply handleClick={() => menu.setIsOpen(false)} />
          </>
        );
      }}
    />
  );
};

const selectionStyle = css`
  height: 16px;
  display: flex;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  position: absolute;
  padding: 0 2em 0 0.5em;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
`;

const SelectedCount = styled.span`
  ${selectionStyle}
`;

const SelectedValues = styled.div`
  ${selectionStyle}
`;

const MenuWrapper = styled.div`
  max-height: 210px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

export default MultiSelector;
