import styled from '@emotion/styled';
import { useField, useFormikContext } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkButton, SubmitButton } from '../../atoms/Button';
import ErrorMessage from '../../molecules/ErrorMessage';
import mailOpenIcon from '../../../assets/icon/mailOpen.svg';
import TextForm from '../../molecules/TextForm';
import { FormValues, SubmissionStatus } from './types';

const RefreshPasswordForm = () => {
  const { t } = useTranslation();

  const { handleSubmit, isSubmitting, status } = useFormikContext<FormValues>();

  const [emailField, emailMeta] = useField('email');

  return (
    <Form onSubmit={handleSubmit}>
      <Title>{t('Password.Reset your password')}</Title>
      {status === SubmissionStatus.OK ? (
        <>
          <MailImage src={mailOpenIcon} />

          <InfoText>{t('checkInbox')}</InfoText>

          <ResetPasswordText>{t('resetFollowInstruction')}</ResetPasswordText>
        </>
      ) : (
        <>
          <Annotation>{t('Password.GetPasswordLink')}</Annotation>

          <Email
            title="Email"
            placeholder={t('TextForm.EnterEmail')}
            error={!!emailMeta.error}
            autoFocus
            {...emailField}
          />
          <Error message={emailMeta.error} />

          <ButtonContainer>
            <BackButton title="Back" href="/" color="#6e7c89" bgcolor="transparent" hoverbgcolor="transparent" />
            <SaveButton value="Send" isSubmitting={isSubmitting} bgcolor="#ff4b61" hoverbgcolor="#eb475d" />
          </ButtonContainer>
        </>
      )}
    </Form>
  );
};

const Form = styled.form`
  box-sizing: border-box;
  max-width: 438px;
  margin: 0 auto;
  padding: 48px 40px;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MailImage = styled.img`
  margin-top: 32px;
`;

const InfoText = styled.p`
  font-size: 13px;
  color: #27313b;
  line-height: 1.38;
  margin-top: 25px;
  text-align: center;
`;

const ResetPasswordText = styled(InfoText)`
  margin-top: 13px;
`;

const Annotation = styled.p`
  padding: 16px 0 40px;
  color: #27313b;
  text-align: center;
  line-height: 1.38;
  font-size: 13px;
`;

const Title = styled.h1`
  font-size: 20px;
  text-align: center;
  color: #27313b;
`;

const Email = styled(TextForm)`
  width: 100%;

  & label {
    padding: 0 0 4px;
  }

  & input {
    line-height: 32px;
    padding: 0 7px;
  }
`;

const Error = styled(ErrorMessage)`
  width: 100%;
`;

const SaveButton = styled(SubmitButton)`
  line-height: 32px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  background-color: #ff2b52;
  flex: 1;

  &:hover {
    background-color: #ff5575;
  }

  &:active {
    background-color: #cc2241;
  }
`;

const BackButton = styled(LinkButton)`
  line-height: 32px;
  border-radius: 3px;
  border: 1px solid #dee5ec;
  margin-right: 16px;
  font-weight: 600;
  font-size: 12px;
  flex: 1;

  &:hover {
    border-color: #c5d0da;
  }

  &:active {
    background-color: #f6f8fa;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
`;

export default RefreshPasswordForm;
