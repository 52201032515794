import React from 'react';
import HighChart, { Highcharts } from '../../../molecules/HighChart';
import { TitleWithHelp } from '../../AnalyticsShared/AnalyticsContentWrapper';
import { OpacityContentWrapper } from '../../AnalyticsShared/SharedComponents';
import { formatPercent } from '../../../../libs/format';

interface GenferBreakdownChartProps {
  femaleRate: number | null;
  maleRate: number | null;
  otherRate: number | null;
  width: number;
}
const GenderBreakdownChart = ({ femaleRate, maleRate, otherRate, width }: GenferBreakdownChartProps) => {
  const options: Highcharts.Options = {
    chart: {
      type: 'pie',
      height: 300,
      width
    },
    title: {
      text: ''
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    legend: {
      symbolHeight: 15,
      symbolWidth: 15,
      symbolRadius: 2,
      itemStyle: {
        fontSize: '13px',
        color: '#27313b',
        fontWeight: 'normal'
      }
    },
    series: [
      {
        innerSize: '50%',
        type: 'pie',
        data: [
          {
            name: 'Female',
            y: femaleRate,
            color: '#ff8a94'
          },
          {
            name: 'Male',
            y: maleRate,
            color: '#707fc0'
          },
          {
            name: 'Other',
            y: otherRate,
            color: '#ffca98'
          }
        ]
      }
    ],
    tooltip: {
      formatter: function formatter(): string {
        return `
          <div style="border-radius:3px;display:flex;align-items:center;padding:13px 16px">
          <span style="display:inline-block;margin-right:8px;background-color:${
            this.point.color
          };height:12px;width:12px"></span>
            <span style="margin-right:16px">${this.point.name}</span>
            <span>${formatPercent(this.y)}</span>
          </div>`;
      },
      padding: 0,
      borderColor: 'green',
      borderWidth: 0,
      borderRadius: 3,
      backgroundColor: '#27313b',
      style: {
        color: '#ffffff'
      },
      useHTML: true
    }
  };

  return (
    <OpacityContentWrapper
      header={<TitleWithHelp help="genderBreakdown" title="Gender Breakdown" />}
      dataNotAvailable={!femaleRate && !maleRate && !otherRate}
    >
      <HighChart options={options} />
    </OpacityContentWrapper>
  );
};

export default GenderBreakdownChart;
