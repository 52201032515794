import styled from '@emotion/styled';
import * as React from 'react';
import { formatDate } from '../../../libs/format';

interface PeriodColumnProps {
  start: string;
  end: string;
}

const PeriodColumn = (props: PeriodColumnProps) => {
  const { start, end } = props;

  return (
    <Wrapper>
      <TableData>
        <Data>
          {formatDate(start, 'MMM dd')}
          <br />
          <Year>{formatDate(start, 'yyyy')}</Year>
        </Data>
        <Hyphen>-</Hyphen>
        <Data>
          {formatDate(end, 'MMM dd')}
          <br />
          <Year>{formatDate(end, 'yyyy')}</Year>
        </Data>
      </TableData>
    </Wrapper>
  );
};

const Wrapper = styled.td`
  padding: 0 8px;
`;

const TableData = styled.div`
  display: flex;
`;

const Data = styled.p`
  font-size: 13px;
  text-align: left;
  white-space: nowrap;
`;

const Year = styled.span`
  color: #8694a2;
  font-size: 11px;
`;

const Hyphen = styled(Data)`
  margin: 0 6px;
`;

export default PeriodColumn;
