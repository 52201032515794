import * as React from 'react';
import AnalyticsAdminFilter from '../../components/organisms/Analytics/AnalyticsAdminFilter';
import AnalyticsAdvertiserFilter from '../../components/organisms/Analytics/AnalyticsAdvertiserFilter';
import AnalyticsList from '../../components/organisms/Analytics/AnalyticList';
import { isAdminStaffAgency } from '../../libs/auth';
import { useAdminFilter } from './useAdminFilter';
import { useAdvertiserFilter } from './useAdvertiserFilter';

export interface TemplateProps {
  currentPage: number;
}

const Template = (props: TemplateProps) => {
  const { currentPage } = props;
  const [adminFilter, setAdminFilter] = useAdminFilter();
  const [advertiserFilter, setAdvertiserFilter] = useAdvertiserFilter();

  return (
    <>
      {isAdminStaffAgency() ? (
        <AnalyticsAdminFilter filter={adminFilter} setFilter={setAdminFilter} />
      ) : (
        <AnalyticsAdvertiserFilter filter={advertiserFilter} setFilter={setAdvertiserFilter} />
      )}
      <AnalyticsList currentPage={currentPage} adminFilter={adminFilter} advertiserFilter={advertiserFilter} />
    </>
  );
};

export default Template;
