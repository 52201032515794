import styled from '@emotion/styled';
import * as React from 'react';

interface ButtonColumnProps extends StyledButtonProps {
  icon: string | React.ReactNode;
  title: string;
  handleClick?: (props: any) => void;
}

const ButtonColumn = (props: ButtonColumnProps) => {
  const { icon, title, hoverbgcolor, handleClick, disabled } = props;

  return (
    <TableData>
      <StyledButton disabled={disabled} hoverbgcolor={hoverbgcolor} onClick={handleClick}>
        <Icon className="material-icons">{icon}</Icon>
        {title && <Title>{title}</Title>}
      </StyledButton>
    </TableData>
  );
};

const TableData = styled.td`
  width: 36px;
  padding: 0 6px;
`;

interface StyledButtonProps {
  disabled?: boolean;
  hoverbgcolor?: string;
}

const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  color: #8694a2;
  transition: color 0.3s ease-in-out;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    color: ${props => (props.hoverbgcolor ? props.hoverbgcolor : '#179cd7')};
  }

  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const Icon = styled.i`
  font-size: 20px;
`;

const Title = styled.p`
  margin-top: 2px;
  font-size: 8px;
`;

export default ButtonColumn;
