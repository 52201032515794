import css from '@emotion/css';
import {
  EngagementCampaignPostStatus,
  EngagementCampaignStatus,
  MarketplaceCampaignPostStatus,
  MarketplaceCampaignPostStatusForAdvertiser,
  MarketplaceCampaignPostStatusForSearch,
  MarketplaceCampaignStatus,
  PaymentRequestStatus,
  SocialAccountStatus,
  WarningReason
} from '../../__generated__/globalTypes';
import * as ThemeColors from '../theme';
import { PostStatusType } from '../../components/organisms/Post/PostDetail/types';

export const getMarketplaceStatusColor = (status?: MarketplaceCampaignStatus | null) => {
  switch (status) {
    case MarketplaceCampaignStatus.REVIEWING:
      return ThemeColors.darkPurple;

    case MarketplaceCampaignStatus.UPCOMING:
      return ThemeColors.darkGreen;

    case MarketplaceCampaignStatus.ONGOING:
      return ThemeColors.darkBlue;

    case MarketplaceCampaignStatus.SUSPENDED:
      return ThemeColors.darkYellow;

    case MarketplaceCampaignStatus.FINISHED:
    default:
      return ThemeColors.darkGrey;
  }
};

export const getEngagementStatusColor = (status?: EngagementCampaignStatus | null) => {
  switch (status) {
    case EngagementCampaignStatus.REVIEWING:
      return ThemeColors.darkPurple;

    case EngagementCampaignStatus.UPCOMING:
      return ThemeColors.darkGreen;

    case EngagementCampaignStatus.ONGOING:
      return ThemeColors.darkBlue;

    case EngagementCampaignStatus.DRAFT:
      return ThemeColors.darkYellow;

    case EngagementCampaignStatus.LOST:
      return ThemeColors.mainRed;

    case EngagementCampaignStatus.DEMO:
      return ThemeColors.lightOrange;

    case EngagementCampaignStatus.FINISHED:
    default:
      return ThemeColors.darkGrey;
  }
};

export const getMarketplacePostStatusColor = (
  status?:
    | MarketplaceCampaignPostStatus
    | MarketplaceCampaignPostStatusForSearch
    | MarketplaceCampaignPostStatusForAdvertiser
    | null
) => {
  switch (status) {
    case MarketplaceCampaignPostStatusForSearch.JOINED:
    case MarketplaceCampaignPostStatusForAdvertiser.JOINED:
    case MarketplaceCampaignPostStatusForSearch.TRACKING:
    case MarketplaceCampaignPostStatusForAdvertiser.TRACKING:
      return ThemeColors.darkPurple;

    case MarketplaceCampaignPostStatus.APPROVED:
    case MarketplaceCampaignPostStatusForSearch.APPROVED:
      return ThemeColors.darkGreen;

    case MarketplaceCampaignPostStatus.EFFECTIVE:
    case MarketplaceCampaignPostStatusForSearch.EFFECTIVE:
    case MarketplaceCampaignPostStatus.WARNING_SOLVED:
      // case MarketplaceCampaignPostStatusForSearch.WARNING_SOLVED:
      return ThemeColors.darkBlue;

    case MarketplaceCampaignPostStatus.WARNING:
    case MarketplaceCampaignPostStatusForSearch.WARNING:
      return ThemeColors.darkYellow;

    case MarketplaceCampaignPostStatus.INEFFECTIVE:
    case MarketplaceCampaignPostStatusForSearch.INEFFECTIVE:
      return ThemeColors.darkGrey;

    default:
      return ThemeColors.darkGrey;
  }
};

export const getEngagementPostStatusColor = (status?: PostStatusType) => {
  switch (status) {
    case EngagementCampaignPostStatus.REVIEWING:
      return ThemeColors.darkPurple;

    case EngagementCampaignPostStatus.READY_TO_POST:
    case EngagementCampaignPostStatus.APPROVED:
      return ThemeColors.darkGreen;

    case EngagementCampaignPostStatus.EFFECTIVE:
    case EngagementCampaignPostStatus.WARNING_SOLVED:
      return ThemeColors.darkBlue;

    case EngagementCampaignPostStatus.WARNING:
    case EngagementCampaignPostStatus.REJECT:
      return ThemeColors.darkYellow;

    case EngagementCampaignPostStatus.INEFFECTIVE:
      return ThemeColors.darkGrey;

    default:
      return ThemeColors.darkGrey;
  }
};

export const getSocialAccountStatusColor = (status?: SocialAccountStatus | null) => {
  switch (status) {
    case SocialAccountStatus.SIGNED_UP:
      return ThemeColors.darkGreen;

    case SocialAccountStatus.SCRAPING:
      return ThemeColors.darkGrey;

    default:
      return gray;
  }
};

export const getWarningReasonStatusColor = (reason: WarningReason) => {
  switch (reason) {
    case WarningReason.AD_FRAUD:
      return ThemeColors.darkYellow;

    case WarningReason.DELETED_POST:
      return ThemeColors.darkGrey;

    case WarningReason.API_ERROR:
    case WarningReason.INVALID_TOKEN:
    case WarningReason.INVALID_CONTENT:
    case WarningReason.SCRAPING_ERROR:
      return ThemeColors.mainRed;

    default:
      return ThemeColors.darkGrey;
  }
};

export const getPaymentRequestStatusColor = (status?: PaymentRequestStatus | null) => {
  switch (status) {
    case PaymentRequestStatus.REVIEWING:
      return ThemeColors.darkPurple;

    case PaymentRequestStatus.PAID:
      return ThemeColors.darkGrey;

    case PaymentRequestStatus.REJECTED:
      return ThemeColors.darkYellow;

    case PaymentRequestStatus.FAILED:
      return ThemeColors.mainRed;

    default:
      return ThemeColors.darkGrey;
  }
};

export const gray = css`
  background-color: rgba(52, 73, 94, 0.05);
  color: rgba(52, 73, 94, 0.6);
`;
