import { AnalyticsTabType } from '../../components/organisms/Analytics/AnalyticsTab';
import { SELECTED_RANGE } from '../../components/molecules/DayPicker';

export const getAnalyticsTabType = (tab: string | null): AnalyticsTabType => {
  switch (tab) {
    case 'compare':
      return AnalyticsTabType.Compare;
    case 'hashtag':
      return AnalyticsTabType.HashTag;
    case 'interaction':
      return AnalyticsTabType.Interaction;
    default:
      return AnalyticsTabType.Dashboard;
  }
};

export const analyticsDashboardRangeList = [
  SELECTED_RANGE.TODAY,
  SELECTED_RANGE.TODAY_TO_MONTH_AGO,
  SELECTED_RANGE.THIS_MONTH,
  SELECTED_RANGE.LAST_MONTH,
  SELECTED_RANGE.ALL_TIME,
  SELECTED_RANGE.CUSTOM
];
