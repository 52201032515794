export const convertLanguageCode = (code: string): string => {
  switch (code) {
    case 'ja':
      return 'ja';
    // TODO: Uncomment when we prepared all translation json files.
    // case 'th':
    //   return 'th';
    // case 'id':
    //   return 'id';
    // case 'vi':
    //   return 'vi';
    // case 'zh-TW':
    // case 'zh-HK':
    //   return 'tw';
    // case 'zh-CN':
    // case 'zh':
    //   return 'ch';
    // case 'km':
    //   return 'km';
    // case 'my':
    //   return 'my';
    default:
      return 'en';
  }
};
