import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface IconButtonProps extends StyledButtonProps, TitleProps {
  className?: string;
  icon: string;
  title?: string;
  handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const IconButton = (props: IconButtonProps) => {
  const { t } = useTranslation();
  const { className, icon, title, disabled, color, hoverbgcolor, handleClick } = props;

  return (
    <StyledButton className={className} disabled={disabled} hoverbgcolor={hoverbgcolor} onClick={handleClick}>
      <Icon className="material-icons" color={color}>
        {icon}
      </Icon>
      {title && <Title color={color}>{t(`Button.${title}`)}</Title>}
    </StyledButton>
  );
};

interface StyledButtonProps {
  disabled?: boolean;
  hoverbgcolor?: string;
}

const StyledButton = styled.button<StyledButtonProps>`
  width: auto;
  height: 50px;
  border-radius: 3px;
  transition: background-color 0.3s ease-in-out;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  box-sizing: border-box;

  &:hover {
    background-color: ${props => (props.hoverbgcolor ? props.hoverbgcolor : '#f5f5f5')};
  }
`;

interface TitleProps {
  color?: string;
}

const Icon = styled.i<TitleProps>`
  color: ${props => (props.color ? props.color : '#00ac56')};
  font-size: 20px;
`;

const Title = styled.p<TitleProps>`
  color: ${props => (props.color ? props.color : '#00ac56')};
  font-size: 10px;
  line-height: 10px;
`;

export default IconButton;
