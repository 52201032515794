import css from '@emotion/css';
import { InstagramFeedPostSortField } from '../../../__generated__/globalTypes';

export const getAllPostsHeaders = (hasViewColumn?: boolean, hasEngagementRate: boolean | undefined = true) => [
  // compare post list, hashtag post list
  ...(hasViewColumn
    ? [
        {
          title: 'Video view',
          help: 'ViewHelp',
          orderField: InstagramFeedPostSortField.VIEW,
          columnName: 'view',
          css: css`
            min-width: 90px;
          `
        }
      ]
    : [
        {
          title: 'Impression',
          help: 'ImpsHelp',
          orderField: InstagramFeedPostSortField.IMPRESSIONS,
          columnName: 'impressions'
        },
        {
          title: 'Reach',
          help: 'ReachPostHelp',
          orderField: InstagramFeedPostSortField.REACH,
          columnName: 'reach'
        }
      ]),
  ...(hasEngagementRate
    ? [
        {
          title: 'Engagement Rate',
          help: 'EngagementRateHelp',
          orderField: InstagramFeedPostSortField.ENGAGEMENT_RATE,
          columnName: 'engagementRate'
        }
      ]
    : []),
  {
    title: 'Engagement',
    help: 'EngagementHelp',
    orderField: InstagramFeedPostSortField.ENGAGEMENT,
    columnName: 'engagement'
  },
  {
    title: 'Like',
    help: 'LikeHelp',
    orderField: InstagramFeedPostSortField.LIKE,
    columnName: 'like'
  },
  {
    title: 'Comment',
    help: 'CommentHelp',
    orderField: InstagramFeedPostSortField.COMMENT,
    columnName: 'comment'
  },
  {
    title: 'Positive Rate',
    help: 'PositiveRateHelp',
    orderField: InstagramFeedPostSortField.POSITIVE_RATE,
    columnName: 'positiveRate'
  },
  {
    title: 'Negative Rate',
    help: 'NegativeRateHelp',
    orderField: InstagramFeedPostSortField.NEGATIVE_RATE,
    columnName: 'negativeRate'
  },
  ...(hasViewColumn
    ? []
    : [
        {
          title: 'Save',
          help: 'SaveHelp',
          orderField: InstagramFeedPostSortField.SAVED,
          columnName: 'saved'
        }
      ]),
  {
    title: 'Post date',
    help: 'PostDateHelp',
    orderField: InstagramFeedPostSortField.POST_DATE,
    columnName: 'postDate',
    css: css`
      min-width: 95px;
    `
  }
];
