import React from 'react';
import { format, isValid, parseISO } from 'date-fns';

export const formatNumber = (n: number | null, digits: number = 2) =>
  n === null ? '-' : n.toLocaleString(undefined, { minimumFractionDigits: digits, maximumFractionDigits: digits });
export const formatIntNumber = (n: number | null) => formatNumber(n, 0);
export const formatPrice = (n: number | null, currency: string) => {
  const digits = currency === 'JPY' ? 0 : 2;

  return formatNumber(n, digits);
};
export const formatPercent = (n: number | null, unit: boolean = true, digits: number = 2) => {
  const num =
    n === null
      ? '-'
      : `${Number(n * 100)
          .toFixed(digits)
          .toLocaleString()}`;
  if (unit && n !== null) {
    return `${num}%`;
  }

  return num;
};

export const convertBreakLines = (text: string) =>
  text.split('\n').map((item, key) => (
    <React.Fragment key={key}>
      {item}
      <br />
    </React.Fragment>
  ));

export const formatDate = (date: string | null, dateFormat: string = 'MMM d, Y') => {
  if (!date || !isValid(parseISO(date))) {
    return '-';
  }

  return format(new Date(date), dateFormat);
};

/**
 * Hash Tags
 */
export const formatHashTag = (tag: string | null) => {
  const regex = /[ !"#$%&'()*+\-.,/:;<=>?@[\\\]^`{|}~]/g;

  return !!tag ? tag.replace(regex, '') : tag;
};
export const formatHashTags = (tags: Array<string | null>) =>
  tags.map(tag => formatHashTag(tag)).filter((tag): tag is string => tag !== null);

interface RenderIfMoreThan10PercentType {
  value: number;
  unit?: boolean;
  digits?: number;
}
export const renderIfMoreThan10Percent = ({ value, unit, digits }: RenderIfMoreThan10PercentType) =>
  value > 0.1 ? formatPercent(value, unit, digits) : '';

export const analyticsChartNumberFormatter = (value: number): string => {
  const absValue = Math.abs(value);
  if (absValue >= 1000000) {
    return `${(value / 1000000).toFixed(1)}m`;
  }

  return value.toLocaleString();
};

export const clearDotFromResponseErrorMessage = (msg: string) => msg.replace(/\./g, '');
