import styled from '@emotion/styled';
import React from 'react';
import { getIconByName } from '../../../libs/icons';

interface ArrowProps {
  className?: string;
  to: string;
  onClick?: () => void;
}

export const LeftArrow = ({ className, to, onClick }: ArrowProps) => (
  <Button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
    <Icon className="icon" src={getIconByName('arrowLeft')} />
  </Button>
);

export const RightArrow = ({ className, to, onClick }: ArrowProps) => (
  <Button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
    <Icon className="icon" src={getIconByName('arrowLeft')} rotate="180deg" />
  </Button>
);

// generate svg color with 'filter' https://codepen.io/sosuke/pen/Pjoqqp
const Icon = styled.img<{ rotate?: string }>`
  filter: invert(50%) sepia(17%) saturate(366%) hue-rotate(168deg) brightness(92%) contrast(85%);
  width: 18px;
  margin: auto;
  ${({ rotate }) => rotate && `transform: rotate(${rotate})`};
`;
const Button = styled.button`
  width: 24px;
  height: 48px;
  background-color: #fff !important;

  &:hover {
    background: #fff;
  }
`;
