import React from 'react';
import { Redirect } from 'react-router';
import PostDetail from '../../AnalyticsDashboard/PostDetail';
import { InstagramAnalyticsStoryDetails } from './__generated__/InstagramAnalyticsStoryDetails';

interface TemplateProps {
  closeModal: () => void;
  data: InstagramAnalyticsStoryDetails | undefined;
}

const Template = (props: TemplateProps) => {
  const { data, closeModal } = props;

  if (!data || !data.instagramAnalyticsStoryDetails) {
    return <Redirect to="/404" />;
  }

  const {
    instagramAnalyticsStoryDetails: {
      avatarUrl,
      instagramAccountName,
      followers,
      content,
      postImageUrl,
      postUrl,
      comments,
      reach,
      impressions,
      exits,
      tapsForward,
      tapsBack,
      storyVideoUrl
    }
  } = data;

  return (
    <PostDetail
      avatarUrl={avatarUrl}
      closeModal={closeModal}
      accountName={instagramAccountName}
      followers={followers}
      content={content}
      commentList={[]}
      postImageUrl={storyVideoUrl || postImageUrl}
      engagement={{
        postUrl,
        analysis: null,
        sentiment: null,
        storyData: { comments, reach, impressions, exits, tapsForward, tapsBack }
      }}
    />
  );
};

export default Template;
