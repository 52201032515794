import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import notfoundimg from '../../assets/img/notfound.jpg';
import { useUpdateDocumentTitle } from '../../libs/hooks/index';

const Template = () => {
  useUpdateDocumentTitle({ title: 'documentTitle.NotFound', appHeader: '', href: '' });
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Shade className="error_wrapper">
        <TextWrapper>
          <Header>404</Header>
          <SubHeader>{t('The page you are looking for does not exist')}</SubHeader>
          <Text>{t('Sorry the page you are looking for could not be found')}</Text>
          <StyledLink to="/analytics">{t('Go to top page')}</StyledLink>
        </TextWrapper>
      </Shade>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: url(${notfoundimg}) no-repeat;
  background-size: cover;
  background-position: center;
`;

const Shade = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
`;

const TextWrapper = styled.div`
  max-width: 740px;
  margin: 0 auto;
  text-align: center;
  color: #f0f0f0;
  padding: 0 5%;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
`;

const Header = styled.h1`
  font-size: 80px;
  font-weight: 600;
  line-height: 60px;
  color: #333;
  text-shadow: 2px 2px 10px #f0f0f0;

  @media screen and(max-width: 850px) {
    font-size: 60px;
  }
`;

const SubHeader = styled.h2`
  margin-top: 20px;
  padding: 0 24px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.4em;
`;

const Text = styled.h3`
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4em;
`;

const StyledLink = styled(Link)`
  margin-top: 24px;
  color: #179cd7;
  font-size: 16px;
  text-align: center;
  text-decoration: underline;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #1686b9;
  }
`;

export default Template;
