import styled from '@emotion/styled';
import React from 'react';
import { CampaignSocialMediaType, SocialAccountType } from '../../../__generated__/globalTypes';
import { switchImageActive } from '../../../libs/SocialMedia';

interface SocialMediaColumnProps {
  socialMedia: CampaignSocialMediaType | SocialAccountType;
  className?: string;
  isTable?: boolean;
  isActive?: boolean;
  exclusive?: boolean;
  accountName?: string;
  profileUrl?: string;
}

const SocialMediaColumn = (props: SocialMediaColumnProps) => {
  const { socialMedia, className, isTable = true, isActive = true, exclusive = false, accountName, profileUrl } = props;
  const MediaWrapper = isTable ? TableData : Wrapper;
  const src = switchImageActive(socialMedia, isActive);

  return (
    <MediaWrapper className={className}>
      <IconWrapper exclusive={exclusive}>
        <Image src={src} alt={socialMedia} />
        {profileUrl ? (
          <Anchor href={profileUrl} target="_blank" rel="noreferrer">
            {accountName}
          </Anchor>
        ) : (
          accountName
        )}
      </IconWrapper>
    </MediaWrapper>
  );
};

const TableData = styled.td`
  padding: 0 12px;
`;
const Wrapper = styled.div`
  padding: 0 12px;
`;
const Image = styled.img`
  width: 17px;
  margin: 0 auto;
`;
const IconWrapper = styled.div<{ exclusive?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  ${props =>
    !!props.exclusive &&
    `&:after {
      content: '';
      position: absolute;
      top: -25%;
      left: 75%;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      border: solid 1px white;
      background: #00ac56;
    }`}
`;
const Anchor = styled.a`
  color: #3892e5;
  font-weight: normal;

  &:hover {
    text-decoration: underline;
  }
`;

export default SocialMediaColumn;
