import * as yup from 'yup';
import { PasswordSchema, PasswordConfirmSchema } from './password';

export default yup.object().shape({
  currentPassword: yup
    .string()
    .matches(/[\x21-\x7e]+/, 'onlyAlphanumericOrSymbolsMessage')
    .required('requiredPasswordMessage'),
  password: PasswordSchema,
  passwordConfirm: PasswordConfirmSchema
});
