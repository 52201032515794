import React, { useState, useCallback } from 'react';
import { useQuery, ApolloError } from '@apollo/client';
import styled from '@emotion/styled';
import { useInstagramAnalyticsHeader, useQueryHelper } from '../../../libs/hooks';
import ListIndicator from '../../molecules/ListIndicator';
import { AnalyticsPeriodWithId } from '../AnalyticsShared/types';
import { BlurImage } from '../AnalyticsShared/SharedComponents';
import hashtagBlur from '../../../assets/img/hashtagPageBlur.jpg';
import { UpgradeToPremiumModal } from '../AnalyticsShared/UpgradeToPremiumModal';
import AnalyticsHashtagPosts from './AnalyticsHashtagPosts';
import HashtagCards from './HashtagCards';
import HashtagOverview from './Overview';
import { HashtagType } from './types';
import * as IG_ANALYTICS_HASHTAGS from './InstagramAnalyticsHashtags.graphql';
import {
  InstagramAnalyticsHashtags,
  InstagramAnalyticsHashtagsVariables
} from './__generated__/InstagramAnalyticsHashtags';

const HashTagTab = ({ id, period }: AnalyticsPeriodWithId) => {
  const { t, enqueueSnackbar, history } = useQueryHelper();
  const [hashtagData, setHashtagData] = useState<HashtagType[]>([]);
  const {
    analyticsAccountInfo: { availableFeatures, capabilities }
  } = useInstagramAnalyticsHeader();
  const hasFreePlan = !availableFeatures?.hashtagsEnabled;

  const { loading } = useQuery<InstagramAnalyticsHashtags, InstagramAnalyticsHashtagsVariables>(IG_ANALYTICS_HASHTAGS, {
    variables: { accountId: id },
    skip: hasFreePlan || !id,
    onError: useCallback((error: ApolloError) => {
      history.push('/analytics');
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }, []),
    onCompleted: useCallback((hashtagRespData: InstagramAnalyticsHashtags) => {
      setHashtagData(
        hashtagRespData.instagramAnalyticsHashtags?.hashTags?.map(ht => ({ ...ht, isSelected: true })) || []
      );
    }, []),
    notifyOnNetworkStatusChange: true
  });

  if (loading) {
    return <StyledLoadingIndicator />;
  }

  const selectedHashtagIds = hashtagData.filter(ht => ht.isSelected && !ht.blocked).map(ht => ht.id);

  const allHashtag = hashtagData.reduce((acc, curr) => {
    acc[curr.id] = curr.hashTag;

    return acc;
  }, {} as { [key: number]: string });

  return hasFreePlan ? (
    <>
      <BlurImage src={hashtagBlur} />
      <UpgradeToPremiumModal page="hastag" />
    </>
  ) : (
    <>
      <HashtagCards
        id={id}
        hashtagData={hashtagData}
        setHashtagData={setHashtagData}
        maxHashtags={capabilities?.maxHashtags}
      />
      <HashtagOverview id={id} period={period} selectedHashtagIds={selectedHashtagIds} allHashtag={allHashtag} />
      <AnalyticsHashtagPosts id={id} period={period} allHashtag={allHashtag} selectedHashtagIds={selectedHashtagIds} />
    </>
  );
};

const StyledLoadingIndicator = styled(ListIndicator)`
  margin: 0;
  margin-bottom: 24px;
`;

export default HashTagTab;
