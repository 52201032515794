import { getOffset } from '../../../../libs/paging';
import { LIMIT } from '../../../../libs/paging/constant';
import { InstagramFeedPostListProps } from './index';

export const allInstagramFeedPostsVariables = (props: InstagramFeedPostListProps) => {
  const {
    currentPage,
    filter: { keyword, period },
    sort: { field, order },
    accountId
  } = props;

  return {
    accountId,
    offset: getOffset(currentPage),
    limit: LIMIT,
    keyword,
    orderBy: { field, order },
    startDate: period.startDate,
    endDate: period.endDate
  };
};
