import { css } from '@emotion/core';

const flex = css`
  flex: 1;
`;
const marginRight = css`
  margin-right: 16px;
`;
const marginRightIcon = css`
  i {
    margin-right: 4px;
  }
`;
const marginRightLabel = css`
  label {
    margin-right: 16px;
  }
`;
const marginRightDiv = css`
  div {
    margin-right: 16px;
  }
`;
const noLeftpadding = css`
  div {
    padding-left: 0;
  }
`;
const noMarginTop = css`
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
`;
const paddingSides24 = css`
  padding: 0 24px;
`;
const marginLeft = css`
  margin-left: 16px;
`;
const marginBottom = css`
  margin-bottom: 16px;
`;
const flexEnd = css`
  align-self: flex-end;
`;
const paddingBottom16 = css`
  padding-bottom: 16px;
`;
const noMarginBottom = css`
  margin-bottom: 0;
`;
const width400 = css`
  width: 400px;
`;
const fullHeight = css`
  height: 100%;
`;
const setWidth = (width: string) =>
  css`
    width: ${width};
  `;
const setFlex = (f: number) => css`
  flex: ${f};
`;

export default {
  flex,
  fullHeight,
  marginRight,
  marginRightIcon,
  marginRightLabel,
  marginRightDiv,
  noLeftpadding,
  noMarginTop,
  paddingSides24,
  marginLeft,
  marginBottom,
  flexEnd,
  paddingBottom16,
  noMarginBottom,
  width400,
  setWidth,
  setFlex
};
