import React from 'react';
import { useQuery } from '@apollo/client';
import { getOffset, getPageInfo } from '../../../../libs/paging';
import { SortActionType } from '../../../../libs/filter';
import TaggedPostList from '../TaggedPostList/TaggedPostList';
import ListIndicator from '../../../molecules/ListIndicator';
import { useQueryHelper } from '../../../../libs/hooks';
import { LIMIT } from '../../../../libs/paging/constant';
import { AnalyticsPostFilterType } from '../types';
import { InstagramFeedPostSortField } from '../../../../__generated__/globalTypes';
import {
  AllInstagramMentionedPosts,
  AllInstagramMentionedPostsVariables
} from './__generated__/AllInstagramMentionedPosts';
import * as ALL_INSTAGRAM_MENTIONED_POSTS from './AllInstagramMentionedPosts.graphql';

export interface MentionedPostListProps extends SortActionType<InstagramFeedPostSortField> {
  currentPage: number;
  filter: AnalyticsPostFilterType;
  id: number;
}

const MentionedPostListComponent = (props: MentionedPostListProps) => {
  const {
    id,
    currentPage,
    sort,
    setSort,
    filter: { keyword, period }
  } = props;
  const { t, history, enqueueSnackbar } = useQueryHelper();

  const { data, loading, error } = useQuery<AllInstagramMentionedPosts, AllInstagramMentionedPostsVariables>(
    ALL_INSTAGRAM_MENTIONED_POSTS,
    {
      variables: {
        accountId: id,
        offset: getOffset(currentPage),
        limit: LIMIT,
        keyword,
        orderBy: sort,
        startDate: period.startDate,
        endDate: period.endDate
      },
      fetchPolicy: 'cache-and-network',
      skip: !id
    }
  );

  if (loading) {
    return <ListIndicator />;
  }

  if (error) {
    history.push(`/analytics/dashboard/${id}/interaction`);
    enqueueSnackbar(t(error.message), { variant: 'error' });
  }

  const pageInfo = getPageInfo(currentPage, data);
  const mentionedPosts = data?.allInstagramMentionedPosts || [];

  // UI of mentioned and tagged posts is same
  return (
    <TaggedPostList
      sort={sort}
      setSort={setSort}
      pageInfo={pageInfo}
      taggedPosts={mentionedPosts}
      type="mentionedPost"
    />
  );
};

export default MentionedPostListComponent;
