import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { defaultInfluencerAvatar } from '../../../../libs/image';
import { SliderComponents, SliderCss, StyledComponents } from '../../../molecules/SliderTable';
import * as List from '../../../atoms/List';
import Dialog from '../../../molecules/Dialog';
import CarouselModal from '../../../molecules/CarouselPopup';
import AnayticsFeedPostDetail from '../../AnalyticsPost/AnalyticsFeedPostDetail';
import { usePostDetailModal } from '../../AnalyticsShared/BasePostList/usePostDetailModal';
import CompareFeedPostDetail from '../../AnalyticsPost/CompareFeedPostDetail';
import AnalyticsContentWrapper, { ContentType } from '../../AnalyticsShared/AnalyticsContentWrapper';
import * as DashboardStyledComponents from '../../AnalyticsDashboard/StyledComponents';
import { Tooltip } from '../../../atoms/Tooltip';
import CustomCss from './CustomCss';
import HashtagDialogContent from './HashtagDialogContent';
import PostingHabitsDialogContent from './PostingHabitsDialogContent';
import { generateTimeslots } from './helpers';
import { ComparePostType } from './types';

interface TemplateProps {
  id: number;
  accountsWithAvatar: ComparePostType[];
}
const Template = ({ accountsWithAvatar, id }: TemplateProps) => {
  const [dialog, setdialog] = useState<{
    showHashtagsDialog: boolean;
    showPostingHabitsDialog: boolean;
    data: ComparePostType | null;
  }>({ showHashtagsDialog: false, showPostingHabitsDialog: false, data: null });

  const [clickedThumbnailInfo, setClickedThumbnailInfo] = useState<{ accountId: number; postId: number } | null>(null);

  const allFeedPostsOfClickedAccount =
    accountsWithAvatar.find(acc => acc.id === clickedThumbnailInfo?.accountId)?.feedPosts || [];

  const {
    openPostDetail,
    siblingPostId,
    handleClosePostDetail,
    handleClickPost,
    handleClickNext,
    handleClickPrev
  } = usePostDetailModal(allFeedPostsOfClickedAccount);

  const { t } = useTranslation();

  const dialogTitle = dialog.showPostingHabitsDialog ? 'PostingHabits' : 'HashTag';

  const handleCloseDialog = () => {
    setdialog({ showHashtagsDialog: false, showPostingHabitsDialog: false, data: null });
  };

  const handleClickPostingHabitDetail = (item: ComparePostType) => {
    setdialog({ ...dialog, showPostingHabitsDialog: true, data: item });
  };

  const handleClickPopularHashtagDetail = (item: ComparePostType) => {
    setdialog({ ...dialog, showHashtagsDialog: true, data: item });
  };

  const handlePostThumbnailClick = (accountId: number, postId: number) => {
    setClickedThumbnailInfo({ accountId, postId });
  };

  const handleCloseModal = () => {
    handleClosePostDetail();
    setClickedThumbnailInfo(null);
  };

  useEffect(() => {
    if (clickedThumbnailInfo) {
      handleClickPost(clickedThumbnailInfo.postId);
    }
  }, [clickedThumbnailInfo?.accountId, clickedThumbnailInfo?.postId]);

  return (
    <>
      <AnalyticsContentWrapper
        contentType={ContentType.Posts}
        tab="compare"
        css={css`
          & > div {
            border-bottom: none;
          }
        `}
      >
        <SliderComponents.Wrapper css={[SliderCss.clearPadding, CustomCss.clearMargin, CustomCss.clearLeftRightBorder]}>
          <SliderComponents.Container>
            <SliderComponents.SliderSection>
              <SliderComponents.SliderTable css={CustomCss.width100}>
                <thead>
                  <SliderComponents.HeaderRow css={SliderCss.bgndColor}>
                    <StyledComponents.StyledHeaderColumn
                      title="Account"
                      css={[
                        CustomCss.width160,
                        css`
                          justify-content: flex-start;

                          & > div {
                            justify-content: flex-start;
                            border-top: none;
                          }
                        `
                      ]}
                    />
                    {/* we are imitating header row bcs we have to place posts-slider in full width */}
                    <HeaderImitation>
                      <HeaderTitle
                        css={css`
                          flex: 1;
                        `}
                      >
                        <Tooltip help="compareSentimentAnalysis" placement="top" iconMargin="6px" />
                        {t('HeaderColumn.Sentiment Analysis')}
                      </HeaderTitle>
                      <HeaderTitle
                        css={css`
                          flex: 1.2;
                        `}
                      >
                        <Tooltip help="comparePostingHabits" placement="top" iconMargin="6px" />
                        {t('HeaderColumn.Posting Habits')}
                      </HeaderTitle>
                      <HeaderTitle
                        css={css`
                          flex: 1;
                        `}
                      >
                        <Tooltip help="comparePopularHashtags" placement="top" iconMargin="6px" />
                        {t('HeaderColumn.Popular Hashtags')}
                      </HeaderTitle>
                    </HeaderImitation>
                  </SliderComponents.HeaderRow>
                </thead>

                <tbody>
                  {accountsWithAvatar.map((item, index) => {
                    const timeslots = generateTimeslots(item.postsHabit);

                    return (
                      <StyledComponents.StyledRowNew css={[CustomCss.padding16]} key={index}>
                        <ThumbnailCell
                          title={item.name}
                          src={defaultInfluencerAvatar(item.avatar)}
                          imageWidth="42px"
                          titleWidth="100%"
                        />
                        <SectionWrapper>
                          {/* top part */}
                          <TopPostSection>
                            <List.SentimentAnalysisColumn
                              positive={item.sentiment?.positiveRatio || 0}
                              neutral={item.sentiment?.neutralRatio || 0}
                              negative={item.sentiment?.negativeRatio || 0}
                              css={css`
                                flex: 1;
                                border-right: none !important;
                                border-top: 1px solid #dee5ec;
                                padding-right: 0;

                                & > section {
                                  padding-right: 16px;
                                  border-right: 1px solid #dee5ec;
                                }
                              `}
                              isTable={false}
                            />
                            <List.PostingHabitsColumn
                              timeslots={timeslots}
                              onClickAdd={() => handleClickPostingHabitDetail(item)}
                              css={css`
                                flex: 1.2;
                                border-right: none !important;
                                border-top: 1px solid #dee5ec;
                                padding-right: 0;

                                & > div {
                                  height: 100%;
                                  padding-right: 16px;
                                  border-right: 1px solid #dee5ec;
                                }
                              `}
                              isTable={false}
                            />
                            <List.PopularHashtagsColumn
                              hashtags={item?.popularHashtags?.map(ht => ht.hashtag) || []}
                              onClickAdd={() => handleClickPopularHashtagDetail(item)}
                              css={[
                                SliderCss.paddingLeft,
                                css`
                                  flex: 1;
                                  border-top: 1px solid #dee5ec;
                                  padding-right: 0;

                                  & > div {
                                    padding-right: 16px;
                                  }
                                `
                              ]}
                              isTable={false}
                            />
                          </TopPostSection>
                          <StyledFeedPostsColumn
                            data={item.feedPosts || []}
                            onClick={postId => handlePostThumbnailClick(item.id, postId)}
                            isTable={false}
                          />
                        </SectionWrapper>
                      </StyledComponents.StyledRowNew>
                    );
                  })}
                  <StyledComponents.StyledRowNew css={[CustomCss.rightAlignedCol, CustomCss.heightAuto]}>
                    <List.TextColumn data="" />

                    <List.TextColumn
                      data={
                        <DashboardStyledComponents.MoreText
                          to={`/analytics/dashboard/${id}/instagram_compare_feed_post`}
                          css={CustomCss.marginRight8}
                        >
                          <span>{t('Button.More')}</span>{' '}
                          <DashboardStyledComponents.Arrow>{'\u2192'}</DashboardStyledComponents.Arrow>
                        </DashboardStyledComponents.MoreText>
                      }
                    />
                  </StyledComponents.StyledRowNew>
                </tbody>
              </SliderComponents.SliderTable>
            </SliderComponents.SliderSection>
          </SliderComponents.Container>
        </SliderComponents.Wrapper>
      </AnalyticsContentWrapper>
      <Dialog
        open={!!dialog.showHashtagsDialog || !!dialog.showPostingHabitsDialog}
        titleIcon={<Avatar src={dialog?.data?.avatar} />}
        title={`${t(`Dialog.${dialogTitle}`, { name: dialog?.data?.name })}`}
        cancel="Close"
        onClose={handleCloseDialog}
        maxWidth="md"
      >
        <DialogContentWrapper>
          {dialog.showPostingHabitsDialog && dialog.data?.postsHabit && (
            <PostingHabitsDialogContent postsHabit={dialog.data.postsHabit} />
          )}
          {dialog.showHashtagsDialog && dialog.data?.popularHashtags && (
            <HashtagDialogContent hashtagsData={dialog.data.popularHashtags} />
          )}
        </DialogContentWrapper>
      </Dialog>
      <CarouselModal
        open={!!openPostDetail}
        title=""
        cancel="OK"
        onClose={handleCloseModal}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrev}
        siblingPostId={siblingPostId}
      >
        {clickedThumbnailInfo?.accountId === id ? (
          <AnayticsFeedPostDetail closeModal={handleCloseModal} hasViewColumn={true} />
        ) : (
          <CompareFeedPostDetail closeModal={handleClosePostDetail} />
        )}
      </CarouselModal>
    </>
  );
};

const HeaderImitation = styled.th`
  line-height: 46px;
  display: flex;
`;
const HeaderTitle = styled.h3`
  display: flex;
  align-items: center;
  color: #27313b;
  font-size: 11px;
  font-weight: 600;
  line-height: 48px;
  padding-left: 16px;
`;
const ThumbnailCell = styled(List.ThumbnailColumn)<{ titleWidth?: string }>`
  border-right: 1px solid #dee5ec;

  & div > div:first-of-type {
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }

  p {
    overflow: hidden;
    width: ${({ titleWidth }) => titleWidth};
    max-height: 33px;
    text-overflow: ellipsis;
    min-width: unset;
  }
`;
const DialogContentWrapper = styled.div`
  min-width: 440px;
`;
const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
`;
const TopPostSection = styled.div`
  display: flex;
  min-height: 90px;
`;
const SectionWrapper = styled.td`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 !important;
  border-top: none !important;
`;
const StyledFeedPostsColumn = styled(List.FeedPostsColumn)`
  min-height: 60px;
  display: flex;
  place-items: center;
  border-top: 1px solid #dee5ec;
  padding-right: 0;
`;

export default Template;
